import React from "react";
import Header from "../../../components/Header";

import { FormattedMessage } from "react-intl";
import {PATH} from "../../../routes/routes";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
function AiInsights(props) {
    const { providerId } = useParams();
    const navigateTo = useNavigate();

    const insights = [
        "Productivity is high during mornings and decreases in the afternoons.",
        "Employees in Cora Cluj-Napoca location tend to have more overtime hours.",
        "Manual clock-ins are significantly lower than NFC clock-ins for most employees.",
        "Wednesday has the highest average hours worked by employees.",
        "Employees with more manual clock-ins tend to have higher overtime hours.",
        "There is a correlation between NFC clock-ins and lower instances of overtime.",
        "Employees working in GEBS Cluj-Napoca location have the lowest average overtime hours.",
        "Cora Cluj-Napoca location has the highest total NFC clock-ins.",

    ];

    const summaryStatistics = [
        { label: "Total Overtime Hours", value: "120 hours", icon: "\u23F0" },
        { label: "Total Manual Clock-ins", value: "200", icon: "\u270D" },
        { label: "Total NFC Clock-ins", value: "500", icon: "\uD83D\uDCF1" },


    ];

    const toStatisticsPage = () => {
        navigateTo(PATH.COMPANIES_STATISTICS(props.companyId));
    }



    return (
        <div className="content">
            <Header
                companyId={providerId}
                hasBackBtn={true}
                headerTitle={<FormattedMessage id="AiInsights" />}
            />
            <div className="inner-content">
                <div className="empty-div" style={{ height: "25px" }}></div>
                <section className="insights-container">
                    <h1>Insights for this month</h1>
                    <ul className="insights-list">
                        {insights.map((insight, index) => (
                            <li key={index} className="insight-item row">
                                <span className="insight-icon">&#2022;</span>
                                <span className="insight-text"><h3>{insight}</h3></span><br/><br/>
                            </li>
                        ))}
                    </ul>
                </section>
                <section className="statistics-summary">
                    <h1>Statistics Summary</h1>
                    <ul className="statistics-list">
                        {summaryStatistics.map((statistic, index) => (
                            <li key={index} className="statistics-item row">
                               <h3>
                                   <span className="statistics-icon">{statistic.icon}</span>
                                   <span className="statistics-text">{statistic.label}:</span>
                                   <strong><span className="statistics-text">{statistic.value}</span></strong></h3>

                            </li>
                        ))}
                    </ul>
                    <div onClick={() => toStatisticsPage()}className="link">See the statistics</div>
                </section>
            </div>
        </div>
    );
}

export default AiInsights;
