import * as CompaniesService from "../../services/companiesServices/CompaniesService";
import * as ClientConfig from "../../config/ClientConfig";
import * as BeaconService from "../../services/companiesServices/CompaniesNFCsService";
import {deleteCompanyById} from "../../services/companiesServices/CompaniesService";

export const getAllCompaniesHandler = async () => {
    let error = null;
    const response = await CompaniesService.getAllCompanies();
    let allCompanies = null;

    if (response.ok) {
        allCompanies = response.data.map(e => {
            return {
                id: e.id,
                name: e.name,
                address: e.address,
                picture: ClientConfig.URL_RESOURCE_SERVER + "/api/files/" + e.picture
            }
        });

    } else {
        error ="Error getAllServiceProvidersHandler";
    }

    return {
        data: {
            allFacilities: allCompanies
        }
    };
}



export const deleteCompanyHandler = async (id) => {
    const response = await CompaniesService.deleteCompanyById(id);
    let error = null;
    if (response.ok) {

    } else {
        error ="Error deleteFacilityHandler";
    }

    return {
        data: {
            error: error
        }
    };
}

