import React, {useEffect, useState, useRef, useContext} from "react";
import {FormattedDate, FormattedMessage} from "react-intl";
import { useParams} from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import Header from "../../components/Header";
import Spinner from "../../components/Spinner";
import CalendarTmz from "../../components/CalendarTmz";


import {
    getAllLocationsAndRolesForCompanyHandler
} from "../../handlers/companiesHandlers/employeesHandlers/HandlerEmployeesDetails";
import Select from "react-select";
import * as StorageServices from "../../services/StorageServices";
import {LOCALSTORAGE_ITEMS, REPORT_TYPES, TYPES, TYPES as TYPE} from "../../util/Constants";
import * as StorageService from "../../services/StorageServices";
import {
    getTotalDaysInMonth,
    getDateObjectTmz, getWeekends,
    resetTime, roundToHoursAndMinutes, selectStyles, getUserAgent
} from "../../util/common";
import {
    editHourlyReportsHandler,
    editReportsIntervalHandler,
    getAllEditedHourlyReportsHandler,
    getAllEditedReportsIntervalHandler,
    getAllHourlyReportsHandler,
    getAllReportsIntervalsHandler
} from "../../handlers/reportsHandlers/HandlerReportsAll";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    generateFromDayToDayReportHandler,
    generateReportHandler,
    generateReportWithIntervalsHandler
} from "../../handlers/reportsHandlers/HandlerReportsGenerator";
import HourlyReport from "./HourlyReport";
import ClockingsReport from "./ClockingsReport";
import ClockingsReportEditable from "./ClockingsReportEditable";
import {closeIcon, downArrowIcon} from "../../components/Icons";
import {LocaleContext} from "../../util/LocaleContext";
import analyticsLogEvent from "../../services/FirebaseService";


function ReportsAll(props) {
    const {companyId} = useParams();
    const type = StorageServices.getItem(LOCALSTORAGE_ITEMS.TYPE);
    const today = resetTime(new Date());
    const localeContext = useContext(LocaleContext);
    const [state, setState] = useState({
        loading: true,
        allHourlyReports: [],
        allEditedHourlyReports: [],
        allClockingsReports: [],
        allEditedClockingsReports: [],
        calendarIsVisible: false,
        intervalCalendarVisible:false,
        allLocations: [],
        allRoles: [],
        selectedLocation: {
            label: <FormattedMessage id="All"/>,
            value: null
        },
        selectedRole: {
            label: <FormattedMessage id="All"/>,
            value: null
        },
        selectedDate: today,
        reportTypes: type === "MANAGER"?[REPORT_TYPES.HOURLY_REPORT, REPORT_TYPES.CLOCKING_REPORT]:[REPORT_TYPES.HOURLY_REPORT, REPORT_TYPES.CLOCKING_REPORT,REPORT_TYPES.CLOCKING_REPORT_EDITABLE],
        selectedReportType: REPORT_TYPES.HOURLY_REPORT,
        downloadReportPopupVisible: false,
        numOfDaysInMonth: 31,
        weekendDaysInMonth: getWeekends(today),
        selectedEmployee: null,
        selectedEmployeeLocation: null,
        selectedDay: null,

        editedHourlyReportMinutes: null,
        editedHourlyReportHours: null,

        editedClockingReportMinutesFrom: null,
        editedClockingReportHoursFrom: null,
        editedClockingReportMinutesTo: null,
        editedClockingReportHoursTo: null,

        hasErrors: false,
        errorMsg:null,
        hasSelectErrors: false,
        hasRoleSelectErrors: false,
        infoToolTipVisible: false,
    });


    const firstDayOfMonth = new Date(state.selectedDate.getFullYear(), state.selectedDate.getMonth(), 1);
    const lastDayOfMonth = new Date(state.selectedDate.getFullYear(), state.selectedDate.getMonth() + 1, 0);
    const minDate = new Date(firstDayOfMonth);
    const maxDate = new Date(lastDayOfMonth);

    let didInit = false;
    useEffect(() => {
        analyticsLogEvent("page_view", {
            page_title: "ReportsAll"
        });
        if(!didInit){
            didInit = true;
            getAllLocationsAndRolesForCompanyHandler(companyId).then(locationsAndRolesResponse => {
                // get user roles in company and filter 
                setState({
                    ...state,
                    allLocations: locationsAndRolesResponse.data.locations,
                    allRoles: locationsAndRolesResponse.data.roles
                });
            });
        }
    },[]);

    useEffect(() => {
        if(state.allLocations.length > 0){
            getPageContent(state.selectedReportType);
        }
    }, [state.allLocations, state.selectedReportType, state.selectedDate, state.selectedLocation, state.selectedRole]);

    useEffect(() => {
        if(state.allLocations.length > 0){
            setState({
                ...state,
                numOfDaysInMonth: getTotalDaysInMonth(state.selectedDate),
                weekendDaysInMonth: getWeekends(state.selectedDate)
            });
        }
    }, [state.selectedDate]);

    const getPageContent = (selectedReportType) => {
        const selectedLocationId = state.selectedLocation == null?null: state.selectedLocation.value;
        const selectedRoleId = state.selectedRole == null?null: state.selectedRole.value;
        const dtmz = getDateObjectTmz(state.selectedDate);

        if (selectedReportType === REPORT_TYPES.HOURLY_REPORT) {
            getAllHourlyReportsHandler(companyId, parseInt(dtmz.year), parseInt(dtmz.month)-1, selectedLocationId, selectedRoleId).then(hourlyReportsResponse => {
                getAllEditedHourlyReportsHandler(companyId, parseInt(dtmz.year), parseInt(dtmz.month)-1, selectedLocationId).then(editedHourlyReportsResponse => {
                     const callback = () => {
                        setState({
                            ...state,
                            loading: false,
                            numOfDaysInMonth: getTotalDaysInMonth(state.selectedDate),
                            weekendDaysInMonth: getWeekends(state.selectedDate),
                            allHourlyReports: hourlyReportsResponse.data.allReports,
                            allEditedHourlyReports: editedHourlyReportsResponse.data.allReports
                        })
                    }

                    if (type !== TYPES.SUPER_ADMIN) {
                        localeContext.setLocale(StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE), callback);
                    }else{
                        callback();
                    }

                })
            })
        } else if (selectedReportType === REPORT_TYPES.CLOCKING_REPORT) {
            getAllReportsIntervalsHandler(companyId, parseInt(dtmz.year), parseInt(dtmz.month)-1, selectedLocationId, selectedRoleId).then(allReportsIntervalsResponse => {
                const callback = () => {
                    setState({
                        ...state,
                        loading: false,
                        numOfDaysInMonth: getTotalDaysInMonth(state.selectedDate),
                        weekendDaysInMonth: getWeekends(state.selectedDate),
                        allClockingsReports: allReportsIntervalsResponse.data.allReports,
                    })
                }

                if (type !== TYPES.SUPER_ADMIN) {
                    localeContext.setLocale(StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE), callback);
                }else{
                    callback();
                }
            })
        } else if (selectedReportType === REPORT_TYPES.CLOCKING_REPORT_EDITABLE) {
            getAllReportsIntervalsHandler(companyId, parseInt(dtmz.year), parseInt(dtmz.month)-1, selectedLocationId, selectedRoleId).then(allReportsIntervalsResponse => {
                getAllEditedReportsIntervalHandler(companyId, parseInt(dtmz.year), parseInt(dtmz.month)-1, selectedLocationId).then(allEditedReportsIntervalsResponse => {
                    const callback = () => {
                        setState({
                            ...state,
                            loading: false,
                            numOfDaysInMonth: getTotalDaysInMonth(state.selectedDate),
                            weekendDaysInMonth: getWeekends(state.selectedDate),
                            allClockingsReports: allReportsIntervalsResponse.data.allReports,
                            allEditedClockingsReports: allEditedReportsIntervalsResponse.data.allReports,
                        })
                    }

                    if (type !== TYPES.SUPER_ADMIN) {
                        localeContext.setLocale(StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE), callback);
                    }else{
                        callback();
                    }

                })
            })
        }
    }

    const getAvailableLocations = () => {
        const locs = state.allLocations.map(loc => {
            return {
                label: loc.name,
                value: loc.id
            }
        });
        locs.unshift({
            label: <FormattedMessage id="All"/>,
            value: null
        });
        return locs;
    }

    const getAvailableRoles = () => {
        const roles = state.allRoles.map(role => {
            return {
                label: role.name,
                value: role.id
            }
        });
        roles.unshift({
            label: <FormattedMessage id="All"/>,
            value: null
        });
        return roles;
    }

    const editHourlyReport = (totalMinutes, totalHours) => {

        // TODO: calculate with functions from common
        let totalMillis = totalMinutes * 60000 + totalHours * 60 * 60000;

        const dtmz = getDateObjectTmz(state.selectedDate);

        const bodyHourlyReport = {
            "companyId": companyId,
            "employeeId": state.selectedEmployee,
            "locationId": state.selectedEmployeeLocation,
            "year": parseInt(dtmz.year),
            "month": parseInt(dtmz.month)-1,
            "day": state.selectedDay+1,
            "workedTime": totalMillis
        }

        editHourlyReportsHandler(bodyHourlyReport).then(response => {
            if (response.data.error == null) {
                getAllEditedHourlyReportsHandler(companyId, parseInt(dtmz.year), parseInt(dtmz.month)-1, state.selectedLocation == null?null:state.selectedLocation.value).then(editedHourlyReportsResponse => {
                    setState({
                        ...state,
                        loading: false,
                        allEditedHourlyReports: editedHourlyReportsResponse.data.allReports,
                    })
                })
            }
        })
    }

    const openDownloadReportPopup = () => {
        setState({
            ...state,
            downloadReportPopupVisible: true
        })
    }

    const closeDownloadReportPopup = () => {
        setState({
            ...state,
            downloadReportPopupVisible: false
        })
    }

    const openRangeCalendar = () => {
        setState({
            ...state,
            intervalCalendarVisible: true
        })
    }
    const downloadReport = (typeOfReport) => {
        const dtmz = getDateObjectTmz(state.selectedDate);
        const year = parseInt(dtmz.year);
        const month = parseInt(dtmz.month)-1;
        let fileName = "pontaj-mcc";
        const language = "ro";


        if (state.selectedLocation && state.selectedLocation.value !== null) {
            const locationName = state.allLocations.filter(location => location.id === state.selectedLocation.value).map(location => location.name).toString().toLowerCase().replace(/ /g, "-");
            fileName += "-"+locationName;
        }

        if (state.selectedRole && state.selectedRole.value !== null) {
            const roleName = state.allRoles.filter(role => role.id === state.selectedRole.value).map(role => role.name).toString().toLowerCase().replace(/ /g, "-");
            fileName += "-"+roleName;
        }

        fileName += "-"+year + "-" + (month + 1);

        if (state.selectedReportType === REPORT_TYPES.HOURLY_REPORT) {
            generateReportHandler(fileName, language, companyId, year, month, 
                state.selectedLocation ? state.selectedLocation.value : null, 
                state.selectedRole ? state.selectedRole.value : null).then(response => {
                if (response.data.error == null) {
                    var a = document.createElement('a');
                    a.href = response.data.report;
                    a.download = fileName + ".xlsx";
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();
                }
            });
            closeDownloadReportPopup();
        } else if (state.selectedReportType === REPORT_TYPES.CLOCKING_REPORT || state.selectedReportType === REPORT_TYPES.CLOCKING_REPORT_EDITABLE ) {
            if (state.selectedLocation === null || state.selectedLocation.value === null) {
                setState({
                    ...state,
                    hasSelectErrors: true,
                    downloadReportPopupVisible: false
                });
            } else {

                if (typeOfReport === "monthly") {                    
                    generateReportWithIntervalsHandler(fileName, language, companyId, year, month, 
                        state.selectedLocation ? state.selectedLocation.value : null,"","",
                    state.selectedReportType === REPORT_TYPES.CLOCKING_REPORT_EDITABLE ? true: false,
                    state.selectedRole ? state.selectedRole.value : null).then(response => {
                        if (response.data.error == null) {

                            var a = document.createElement('a');
                            a.href = response.data.report;
                            a.download = fileName + ".xlsx";
                            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                            a.click();
                            a.remove();
                        }
                    });
                    closeDownloadReportPopup();
                } else if (typeOfReport === "untilToday") {
                    generateReportWithIntervalsHandler(fileName, language,companyId, year, month, 
                        state.selectedLocation ? state.selectedLocation.value : null,1,getDateObjectTmz(today).day,
                    state.selectedReportType === REPORT_TYPES.CLOCKING_REPORT_EDITABLE ? true: false,
                    state.selectedRole ? state.selectedRole.value : null).then(response => {
                        if (response.data.error == null) {
                            var a = document.createElement('a');
                            a.href = response.data.report;
                            a.download = fileName + ".xlsx";
                            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                            a.click();
                            a.remove();
                        }
                    });
                    closeDownloadReportPopup();
                }
            }
        }
    }


    const handleOnChangeSelect = (e, selectType) => {
        if (selectType === "location") {
            setState({
                ...state,
                loading:true,
                selectedLocation: e !== null ? e : null,
                hasSelectErrors: false,
                downloadReportPopupVisible: false
            });
        } else if (selectType === "report") {
            setState({
                ...state,
                errorMsg: null,
                selectedReportType: e !== null ? e.value : null,
                downloadReportPopupVisible: false
            });
        }else if (selectType === "role") {
            setState({
                ...state,
                selectedRole: e !== null ? e : null,
                hasRoleSelectErrors: false
            });
        }
    }


    const openCalendar = () => {
        setState({
            ...state,
            calendarIsVisible: true
        });
    }

    const closeCalendar = () => {
        setState({
            ...state,
            calendarIsVisible: false
        });
    }

    const closeRangeCalendar = () => {
        setState({
            ...state,
            intervalCalendarVisible: false,
            downloadReportPopupVisible:false
        });
    }

    const handleCalendarDatePick = (e) => {
            setState({
                    ...state,
                   loading:true,
                   selectedDate: e,
                   calendarIsVisible: false,
                   intervalCalendarVisible: false
                }
            );
    }

    const handleRangeCalendarDatePick = (value) => {

        const language = "ro";
        const dtmz1 = getDateObjectTmz(value[0]);
        const dtmz2 = getDateObjectTmz(value[1]);
        const year = parseInt(dtmz1.year);
        const month = parseInt(dtmz1.month)-1;
        const fromDay = parseInt(dtmz1.day);
        const toDay = parseInt(dtmz2.day);
        const fileName = "pontaj-mcc-" + year + "-" + (month + 1) + "-" + fromDay + "-" + toDay;
        generateFromDayToDayReportHandler(fileName, language,companyId, year, month,
            fromDay,toDay,
            state.selectedLocation ? state.selectedLocation.value : null,
            state.selectedRole ? state.selectedRole.value : null).then(response => {
            if (response.data.error == null) {
                var a = document.createElement('a');
                a.href = response.data.report;
                a.download = fileName + ".xlsx";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
                setState({
                    ...state,
                    intervalCalendarVisible: false,
                    downloadReportPopupVisible: false
                });
            }
        });
    }

    const filteredEditedClockingsReports = state.allClockingsReports.map(clockingReport=>{
        const mergedEmployeeClockingInfoList = {
            employeeId: clockingReport.employeeId,
            locationId: clockingReport.locationId,
            firstName: clockingReport.firstName,
            lastName: clockingReport.lastName,
            location: clockingReport.location,
            totalHours: 0,
            isEdited: false,
            daysClockingIntervals: []
        };

        const editedClockingsReports = state.allEditedClockingsReports.find(editedClockingReport=>
            clockingReport.employeeId === editedClockingReport.employeeId &&
            clockingReport.locationId === editedClockingReport.locationId
        )



        if (editedClockingsReports) {
            mergedEmployeeClockingInfoList.isEdited = true;
            editedClockingsReports.daysClockingIntervals.forEach((editedDayClockingIntervals,index) => {
                mergedEmployeeClockingInfoList.daysClockingIntervals[index] = [];
                if (editedDayClockingIntervals.length > 0 && editedDayClockingIntervals[0].from != -1 && editedDayClockingIntervals[0].to != -1) {
                    editedDayClockingIntervals.forEach(editedInterval => {
                        mergedEmployeeClockingInfoList.totalHours += editedInterval.workedTime
                        mergedEmployeeClockingInfoList.daysClockingIntervals[index].push({
                            workedTime: editedInterval.workedTime,
                            from: editedInterval.from,
                            to: editedInterval.to,
                            isEdited: true
                        });
                    });
                }else{
                  clockingReport.daysClockingIntervals[index]?.forEach((interval, index2) => {
                        mergedEmployeeClockingInfoList.totalHours += interval.workedTime
                        mergedEmployeeClockingInfoList.daysClockingIntervals[index].push({
                            workedTime:  interval.workedTime,
                            from: interval.from,
                            to:interval.to,
                            isEdited: false
                        });
                    })
                }
            });
            return mergedEmployeeClockingInfoList;
        }else{
            clockingReport.daysClockingIntervals.forEach((dayClockingIntervals, index)  => {
                mergedEmployeeClockingInfoList.daysClockingIntervals[index] = [];
                dayClockingIntervals.forEach(interval => {
                    mergedEmployeeClockingInfoList.totalHours += interval.workedTime
                    mergedEmployeeClockingInfoList.daysClockingIntervals[index].push({
                        workedTime:  interval.workedTime,
                        from: interval.from,
                        to:interval.to,
                        isEdited: false
                    });
                })
            });
            return mergedEmployeeClockingInfoList;
        }
    })

    const showEditableInputsForSelectedDay = (selectedEmployee, selectedDay, selectedEmployeeLocation) => {
        // if (!employeeCanBeEdited(selectedEmployee, true)) {
        //     return;
        // }
        if(type === TYPES.MANAGER){
            return;
        }

        const newState = {
            ...state,
            selectedEmployee: selectedEmployee,
            selectedEmployeeLocation: selectedEmployeeLocation,
            selectedDay: selectedDay,
            hasErrors: false,
        }

        setState(newState);

        if (state.selectedDay !== selectedDay|| state.selectedEmployee !== selectedEmployee)  {
            if (state.editedClockingReportHoursFrom === null &&
                state.editedClockingReportMinutesFrom === null &&
                state.editedClockingReportHoursTo === null &&
                state.editedClockingReportMinutesTo === null) {
                setState(newState);
                return;
            }
        }

        if (state.selectedDay !== selectedDay || state.selectedEmployee !== selectedEmployee) {
                const oldValues = filteredEditedClockingsReports.filter(e => e.employeeId == state.selectedEmployee && e.locationId == state.selectedEmployeeLocation)[0]?.daysClockingIntervals[state.selectedDay];

                if(oldValues && oldValues.length > 0){
                    const firstInt = oldValues[0];
                    const lastInt = oldValues[oldValues.length -1];
                    const firstHourMin = roundToHoursAndMinutes(firstInt.from);
                    const lastHourMin = roundToHoursAndMinutes(lastInt.to);

                    if(newState.editedClockingReportMinutesFrom == null){
                        newState.editedClockingReportMinutesFrom = firstHourMin.m;
                    }
                    if(newState.editedClockingReportHoursFrom == null){
                        newState.editedClockingReportHoursFrom = firstHourMin.h;
                    }
                    if(newState.editedClockingReportMinutesTo == null){
                        newState.editedClockingReportMinutesTo = lastHourMin.m;
                    }
                    if(newState.editedClockingReportHoursTo == null){
                        newState.editedClockingReportHoursTo = lastHourMin.h;
                    }
                    if(firstHourMin.m != parseInt(newState.editedClockingReportMinutesFrom) || firstHourMin.h != parseInt(newState.editedClockingReportHoursFrom) ||
                        lastHourMin.m != parseInt(newState.editedClockingReportMinutesTo) || lastHourMin.h != parseInt(newState.editedClockingReportHoursTo)
                    ){
                        newState.hasErrors = false;
                        setState(newState);
                        saveIntervalChanges(newState);
                    }
                }else{
                    setState(newState);
                    saveIntervalChanges(newState);

                }


        }
    }

    const saveIntervalChanges = (newState) => {
        if (state.selectedEmployee!==null) {

            if (state.editedClockingReportMinutesFrom > 59 ||
                state.editedClockingReportMinutesTo > 59 ||
                state.editedClockingReportHoursFrom > 24 ||
                state.editedClockingReportHoursTo > 24 ||
                state.editedClockingReportHoursTo < 0 ||
                state.editedClockingReportHoursFrom < 0 ||
                state.editedClockingReportHoursTo === "" ||
                state.editedClockingReportMinutesTo < 0 ||
                state.editedClockingReportMinutesFrom < 0 ||
                state.editedClockingReportMinutesTo === "" ||
                state.editedClockingReportHoursFrom == 24 && state.editedClockingReportMinutesFrom > 0 ||
                state.editedClockingReportHoursTo == 24 && state.editedClockingReportMinutesTo > 0) {
                  setState({
                      ...state,
                      hasErrors:true,
                      errorMsg: <FormattedMessage id="Error.clockInTimeMustBeInCorrectFormat" defaultMessage="Error.clockInTimeMustBeInCorrectFormat"/>
                  })
                return;
            }else if (newState.editedClockingReportHoursFrom > newState.editedClockingReportHoursTo && newState.editedClockingReportHoursTo !==null ||
                (newState.editedClockingReportHoursFrom === newState.editedClockingReportHoursTo &&
                    newState.editedClockingReportMinutesFrom > newState.editedClockingReportMinutesTo)) {
                setState({
                    ...state,
                    hasErrors:true,
                    errorMsg: <FormattedMessage id="Error.clockInTimeMustBeBeforeClockOutTime" defaultMessage="Error.clockInTimeMustBeBeforeClockOutTime"/>
                })
                return;
            }

            let totalMillisFrom = newState.editedClockingReportMinutesFrom * 60000 + newState.editedClockingReportHoursFrom * 60 * 60000;
            let totalMillisTo = newState.editedClockingReportHoursTo!==null && newState.editedClockingReportMinutesTo !==null? newState.editedClockingReportMinutesTo * 60000 + newState.editedClockingReportHoursTo * 60 * 60000 : -1;

            const dtmz = getDateObjectTmz(state.selectedDate);

            const bodyIntervalReport = {
                "companyId": companyId,
                "employeeId": state.selectedEmployee,
                "locationId": state.selectedEmployeeLocation,
                "year": parseInt(dtmz.year),
                "month": parseInt(dtmz.month)-1,
                "day": state.selectedDay + 1,
                "clockingIntervals": [{
                    "from": totalMillisFrom,
                    "to":totalMillisTo
                }]
            }

            editReportsIntervalHandler(bodyIntervalReport).then(response => {
                if (response.data.error == null) {
                    getAllEditedReportsIntervalHandler(companyId, parseInt(dtmz.year), parseInt(dtmz.month)-1, state.selectedLocation ? state.selectedLocation.value : null).then(editedClockingsReportsResponse => {

                        setState({
                            ...newState,
                            loading: false,
                            allEditedClockingsReports: editedClockingsReportsResponse.data.allReports,
                            editedClockingReportMinutesFrom: null,
                            editedClockingReportHoursFrom: null,
                            editedClockingReportMinutesTo: null,
                            editedClockingReportHoursTo: null,
                            hasErrors: false
                        })
                    })

                }
            })
        }
    }

    const handleOnChangeHourly = (e, type) => {
        if (type === "hours") {
            setState({
                ...state,
                editedHourlyReportHours: e.target.value,
                hasErrors: false
            })
        } else if (type === "minutes") {

            setState({
                ...state,
                editedHourlyReportMinutes: e.target.value,
                hasErrors: false
            })
        }
    }

    const saveIntervalsInState = (e, type) => {
        if (type === "hoursFrom") {
            setState({
                ...state,
                editedClockingReportHoursFrom: e.target.value,
                hasErrors: false
            })
        } else if (type === "minutesFrom") {

            setState({
                ...state,
                editedClockingReportMinutesFrom: e.target.value,
                hasErrors: false
            })
        }else if (type === "hoursTo") {
            setState({
                ...state,
                editedClockingReportHoursTo: e.target.value,
                hasErrors: false
            })
        }else if (type === "minutesTo") {
            setState({
                ...state,
                editedClockingReportMinutesTo: e.target.value,
                hasErrors: false
            })
        }
    }

    const handleHoursAndMinsChange = (e, type) => {
        if (state.editedHourlyReportHours === null &&
            state.editedHourlyReportMinutes === null &&
            (e.target.value === null || e.target.value === "")) {
            return;
        } else if (state.editedHourlyReportHours === null) {
            setState({
                ...state,
                editedHourlyReportHours: 0
            })
        } else if (state.editedHourlyReportMinutes === null || state.editedHourlyReportMinutes === "") {
            setState({
                ...state,
                editedHourlyReportMinutes: 0
            })
        } else if (state.editedHourlyReportMinutes > 59 || e.target.value < 0 || state.editedHourlyReportHours > 24 || state.editedHourlyReportHours < 0 || state.editedHourlyReportHours === "") {

            setState({
                ...state,
                hasErrors: true
            })
        } else if (e.target.value === "" || e.target.value === null) { //todo remove this if you want to paste last values when pressing tab/right-arrow
            setState({
                ...state,
                hasErrors: false
            })
            return;
        } else {
            editHourlyReport(state.editedHourlyReportMinutes, state.editedHourlyReportHours);
        }
    }




    const changeOrDeselectCell = (e, type) => {
        if (e.key === 'Enter') {
            const currentEl = e.target.closest("td");
            const closest = currentEl.nextSibling;
            closest.focus();
        }

        if (e.key === 'Escape') {
            deselectCell();
        }
        if (e.key === "ArrowLeft") {
            const currentEl = e.target.closest("td");
            const closest = currentEl.previousSibling;
            closest.focus();
        }

        if (e.key === "ArrowRight") {
            const currentEl = e.target.closest("td");
            const closest = currentEl.nextSibling;
            closest.focus();
        }

        if (e.key === "ArrowUp") {
            e.preventDefault();
            let currentTabIndex = e.target.closest("td").tabIndex - state.numOfDaysInMonth;
            let closest = document.querySelectorAll("[tabIndex='" + currentTabIndex + "']");

            if (closest.length > 0) {
                closest[0].focus();
            } else {
                return;
            }
        }

        if (e.key === "ArrowDown") {

            e.preventDefault();
            let currentTabIndex = e.target.closest("td").tabIndex + state.numOfDaysInMonth;
            let closest = document.querySelectorAll("[tabIndex='" + currentTabIndex + "']");
            if (closest.length > 0) {
                closest[0].focus();
            } else {
                return;
            }
        }
    }


    const getIndex = (trIndex, tdIndex) => {
        if (trIndex === 0) {
            return tdIndex;
        } else if (trIndex === 1) {
            return state.numOfDaysInMonth + tdIndex;
        } else {
            return state.numOfDaysInMonth * trIndex + tdIndex;
        }
    }

    const toggleInfoToolTip = () => {
        setState({
            ...state,
            infoToolTipVisible: !state.infoToolTipVisible
        })
    }


    const deselectCell = () => {
        setState({
            ...state,
            selectedEmployee: null,
            editedHourlyReportHours: null,
            editedHourlyReportMinutes: null,
            editedClockingReportMinutesFrom: null,
            editedClockingReportHoursFrom: null,
            editedClockingReportMinutesTo: null,
            editedClockingReportHoursTo: null,
            hasErrors:false
        })
    }


    return state.loading ?
        <Spinner/>
        : (
            <div className="content">
                <Header
                    companyId={companyId}
                    hasProfileBtn={true}
                    hasBackBtn={type != TYPE.MANAGER && type != TYPE.ADMIN ? true : false}
                    headerTitle={<FormattedMessage id="Reports"/>}
                />

                <div className="inner-content inner-content-for-table">
                    <div className="empty-div" style={{height: "25px"}}></div>
                    <section className="row top-actions-row">

                            <div className="form-group select-form-group ml-0">
                                <div className="row">
                                    <label className="label">
                                        <FormattedMessage id="Report"/>
                                    </label>

                                </div>
                                <div className="row">
                                    <FormattedMessage id="Report" defaultMessage="Report">
                                        {placeholder =>
                                            <Select
                                                tabIndex={-1}
                                                className="select"
                                                options={state.reportTypes.map(report => {
                                                    return {label: report, value: report}
                                                })}
                                                value={{
                                                    label: state.selectedReportType,
                                                    value: state.selectedReportType
                                                }}

                                                onChange={e => handleOnChangeSelect(e, "report")}
                                                placeholder={placeholder}
                                                noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                                id="report"
                                                styles={selectStyles}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                            />
                                        }
                                    </FormattedMessage>
                                </div>
                            </div>


                            <div className="form-group select-form-group">
                                <div className="row">
                                    <label className="label">
                                        <FormattedMessage id="Location"/>
                                    </label>

                                </div>
                                <div className="row select-row">
                                    <FormattedMessage id="All" defaultMessage="All">
                                        {placeholder =>
                                            <Select
                                                tabIndex={-1}
                                                className={state.hasSelectErrors ? "select with-errors" : "select"}
                                                options={getAvailableLocations()}
                                                onChange={e => handleOnChangeSelect(e, "location")}
                                                value={state.selectedLocation}
                                                placeholder={placeholder}
                                                noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                                id="location"
                                                styles={selectStyles}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                            />
                                        }
                                    </FormattedMessage>
                                    {state.hasSelectErrors && <div className="error-div-for-select">
                                        <FormattedMessage id="SelectOneLocationToDownloadReport"
                                                          defaultMessage="SelectOneLocationToDownloadReport"/>
                                    </div>}
                                </div>
                            </div>

                            <div className="form-group select-form-group">
                                <div className="row">
                                    <label className="label">
                                        <FormattedMessage id="Role"/>
                                    </label>

                                </div>
                                <div className="row select-row">
                                    <FormattedMessage id="All" defaultMessage="All">
                                        {placeholder =>
                                            <Select
                                                tabIndex={-1}
                                                className={state.hasRoleSelectErrors ? "select with-errors" : "select"}
                                                options={getAvailableRoles()}
                                                onChange={e => handleOnChangeSelect(e, "role")}
                                                value={state.selectedRole}
                                                placeholder={placeholder}
                                                noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                                id="role"
                                                styles={selectStyles}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                            />
                                        }
                                    </FormattedMessage>
                                    {state.hasRoleSelectErrors && <div className="error-div-for-select">
                                        <FormattedMessage id="SelectOneRoleLocationToDownloadReport"
                                                          defaultMessage="SelectOneRoleLocationToDownloadReport"/>
                                    </div>}
                                </div>
                            </div>    


                        <div className="form-group calendar-form-group">
                            <div className="row centered-x">
                                <label className="label">
                                    <FormattedMessage id="Month"/>
                                </label>
                            </div>
                            <div className="row">
                                <a onClick={openCalendar} className="link calendar-link">
                                    <FormattedDate
                                        value={state.selectedDate}
                                        month="short"
                                        year="numeric"
                                        timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                                        locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="row ml-auto buttons-with-margin">
                            <button tabIndex={-1} className="btn btn-success"
                                    onClick={() => openDownloadReportPopup()}
                            >
                                <FormattedMessage id="Download" defaultMessage="Download"/>{downArrowIcon}</button>
                        </div>
                        {state.downloadReportPopupVisible && <div className="report-picker-popup">
                            <div className="row" onClick={() => downloadReport("monthly")}>
                                <FormattedMessage id="MonthlyReport" defaultMessage="MonthlyReport"/>
                            </div>
                            {state.selectedReportType === REPORT_TYPES.HOURLY_REPORT && <div className="row" onClick={() => openRangeCalendar()}>
                                <FormattedMessage id="IntervalReport" defaultMessage="IntervalReport"/>
                            </div>}
                            {state.selectedReportType !== REPORT_TYPES.HOURLY_REPORT && getDateObjectTmz(today).month === getDateObjectTmz(state.selectedDate).month && <div className="row" onClick={() => downloadReport("untilToday")}>
                                <FormattedMessage id="ReportUntilToday" defaultMessage="ReportUntilToday"/>
                            </div>}
                            <div className="invisible-close-report-overlay" onClick={() => closeDownloadReportPopup()}></div>
                        </div>}

                    </section>

                    <ScrollContainer
                        hideScrollbars={false}
                        activationDistance={1}
                        ignoreElements={".prevent-scroll"}
                        className="scroll-container reports-table-scroll-container"
                    >
                        {state.selectedReportType === REPORT_TYPES.HOURLY_REPORT &&
                        <HourlyReport
                            allHourlyReports={state.allHourlyReports}
                            allEditedHourlyReports={state.allEditedHourlyReports}
                            numOfDaysInMonth={state.numOfDaysInMonth}
                            selectedEmployee={state.selectedEmployee}
                            selectedEmployeeLocation={state.selectedEmployeeLocation}
                            selectedDay={state.selectedDay}
                            weekendDaysInMonth={state.weekendDaysInMonth}
                            selectedLocation={state.selectedLocation?.value}
                            hasErrors={state.hasErrors}
                            handleKeyDown={changeOrDeselectCell}
                            handleOnChange={handleOnChangeHourly}
                            showEditableInputsForSelectedDay={showEditableInputsForSelectedDay}
                            handleHoursAndMinsChange={handleHoursAndMinsChange}
                            getIndex={getIndex}

                        />}
                        {state.selectedReportType === REPORT_TYPES.CLOCKING_REPORT &&
                        <ClockingsReport
                            allClockingsReports={state.allClockingsReports}
                            numOfDaysInMonth={state.numOfDaysInMonth}
                            selectedEmployee={state.selectedEmployee}
                            selectedDay={state.selectedDay}
                            selectedDate={state.selectedDate}
                            weekendDaysInMonth={state.weekendDaysInMonth}
                            selectedLocation={state.selectedLocation?.value}
                            hasErrors={state.hasErrors}
                            handleKeyDown={changeOrDeselectCell}
                            handleOnChange={handleOnChangeHourly}
                            showEditableInputsForSelectedDay={showEditableInputsForSelectedDay}
                            handleHoursAndMinsChange={handleHoursAndMinsChange}
                            getIndex={getIndex}
                        />}

                        {state.selectedReportType === REPORT_TYPES.CLOCKING_REPORT_EDITABLE &&
                        <ClockingsReportEditable
                            allClockingsReports={state.allClockingsReports}
                            allEditedClockingsReports={state.allEditedClockingsReports}
                            getFilteredEditedClockingsReports={filteredEditedClockingsReports}
                            numOfDaysInMonth={state.numOfDaysInMonth}
                            selectedEmployee={state.selectedEmployee}
                            selectedEmployeeLocation={state.selectedEmployeeLocation}
                            selectedDay={state.selectedDay}
                            selectedDate={state.selectedDate}
                            weekendDaysInMonth={state.weekendDaysInMonth}
                            selectedLocation={state.selectedLocation?.value}
                            hasErrors={state.hasErrors}
                            errorMsg={state.errorMsg}
                            handleKeyDown={changeOrDeselectCell}
                            saveIntervalsInState={saveIntervalsInState}
                            showEditableInputsForSelectedDay={showEditableInputsForSelectedDay}
                            getIndex={getIndex}
                            saveChanges={saveIntervalChanges}
                        />}

                        <div onClick={() => toggleInfoToolTip()} className="info-container">
                            <>
                                {state.infoToolTipVisible &&
                                <div className="tooltip">
                                    <div className="close-tooltip-btn"
                                         onContextMenu={(e) => e.preventDefault()}
                                         onClick={(e) => toggleInfoToolTip()}>
                                                 <span className="close-icon">
                                                     {closeIcon}
                                                 </span>
                                    </div>
                                    <div className="arrow-down"></div>
                                    <div className="row colour-one">
                                        <div className="col colour-col"></div>
                                        <div className="col text-col"><FormattedMessage
                                            id="EditedInReportByManagerAdmin"/></div>
                                    </div>
                                    <div className="row colour-two">
                                        <div className="col colour-col"></div>
                                        <div className="col text-col"><FormattedMessage
                                            id="RecordedTroughOurVerifiedApp"/></div>
                                    </div>
                                    {/*<div className="row colour-three">*/}
                                    {/*    <div className="col colour-col"></div>*/}
                                    {/*    <div className="col text-col"><FormattedMessage*/}
                                    {/*        id="RecordedManuallyByManagerAdmin"/></div>*/}
                                    {/*</div>*/}
                                </div>}
                                <div className="info-icon">?</div>
                            </>
                        </div>
                    </ScrollContainer>

                    {state.calendarIsVisible &&
                    <>
                        <div onClick={closeCalendar}
                             className={!state.calendarIsVisible ? "blurry-background hidden" : "blurry-background"}></div>
                        <div className="calendar-container">
                            <CalendarTmz
                                showNavigation={true}
                                locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                className={"custom-calendar"}
                                view="year"
                                onClickMonth={handleCalendarDatePick}
                                defaultValue={state.selectedDate}
                                tz={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                            />
                        </div>
                    </>
                    }

                    {state.intervalCalendarVisible &&
                        <>
                            <div onClick={closeRangeCalendar}
                                 className={!state.intervalCalendarVisible ? "blurry-background hidden" : "blurry-background"}></div>
                            <div className="calendar-container">
                                <CalendarTmz
                                    showNavigation={false}
                                    locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                    className={"custom-calendar"}
                                    selectRange={true}
                                    view="month"
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    onChange={handleRangeCalendarDatePick}
                                    activeStartDate={state.selectedDate}
                                    tz={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                                />
                            </div>
                        </>
                    }
                </div>

            </div>
        );
}

export default ReportsAll;
