import * as ClientConfig from "../../config/ClientConfig.js";
import * as StorageService from "../StorageServices.js";
import * as RestClient from "../../util/api/restClient"
import {LOCALSTORAGE_ITEMS} from "../../util/Constants";
import {handleAuthEmptyResponse, handleAuthResponse} from "../../util/api/restClient";


const getAllEmployeeLeaves =  async (companyId, locationsIds, rolesIds, from, to, createdFrom, createdTo, leaveStatus, page, size, sort) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/employee-leave"+"?companyId=" + companyId +
            (locationsIds ? "&locationsIds=" + locationsIds + "&" : "") +
            (rolesIds ? "rolesIds=" + rolesIds + "&" : "") +
            (from ? "from=" + from + "&" : "") +
            (to ? "to=" + to : "") +
            (createdFrom ? "createdFrom=" + createdFrom + "&" : "") +
            (createdTo ? "createdTo=" + createdTo + "&" : "") +
            (leaveStatus ? "leaveStatus=" + leaveStatus + "&" : "") +
            (page ? "page=" + page + "&" : "") +
            (size ? "size=" + size + "&" : "") +
            (sort ? "sort=" + sort + "&" : "");
        return fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            }
        });
    });
}


const getAllEmployeeLeavesCount =  async (companyId, from, to) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/employee-leave/count"+"?companyId=" + companyId +
            (from ? "&from=" + from + "&" : "") +
            (to ? "to=" + to : "") +
            ("&leaveStatuses=PENDING")+
            ("&leaveStatuses=APPROVED");
        return fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            }
        });
    });
}


const getEmployeeLeaveById =  async (leaveId) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/employee-leave/" + leaveId;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
        });
        return response;
    });
}

const createEmployeeLeave =  async (body) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/employee-leave/many";
        const response = fetch(finalURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
            body: JSON.stringify(body),
        });
        return response;
    });
}

const editEmployeeLeaveById =  async (leaveId, body) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/employee-leave/" + leaveId;
        const response = fetch(finalURL, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
            body: JSON.stringify(body)
        });
        return response;
    });
}


const getAllEmployeeLeaveTypes =  async (country) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/employee-leave/types"+
            (country ? "?country=" + country : "");
        return fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            }
        });
    });
}



const deleteEmployeeLeaveById =  async (id) => {
    return handleAuthEmptyResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/employee-leave/" + id;
        const response = fetch(finalURL, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
        });
        return response;
    });
}

export {
    getAllEmployeeLeavesCount,
    getAllEmployeeLeaves,
    createEmployeeLeave,
    getEmployeeLeaveById,
    getAllEmployeeLeaveTypes,
    editEmployeeLeaveById,
    deleteEmployeeLeaveById
}
