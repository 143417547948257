// src/styles/pdfStyles.js

import { StyleSheet, Font } from "@react-pdf/renderer";
import mavenProFont from "../../../assets/fonts/MavenPro.ttf";
import mavenProBoldFont from "../../../assets/fonts/MavenProBold.ttf";
import mavenProSemiBoldFont from "../../../assets/fonts/MavenProSemiBold.ttf";

// Register fonts
Font.register({
    family: "MavenPro",
    fonts: [
        { src: mavenProFont, fontWeight: "normal" },
        { src: mavenProSemiBoldFont, fontWeight: "semibold" },
        { src: mavenProBoldFont, fontWeight: "bold" }
    ]
});

// Export styles
export const styles = StyleSheet.create({
    page: {
        fontFamily: "MavenPro",
        flexDirection: 'column',
        padding: '50',
        backgroundColor: '#ffffff',
    },
    topBranding: {
        justifyContent: 'flex-start',
        marginBottom:50,
        flexDirection: 'row',
        alignItems:'center'
    },

    topBrandingText: {
        textAlign: 'left',
        marginLeft:5,
        fontWeight:'semibold',


    },
    header: {
        marginBottom: 50,
        textAlign: 'center',

    },
    section: {
        marginBottom: 15,
    },
    alineat: {
        textIndent: 10,
    },
    text: {

        fontSize: 13,
        marginBottom: 5,
    },
    semiBold: {
        fontWeight: 'semibold',
    },
    bold: {
        fontWeight: 'bold',
    },
    signatureWrapper: {
        marginTop: 60,
    },
    signatureSection: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    signatureColumn: {
        width: '45%',
        fontSize: 14,
        textAlign: 'left',
        paddingTop: 10,
    },
    underlined: {
        fontSize: 13,
        textDecoration:'underline',
        textDecorationStyle: 'dotted'
    },
    line: {
        marginBottom: 10,
    },
    footer: {
        marginTop:'auto',
        flexDirection:'row',

    },
    footerText: {
        textAlign:'right',
        marginLeft:'auto'
    }
});
