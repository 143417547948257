import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';

import * as StorageService from "../services/StorageServices";
import {LOCALSTORAGE_ITEMS, STATUSES, TYPES} from "../util/Constants";
import {getAllEmployeeLeavesByCompanyIdHandler} from "../handlers/vacationPlannerHandlers/HandlerVacationRequestsAll";

export const PendingLeavesContext = createContext();


export const PendingLeavesProvider = ({ children }) => {
    const type = StorageService.getItem(LOCALSTORAGE_ITEMS.TYPE) || "Unknown";

    const [pendingLeavesCount, setPendingLeavesCount] = useState(0);

    const fetchPendingLeaves = useCallback(() => {

        const companyInfo = StorageService.getItem(LOCALSTORAGE_ITEMS.COMPANY_INFO) ? JSON.parse(StorageService.getItem(LOCALSTORAGE_ITEMS.COMPANY_INFO)) : null;

        if (!companyInfo?.leaveEnabled) {
            return;
        }

        if (companyInfo!== null && companyInfo && companyInfo.id) {
            getAllEmployeeLeavesByCompanyIdHandler(companyInfo.id)
                .then(response => {

                    const allLeavesResponse = response.data.allCompanyAndEmployees.allEmployeesLeavesResponse;
                    const pendingLeaves = allLeavesResponse.content.filter(leaveRecord => leaveRecord.status === STATUSES.PENDING);
                    setPendingLeavesCount(pendingLeaves.length);
                })
                .catch(error => {
                    console.error("Failed to fetch leaves data:", error);
                });
        }
    }, []);

    useEffect(() => {

        fetchPendingLeaves();

        const intervalId = setInterval(fetchPendingLeaves, 10 * 60 * 1000); // Every 10 minutes

        return () => clearInterval(intervalId);
    }, [fetchPendingLeaves]);


     const updatePendingLeavesCount = useCallback(() => {
        fetchPendingLeaves();
    }, [fetchPendingLeaves]);
    return (
        <PendingLeavesContext.Provider value={{pendingLeavesCount, updatePendingLeavesCount }}>
            {children}
        </PendingLeavesContext.Provider>
    );
};
