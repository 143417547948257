import React, {useContext, useEffect, useRef, useState} from "react";
import {Image, Path, Svg, Text, View} from "@react-pdf/renderer";
import * as StorageService from "../../../services/StorageServices";
import {LOCALSTORAGE_ITEMS} from "../../../util/Constants";
import {styles} from "../styles/PdfStyles";

function CompanyLogo() {
    const companyInfo = StorageService.getItem(LOCALSTORAGE_ITEMS.COMPANY_INFO) ? JSON.parse(StorageService.getItem(LOCALSTORAGE_ITEMS.COMPANY_INFO)) : null;

    if (companyInfo?.picture) {
        return (
            <View style={styles.topBranding}>
                <Image style={{ width: 'auto', height: '40px', borderRadius: '8' }} src={companyInfo.picture} />
                <Text style={styles.topBrandingText}>{companyInfo?.name}</Text>
            </View>
        )
    }else{
        return (
            <View style={styles.topBranding}>
                <Text style={styles.topBrandingText}>
                    {companyInfo?.name}
                </Text>
            </View>
        )
    }
}

export default CompanyLogo;
