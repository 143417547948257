import * as ClientConfig from "../../config/ClientConfig.js";
import * as StorageService from "../StorageServices.js";
import * as RestClient from "../../util/api/restClient"
import {LOCALSTORAGE_ITEMS} from "../../util/Constants";
import {handleAuthEmptyResponse, handleAuthFileResponse, handleAuthResponse} from "../../util/api/restClient";


const getAllHourlyReports = async (companyId, year, month, locationId, roleId) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/reports/employeesclocking?" +
            (companyId ? "companyId=" + companyId + "&" : "") +
            (locationId ? "locationId=" + locationId + "&" : "") +
            (roleId ? "roleId=" + roleId + "&" : "") +
            "year=" + year + "&" +
            "month=" + month;


        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN),
            }
        });
        return response;
    });
}

const getAllEditedHourlyReports = async (companyId, year, month, locationId) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/reports/modifiedemployeesclocking?" +
            (companyId ? "companyId=" + companyId + "&" : "") +
            (locationId ? "locationId=" + locationId + "&" : "") +
            "year=" + year + "&" +
            "month=" + month;


        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN),
            }
        });
        return response;
    });
}


const editHourlyReports =  async (body) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/reports/modifiedemployeedayclocking";
        const response = fetch(finalURL, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
            body: JSON.stringify(body)
        });
        return response;
    });
}


const getAllReportsIntervals = async (companyId, year, month, locationId, roleId) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/reports/employeesclockingintervals?" +
            (companyId ? "companyId=" + companyId + "&" : "") +
            (locationId ? "locationId=" + locationId + "&" : "") +
            (roleId ? "roleId=" + roleId + "&" : "") +
            "year=" + year + "&" +
            "month=" + month;


        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN),
            }
        });
        return response;
    });
}


const getAllEditedReportsIntervals = async (companyId, year, month, locationId) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/reports/modifiedemployeesclockingintervals?" +
            (companyId ? "companyId=" + companyId + "&" : "") +
            (locationId ? "locationId=" + locationId + "&" : "") +
            "year=" + year + "&" +
            "month=" + month;


        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN),
            }
        });
        return response;
    });
}


const editReportsIntervals =  async (body) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/reports/modifiedemployeesclockingintervals";
        const response = fetch(finalURL, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
            body: JSON.stringify(body)
        });
        return response;
    });
}



const generateReport =  async (fileName, language, companyId, year, month, locationId, roleId) => {
    return handleAuthFileResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/reports/employeesclocking/"+fileName+".xlsx"+"?" +
            (companyId ? "companyId=" + companyId + "&" : "") +
            (locationId ? "locationId=" + locationId + "&" : "") +
            (roleId ? "roleId=" + roleId + "&" : "") +
            "language=" + language + "&" +
            "year=" + year + "&" +
            "month=" + month;

        const response =  fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            }
        });
        return response;
    });
}

const generateFromDayToDayReport =  async (fileName, language, companyId, year, month, fromDay, toDay, locationId, roleId) => {
    return handleAuthFileResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/reports/employeesclockingdays/"+fileName+".xlsx"+"?" +
            (companyId ? "companyId=" + companyId + "&" : "") +
            (locationId ? "locationId=" + locationId + "&" : "") +
            (roleId ? "roleId=" + roleId + "&" : "") +
            "language=" + language + "&" +
            "year=" + year + "&" +
            "month=" + month+ "&" +
            "fromDay=" + fromDay + "&" +
            "toDay=" + toDay;

        const response =  fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            }
        });
        return response;
    });
}

const generateReportWithIntervals =  async (fileName, language, companyId, year, month, locationId, fromDay, toDay, edited, roleId) => {
    return handleAuthFileResponse(() => {

        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/reports/employeesclockingintervals/"+fileName+".xlsx"+"?" +
            (companyId ? "companyId=" + companyId + "&" : "") +
            (locationId ? "locationId=" + locationId + "&" : "") +
            (roleId ? "roleId=" + roleId + "&" : "") +
            "language=" + language + "&" +
            "year=" + year + "&" +
            "month=" + month+ "&"+
            "fromDay=" + fromDay + "&"+
            "toDay=" + toDay+"&"+
            "edited=" + edited;

        const response =  fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            }
        });
        return response;
    });
}

export {
    generateFromDayToDayReport,
    getAllHourlyReports,
    getAllEditedHourlyReports,
    editHourlyReports,
    getAllReportsIntervals,
    getAllEditedReportsIntervals,
    editReportsIntervals,
    generateReport,
    generateReportWithIntervals
}
