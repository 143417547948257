import React, { useContext } from "react";
import { AuthContext } from "../util/AuthContext";
import Login from "../views/authentication/Login";
import {useNavigate} from "react-router";

function PrivateComponent({ children, ...rest }) {
    const navigateTo = useNavigate();
    const { isLoggedIn } = useContext(AuthContext);

    if (isLoggedIn) {
        return children;
    }else{
        return <Login/>
    }

}

export default PrivateComponent;
