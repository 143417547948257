import * as ClientConfig from "../../config/ClientConfig.js";
import * as StorageService from "../StorageServices.js";
import * as RestClient from "../../util/api/restClient"
import {LOCALSTORAGE_ITEMS} from "../../util/Constants";
import {handleAuthEmptyResponse, handleAuthFileResponse, handleAuthResponse} from "../../util/api/restClient";

export const getReportedProblemByEmployeeId =  async (employeeId, from, to) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/reported-problem/employee/" + employeeId+
            (from?"?from="+from.toISOString()+"&" : "")+
            (to?"to="+to.toISOString() : "");
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
        });
        return response;
    });
}

export const getAllReportedProblemsByCompanyId =  async (companyId, from, to) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/reported-problem/company/" + companyId+
            (from?"?from="+from.toISOString()+"&" : "")+
            (to?"to="+to.toISOString() : "");
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
        });
        return response;
    });
}

