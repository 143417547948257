
import {
    deleteEmployeeLeaveById,
    editEmployeeLeaveById, getAllEmployeeLeavesCount,
    getEmployeeLeaveById
} from "../../services/vacationPlannerServices/LeavesService";




export const getEmployeeLeaveByIdHandler = async (leaveId) => {
    let error = null;
    const response = await getEmployeeLeaveById(leaveId);
    let vacationRequestResponse = null;


    if (response.ok) {
        vacationRequestResponse = response.data;
    } else {
        error ="Error getAllEmployeesByFacilityIdHandler";
    }
    return {
        data: {
            error: error,
            request: vacationRequestResponse
        }
    };

}




export const getAllEmployeeLeavesCountHandler = async (companyId, from, to) => {
    let error = null;
    const allEmployeeLeavesCountResponse = await getAllEmployeeLeavesCount(companyId, from, to);


    if (!allEmployeeLeavesCountResponse.ok) {
        error = "Error getAllEmployeeLeavesCountHandler";
    }
    return {
        data: {
            error: error,
            allEmployeeLeavesCountResponse: allEmployeeLeavesCountResponse.data
        }
    };
};



export const editEmployeeLeaveByIdHandler = async (leaveId, data) => {
    let error = null;
    const body = {
        "from": data.from,
        "to": data.to,
        "request": data.request,
        "response":data.response,
        "typeId": data.type,
        "status": data.status
    }
    const response = await editEmployeeLeaveById(leaveId, body);
    if (response.ok) {

    } else {
        error ="Error editEmployeeLeaveById";
    }
    return {
        data: {
            id: leaveId,
            error: response.data.error? {
                error:response.data.error,
                errorCode: response.data.errorCode,
                message: response.data.message
            }: null
        }
    };
}


export const deleteEmployeeLeaveByIdHandler = async (leaveId, data) => {
    let error = null;

    const response = await deleteEmployeeLeaveById(leaveId);
    if (response.ok) {

    } else {
        error ="Error editEmployeeLeaveById";
    }
    return {
        data: {
            error: error
        }
    };
}















