import React, {useEffect, useState, useRef, useContext} from "react";
import {FormattedDate, FormattedMessage} from "react-intl";

import 'react-calendar/dist/Calendar.css';
import {
    getTotalHoursAndMinsFromIntervalMillis,
    getTotalHoursAndMinsFromMillisWithLabels, getTotalHoursFromIntervalMillis, getTotalMinutesFromIntervalMillis,
} from "../../util/common";

import {calendarIcon, closeIcon} from "../../components/Icons";
import {alertIcon} from "../../components/Icons";
import * as StorageService from "../../services/StorageServices";
import {LOCALSTORAGE_ITEMS} from "../../util/Constants";

function ClockingsReportEditable(props) {
    const [state, setState] = useState({
        intervalPopupVisible: false,
        selectedDay: null,
        selectedEmployeeId: null,
        selectedLocationId: null,
    });

    const toggleIntervalPopupVisibility = (selectedEmployee, selectedLocation, index) => {
        setState({
            ...state,
            intervalPopupVisible: !state.intervalPopupVisible,
            selectedEmployeeId: selectedEmployee,
            selectedLocationId: selectedLocation,
            selectedDay: index
        })
    }



    return (
        <>
            <table className="table reports-table clocking-reports-table editable">

                {/*---------------------------------start thead------------------------*/}
                <thead>
                <tr>
                    <th className="name"><FormattedMessage id="Employee"/></th>
                    {
                        Array.from(Array(props.numOfDaysInMonth)).map((day, index) => (
                            <th key={index}>{index + 1}</th>
                        ))
                    }
                </tr>
                </thead>
                {/*---------------------------------end thead------------------------*/}



                {/*---------------------------------start tbody------------------------*/}
                <tbody>
                {props.getFilteredEditedClockingsReports.map((report, reportIndex) => (
                    <tr className={reportIndex} key={reportIndex}>
                        <td className="name">
                            {report.firstName + " " + report.lastName} {props.selectedLocation === null ? "(" + report.location + ")" : null}
                        </td>
                        {
                            Array.from(Array(props.numOfDaysInMonth)).map((day, index) => (
                                <td className={(props.weekendDaysInMonth.includes(index + 1) ? "weekend" : "") + (report.daysClockingIntervals[index] && report.daysClockingIntervals[index][0] && report.daysClockingIntervals[index][0].isEdited ? " edited" : "")}
                                    tabIndex={props.getIndex(reportIndex, index)+1} key={index}
                                    onKeyUp={() => props.showEditableInputsForSelectedDay(report.employeeId, index,  report.locationId)}
                                    onClick={() => props.showEditableInputsForSelectedDay(report.employeeId, index, report.locationId)}
                                >
                                    <span className="from">
                                        {
                                            getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[index]) !== null &&
                                            getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[index])?.from !== null ?
                                                getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[index]).from
                                                : "none "
                                        }
                                    </span>&nbsp;-&nbsp;
                                    <span className="to">
                                        {getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[index]) !== null &&
                                        getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[index])?.to !== null ?
                                            getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[index]).to
                                            : "none "
                                        }
                                    </span>

                                    {getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[index]) !== null &&
                                    getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[index])?.totalTime !== null &&
                                    <span className="total-hours">&nbsp;&nbsp;|&nbsp;&nbsp;
                                        {getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[index]).totalTime}
                                        {report.daysClockingIntervals[index].length > 1 &&
                                        <span onClick={() => toggleIntervalPopupVisibility(report.employeeId, report.locationId, index)}
                                              className="prevent-scroll">&nbsp;&nbsp;{calendarIcon}</span>
                                        }
                                    </span>}


                                    {/* START EDITABLE INPUTS*/}
                                    {
                                        props.selectedEmployee && props.selectedEmployee == report.employeeId && props.selectedEmployeeLocation === report.locationId && props.selectedDay === index &&
                                        <div
                                            className={props.hasErrors ? "row has-errors editable-time prevent-scroll" : "row editable-time prevent-scroll"}>
                                            {/*<span className="copy-value"></span>*/}
                                            <span className="from row">
                                                 <input
                                                     placeholder={
                                                         getTotalHoursFromIntervalMillis(report.daysClockingIntervals[index])?.from.h
                                                     }
                                                     autoFocus
                                                     tabIndex={props.getIndex(reportIndex, index)+1}
                                                     onChange={(e) => props.saveIntervalsInState(e, "hoursFrom")}
                                                     onKeyDown={(e) => props.handleKeyDown(e, "hoursFrom")}
                                                     type="number"
                                                     className="hours"/>
                                            <span className="middot">:</span>
                                            <input
                                                placeholder={getTotalHoursFromIntervalMillis(report.daysClockingIntervals[index])?.from.m}
                                                tabIndex={props.getIndex(reportIndex, index)+1}
                                                onChange={(e) => props.saveIntervalsInState(e, "minutesFrom")}
                                                onKeyDown={(e) => props.handleKeyDown(e, "minutesFrom")}
                                                type="number"
                                                className="minutes"/>
                                            </span>


                                            <span className="dash">-</span>
                                            <span className="to row">
                                                <input
                                                    placeholder={getTotalHoursFromIntervalMillis(report.daysClockingIntervals[index])?.to.h
                                                    }
                                                    tabIndex={props.getIndex(reportIndex, index)+1}
                                                    onChange={(e) => props.saveIntervalsInState(e, "hoursTo")}
                                                    onKeyDown={(e) => props.handleKeyDown(e, "hoursTo")}
                                                    type="number"
                                                    className="hours"/>
                                            <span className="middot">:</span>
                                            <input
                                                placeholder={getTotalHoursFromIntervalMillis(report.daysClockingIntervals[index])?.to.m
                                                }
                                                tabIndex={props.getIndex(reportIndex, index)+1}
                                                onChange={(e) => props.saveIntervalsInState(e, "minutesTo")}
                                                onKeyDown={(e) => props.handleKeyDown(e, "minutesTo")}
                                                type="number"
                                                className="minutes"/>
                                            </span>

                                            {props.hasErrors && <div className="tooltip cell-tooltip">
                                                <div className="arrow-up"></div>
                                                <div className="row colour-one">
                                                    <div className="col icon-col">{alertIcon}&nbsp;</div>
                                                    <div className="col text-col">
                                                        {props.errorMsg}
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    }
                                    {/*END EDITABLE INPUTS*/}


                                </td>
                            ))
                        }
                    </tr>
                ))}
                </tbody>
                {/*---------------------------------end tbody------------------------*/}
            </table>


            {state.intervalPopupVisible && <>
                <div
                    onClick={() => toggleIntervalPopupVisibility(null, null, null)}
                    className="blurry-background">
                </div>
                {props.allClockingsReports.filter(report => report.employeeId === state.selectedEmployeeId &&
                    report.locationId === state.selectedLocationId).map((report, reportIndex) => (
                    <div className="interval-popup-cont">
                        <div key={reportIndex}
                             className="intervals-popup">
                            <div className="row title-row">
                                <FormattedMessage id="ParamsShift"
                                                  values={{param: report.firstName + " " + report.lastName}}>
                                </FormattedMessage>
                                &nbsp;-&nbsp;
                                {state.selectedDay + 1}&nbsp;
                                {
                                    <FormattedDate
                                        value={props.selectedDate}
                                        month="long"
                                        timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                                        locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                    />
                                }.
                                <span
                                    onClick={() => toggleIntervalPopupVisibility(null, null, null)}
                                    className="ml-auto close-icon">
                                    {closeIcon}
                                </span>
                            </div>
                            <div className="mini-table-container">
                                <table className="mini-clocking-table">
                                    <thead>
                                    <tr>
                                        <th><FormattedMessage id="Nr" defaultMessage="Nr."/></th>
                                        <th><FormattedMessage id="Clockin" defaultMessage="Clockin"/></th>
                                        <th><FormattedMessage id="Clockout" defaultMessage="Clockout"/></th>
                                        <th><FormattedMessage id="WorkedHours" defaultMessage="WorkedHours"/></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {report.daysClockingIntervals[state.selectedDay].map((clocking, index) => (
                                        <tr key={index}>
                                            <td className="number-td">{report.daysClockingIntervals[state.selectedDay].indexOf(clocking) + 1}.</td>
                                            <td>{getTotalHoursAndMinsFromIntervalMillis([clocking]).from}</td>
                                            <td>{getTotalHoursAndMinsFromIntervalMillis([clocking]).to}</td>
                                            <td>{getTotalHoursAndMinsFromMillisWithLabels(clocking.workedTime)}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>

                            </div>
                            <div className="row total-row">
                                <FormattedMessage
                                    id="TotalWorkedHours"
                                    defaultMessage="TotalWorkedHours"/>
                                <span className="ml-auto">
                                    {
                                        getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[state.selectedDay]).totalTime
                                    }
                               </span>
                            </div>
                        </div>
                    </div>
                ))}
            </>
            }
        </>

    );
}

export default ClockingsReportEditable;
