import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import 'react-calendar/dist/Calendar.css';
import {
    createCompanyEmployeeHandler, editCompanyEmployeeByIdHandler, getAllLocationsAndRolesForCompanyHandler,
    getCompanyEmployeeByIdHandler, removeDeviceIdFromEmployeeHandler
} from "../../../handlers/companiesHandlers/employeesHandlers/HandlerEmployeesDetails";
import Select from "react-select";
import {LOCALSTORAGE_ITEMS, TYPES} from "../../../util/Constants";
import * as StorageServices from "../../../services/StorageServices";
import {getUserAgent, selectStyles} from "../../../util/common";
import Alert from "../../../components/Alert";
import analyticsLogEvent from "../../../services/FirebaseService";
import * as StorageService from "../../../services/StorageServices";


function EmployeeDetails(props) {

    const openedRightPanelStyleName = "open";
    const closedRightPanelStyleName = "closed";
    const type = StorageServices.getItem(LOCALSTORAGE_ITEMS.TYPE);
    const defaultFormFields = {
        firstName: "",
        lastName: "",
        phone: "",
        password: "",
        selectedClockingLocations: null,
        selectedRoles: null,
        selectedType: null,
        selectedManagedLocations: null,
        selectedManagedRoles: null,
        deviceId:null,

    };

    const [state, setState] = useState({
        rightPanelStyleName: closedRightPanelStyleName,
        errorMsg: null,
        clockingLocations: [],
        roles:[],
        type: [TYPES.ADMIN, TYPES.MANAGER, TYPES.WORKER],
        managedLocations:null,
        managedRoles:[],
        isDeleteDeviceIdAlertOpen:false,
        includeInReportsSwitchEnabled:true,
        ...defaultFormFields
    });

    useEffect(() => {
        analyticsLogEvent("page_view", {
            page_title: "EmployeeDetails"
        });
        getFormData();
    }, [props.employeeDetailsId]);



    const handleOnChange = (e, input) => {
        if (input === "userType") {
            setState({
                ...state,
                selectedType:  e !== null ? e.value : null,
                errorMsg: null,
            })
        }
        else if (input === "firstName") {
            setState({
                ...state,
                firstName: e.target.value,
                errorMsg: null,
            })

        } else if (input === "lastName") {
            setState({
                ...state,
                lastName: e.target.value,
                errorMsg: null,
            })
        } else if (input === "phone") {
            setState({
                ...state,
                phone: e.target.value,
                errorMsg: null,
            })

        } else if (input === "password") {
            setState({
                ...state,
                password: e.target.value,
                errorMsg: null,
            })

        } else if (input === "clockingLocations") {
            const selectedLocations = e == null?[]:e.map(l => l.value);


            setState({
                ...state,
                errorMsg: null,
                selectedClockingLocations: selectedLocations,
            })
        }
        else if (input === "managedLocations") {
            const eLocations = e == null?[]:e.map(managedLocation => managedLocation.value);

            setState({
                ...state,
                errorMsg: null,
                selectedManagedLocations: eLocations,
            })
        }
        else if (input === "roles") {
            setState({
                ...state,
                errorMsg: null,
                selectedRoles: e !== null ? e.map(role => {
                    return role?.value
                }) : []
            })
        }

        else if (input === "managedRoles") {
            setState({
                ...state,
                errorMsg: null,
                selectedManagedRoles: e !== null ? e.map(managedRole => {
                    return managedRole?.value
                }) : []
            })
        }

    }


    const getAllSelectedClockingLocationsSelectOptions = () => state.clockingLocations?.filter(e => state.selectedClockingLocations?.includes(e.id)).map(e => {
        return {
            label: e.name,
            value: e.id
        }
    });

    const getAllSelectedManagedLocationsSelectOptions = () => state.managedLocations?.filter(e => state.selectedManagedLocations?.includes(e.id)).map(e => {
        return {
            label: e.name,
            value: e.id
        }
    });

    const getAllRolesSelectOptions = () => state.roles?.filter(e => state.selectedRoles?.includes(e.id)).map(e => {
        return {
            label: e.name,
            value: e.id
        }
    });

    const getAllSelectedManagedRolesSelectOptions = () => state.managedRoles?.filter(e => state.selectedManagedRoles?.includes(e.id)).map(e => {
        return {
            label: e.name,
            value: e.id
        }
    });




    const selectAllManagedLocations = () => {
        setState({
            ...state,
            errorMsg: null,
            selectedManagedLocations: state.managedLocations?.map(managedLocation => managedLocation.id)
        })
    }

    const selectAllManagedRoles = () => {
        setState({
            ...state,
            errorMsg: null,
            selectedManagedRoles: state.managedRoles?.map(managedRole => managedRole.id)
        })
    }

    const selectAllClockingLocations = () => {
        setState({
            ...state,
            errorMsg: null,
            selectedClockingLocations: state.clockingLocations.map(clockingLocation => clockingLocation.id)
        })
    }

    const selectAllRoles = () => {
        setState({
            ...state,
            errorMsg: null,
            selectedRoles: state.roles.map(role => role.id)
        })
    }



    const getFormData = () => {

        getAllLocationsAndRolesForCompanyHandler(props.companyId).then(allLocationsAndRolesResponse => {
            if (props.employeeDetailsId === "") { //NEW EMPLOYEE
                setState({
                    ...state,
                    type: [TYPES.ADMIN, TYPES.MANAGER, TYPES.WORKER],
                    roles: allLocationsAndRolesResponse.data.roles,
                    managedRoles: allLocationsAndRolesResponse.data.roles,
                    clockingLocations: allLocationsAndRolesResponse.data.locations,
                    managedLocations: allLocationsAndRolesResponse.data.locations,
                    rightPanelStyleName: openedRightPanelStyleName,
                    includeInReportsSwitchEnabled: true,
                    errorMsg: null,
                    ...defaultFormFields
                })
            } else { //EDIT EMPLOYEE
                getCompanyEmployeeByIdHandler(props.employeeDetailsId).then(employeeResponse => {

                    setState({
                        ...state,
                        type: [TYPES.ADMIN, TYPES.MANAGER, TYPES.WORKER],
                        selectedType: employeeResponse.data.account.type,

                        firstName: employeeResponse.data.account.firstName,
                        lastName: employeeResponse.data.account.lastName,
                        phone: employeeResponse.data.account.phone,

                        roles: allLocationsAndRolesResponse.data.roles,
                        selectedRoles: employeeResponse.data.account.roles,

                        managedRoles: allLocationsAndRolesResponse.data.roles,
                        selectedManagedRoles: employeeResponse.data.account.managedRoles,

                        clockingLocations: allLocationsAndRolesResponse.data.locations,
                        selectedClockingLocations: employeeResponse.data.account.clockingLocations,


                        managedLocations: allLocationsAndRolesResponse.data.locations,
                        selectedManagedLocations: employeeResponse.data.account.managedLocations,

                        deviceId: employeeResponse.data.account.deviceId,
                        includeInReportsSwitchEnabled: employeeResponse.data.account.includeInReports,
                        isDeleteDeviceIdAlertOpen: false,
                        rightPanelStyleName: openedRightPanelStyleName,
                        errorMsg: null
                    })
                })
            }
        })

    }

    let bodyNewAccount;

    if (type === TYPES.MANAGER) {
        bodyNewAccount = {
            "companyId": props.companyId,
            "firstName": state.firstName,
            "lastName": state.lastName,
            "phone": state.phone,
            "password": state.password,
            "clockingLocations": state.selectedClockingLocations,
            "roles": state.selectedRoles,
            "type": "WORKER",
            "managedLocations":state.selectedManagedLocations,
            "managedRoles":state.selectedManagedRoles,
            "includeInReports": state.includeInReportsSwitchEnabled
        }
    }else{
        bodyNewAccount = {
            "companyId": props.companyId,
            "firstName": state.firstName,
            "lastName": state.lastName,
            "phone": state.phone,
            "password": state.password,
            "clockingLocations": state.selectedClockingLocations,
            "roles": state.selectedRoles,
            "type": state.selectedType,
            "managedLocations":state.selectedManagedLocations,
            "managedRoles":state.selectedManagedRoles,
            "includeInReports": state.includeInReportsSwitchEnabled
        }
    }

    let bodyEditAccount;

    if (state.password === "") {

        bodyEditAccount = {
            "employeeId": props.employeeDetailsId,
            "companyId": props.companyId,
            "firstName": state.firstName,
            "lastName": state.lastName,
            "phone": state.phone,
            "clockingLocations": state.selectedClockingLocations,
            "roles": state.selectedRoles,
            "type": state.selectedType,
            "managedLocations":state.selectedManagedLocations,
            "managedRoles":state.selectedManagedRoles,
            "deviceId":state.deviceId,
            "includeInReports": state.includeInReportsSwitchEnabled
        }
    } else {
        bodyEditAccount = {
            "companyId": props.companyId,
            "employeeId": props.employeeDetailsId,
            "firstName": state.firstName,
            "lastName": state.lastName,
            "password": state.password,
            "phone": state.phone,
            "clockingLocations": state.selectedClockingLocations,
            "roles": state.selectedRoles,
            "type": state.selectedType,
            "managedLocations":state.selectedManagedLocations,
            "managedRoles":state.selectedManagedRoles,
            "deviceId":state.deviceId,
            "includeInReports": state.includeInReportsSwitchEnabled
        }
    }

    const createNewAccount = () => {
        if (validateData()) {
            createCompanyEmployeeHandler(bodyNewAccount).then(response => {
                if (response.data.error == null) {
                    setState({
                        ...state,
                        errorMsg: null,
                        ...defaultFormFields
                    })
                    props.closeRightPanel(true);
                }else{
                    setState({
                        ...state,
                        errorMsg:response.data.message
                    })
                }
            })
        }
    }

    const editExistingAccount = () => {

        if (validateData()) {
            editCompanyEmployeeByIdHandler([bodyEditAccount]).then(response => {
                if (response.data.error == null) {
                    setState({
                        ...state,
                        errorMsg: null
                    })
                    props.closeRightPanel(true);
                }else{
                    setState({
                        ...state,
                        errorMsg:response.data.message
                    })
                }
            })
        }
    }

    const handleSubmit = () => {
        if (props.employeeDetailsId === "") {
            createNewAccount();
        } else {
            editExistingAccount();
        }
    }



    const validateData = () => {
        if (!state.firstName ||
            !state.lastName ||
            !state.phone ||
            !props.employeeDetailsId && (state.password.length === 0 || state.password === "") ||
            (type!== TYPES.MANAGER && type!== TYPES.WORKER && !state.selectedType) ||
            (type== TYPES.MANAGER && state.selectedClockingLocations.length === 0) ||
            (type== TYPES.MANAGER && state.selectedRoles.length === 0)
        ) {
            setState({
                ...state,
                errorMsg: <FormattedMessage id="PleaseFillInAllFields" defaultMessage="PleaseFillInAllFields"/>
            })
            return false;
        }

        if (!state.phone.startsWith("+")) {
            setState({
                ...state,
                errorMsg: <FormattedMessage id="InvalidPhoneNumber" defaultMessage="InvalidPhoneNumber"/>
            })
            return false;
        }


        setState({
            ...state,
            errorMsg: null
        })
        return true;
    }

    const deleteDeviceId = (employeeId) => {
        const body = {
            deviceId:state.deviceId
        }
        removeDeviceIdFromEmployeeHandler(employeeId, body).then(()=>{
            getFormData();
        })
    }




    const openDeleteAlert = (e) => {
        e.stopPropagation();
        setState({
            ...state,
            isDeleteDeviceIdAlertOpen: true,
        });
    }

    const hideAlert = () => {
        setState({
            ...state,
            isDeleteDeviceIdAlertOpen: false,
        })
    }

    const toggleSwitch = () => {
        setState({
            ...state,
            includeInReportsSwitchEnabled: !state.includeInReportsSwitchEnabled
        })
    }




    const closeIcon = <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6036 2.08952C11.8273 1.86189 11.954 1.55208 11.9559 1.22825C11.9577 0.904421 11.8346 0.593093 11.6135 0.362755C11.3924 0.132417 11.0915 0.0019376 10.777 2.14055e-05C10.4625 -0.00189478 10.1601 0.124909 9.93642 0.352537L5.30215 5.12412C5.08396 5.35331 4.96175 5.66146 4.96175 5.98239C4.96175 6.30333 5.08396 6.61147 5.30215 6.84066L9.93642 11.5918C10.0433 11.7157 10.1737 11.8157 10.3195 11.8858C10.4653 11.9559 10.6235 11.9946 10.7844 11.9995C10.9452 12.0043 11.1053 11.9753 11.2549 11.9142C11.4045 11.853 11.5403 11.761 11.6541 11.6439C11.7679 11.5267 11.8573 11.3868 11.9166 11.2328C11.976 11.0788 12.0042 10.914 11.9995 10.7483C11.9948 10.5827 11.9572 10.4198 11.8891 10.2697C11.821 10.1196 11.7239 9.98534 11.6036 9.87527L7.81279 6.00283L11.6036 2.08952Z" fill="#4D5062"/>
        <path d="M0.39644 2.08952C0.172731 1.86189 0.0460062 1.55208 0.0441452 1.22825C0.0422841 0.904421 0.165439 0.593093 0.386516 0.362755C0.607593 0.132417 0.908484 0.0019376 1.22299 2.14055e-05C1.53751 -0.00189478 1.83987 0.124909 2.06358 0.352537L6.69785 5.12412C6.91604 5.35331 7.03825 5.66146 7.03825 5.98239C7.03825 6.30333 6.91604 6.61147 6.69785 6.84066L2.06358 11.5918C1.95668 11.7157 1.82632 11.8157 1.68051 11.8858C1.53469 11.9559 1.3765 11.9946 1.21564 11.9995C1.05478 12.0043 0.894655 11.9753 0.745087 11.9142C0.595519 11.853 0.459669 11.761 0.345876 11.6439C0.232083 11.5267 0.142748 11.3868 0.0833582 11.2328C0.0239681 11.0788 -0.00422384 10.914 0.000511366 10.7483C0.00524657 10.5827 0.0428095 10.4198 0.110895 10.2697C0.17898 10.1196 0.276151 9.98534 0.39644 9.87527L4.18721 6.00283L0.39644 2.08952Z" fill="#4D5062
"/>
    </svg>




    return (
        <>
            {state.isDeleteDeviceIdAlertOpen &&
            <Alert
                title={<FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="Device" defaultMessage="Device"/> }} />}
                contentRow1={<FormattedMessage id="DoYouWantToDeleteParam" values={{ param:  state.deviceId}} />}
                deleteAction1={() => deleteDeviceId(props.employeeDetailsId)}
                deleteText1={<FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="ThisDevice" defaultMessage="ThisDevice"/> }} />}
                cancelAction={() => hideAlert()}
                cancelText={<FormattedMessage id="Cancel" defaultMessage="Cancel"/>}
                hideAlert={() => hideAlert()}
            />}

            <section className="form-container">
                <div className="details-form">
                    {type!== TYPES.MANAGER && type!== TYPES.WORKER && <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="userType"> <FormattedMessage id="UserType"
                                                                                           defaultMessage="UserType"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="UserType" defaultMessage="UserType">
                                {placeholder =>
                                    <Select
                                        className="select"
                                        options={state.type.map(type => {
                                            return {label: type, value: type}
                                        })}
                                        value={{label: state.selectedType, value: state.selectedType}}
                                        onChange={e => handleOnChange(e, "userType")}
                                        placeholder={placeholder}
                                        noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                        id="userType"
                                        styles={selectStyles}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>}


                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="firstName"> <FormattedMessage id="FirstName" defaultMessage="FirstName"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="FirstName" defaultMessage="FirstName">
                                {placeholder =>
                                    <input
                                        className="input"
                                        type="text"
                                        onChange={e => handleOnChange(e, "firstName")}
                                        maxLength={80}
                                        placeholder={placeholder}
                                        autoComplete="off"
                                        id="firstName"
                                        value={state.firstName}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>


                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="lastName">
                                <FormattedMessage id="LastName" defaultMessage="LastName"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="LastName" defaultMessage="LastName">
                                {placeholder =>
                                    <input
                                        className="input"
                                        type="text"
                                        onChange={e => handleOnChange(e, "lastName")}
                                        maxLength={100}
                                        placeholder={placeholder}
                                        autoComplete="off"
                                        id="lastName"
                                        value={state.lastName}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>


                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="phone"> <FormattedMessage id="PhoneNumber"
                                                                                        defaultMessage="Phone Number"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="PhoneNumberFormat" defaultMessage="PhoneNumberFormat">
                                {placeholder =>
                                    <input
                                        autoComplete="new-password"
                                        className="input"
                                        type="tel"
                                        onChange={e => handleOnChange(e, "phone")}
                                        maxLength={100}
                                        placeholder={placeholder}

                                        id="phone"
                                        value={state.phone}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>

                    {props.employeeDetailsId !== "" && state.deviceId && <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="deviceId">
                                <FormattedMessage id="DeviceUsed" defaultMessage="DeviceUsed"/>
                            </label>
                        </div>
                        <div className="row">
                            <div className="single-option">
                                {state.deviceId}
                            </div>
                            <a onClick={(e) => openDeleteAlert(e, props.employeeDetailsId)} className="icon remove-icon ml-auto">
                                {closeIcon}
                            </a>
                        </div>
                    </div>}


                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="pwd"> <FormattedMessage id="Password"
                                                                                      defaultMessage="Password"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="Password" defaultMessage="Password">
                                {placeholder =>
                                    <input
                                        className="input"
                                        type="password"
                                        onChange={e => handleOnChange(e, "password")}
                                        maxLength={100}
                                        placeholder={placeholder}
                                        autoComplete="new-password"
                                        id="pwd"
                                        value={state.password}

                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>


                    {state.selectedType !== "ADMIN" &&<div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="clockingLocations">
                                <FormattedMessage id="ClockInLocations" defaultMessage="ClockInLocations"/>
                            </label>
                            <a onClick={() => selectAllClockingLocations()} className="link">
                                <FormattedMessage id="SelectAll" defaultMessage="SelectAll"/>
                            </a>
                        </div>
                        <div className="row">
                            <FormattedMessage id="ClockInLocations" defaultMessage="ClockInLocations">
                                {placeholder =>
                                    <Select
                                        className="select"
                                        options={state.clockingLocations.map(clockingLocation => {
                                            return {label: clockingLocation.name, value: clockingLocation.id}
                                        })}
                                        value={getAllSelectedClockingLocationsSelectOptions()}
                                        onChange={e => handleOnChange(e, "clockingLocations")}
                                        placeholder={placeholder}
                                        noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                        id="clockingLocations"
                                        styles={selectStyles}
                                        isMulti={true}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>
                    }


                    {state.selectedType !== "ADMIN" && <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="roles">
                                <FormattedMessage id="Roles" defaultMessage="Roles"/>
                            </label>
                            <a onClick={() => selectAllRoles()} className="link">
                                <FormattedMessage id="SelectAll" defaultMessage="SelectAll"/>
                            </a>
                        </div>
                        <div className="row">
                            <FormattedMessage id="Roles" defaultMessage="Roles">
                                {placeholder =>
                                    <Select
                                        className="select"
                                        options={state.roles?.map(role => {
                                            return {label: role.name, value: role.id}
                                        })}
                                        value={getAllRolesSelectOptions()}
                                        onChange={e => handleOnChange(e, "roles")}
                                        placeholder={placeholder}
                                        noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                        id="roles"
                                        styles={selectStyles}
                                        isMulti={true}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>
                    }


                    {state.selectedType === "MANAGER" && <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="managedLocations">
                                <FormattedMessage id="ManagedLocations" defaultMessage="ManagedLocations"/>
                            </label>
                            <a onClick={() => selectAllManagedLocations()} className="link">
                                <FormattedMessage id="SelectAll" defaultMessage="SelectAll"/>
                            </a>
                        </div>
                        <div className="row">
                            <FormattedMessage id="ManagedLocations" defaultMessage="ManagedLocations">
                                {placeholder =>
                                    <Select
                                        className="select"
                                        options={state.managedLocations?.map(managedLocation => {
                                            return {label: managedLocation.name, value: managedLocation.id}
                                        })}
                                        value={getAllSelectedManagedLocationsSelectOptions()}
                                        onChange={e => handleOnChange(e, "managedLocations")}
                                        placeholder={placeholder}
                                        noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                        id="managedLocations"
                                        styles={selectStyles}
                                        isMulti={true}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>
                    }


                    {state.selectedType === "MANAGER" && <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="managedRoles">
                                <FormattedMessage id="ManagedRoles" defaultMessage="ManagedRoles"/>
                            </label>
                            <a onClick={() => selectAllManagedRoles()} className="link">
                                <FormattedMessage id="SelectAll" defaultMessage="SelectAll"/>
                            </a>
                        </div>
                        <div className="row">
                            <FormattedMessage id="ManagedRoles" defaultMessage="ManagedRoles">
                                {placeholder =>
                                    <Select
                                        className="select"
                                        options={state.managedRoles?.map(managedRole => {
                                            return {label: managedRole.name, value: managedRole.id}
                                        })}
                                        value={getAllSelectedManagedRolesSelectOptions()}
                                        onChange={e => handleOnChange(e, "managedRoles")}
                                        placeholder={placeholder}
                                        noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                        id="managedRoles"
                                        styles={selectStyles}
                                        isMulti={true}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>
                    }

                    <div className="form-group">
                        <div className="row switch-row">
                            <label className="label" htmlFor="IncludeInReports">
                                <FormattedMessage id="IncludeInReports"/>
                            </label>
                            <div
                                onClick={() => toggleSwitch()}
                                className={state.includeInReportsSwitchEnabled ? "ml-auto switch labeled on": "ml-auto switch labeled off"}>
                                <input type="text" tabIndex="-1"/>
                                <div className="labels">
                                    <div className="dot"></div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="row buttons-container">
                        <button onClick={() =>  props.closeRightPanel()}  className="btn btn-default"><FormattedMessage
                            id="Cancel" defaultMessage="Cancel"/></button>
                        <button onClick={() => handleSubmit()} className="btn btn-success"><FormattedMessage
                            id="Save" defaultMessage="Save"/></button>
                    </div>

                    {state.errorMsg && <div className="error">&#9888;&nbsp;{state.errorMsg}</div>}
                </div>
            </section>
        </>
    );
}

export default EmployeeDetails;
