import React, {useEffect, useState, useContext} from "react";
import {FormattedDate, FormattedMessage, useIntl} from "react-intl";
import { useParams} from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import Header from "../../components/Header";
import Spinner from "../../components/Spinner";
import * as StorageServices from "../../services/StorageServices";
import {APP_DOMAINS, LOCALSTORAGE_ITEMS, STATUSES, TYPES, TYPES as TYPE} from "../../util/Constants";

import CalendarTmz from "../../components/CalendarTmz";
import * as StorageService from "../../services/StorageServices";
import {
    getFirstDayOfMonth,
    getTotalDaysInMonth,
    selectStyles, resetTime, getLeaveRequirements, resetTimeToEndOfDay, getUserAgent,

} from "../../util/common";
import ScrollContainer from "react-indiana-drag-scroll";

import VacationCalendarAllTable from "./VacationCalendarAllTable";
import TabNavigation from "../../components/TabNavigation";

import {chevronLeft, chevronRight, closeIcon, warningIcon} from "../../components/Icons";
import {
    createEmployeeLeaveHandler,
    getAllEmployeesByCompanyIdHandler, getAllLeavesByCompanyIdHandler
} from "../../handlers/vacationPlannerHandlers/HandlerVacationCalendar";
import {
    getAllLocationsForCompanyHandler
} from "../../handlers/companiesHandlers/employeesHandlers/HandlerEmployeesDetails";
import Select from "react-select";
import {getCompanyByIdHandler} from "../../handlers/companiesHandlers/HandlerCompaniesSettings";
import RightPanel from "../../components/RightPanel";
import {LocaleContext} from "../../util/LocaleContext";
import {PendingLeavesContext} from "../../util/PendingLeavesContext";
import VacationRequestDetails from "./VacationRequestDetails";
import * as ClientConfig from "../../config/ClientConfig";
import analyticsLogEvent from "../../services/FirebaseService";



function VacationCalendarAll() {
    const {companyId} = useParams();
    const type = StorageServices.getItem(LOCALSTORAGE_ITEMS.TYPE);
    const allLeavesTypes = StorageService.getItem(LOCALSTORAGE_ITEMS.LEAVE_TYPES)
        ? JSON.parse(StorageService.getItem(LOCALSTORAGE_ITEMS.LEAVE_TYPES))
        : null;
    const userId = StorageService.getItem(LOCALSTORAGE_ITEMS.USER_ID) ? JSON.parse(StorageService.getItem(LOCALSTORAGE_ITEMS.USER_ID)) : null;
    const openedRightPanelStyleName = "open";
    const closedRightPanelStyleName = "closed";
    const intl = useIntl();
    const date = new Date();
    const today = getFirstDayOfMonth(date);

    const pendingLeaveContext = useContext(PendingLeavesContext);
    const localeContext = useContext(LocaleContext);
    const exclamationIcon = <svg className="icon" xmlns="http://www.w3.org/2000/svg" height="24px"
                                 viewBox="0 -960 960 960" width="24px" fill="#5f6368">
        <path
            d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
            fill="#FF6969"/>
    </svg>
    const exclamationIconInfo = <svg className="icon" xmlns="http://www.w3.org/2000/svg" height="24px"
                                     viewBox="0 -960 960 960" width="24px" fill="#5f6368">
        <path
            d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
            fill="#A9ACBE"/>
    </svg>
    const [state, setState] = useState({
        loading: true,
        name: null,
        allEmployees: [],
        listOfEditedEmployees: [],
        calendarIsVisible: false,

        allLocations: null,
        allRoles: null,
        allEmployeesLeaves: null,
        allTypes: null,

        leaveRequirements: null,
        selectedDate: today,
        assignVacationPopupVisible: false,
        assignVacationPopupPosition: null,
        isDeletePatternAlertOpen: false,
        numOfDaysInMonth: 31,
        confirmationModalVisible: false,
        searchedEmployee: null,
        selectedDay: null,
        selectedLeaveType: null,
        leaveId: null,
        from: null,
        to: null,
        request: null,

        vacationCalendarIsVisible: false,
        selectedSchedule: null,
        selectedWorkHoursPeriodFromCalendar: null,
        activeScheduleForEmployee: null,


        editedClockingReportMinutesFrom: null,
        editedClockingReportHoursFrom: null,
        editedClockingReportMinutesTo: null,
        editedClockingReportHoursTo: null,
        hasErrors: false,
        errorMsg: null,
        selectedRowIds: [],
        infoToolTipVisible: false,
        selectedHolidayType: null,
        disabledArrows: false,
        notAllowedPopupIsVisible: false,
        selectedRole: {
            label: <FormattedMessage id="All"/>,
            value: null
        },
        selectedLocation: {
            label: <FormattedMessage id="All" defaultMessage="All"/>,
            value: null
        },
        selectedStatus: {
            label: <FormattedMessage id="All" defaultMessage="All"/>,
            value: null
        },
        selectedType: {
            label: <FormattedMessage id="All" defaultMessage="All"/>,
            value: null
        }
    });

    const locale = StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE);


    useEffect(() => {
        analyticsLogEvent("page_view", {
            page_title: "VacationCalendarAll"
        });
    }, []);

    useEffect(() => {
        getPageContent();
    }, [pendingLeaveContext]);


    useEffect(() => {
        setState({
            ...state,
            numOfDaysInMonth: getTotalDaysInMonth(state.selectedDate),

        })
    }, [state.selectedDate]);




    const getPageContent = () => {
        let companyName;
        if (type === TYPES.SUPER_ADMIN) {
            getCompanyByIdHandler(companyId).then(r => {
                const companyDetailsResponse = r.data.company.name
                companyName = companyDetailsResponse;
            });
        }
        getAllEmployeesByCompanyIdHandler(companyId, state.selectedDate).then(employeesResponse => {
            getAllLocationsForCompanyHandler(companyId).then(locationsResponse => {
                getAllLeavesByCompanyIdHandler(companyId).then(allEmployeesLeavesResponse => {
                    const allTypes = TYPES;
                    const allRoles = employeesResponse.data.allEmployees.allRoles;
                    const allEmployeesLeaves = allEmployeesLeavesResponse.filter(el =>
                        (el.status === STATUSES.APPROVED || el.status === STATUSES.PENDING)
                    );

                    const employeeLeavesMap = {};

                    allEmployeesLeaves.forEach(el => {
                        let x = employeeLeavesMap[el.employee.id];
                        if (x == null) {
                            x = [];
                        }
                        x.push(el);
                        employeeLeavesMap[el.employee.id] = x;
                    });
                    const callback = () => {
                        setState({
                            ...state,
                            loading: false,
                            numOfDaysInMonth: getTotalDaysInMonth(state.selectedDate),
                            name: companyName,
                            allEmployees: employeesResponse.data.allEmployees.employees,
                            allLocations: locationsResponse.data.locations,
                            allRoles: allRoles,
                            allTypes: allTypes,
                            leaveId: null,
                            selectedWorkHoursPeriodFromCalendar: null,
                            vacationCalendarIsVisible: false,
                            selectedSchedule: null,
                            assignVacationPopupVisible: false,
                            selectedRowIds: [],
                            selectedDay: null,
                            rightPanelStyleName: closedRightPanelStyleName,
                            isDeletePatternAlertOpen: false,
                            allEmployeesLeaves: employeeLeavesMap,
                            lastSelectedRowIndex: null,
                        });
                    }

                    if (type !== TYPES.SUPER_ADMIN) {
                        localeContext.setLocale(StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE), callback);
                    }else{
                        callback();
                    }

                });
            });
        });
    };


    const isAllowedToAddVacation = (selectedRowIds) => {

        if (type === TYPES.SUPER_ADMIN) {
            return true;
        }
        // 1. Check if the selected row is the manager themselves
        if (type === TYPES.MANAGER &&
            selectedRowIds !== null &&
            selectedRowIds.length === 1 &&
            selectedRowIds.includes(userId.toString()) &&
            state.allEmployees.some(
                employee =>
                    parseInt(selectedRowIds[0]) === parseInt(employee.id) &&
                    employee.type === TYPES.MANAGER
            )) {

            return true;
        }

        // 2. Check if all selected rows are workers
        if (type === TYPES.MANAGER || type === TYPES.ADMIN &&
            selectedRowIds !== null &&
            selectedRowIds.length >= 1 &&
            selectedRowIds.every(
                id => {
                    const employee = state.allEmployees.find(emp => emp.id.toString() === id);
                    return employee && employee.type === TYPES.WORKER;
                }
            )
        ) {

            return true;
        }

        // 3. Check if the selected row includes the current manager and no other managers
        if (type === TYPES.MANAGER &&
            selectedRowIds !== null &&
            selectedRowIds.length >= 1 &&
            selectedRowIds.includes(userId.toString()) &&
            selectedRowIds.every(
                id => {
                    const employee = state.allEmployees.find(emp => emp.id.toString() === id);
                    return employee && (employee.type === TYPES.WORKER || employee.id.toString() === userId.toString());
                }
            )
        ) {

            return true;
        }

        // 4. Check if the selected row includes the current admin and no other admins
        if (type === TYPES.ADMIN &&
            selectedRowIds !== null &&
            selectedRowIds.length >= 1 &&
            selectedRowIds.includes(userId.toString()) &&
            selectedRowIds.every(
                id => {
                    const employee = state.allEmployees.find(emp => emp.id.toString() === id);
                    return employee && (employee.type === TYPES.WORKER || employee.id.toString() === userId.toString());
                }
            )
        ) {

            return true;
        }

        // 5. Check if selected rows are managers, workers and himself
        if (type === TYPES.ADMIN &&
            selectedRowIds !== null &&
            selectedRowIds.length >= 1 &&
            selectedRowIds.every(
                id => {
                    const employee = state.allEmployees.find(emp => emp.id.toString() === id);
                    return employee && employee.type === TYPES.MANAGER || employee.type === TYPES.WORKER || employee.id.toString() === userId.toString();
                }
            )
        ) {

            return true;
        }
        return false;
    }



    const handleOnChange = (e, input) => {

        if (input === "searchEmployee") {
            setState({
                ...state,
                searchedEmployee: e.target.value
            })
        } else if (input === "location") {

            setState({
                ...state,
                selectedLocation: e !== null ? e : null,
            })
        } else if (input === "status") {
            setState({
                ...state,
                errorMsg: null,
                selectedStatus: {
                    label: e.label,
                    value: e.value
                }
            })
        } else if (input === "role") {
            setState({
                ...state,
                selectedRole: {
                    label: e.label,
                    value: e.value
                }
            });
        } else if (input === "request") {
            setState({
                ...state,
                request: e.target.value
            })
        } else if (input === "type") {
            setState({
                ...state,
                errorMsg: null,
                selectedType: {
                    label: e.label,
                    value: e.value
                }
            })
        }
    }

    const getAvailableLocations = () => {
        const locs = state.allLocations.map(employee => {
            return {
                label: employee.name,
                value: employee.id
            }
        });
        locs.unshift({
            label: <FormattedMessage id="All"/>,
            value: null
        });
        return locs;
    }

    const getAvailableRoles = () => {
        const roles = state.allRoles?.map(role => {
            return {
                label: role.name,
                value: role.id
            }
        });
        roles?.unshift({
            label: <FormattedMessage id="All"/>,
            value: null
        });
        return roles;
    }

    const getAvailableTypes = () => {
        const types = Object.entries(state.allTypes)
            .filter(([key, value]) => key !== TYPES.SUPER_ADMIN)
            .map(([key, value]) => ({
                label: value,
                value: key
            }));

        types.unshift({
            label: <FormattedMessage id="All"/>,
            value: null
        });
        return types;
    }


    const getFilteredEmployees = () => {
        // Filter employees by name
        const clockingInfosFilteredByName = state.allEmployees.filter(e =>
            !state.searchedEmployee ||
            !state.searchedEmployee.length ||
            (e.name || "").toLowerCase().includes(state.searchedEmployee.toLowerCase())
        );


        const clockingInfosFinalFiltered = clockingInfosFilteredByName
            .filter(e =>
                // Filter by selected role
                (!state.selectedRole?.value || e.roles?.includes(state.selectedRole.value)) &&
                // Filter by selected type
                (!state.selectedType?.value || e.type === state.selectedType.value)
            )

            .map(e => {
                let dateMap = {};

                const newWorkSchedule = e.workSchedule?.map(day => {
                    const existentDate = dateMap["" + day.date.getTime()];
                    if (existentDate) {
                        return {
                            ...day,
                            isEdited: true,
                            editedDateId: existentDate.id,
                            dayIntervals: existentDate.dayIntervals.length > 0 ? existentDate.dayIntervals : [],
                        };
                    } else {
                        return day;
                    }
                });

                return {
                    ...e,
                    workSchedule: newWorkSchedule,
                };
            });

        // Filter by location if specified
        if (state.selectedLocation?.value !== null) {
            return clockingInfosFinalFiltered.filter(finalClocking =>
                finalClocking.clockingLocations.includes(state.selectedLocation.value)
            );
        } else {
            return clockingInfosFinalFiltered;
        }
    };

    const getIndex = (trIndex, tdIndex) => {
        if (trIndex === 0) {
            return tdIndex;
        } else if (trIndex === 1) {
            return state.numOfDaysInMonth + tdIndex;
        } else {
            return state.numOfDaysInMonth * trIndex + tdIndex;
        }
    }

    const closeConfirmationModal = () => {
        setState({
            ...state,
            confirmationModalVisible: false
        })
    }

    const rowIds = state.allEmployees.map(employee => employee.id);

    const onSelectSingleRowId = (selectedEmployee, index, event) => {
        let newSelectedRowIds = [...state.selectedRowIds];

        if (event.shiftKey && state.lastSelectedRowIndex !== null) {
            const start = Math.min(state.lastSelectedRowIndex, index);
            const end = Math.max(state.lastSelectedRowIndex, index);

            for (let i = start; i <= end; i++) {
                if (!newSelectedRowIds.includes(rowIds[i])) {
                    newSelectedRowIds.push(rowIds[i]);
                }
            }
        } else {
            if (newSelectedRowIds.includes(selectedEmployee.id)) {
                newSelectedRowIds = newSelectedRowIds.filter(item => item !== selectedEmployee.id);
            } else {
                newSelectedRowIds.push(selectedEmployee.id);
            }
        }
        setState({
            ...state,
            selectedRowIds: newSelectedRowIds,
            lastSelectedRowIndex: index
        });
    };

    const onSelectAllRowsIds = (e, rowIds) => {

        let newSelectedRowIds = [...state.selectedRowIds];
        if (JSON.stringify(newSelectedRowIds) === JSON.stringify(rowIds)) {
            newSelectedRowIds = [];
        } else {
            newSelectedRowIds = rowIds;
        }

        setState({
            ...state,
            selectedRowIds: newSelectedRowIds,
            assignVacationPopupVisible: false,
            lastSelectedRowIndex: null
        });


    };

    const openVacationPopup = (e, employee) => {
        e.preventDefault();
        let newSelectedRowIds = [...state.selectedRowIds];
        if (!newSelectedRowIds.includes(employee.id)) {
            newSelectedRowIds.push(employee.id);
        }

        if (isAllowedToAddVacation(newSelectedRowIds)) {

            setState({
                ...state,
                assignVacationPopupVisible: true,
                assignVacationPopupPosition: {
                    left: e.clientX,
                    top: e.clientY
                },
                activeScheduleForEmployee: state.selectedRowIds.length > 1 ? null : employee?.workScheduleDetails,
                selectedRowIds: newSelectedRowIds,

            })

        }else{
            setState({
                ...state,
                notAllowedPopupIsVisible: true,
                calendarIsVisible: false,
                vacationCalendarIsVisible: false
            })
        }


    }

    const closeVacationPopup = (e) => {
        e.preventDefault();
        setState({
            ...state,
            assignVacationPopupVisible: false,
            assignVacationPopupPosition: null,
            activeScheduleForEmployee: null,
        })
    }

    const openCalendar = () => {
        console.log("blana")
        setState({
            ...state,
            calendarIsVisible: true
        });
    }

    const closeCalendar = () => {
        setState({
            ...state,
            calendarIsVisible: false,
            errorMsg: null
        });
    }

    const handleCalendarDatePick = (e) => {
        setState({
                ...state,
                selectedDate: e,
                calendarIsVisible: false,
                errorMsg: null,
            }
        );
    }

    const openVacationCalendar = (selectedScheduleId) => {

        setState({
            ...state,
            leaveRequirements: getLeaveRequirements(selectedScheduleId),
            selectedSchedule: selectedScheduleId,
            vacationCalendarIsVisible: true,
            errorMsg: null,
        });
    }

    const closeVacationCalendar = () => {
        setState({
            ...state,
            vacationCalendarIsVisible: false,
            assignVacationPopupVisible: false,
            selectedSchedule: null,
            request: null,
            from: null,
            to: null
        });
    }


    const handleSelectedVacationPeriodFromCalendar = (value) => {

        const fromDate = resetTime(new Date(value[0]));
        const toDate = resetTimeToEndOfDay(new Date(value[1]));
        const fromISOString = fromDate.toISOString();
        const toISOString = toDate.toISOString();


        setState({
            ...state,
            from: fromISOString,
            to: toISOString
        })
    }


    const createEmployeeLeave = () => {
        const bodyEditAccount = state.selectedRowIds.map(employeeId => {
            return {
                "employeeId": employeeId,
                "from": state.from,
                "to": state.to,
                "typeId": state.selectedSchedule,
                "request": state.request,
                "status": type === TYPES.MANAGER && employeeId === userId.toString() ? STATUSES.PENDING : STATUSES.APPROVED
            };
        });


        createEmployeeLeaveHandler(bodyEditAccount).then(response => {
            if (response.data && response.data.error == null) {
                const newLeaves = response.data.updatedEmployees;
                const updatedEmployeeLeaves = {...state.allEmployeesLeaves};
                if (response.data.updatedEmployees.error) {
                    setState({
                        ...state,
                        errorMsg: <FormattedMessage id="SelectEndDate"/>,
                    });
                    return;
                }
                newLeaves.forEach(leave => {
                    const employeeId = leave.employee.id;
                    if (!updatedEmployeeLeaves[employeeId]) {
                        updatedEmployeeLeaves[employeeId] = [];
                    }
                    updatedEmployeeLeaves[employeeId].push(leave);
                });

                setState({
                    ...state,
                    confirmationModalVisible: true,
                    vacationCalendarIsVisible: false,
                    assignVacationPopupVisible: false,
                    selectedRowIds: [],
                    errorMsg: null,
                    request: null,
                    allEmployeesLeaves: updatedEmployeeLeaves,
                });

                setTimeout(() => {
                    setState({
                        ...state,
                        confirmationModalVisible: false,
                        vacationCalendarIsVisible: false,
                        assignVacationPopupVisible: false,
                        selectedRowIds: [],
                        request: null,
                        errorMsg: null,
                        from: null,
                        to: null,
                        allEmployeesLeaves: updatedEmployeeLeaves,
                    });
                }, 2000);
            } else {
                let errorMsg;
                if (response.data.error.errorCode && response.data.error.errorCode === 1) {
                    errorMsg = <FormattedMessage id="HigherAuthorityRequired"/>
                } else if (response.data.error.errorCode && response.data.error.errorCode === 2) {
                    errorMsg = <FormattedMessage id="LeaveWithOverlappedPeriodExists"/>
                } else {
                    errorMsg = response.data.error.message;
                }
                setState({
                    ...state,
                    errorMsg: response.data ? errorMsg : <FormattedMessage id="AnErrorOccurred"/>,
                });
            }
        });
    };


    const toggleInfoToolTip = () => {
        setState({
            ...state,
            infoToolTipVisible: !state.infoToolTipVisible
        })
    }
    const changeMonth = (month) => {
        if (state.disabledArrows) {
            return;
        }
        let newDate = new Date(state.selectedDate);

        if (month === "previous") {
            newDate.setMonth(newDate.getMonth() - 1);
        } else if (month === "next") {
            newDate.setMonth(newDate.getMonth() + 1);
        }

        setState({
            ...state,
            disabledArrows: true,
        })
        setTimeout(() => {
            setState({
                ...state,
                disabledArrows: false,
                selectedDate: newDate,
            })
        }, 100);

    };

    const getLeaveTypeName = (type) => {
        const names = {
            ro: type.ro,
            en: type.en,
            sv: type.sv,
        };
        return names[locale] || type.en;
    };

    const openRightPanel = (leaveId, employee, leaveType) => {

        if (leaveId) {
            setState({
                ...state,
                leaveId: leaveId,
                selectedLeaveType: leaveType,
                typeId: leaveType,
                rightPanelStyleName: openedRightPanelStyleName,

            });
        } else {
            setState({
                ...state,
                leaveId: null,
                rightPanelStyleName: openedRightPanelStyleName,
            });
        }
    }

    const closeRightPanel = (isSaveBtnClicked) => {
        if (isSaveBtnClicked) {
            getPageContent();
        } else {
            setState({
                ...state,
                rightPanelStyleName: closedRightPanelStyleName,
                leaveId: null,
            });
        }
    }

    const hideAlert = () => {

        setState({
            ...state,
            notAllowedPopupIsVisible: false,
        })
    }


    const getAlert = () => {
        if (state.notAllowedPopupIsVisible) {
            return {
                title: <FormattedMessage id="HigherAuthorityRequired"/>,
                contentRow1: type === TYPES.ADMIN ? <FormattedMessage id="OneOrMoreSelectedEmployeesAreAdminsAndYouDontHaveAuthorityToAddVacation"/>  : <FormattedMessage id="OneOrMoreSelectedEmployeesAreAdminsOrManagersAndYouDontHaveAuthorityToAddVacation"/>,
                cancelAction: () => hideAlert(),
                cancelText: <FormattedMessage id="Close" defaultMessage="Close"/>,
                hideAlert: () => hideAlert()
            }
        }
    }


    return state.loading ?
        <Spinner/>
        : (
            <div className="content">

                <Header
                    alert={getAlert()}
                    companyId={companyId}
                    hasProfileBtn={true}
                    hasBackBtn={type !== TYPE.MANAGER && type !== TYPE.ADMIN}
                    headerTitle={type === TYPES.SUPER_ADMIN ? state.name :
                        <FormattedMessage id="VacationCalendar" defaultMessage="VacationCalendarAll"/>}
                />

                <div className="inner-content inner-content-for-table">
                    <TabNavigation
                        companyId={companyId}
                        activeTab={type === TYPE.SUPER_ADMIN ? 8 : 1}
                        page="vacation-planner"
                        pendingLeavesCount={pendingLeaveContext.pendingLeavesCount}
                    />

                    <section className="row top-actions-row">

                        <div className="form-group">
                            <div className="row">
                                <label className="label">
                                    <FormattedMessage id="SearchEmployee" defaultMessage="SearchEmployee"/>
                                </label>

                            </div>
                            <div className="row">
                                <FormattedMessage id="Search" defaultMessage="Search">
                                    {placeholder =>
                                        <input
                                            className="input"
                                            placeholder={placeholder}
                                            onChange={e => handleOnChange(e, "searchEmployee")}

                                        />
                                    }
                                </FormattedMessage>
                            </div>
                        </div>

                        <div className="form-group select-form-group">
                            <div className="row">
                                <label className="label">
                                    <FormattedMessage id="Location"/>
                                </label>

                            </div>
                            <div className="row select-row">
                                <FormattedMessage id="All" defaultMessage="All">
                                    {placeholder =>
                                        <Select
                                            tabIndex={-1}
                                            className={"select"}
                                            options={getAvailableLocations()}
                                            onChange={e => handleOnChange(e, "location")}
                                            value={state.selectedLocation}
                                            placeholder={placeholder}
                                            noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                            id="location"
                                            styles={selectStyles}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    }
                                </FormattedMessage>

                            </div>
                        </div>

                        <div className="form-group select-form-group">
                            <div className="row">
                                <label className="label">
                                    <FormattedMessage id="Roles"/>
                                </label>
                            </div>
                            <div className="row">
                                <FormattedMessage id="Roles" defaultMessage="Roles">
                                    {placeholder =>
                                        <Select
                                            className="select"
                                            options={getAvailableRoles()}
                                            onChange={e => handleOnChange(e, "role")}
                                            defaultValue={state?.selectedRole}
                                            placeholder={state?.selectedRole ? state?.selectedRole.label : placeholder}
                                            noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                            id="status"
                                            styles={selectStyles}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                        </div>

                        <div className="form-group select-form-group">
                            <div className="row">
                                <label className="label">
                                    <FormattedMessage id="Type"/>
                                </label>
                            </div>
                            <div className="row">
                                <FormattedMessage id="Type" defaultMessage="Type">
                                    {placeholder =>
                                        <Select
                                            className="select"
                                            options={getAvailableTypes()}
                                            onChange={e => handleOnChange(e, "type")}
                                            defaultValue={state?.selectedType}
                                            placeholder={state?.selectedType ? state?.selectedType.label : placeholder}
                                            noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                            id="type"
                                            styles={selectStyles}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                        </div>


                        <div className="form-group calendar-form-group">
                            <div className="row centered-x">
                                <label className="label">
                                    <FormattedMessage id="Month"/>
                                </label>
                            </div>
                            <div className="row calendar-row">
                                <i onClick={() => changeMonth("previous")}>{chevronLeft}</i>
                                <a onClick={openCalendar} className="link calendar-link">
                                    <FormattedDate
                                        value={state.selectedDate}
                                        month="short"
                                        year="numeric"
                                        timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                                        locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                    />
                                </a>
                                <i onClick={() => changeMonth("next")}>{chevronRight}</i>
                            </div>
                        </div>


                    </section>

                    <ScrollContainer
                        hideScrollbars={false}
                        activationDistance={1}
                        ignoreElements={".prevent-scroll"}
                        className="scroll-container vacation-planner-scroll-container"
                    >
                        <VacationCalendarAllTable
                            openRightPanel={openRightPanel}
                            assignVacationPopupVisible={state.assignVacationPopupVisible}
                            assignVacationPopupPosition={state.assignVacationPopupPosition}
                            listOfEditedEmployees={state.listOfEditedEmployees}
                            openVacationPopup={openVacationPopup}
                            closeVacationPopup={closeVacationPopup}
                            onSelectSingleRowId={onSelectSingleRowId}
                            onSelectAllRowsIds={onSelectAllRowsIds}
                            selectedRowIds={state.selectedRowIds}
                            openScheduleCalendar={openVacationCalendar}
                            selectedSchedule={state.selectedSchedule}
                            activeScheduleForEmployee={state.activeScheduleForEmployee}

                            allEmployees={getFilteredEmployees()}
                            allEmployeesLeaves={state.allEmployeesLeaves}
                            allLeavesTypes={allLeavesTypes}
                            numOfDaysInMonth={state.numOfDaysInMonth}

                            selectedDay={state.selectedDay}
                            selectedDate={state.selectedDate}

                            getIndex={getIndex}
                            selectedHolidayType={state.selectedHolidayType}
                            locale={locale}
                        />


                        <div onClick={() => toggleInfoToolTip()} className="info-container">
                            <>
                                {state.infoToolTipVisible &&
                                    <div className="tooltip">
                                        <div className="close-tooltip-btn"
                                             onContextMenu={(e) => e.preventDefault()}
                                             onClick={(e) => toggleInfoToolTip()}>
                                                 <span className="close-icon">
                                                     {closeIcon}
                                                 </span>
                                        </div>
                                        <div className="arrow-down"></div>
                                        <h3 className="legend-title"><FormattedMessage id="Legend"/></h3>
                                        <ScrollContainer

                                            hideScrollbars={false}
                                            horizontal={false}
                                            className="scroll-container dropdown-scroll-container">
                                            {allLeavesTypes?.map(type => (
                                                <div key={type.id} className="row">
                                                    <div
                                                        className={`col legend ${type.id.replace(/_/g, "-").toLowerCase()}`}></div>
                                                    <div className="col text-col">
                                                        {getLeaveTypeName(type)}
                                                    </div>
                                                </div>
                                            ))}
                                        </ScrollContainer>

                                    </div>
                                }
                                <div className="info-icon">?</div>
                            </>
                        </div>

                    </ScrollContainer>


                    {state.calendarIsVisible &&
                        <>
                            <div onClick={closeCalendar}
                                 className={!state.calendarIsVisible ? "blurry-background hidden" : "blurry-background"}></div>
                            <div className="calendar-container">
                                <CalendarTmz
                                    showNavigation={true}
                                    locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                    className={"custom-calendar"}
                                    view="year"
                                    onClickMonth={handleCalendarDatePick}
                                    defaultValue={state.selectedDate}
                                    tz={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                                />
                            </div>
                        </>

                    }


                    {state.vacationCalendarIsVisible &&
                        <>
                            <div onClick={closeVacationCalendar}
                                 className={!state.vacationCalendarIsVisible ? "blurry-background hidden" : "blurry-background"}></div>
                            <div className="calendar-container">
                                <div className="calendar-header row">
                                    <FormattedMessage id="ChoosingVacationDays"/>
                                    <span
                                        onClick={() => closeVacationCalendar()}
                                        className="ml-auto close-icon">
                                    {closeIcon}
                                </span>
                                </div>

                                <CalendarTmz
                                    showNavigation={true}
                                    locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                    className={"scheduler-calendar custom-calendar"}
                                    view="month"
                                    selectRange={true}
                                    onChange={handleSelectedVacationPeriodFromCalendar}
                                    showNeighboringMonth={true}
                                    showFixedNumberOfWeeks={true}
                                    tz={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                                />

                                <div className="calendar-footer">
                                    <>
                                        {state.errorMsg &&
                                            <>
                                                <div className="error">&#9888;&nbsp;{state.errorMsg}</div>
                                                <br/>
                                            </>
                                        }
                                    </>
                                    {state.leaveRequirements && (state.leaveRequirements.req_ro || state.leaveRequirements.req_en) && (
                                        <>
                                            <div className="row">
                                                <div className="leave-requirements">
                                                    <h3><FormattedMessage id="Requirements"
                                                                          defaultMessage="Requirements"/></h3>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: locale === 'ro' ? state.leaveRequirements.req_ro : state.leaveRequirements.req_en,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <br/>
                                        </>


                                    )
                                    }

                                    <div className="form-group">
                                        <div className="row">
                                            {state.leaveRequirements === null &&
                                                <label className="label" htmlFor="information"> <FormattedMessage
                                                    id="OtherInformation"
                                                    defaultMessage="OtherInformation"/></label>}
                                        </div>
                                        <div className="row">
                                                <textarea
                                                    className="input"
                                                    readOnly={false}
                                                    onChange={e => handleOnChange(e, "request")}
                                                    maxLength={300}

                                                    autoComplete="off"
                                                    id="reason"
                                                    value={state.request ? state.request : ""}
                                                />


                                        </div>
                                    </div>

                                    {(type === TYPES.MANAGER && (state.selectedRowIds !== null && state.selectedRowIds.length === 1 && parseInt(state.selectedRowIds[0]) === parseInt(userId))) &&
                                        <div className="form-group">

                                            <div className="row info-msg">
                                                <p className="label info">
                                                    {exclamationIconInfo}
                                                    <FormattedMessage
                                                        id="ThisRequestWillHaveToBeApprovedByHigherAuthority"/>
                                                </p>
                                            </div>
                                        </div>}

                                    {
                                        type === TYPES.MANAGER &&
                                        state.selectedRowIds !== null &&
                                        state.selectedRowIds.length === 1 &&
                                        !state.selectedRowIds.includes(userId.toString()) &&
                                        state.allEmployees.some(
                                            employee =>
                                                parseInt(state.selectedRowIds[0]) === parseInt(employee.id) &&
                                                employee.type === TYPES.MANAGER
                                        ) && (
                                            <div className="form-group">
                                                <div className="row info-msg">
                                                    <p className="label danger">
                                                        {exclamationIcon}
                                                        <FormattedMessage
                                                            id="HigherAuthorityRequiredToAddVacationToOtherManagers"/>
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        type === TYPES.MANAGER &&
                                        state.selectedRowIds !== null &&
                                        state.selectedRowIds.length > 1 &&
                                        state.allEmployees.some(
                                            employee =>
                                                state.selectedRowIds.includes(employee.id.toString()) &&
                                                employee.type === TYPES.MANAGER &&
                                                ! state.selectedRowIds.includes(userId.toString())
                                        ) && (
                                            <div className="form-group">
                                                <div className="row info-msg">
                                                    <p className="label danger">
                                                        {exclamationIcon}
                                                        <FormattedMessage
                                                            id="OneOrMoreSelectedEmployeesAreManagersAndYouDontHaveAuthorityToAddVacation"/>
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {(type === TYPES.MANAGER && (state.selectedRowIds !== null && state.selectedRowIds.length > 0 && state.selectedRowIds.length !== 1 && state.selectedRowIds.includes(userId.toString()))) &&
                                        <div className="form-group">

                                            <div className="row info-msg">
                                                <p className="label info">
                                                    {exclamationIconInfo}
                                                    <FormattedMessage
                                                        id="OneOrMoreRequestWillHaveToBeApprovedByHigherAuthority"/>
                                                </p>
                                            </div>
                                        </div>}

                                    {
                                        type === TYPES.ADMIN &&
                                        state.selectedRowIds !== null &&
                                        state.selectedRowIds.length > 1 &&
                                        state.allEmployees.some(
                                            employee =>
                                                state.selectedRowIds.includes(employee.id.toString()) &&
                                                employee.type === TYPES.ADMIN
                                        ) && (
                                            <div className="form-group">
                                                <div className="row info-msg">
                                                    <p className="label danger">
                                                        {exclamationIcon}
                                                        <FormattedMessage
                                                            id="OneOrMoreSelectedEmployeesAreAdminsAndYouDontHaveAuthorityToAddVacation"/>
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    }


                                    <div className="row buttons-container">
                                        <button onClick={() => closeVacationCalendar()} className="btn btn-default">
                                            <FormattedMessage
                                                id="Cancel" defaultMessage="Cancel"/></button>
                                        <button onClick={(e) => createEmployeeLeave()}
                                                title={`${state.from === null ? intl.formatMessage({id: "PleaseSelectDates"}) : ''}`}
                                                className={`btn btn-default ${state.from === null ? 'btn-disabled' : 'btn btn-success'}`}>
                                            <FormattedMessage
                                                id="Save" defaultMessage="Save"/></button>
                                    </div>

                                </div>
                            </div>
                        </>

                    }

                </div>
                <div
                    className={state.confirmationModalVisible ? "visible modal confirmation-modal bigger" : "modal confirmation-modal"}>
                               <span
                                   onClick={() => closeConfirmationModal()}
                                   className="ml-auto close-icon">
                                    {closeIcon}
                                </span>
                    <div className="modal-content">
                        <FormattedMessage
                            id="VacationAddedSuccessfully"/>
                    </div>
                </div>

                <RightPanel
                    isOpened={state.rightPanelStyleName}
                    closeRightPanel={closeRightPanel}
                    title={<FormattedMessage id="VacationDetails" defaultMessage="VacationRequestDetails"/>}
                    accountDetailsId={state.leaveId ? state.leaveId : null}
                    children={
                        state.leaveId !== null ?
                            <VacationRequestDetails
                                companyId={companyId}
                                leaveId={state.leaveId ? state.leaveId : null}
                                selectedLeaveType={state.selectedLeaveType}
                                closeRightPanel={closeRightPanel}
                            /> : null
                    }
                />

            </div>
        );
}

export default VacationCalendarAll;
