import {getCompanyById} from "../../../services/companiesServices/CompaniesService";
import {getAllRolesFromCompany} from "../../../services/companiesServices/CompaniesRolesService";
import {getAllEmployeesFromCompany} from "../../../services/companiesServices/CompaniesEmployeesService";

export const getEmployeesAndRolesHandler = async (companyId) => {
    let error = null;
    const employeesResponse = await getAllEmployeesFromCompany(companyId);
    const rolesResponse = await getAllRolesFromCompany(companyId);
    let allCompanyRolesAndEmployees = null;

    if (employeesResponse.ok && rolesResponse.ok) {
        allCompanyRolesAndEmployees =  {
            allEmployees: employeesResponse.data,
            allRoles:rolesResponse.data,
        }

    } else {
        error ="Error getAllEmployeesByFacilityIdHandler";
    }
    return {
        data: {
            error: error,
            allCompanyRolesAndEmployees:allCompanyRolesAndEmployees
        }
    };
}


export const getCompanyDetailsHandler = async (companyId) => {
    let error = null;
    let companyDetails = null;
    const companyDetailsResponse = await getCompanyById(companyId);


    if (companyDetailsResponse.ok) {

        companyDetails = companyDetailsResponse.data
    } else {
        error ="Error getCompanyDetails";
    }
    return {
        data: {
            error: error,
            companyDetails:companyDetails
        }
    };
}





