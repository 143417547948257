import React, {useEffect, useRef, useState} from "react";
import {FormattedMessage} from "react-intl";

function LunchBreakOptionRow(props) {

    const onDeleteOptionRow = (e) => {
        e.stopPropagation();
        props.deleteOptionRow(props.optionRowId);
    }



    const onInputChange = (inputType, e) => {
        props.saveOptionRowChangesInState(inputType, e, props.optionRowId, props.index);
    }


    return (
        <div
            index={props.index}
            className={"field-row" + (props.rowErrorIndex ? " has-errors" : "")}>
            <div className={"row field-row-editables-container lunch-break-option-row-container editable-inputs-container"}>
                <div className="col row">
                    <label className="label inline-label ml-0" htmlFor="name"> <FormattedMessage
                        id="ForScheduleOverPlaceholder"/></label>
                            <input
                                className="input"
                                type="number"
                                onChange={e => onInputChange("threshold", e.target.value)}
                                maxLength={80}
                                value={props.threshold}
                                autoComplete="off"
                                id="name"
                            />

                    <label className="label inline-label"> <FormattedMessage
                        id="hours"/></label>


                </div>
                <div className="col row">
                    <label className="label inline-label"> <FormattedMessage
                        id="breakTimeIs"/></label>


                            <input
                                className="input"
                                type="number"
                                onChange={e => onInputChange("pause", e.target.value)}
                                maxLength={80}
                                value={props.pause}
                                autoComplete="off"
                                id="name"
                            />

                    <label className="label inline-label"> <FormattedMessage
                        id="minutes"/></label>
                </div>
            </div>

            {<a className="action-btn btn btn-link text-danger"
                                                  onClick={(e) => {
                                                      onDeleteOptionRow(e)
                                                  }}>
                <FormattedMessage id="Delete"/>
            </a>}
        </div>


    );
}


export default LunchBreakOptionRow;
