import React, {useEffect, useState, useRef, useContext} from "react";
import {FormattedMessage} from "react-intl";
import {useParams} from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import Aside from "../../../components/Aside";
import Header from "../../../components/Header";
import Spinner from "../../../components/Spinner";
import RightPanel from "../../../components/RightPanel";
import NfcDetails from "./NfcDetails";
import {
    getAllNFCsByCompanyIdHandler
} from "../../../handlers/companiesHandlers/nfcHandlers/HandlerNFCsAll";
import {PATH} from "../../../routes/routes";
import {
    deleteNfcHandler, getAllNFCLocationsHandler
} from "../../../handlers/companiesHandlers/nfcHandlers/HandlerNFCDetails";
import closeIcon from "../../../assets/img/icon_close.svg";
import {deleteCompanyHandler} from "../../../handlers/companiesHandlers/HandlerCompaniesAll";
import Table from "../../../components/Table";
import Select from "react-select";
import TabNavigation from "../../../components/TabNavigation";
import * as StorageServices from "../../../services/StorageServices";
import {LOCALSTORAGE_ITEMS, TYPES, TYPES as TYPE} from "../../../util/Constants";
import {getUserAgent, selectStyles} from "../../../util/common";
import * as StorageService from "../../../services/StorageServices";
import {LocaleContext} from "../../../util/LocaleContext";
import {useNavigate} from "react-router";
import analyticsLogEvent from "../../../services/FirebaseService";


function NfcAll(props) {
    const {companyId} = useParams();
    const type = StorageServices.getItem(LOCALSTORAGE_ITEMS.TYPE);
    const navigateTo = useNavigate();
    const openedRightPanelStyleName = "open";
    const closedRightPanelStyleName = "closed";
    const localeContext = useContext(LocaleContext);
    const companyInfo = StorageService.getItem(LOCALSTORAGE_ITEMS.COMPANY_INFO) ? JSON.parse(StorageService.getItem(LOCALSTORAGE_ITEMS.COMPANY_INFO)) : null;
    const [state, setState] = useState({
        loading: true,
        name: "",
        nfcs: [],
        nfcDetailsId: null,
        rightPanelStyleName: closedRightPanelStyleName,
        isDeleteCompanyAlertOpen: false,
        isDeleteNfcAlertOpen: false,
        hamburgerMenuVisible: false,
        selectedNfc: null,
        location: "",
        placement: "",
        selectedLocation: null,
        allAvailableLocations:null,

    });

    useEffect(() => {
        analyticsLogEvent("page_view", {
            page_title: "NfcAll"
        });
        getPageContent();
    }, []);




    const getPageContent = async () => {

        getAllNFCLocationsHandler(companyId).then(locationsResponse => {
            getAllNFCsByCompanyIdHandler(companyId).then(nfcResponse => {
                const nfcs = nfcResponse.data.company.nfcs.map(nfc => {
                    const nfcLocation = locationsResponse.data.locations?.filter(location => location.places?.map(place=> place.nfcId).includes(nfc.id))[0];
                    return {
                       ...nfc,
                        location: nfcLocation?.name,
                        placement: nfcLocation?.places?.filter(place => place.nfcId === nfc.id)[0].name
                    }
                })
                const callback = () => {
                    setState({
                        ...state,
                        loading: false,
                        name: companyInfo?.name,
                        allAvailableLocations: locationsResponse.data.locations,
                        nfcs: nfcs,
                        nfcDetailsId: null,
                        rightPanelStyleName: closedRightPanelStyleName,
                    })
                }

                if (type !== TYPES.SUPER_ADMIN) {
                    localeContext.setLocale(StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE), callback);
                }else{
                    callback();
                }
            });
        })
    }




    const columns = [
        {
            Header: <FormattedMessage id="Name" defaultMessage="Name" />,
            accessor: "name",
            isSortable: true,
        },
        {
            Header: <FormattedMessage id="Location" defaultMessage="Location" />,
            accessor: "location",
            isSortable: true,
        },
        {
            Header: <FormattedMessage id="NFCPlacement" defaultMessage="NFCPlacement" />,
            accessor: "placement",
            isSortable: true,
        },
        {
            Header: "Actions",
            accessor: "actions",
            isSortable: false,

        },
    ];

    const getAvailableLocations = () => {
        const locs = state.allAvailableLocations.map(e => {
            return {
                label: e.name,
                value: e.id
            }
        });
        locs.unshift({
            label: <FormattedMessage id="All" defaultMessage="All" />,
            value: null
        });
        return locs;
    }

    const createNewNFC = () => {

        setState({
            ...state,
            nfcDetailsId: "",
            rightPanelStyleName: openedRightPanelStyleName
        });
    }

    const editNfc = (nfcId) => {
        setState({
            ...state,
            nfcDetailsId: nfcId,
            rightPanelStyleName: openedRightPanelStyleName
        });
    }

    const closeRightPanel = (isSaveBtnClicked) => {

        if (isSaveBtnClicked) {
            getPageContent();
        }else{
            setState({
                ...state,
                rightPanelStyleName: closedRightPanelStyleName,
                nfcDetailsId: ''
            });
        }
    }


    const openDeleteNfcAlert = (nfcId) => {


        setState({
            ...state,
            isDeleteNfcAlertOpen: true,
            selectedNfc: nfcId,
        });
    }


    const hideDeleteNfcAlert = () => {
        setState({
            ...state,
            isDeleteNfcAlertOpen: false,
            hamburgerMenuVisible:false,
            selectedNfc: null
        })
    }


    const openDeleteCompanyAlert = (e) => {
        e.stopPropagation();
        setState({
            ...state,
            isDeleteCompanyAlertOpen: true,
        });
    }

    const hideDeleteCompanyAlert = () => {
        setState({
            ...state,
            isDeleteCompanyAlertOpen: false,
            hamburgerMenuVisible: false
        })
    }


    const deleteCompany = async (companyId) => {
        deleteCompanyHandler(companyId).then(()=>{
            navigateTo(PATH.ALL_COMPANIES);
        })
    }


    const deleteNfc = async (beaconId) => {
        deleteNfcHandler(beaconId).then(()=>{
            getAllNFCsByCompanyIdHandler(companyId).then(response => {
                setState({
                    ...state,
                    loading: false,
                    nfcs: response.data.company.nfcs,
                    isDeleteNfcAlertOpen: false,
                    rightPanelStyleName:closedRightPanelStyleName,
                    hamburgerMenuVisible:false
                })
            });
        })
    }


    const getDeleteNfcAlert = () =>{
        if(state.isDeleteNfcAlertOpen) {
            return {
                title: <FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="NFC" defaultMessage="NFC"/> }} />,
                contentRow1: <FormattedMessage id="DoYouWantToDeleteParam" values={{ param: getSelectedNfcName() }} />,
                deleteAction1: () => deleteNfc(state.selectedNfc),
                deleteText1: <FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="NFC" defaultMessage="NFC"/> }} />,
                cancelAction: () => hideDeleteNfcAlert(),
                cancelText: <FormattedMessage id="Cancel" defaultMessage="Cancel"/>,
                hideAlert: () => hideDeleteNfcAlert()
            }
        }
    }


    const getDeleteCompanyAlert = () =>{
        if(state.isDeleteCompanyAlertOpen) {
            return {
                title: <FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="Company" defaultMessage="Delete Company"/> }} />,
                contentRow1: <FormattedMessage id="DoYouWantToDeleteParam" values={{ param: state.name }} />,
                deleteAction1: () => deleteCompany(companyId),
                deleteText1: <FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="Company" defaultMessage="Delete Company"/> }} />,
                cancelAction: () => hideDeleteCompanyAlert(),
                cancelText: <FormattedMessage id="Cancel" defaultMessage="Cancel"/>,
                hideAlert: () => hideDeleteCompanyAlert()
            }
        }
    }

    const openHamburgerMenu = () => {
        setState({
            ...state,
            hamburgerMenuVisible:!state.hamburgerMenuVisible
        })
    }

    const getSelectedNfcName = () => state.nfcs.filter(e => state.selectedNfc?.includes(e.id)).map(e => {
        return e.name;
    });

    const onSelectedRowId = (rowId) => {
        editNfc(state.nfcs[rowId].id);
    };

    const onDeleteRowId = (rowId) => {
        openDeleteNfcAlert(state.nfcs[rowId].id);
    };



    const handleOnChange = (e, input) => {
         if (input === "location") {
            setState({
                ...state,
                errorMsg: null,
                selectedLocation: e !== null ? {
                        label: e.label,
                    value: e.value
                    } : null
            })
        }



    }

    return state.loading?
        <Spinner/>
        : (
        <div className="content">

            <Header
                alert={getDeleteNfcAlert()}
                alert2={getDeleteCompanyAlert()}
                companyId={companyId}
                hasBackBtn={true}
                headerTitle={state.name}
                hasHamburgerMenu={true}
                openHamburgerMenu={() => openHamburgerMenu()}
                isHamburgerMenuVisible={state.hamburgerMenuVisible}
                hamburgerLinkName1={<FormattedMessage id="DeleteCompany" defaultMessage="Delete Company"/>}
                hamburgerLinkAction1={(e) => openDeleteCompanyAlert(e)}


            />
            <div className="inner-content inner-content-for-table">
                {props.hasTabNavigation &&
                    <TabNavigation
                        companyId={companyId}
                        activeTab={3}
                    />
                }
                {state.nfcs ?
                <Table
                    topActions={
                        <section className="top-actions">


                            <div className="form-group select-form-group">
                                <div className="row">
                                    <label className="label">
                                        <FormattedMessage id="Location"/>
                                    </label>

                                </div>
                                <div className="row">
                                    <FormattedMessage id="All" defaultMessage="All">
                                        {placeholder =>
                                            <Select
                                                className="select"
                                                options={getAvailableLocations()}

                                                onChange={e => handleOnChange(e, "location")}
                                                placeholder={placeholder}
                                                noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                                id="location"
                                                styles={selectStyles}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                            />
                                        }
                                    </FormattedMessage>
                                </div>
                            </div>


                            {type ==TYPE.SUPER_ADMIN && <button className="btn btn-success only-for-desktop" onClick={() => createNewNFC()}><FormattedMessage id="NewNFC" defaultMessage="NewNFC"/></button>}
                            {type ==TYPE.SUPER_ADMIN && <button className="btn btn-success only-for-small-screens" onClick={() => createNewNFC()}><img src={closeIcon}/></button>}

                        </section>
                    }

                    columns={columns}
                    data={state.nfcs}
                    onSelectedRowId={onSelectedRowId}
                    deleteAction={onDeleteRowId}
                    selectedItemId = {state.nfcDetailsId}
                    closeRightPanel={closeRightPanel}
                    isNfcTable={true}
                    searchBoxLabel={<FormattedMessage id="SearchNFC" defaultMessage="SearchNFC"/>}
                    filterAccessor={"location"}
                    filterValue = {state.selectedLocation? state.selectedLocation : null}
                />
                    :
                    <div className="no-data-yet-container">
                        <FormattedMessage id="NoNFCsYet" defaultMessage="NoNFCsYet"/>
                    </div>

                }

            </div>
            <RightPanel
                isOpened={state.rightPanelStyleName}
                closeRightPanel = {closeRightPanel}
                title={!state.nfcDetailsId?
                    <FormattedMessage id="NewNFC" defaultMessage="NewNFC"/>
                    :
                    <FormattedMessage id="EditNFC" defaultMessage="EditNFC"/>
                }
                hasHamburgerMenu={true}
                openHamburgerMenu={() => openHamburgerMenu()}
                isHamburgerMenuVisible={state.hamburgerMenuVisible}
                hamburgerLinkName1={<FormattedMessage id="DeleteNFC" defaultMessage="DeleteNFC"/>}
                hamburgerLinkAction1={(e) => openDeleteNfcAlert(e, state.nfcDetailsId)}
                children={
                    state.nfcDetailsId!==null ?
                    <NfcDetails
                        nfc={state.nfc}
                        nfcDetailsId = {state.nfcDetailsId}
                        companyId = {companyId}
                        closeRightPanel = {closeRightPanel}

                    /> : null
                }
            />

        </div>
    );
}

export default NfcAll;
