import * as ClientConfig from "../../config/ClientConfig.js";
import * as StorageService from "../StorageServices.js";
import {LOCALSTORAGE_ITEMS} from "../../util/Constants";
import {handleAuthEmptyResponse, handleAuthResponse} from "../../util/api/restClient";


const getClockInOut =  async (from, to, companyId) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/clock-in-out?"+
            "workStatus=CLOCKED_IN_OUT&"+
            (from?"from="+from.toISOString()+"&" : "")+
            (to?"to="+to.toISOString() +"&" : "")+
            (companyId?"companyId="+companyId: "");

        return fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            }
        });
    });
}


const autoClockOutAllEmployees =  async () => {
    return handleAuthEmptyResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/clock-in-out/auto-clock-out-all-employee";
        const response = fetch(finalURL, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            }
        });
        return response;
    });
}



export {
    getClockInOut,
    autoClockOutAllEmployees
}
