import React, {useContext, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";

import Header from "../../components/Header";
import 'react-calendar/dist/Calendar.css';
import * as StorageService from "../../services/StorageServices";
import {LOCALSTORAGE_ITEMS, TYPES} from "../../util/Constants";
import {
    editCompanyEmployeeByIdHandler,
    getCompanyEmployeeByIdHandler
} from "../../handlers/companiesHandlers/employeesHandlers/HandlerEmployeesDetails";
import Alert from "../../components/Alert";
import {closeIcon} from "../../components/Icons";
import {clearStorageItemsAndLogout, getUserAgent} from "../../util/common";
import {LocaleContext} from "../../util/LocaleContext";
import * as StorageServices from "../../services/StorageServices";
import analyticsLogEvent from "../../services/FirebaseService";

function Profile() {
    const appVersion = StorageService.getItem(LOCALSTORAGE_ITEMS.APP_VERSION);
    const userId = StorageService.getItem(LOCALSTORAGE_ITEMS.USER_ID)? JSON.parse(StorageService.getItem(LOCALSTORAGE_ITEMS.USER_ID)):null;
    const type = StorageServices.getItem(LOCALSTORAGE_ITEMS.TYPE);
    const localeContext = useContext(LocaleContext);
    const [state, setState] = useState({
        loading: false,
        errorMsg: null,
        firstName: "",
        lastname: "",
        email: "",
        phone: "",
        confirmationModalVisible: false,
    });

    useEffect(() => {
        analyticsLogEvent("page_view", {
            page_title: "Profile"
        });
        getFormData();
    }, []);


    const handleOnChange = (e, input) => {
        if (input === "firstName") {
            setState({
                ...state,
                firstName: e.target.value
            })
        } else if (input === "lastName") {
            setState({
                ...state,
                lastName: e.target.value
            })

        } else if (input === "email") {
            setState({
                ...state,
                email: e.target.value
            })
        } else if (input === "phone") {
            setState({
                ...state,
                phone: e.target.value
            })
        }
    }



    const updateBody = {
        "employeeId": userId,
        "firstName": state.firstName,
        "lastName":state.lastName,
        "email": state.email !== "" ? state.email : null ,
        "phone": state.phone,
    }


    const handleSubmit = () => {
        editExistingAccount();
    }



    const validateData = () => {
        if (!state.firstName || !state.lastName || !state.phone  ) {
            setState({
                ...state,
                errorMsg: <FormattedMessage id="PleaseFillInAllFields" defaultMessage="PleaseFillInAllFields"/>
            })

            return false;
        }
        if (!state.phone.startsWith("+")) {
            setState({
                ...state,
                errorMsg: <FormattedMessage id="InvalidPhoneNumber" defaultMessage="InvalidPhoneNumber"/>
            })

            return false;

        }

        return true;
    }

    const getFormData = () => {
                getCompanyEmployeeByIdHandler(userId).then(employeeResponse => {
                    const callback = () => {
                        setState({
                            ...state,
                            firstName: employeeResponse.data.account.firstName,
                            lastName: employeeResponse.data.account.lastName,
                            email: employeeResponse.data.account.email,
                            phone: employeeResponse.data.account.phone,
                            errorMsg:null,
                        })
                    }
                    if (type !== TYPES.SUPER_ADMIN) {
                        localeContext.setLocale(StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE), callback);
                    }else{
                        callback();
                    }
                })
    }

    const editExistingAccount = () => {

        if (validateData()) {
            editCompanyEmployeeByIdHandler([updateBody]).then(response => {
                if (response.data.error == null) {
                    setState({
                        ...state,
                        confirmationModalVisible: true,
                        errorMsg:null,

                    })
                    setTimeout(function () {
                        getFormData();
                    }, 2000);

                }else{
                    setState({
                        ...state,
                        errorMsg:response.data.message
                    })
                }
            })
        }
    }


    const openLogoutAlert = (e) => {
        e.stopPropagation();
        setState({
            ...state,
            isLogoutAlertOpen: true,
        });
    }

    const hideAlert = () => {

        setState({
            ...state,
            isLogoutAlertOpen: false,
            selectedAccount: null
        })
    }




    const closeConfirmationModal = () => {
        setState({
            ...state,
            confirmationModalVisible: false
        })
    }

    return (
        <>
            {state.isLogoutAlertOpen &&
            <Alert
                title={<FormattedMessage id="Logout" defaultMessage="Logout"/>}
                contentRow1={<FormattedMessage id="LogoutConfirmation" defaultMessage="LogoutConfirmation"/>}
                deleteAction1={() => clearStorageItemsAndLogout()}
                deleteText1={<FormattedMessage id="Logout" defaultMessage="Logout"/>}
                cancelAction={() => hideAlert()}
                cancelText={<FormattedMessage id="Cancel" defaultMessage="Cancel"/>}
                hideAlert={() => hideAlert()}
            />
            }
            <div className="content">
                <Header
                    headerTitle={<FormattedMessage id="Settings" defaultMessage="Settings"/>}
                    hasBackBtn={false}
                />
                <div className="inner-content">
                    <div className="empty-div" style={{height: "25px"}}></div>
                    <section className="form-container">
                        <h4><FormattedMessage id="ProfileDetails" defaultMessage="ProfileDetails"/></h4>
                        <div className="form with-big-inputs">

                            <div className="form-group">
                                <div className="row">
                                    <label className="label" htmlFor="firstName"> <FormattedMessage id="FirstName" defaultMessage="First Name"/></label>
                                </div>
                                <div className="row">
                                    <FormattedMessage id="FirstName" defaultMessage="First Name">
                                        {placeholder =>
                                            <input
                                            className="input"
                                            type="text"
                                            onChange={e => handleOnChange(e, "firstName")}
                                            maxLength={80}
                                            placeholder={placeholder}
                                            value={state.firstName}
                                            autoComplete="off"
                                            id="firstName"
                                            />
                                        }
                                    </FormattedMessage>
                                </div>
                            </div>


                            <div className="form-group">
                                <div className="row">
                                    <label className="label" htmlFor="lastName"> <FormattedMessage id="LastName" defaultMessage="Last Name"/></label>
                                </div>
                                <div className="row">
                                    <FormattedMessage id="LastName" defaultMessage="Last Name">
                                        {placeholder =>
                                            <input
                                                className="input"
                                                type="text"
                                                onChange={e => handleOnChange(e, "lastName")}
                                                maxLength={80}
                                                placeholder={placeholder}
                                                value={state.lastName ? state.lastName : ""}
                                                autoComplete="off"
                                                id="lastName"
                                            />
                                        }
                                    </FormattedMessage>
                                </div>
                            </div>



                            <div className="form-group">
                                <div className="row">
                                    <label className="label" htmlFor="email">
                                        <FormattedMessage id="Email" defaultMessage="Email"/></label>
                                </div>
                                <div className="row">
                                    <FormattedMessage id="Email" defaultMessage="Email">
                                        {placeholder =>
                                            <input
                                                className="input"
                                                type="text"
                                                onChange={e => handleOnChange(e, "email")}
                                                maxLength={100}
                                                placeholder={placeholder}
                                                value={state.email? state.email : ""}
                                                autoComplete="off"
                                                id="email"
                                            />
                                        }
                                    </FormattedMessage>
                                </div>
                            </div>


                            <div className="form-group">
                                <div className="row">
                                    <label className="label" htmlFor="phone"> <FormattedMessage id="PhoneNumber" defaultMessage="Phone Number"/></label>
                                </div>
                                <div className="row">
                                    <FormattedMessage id="PhoneNumber" defaultMessage="Phone Number">
                                        {placeholder =>
                                            <input
                                                className="input"
                                                type="tel"
                                                onChange={e => handleOnChange(e, "phone")}
                                                maxLength={100}
                                                placeholder={placeholder}
                                                value={state.phone}
                                                autoComplete="off"
                                                id="phone"
                                            />
                                        }
                                    </FormattedMessage>
                                </div>
                            </div>


                            <div className="form-group">
                                <div className="row">
                                    <label className="label"><FormattedMessage id="CurrentVersion" defaultMessage="CurrentVersion"/>: {appVersion}</label>
                                </div>
                            </div>


                            <div className="row">
                                <button onClick={() => handleSubmit()} className="btn btn-success"><FormattedMessage
                                    id="Save" defaultMessage="Save"/></button>
                            </div>

                            <div
                                className={state.confirmationModalVisible ? "visible modal confirmation-modal" : "modal confirmation-modal"}>
                               <span
                                   onClick={() => closeConfirmationModal()}
                                   className="ml-auto close-icon">
                                    {closeIcon}
                                </span>
                                <div className="modal-content"><FormattedMessage
                                    id="ChangesSaved"/></div>
                            </div>

                            {state.errorMsg && <div className="error">&#9888;&nbsp;{state.errorMsg}</div>}

                        </div>
                        <div className="row">
                            <a onClick={(e)=> openLogoutAlert(e)} className="only-for-small-screens link neutral logout-link"><FormattedMessage id="Logout" defaultMessage="Logout"/></a>
                        </div>
                    </section>
                </div>

            </div>
        </>

    );
}

export default Profile;
