import {LOCALSTORAGE_ITEMS, STATUSES} from "../../../util/Constants";
import VacationLeavePdfTemplate from "../VacationLeavePdfTemplate";
import UnpaidLeavePdfTemplate from "../UnpaidLeavePdfTemplate";
import TrainingLeavePdfTemplate from "../TrainingLeavePdfTemplate";
import PaternityLeavePdfTemplate from "../PaternityLeavePdfTemplate";
import ParentalLeavePdfTemplate from "../ParentalLeavePdfTemplate";
import MaternityRiskLeavePdfTemplate from "../MaternityRiskLeavePdfTemplate";
import SpecialEventsLeavePdfTemplate from "../SpecialEventsLeavePdfTemplate";
import AccommodationLeavePdfTemplate from "../AccommodationLeavePdfTemplate";
import CarerLeavePdfTemplate from "../CarerLeavePdfTemplate";
import OncologyCareLeavePdfTemplate from "../OncologyCareLeavePdfTemplate";
import SickChildCaringLeavePdfTemplate from "../SickChildCaringLeavePdfTemplate";
import DisabledChildCaringLeavePdfTemplate from "../DisabledChildCaringLeavePdfTemplate";
import QuarantineLeavePdfTemplate from "../QuarantineLeavePdfTemplate";
import {formatDate} from "../../../util/common";
import * as StorageService from "../../../services/StorageServices";

const LeavePdfRenderer = ({ state, companyInfo }) => {

    if (!state.from || !state.to || state.status !== STATUSES.APPROVED || !state.employeesLeaves) {
        return null;
    }

    const pdfProps = {
        height: '550px',
        width: '100%',
        name: state.name,
        employeeLeaves: state.employeesLeaves,
        created: formatDate(state.employeesLeaves?.leaveCreated),
        modified: formatDate(state.employeesLeaves?.leaveModified),
        from: formatDate(state?.from),
        companyInfo: companyInfo,
        to: formatDate(state?.to),

    };

    const templates = {
        VACATION: VacationLeavePdfTemplate,
        UNPAID: UnpaidLeavePdfTemplate,
        TRAINING: TrainingLeavePdfTemplate,
        PATERNITY: PaternityLeavePdfTemplate,
        PARENTAL: ParentalLeavePdfTemplate,
        MATERNITY: ParentalLeavePdfTemplate,
        MATERNITY_RISK: MaternityRiskLeavePdfTemplate,
        SPECIAL_EVENTS: SpecialEventsLeavePdfTemplate,
        ACCOMMODATION: AccommodationLeavePdfTemplate,
        CARER: CarerLeavePdfTemplate,
        ONCOLOGY_CARE: OncologyCareLeavePdfTemplate,
        SICK_CHILD_CARING: SickChildCaringLeavePdfTemplate,
        DISABLED_CHILD_CARING: DisabledChildCaringLeavePdfTemplate,
        QUARANTINE: QuarantineLeavePdfTemplate,
    };

    const TemplateComponent = templates[state.typeId];
    return TemplateComponent ? <TemplateComponent {...pdfProps} /> : null;
};

export default LeavePdfRenderer;
