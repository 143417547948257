import React from "react";
import {Document, Page, PDFViewer, Text, View} from "@react-pdf/renderer";

import { styles } from "./styles/PdfStyles";
import Footer from "./components/Footer";
import SignatureWrapper from "./components/SignatureWrapper";
import CompanyLogo from "./components/CompanyLogo";



function SpecialEventsLeavePdfTemplate(props) {

    return (
        <>
            <PDFViewer style={{ width: props.width, height: props.height }}>
                <Document>
                    <Page size="A4" style={styles.page}>
                        <View>
                            <CompanyLogo/>
                        </View>
                        <View style={styles.header}>
                            <Text style={styles.semiBold}>Cerere de concediu pentru evenimente deosebite</Text>
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.text}>
                                <Text style={styles.alineat}>Subsemnatul/a</Text>, <Text style={styles.underlined}>{props.name}</Text>, angajat/ă al/a firmei <Text >{props.companyInfo?.name}</Text>, vă rog să-mi aprobați efectuarea zilelor de concediu în perioada <Text style={styles.underlined}>{props.from} &nbsp;-&nbsp; {props.to} pentru:</Text>
                            </Text>
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.text}>
                                {props.employeeLeaves.leaveRequest ? props.employeeLeaves.leaveRequest :"-"}
                            </Text>
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.text}>
                                Anexez prezentei cereri copia documentului justificativ.
                            </Text>
                        </View>



                        {props && <SignatureWrapper
                          {...props}
                       />}
                      <Footer/>
                    </Page>
                </Document>
            </PDFViewer>
        </>
    );

}

export default SpecialEventsLeavePdfTemplate;
