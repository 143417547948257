
import {
    getAllReportedProblemsByCompanyId,
    getReportedProblemByEmployeeId
} from "../../services/reportedProblemsServices/ReportedProblemsService";

export const getReportedProblemByEmployeeIdHandler = async (id, from, to) => {
    let error = null;
    const response = await getReportedProblemByEmployeeId(id, from, to);
    let allProblems = null;
    if (response.ok) {
        allProblems = response.data.map(e => {
            return {
                id: e.id,
                companyId: e.companyId,
                date: e.date,
                employee: e.employee,
                type: e.type,
                message: e.message,
                attemptsCount:e.attemptsCount,
                attemptsStartDate: e.attemptsStartDate,
                attemptsEndDate:e.attemptsEndDate,
                deviceModel:e.deviceModel,
                deviceOs: e.deviceOs,
                mobileAppVersion: e.mobileAppVersion
            }
        });
    } else {
        error ="Error getAllServiceProvidersHandler";
    }

    return {
        data: {
            allProblems: allProblems
        }
    };
}


export const getAllReportedProblemsByCompanyIdHandler = async (companyId, from, to) => {
    let error = null;
    const response = await getAllReportedProblemsByCompanyId(companyId, from, to);
    let allProblems = null;

    if (response.ok) {
        allProblems = response.data.map(e => {
            return {
                id: e.id,
                companyId: e.companyId,
                date: e.date,
                employee: e.employee ,
                type: e.type,
                message: e.message,
                attemptsCount:e.attemptsCount,
                attemptsStartDate: e.attemptsStartDate,
                attemptsEndDate:e.attemptsEndDate,
                deviceModel:e.deviceModel,
                deviceOs: e.deviceOs,
                mobileAppVersion: e.mobileAppVersion
            }
        }).filter(e => e.type === "USER_REPORTED" || e.type === "USER_REPORTED_CLOCKING");
    } else {
        error ="Error getAllServiceProvidersHandler";
    }

    return {
        data: {
            allProblems: allProblems
        }
    };
}


export const getDEMO_AllReportedProblemsByCompanyIdHandler = async (companyId, from, to) => {
    let error = null;
    const response = await getAllReportedProblemsByCompanyId(companyId, from, to);
    let allProblems = null;

    if (response.ok) {
        allProblems = response.data.map(e => {
            return {
                id: e.id,
                companyId: e.companyId,
                date: e.date,
                employee: e.employee ,
                type: e.type,
                message: e.message,
                attemptsCount:e.attemptsCount,
                attemptsStartDate: e.attemptsStartDate,
                attemptsEndDate:e.attemptsEndDate,
                deviceModel:e.deviceModel,
                deviceOs: e.deviceOs,
                mobileAppVersion: e.mobileAppVersion
            }
        }).filter(e => e.type === "USER_REPORTED" || e.type === "USER_REPORTED_CLOCKING");
    } else {
        error ="Error getAllServiceProvidersHandler";
    }

    return {
        data: {
            allProblems: allProblems
        }
    };
}







