import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import 'react-calendar/dist/Calendar.css';
import Header from "../../components/Header";
import Spinner from "../../components/Spinner";
import RightPanel from "../../components/RightPanel";
import NfcTagsGlobalDetails from "./NfcTagsGlobalDetails";

import closeIcon from "../../assets/img/icon_close.svg";
import Table from "../../components/Table";
import * as StorageServices from "../../services/StorageServices";
import {LOCALSTORAGE_ITEMS, TYPES as TYPE} from "../../util/Constants";
import {
    getAllCompaniesHandler, getAllNFCLocationsHandler, getAllNFCsByCompanyHandler,
    getAllNFCsHandler
} from "../../handlers/nfcTagsHandlers/HandlerNFCtagsAll";
import {deleteNfcHandler} from "../../handlers/nfcTagsHandlers/HandlerNFCtagsDetails";
import Select from "react-select";
import {getUserAgent, selectStyles} from "../../util/common";
import analyticsLogEvent from "../../services/FirebaseService";
import * as StorageService from "../../services/StorageServices";




function NfcTagsGlobalAll() {
    const type = StorageServices.getItem(LOCALSTORAGE_ITEMS.TYPE);
    const openedRightPanelStyleName = "open";
    const closedRightPanelStyleName = "closed";
    const [state, setState] = useState({
        loading: true,
        name: "",
        nfcs: [],
        nfcDetailsId: null,
        rightPanelStyleName: closedRightPanelStyleName,
        isDeleteCompanyAlertOpen:false,
        isDeleteNfcAlertOpen:false,
        hamburgerMenuVisible:false,
        selectedNfc: null,
        location: "",
        placement: "",
        selectedCompany: null,
        allAvailableCompanies:null,

    });

    useEffect(() => {
        analyticsLogEvent("page_view", {
            page_title: "NfcTagsGlobalAll"
        });
        getPageContent();
    }, []);




    const getPageContent = async () => {
        getAllCompaniesHandler().then(companiesResponse => {
            getAllNFCsHandler().then(nfcResponse => {
                const nfcs = nfcResponse.data.company.nfcs.map(nfc => {
                    return {
                       ...nfc,
                    }
                })
                setState({
                    ...state,
                    loading: false,
                    name: nfcResponse.data.company.name,
                    allAvailableCompanies: companiesResponse.data.allCompanies,
                    selectedCompany: null,
                    nfcs: nfcs,
                    nfcDetailsId: null,
                    rightPanelStyleName: closedRightPanelStyleName,
                })
            });
        })
    }

    const handleOnChange = (e) => {
        if (e.value === null) {
            getAllNFCsHandler().then(nfcResponse => {
                const nfcs = nfcResponse.data.company.nfcs.map(nfc => {
                    return {
                        ...nfc,
                    }
                })
                setState({
                    ...state,
                    loading: false,
                    nfcs: nfcs,
                    nfcDetailsId: "",
                    rightPanelStyleName: closedRightPanelStyleName,
                    selectedCompany: null
                })
            });
        } else {
            getAllNFCLocationsHandler(e.value).then(locationsResponse => {
                getAllNFCsByCompanyHandler(e.value).then(nfcResponse => {
                    const nfcs = nfcResponse.data.company.nfcs.map(nfc => {
                        const nfcLocation = locationsResponse.data.locations?.filter(location => location.places?.map(place=> place.nfcId).includes(nfc.id))[0];
                        return {
                            ...nfc,
                            location: nfcLocation?.name,
                            placement: nfcLocation?.places?.filter(place => place.nfcId === nfc.id)[0].name
                        }
                    })

                    setState({
                        ...state,
                        loading: false,
                        name: nfcResponse.data.company.name,
                        nfcs: nfcs,
                        nfcDetailsId: "",
                        rightPanelStyleName: closedRightPanelStyleName,
                        selectedCompany: e.value
                    })
                });
            });
        }
    }


    const columns = [
        {
            Header: <FormattedMessage id="Name" defaultMessage="Name" />,
            accessor: "name",
            isSortable: true,
        },
        {
            Header: <FormattedMessage id="Location" defaultMessage="Location" />,
            accessor: "location",
            isSortable: true,
        },
        {
            Header: <FormattedMessage id="NFCPlacement" defaultMessage="NFCPlacement" />,
            accessor: "placement",
            isSortable: true,
        },
        {
            Header: "Actions",
            accessor: "actions",
            isSortable: false,

        },
    ];

    const getAvailableCompanies = () => {
        const locs = state.allAvailableCompanies?.map(e => {
            return {
                label: e.name,
                value: e.id
            }
        });
        locs.unshift({
            label: "Not assigned",
            value: null
        });
        return locs;
    }

    const createNewNFC = () => {

        setState({
            ...state,
            nfcDetailsId: "",
            rightPanelStyleName: openedRightPanelStyleName
        });
    }

    const editNfc = (nfcId) => {


        setState({
            ...state,
            nfcDetailsId: nfcId,
            rightPanelStyleName: openedRightPanelStyleName
        });
    }

    const closeRightPanel = (isSaveBtnClicked) => {
        if (isSaveBtnClicked) {
            if (state.selectedCompany) {
                getAllNFCLocationsHandler(state.selectedCompany).then(locationsResponse => {
                    getAllNFCsByCompanyHandler(state.selectedCompany).then(nfcResponse => {
                        const nfcs = nfcResponse.data.company.nfcs.map(nfc => {
                            const nfcLocation = locationsResponse.data.locations?.filter(location => location.places?.map(place=> place.nfcId).includes(nfc.id))[0];
                            return {
                                ...nfc,
                                location: nfcLocation?.name,
                                placement: nfcLocation?.places?.filter(place => place.nfcId === nfc.id)[0].name
                            }
                        })
                        setState({
                            ...state,
                            loading: false,
                            name: nfcResponse.data.company.name,
                            nfcs: nfcs,
                            nfcDetailsId: "",
                            rightPanelStyleName: closedRightPanelStyleName,
                        })
                    });
                });
            }else{
                getPageContent();
            }

        }else{
            setState({
                ...state,
                rightPanelStyleName: closedRightPanelStyleName,
                nfcDetailsId: '',
                name:'',
            });
        }
    }


    const openDeleteNfcAlert = (nfcId) => {

        setState({
            ...state,
            isDeleteNfcAlertOpen: true,
            selectedNfc: nfcId,
            name:''
        });
    }


    const hideDeleteNfcAlert = () => {
        setState({
            ...state,
            isDeleteNfcAlertOpen: false,
            hamburgerMenuVisible:false,
            selectedNfc: null
        })
    }


    const openDeleteCompanyAlert = (e) => {
        e.stopPropagation();
        setState({
            ...state,
            isDeleteCompanyAlertOpen: true,
        });
    }



    const deleteNfc = async (beaconId) => {
        deleteNfcHandler(beaconId).then(()=>{
            getAllNFCsHandler().then(response => {
                setState({
                    ...state,
                    loading: false,
                    nfcs: response.data.company.nfcs,
                    isDeleteNfcAlertOpen: false,
                    rightPanelStyleName:closedRightPanelStyleName,
                    hamburgerMenuVisible:false
                })
            });
        })
    }


    const getDeleteNfcAlert = () =>{
        if(state.isDeleteNfcAlertOpen) {
            return {
                title: <FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="NFC" defaultMessage="NFC"/> }} />,
                contentRow1: <FormattedMessage id="DoYouWantToDeleteParam" values={{ param: getSelectedNfcName() }} />,
                deleteAction1: () => deleteNfc(state.selectedNfc),
                deleteText1: <FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="NFC" defaultMessage="NFC"/> }} />,
                cancelAction: () => hideDeleteNfcAlert(),
                cancelText: <FormattedMessage id="Cancel" defaultMessage="Cancel"/>,
                hideAlert: () => hideDeleteNfcAlert()
            }
        }
    }




    const openHamburgerMenu = () => {
        setState({
            ...state,
            hamburgerMenuVisible:!state.hamburgerMenuVisible
        })
    }

    const getSelectedNfcName = () => state.nfcs.filter(e => state.selectedNfc?.includes(e.id)).map(e => {
        return e.name;
    });

    const onSelectedRowId = (rowId) => {
        editNfc(state.nfcs[rowId].id);
    };

    const onDeleteRowId = (rowId) => {
        openDeleteNfcAlert(state.nfcs[rowId].id);
    };





    return state.loading?
        <Spinner/>
        : (
        <div className="content">

            <Header
                alert={getDeleteNfcAlert()}
                hasBackBtn={false}
                headerTitle={<FormattedMessage id="NfcTags" defaultMessage="NfcTags"/>}
                hasHamburgerMenu={true}
                openHamburgerMenu={() => openHamburgerMenu()}
                isHamburgerMenuVisible={state.hamburgerMenuVisible}
                hamburgerLinkName1={<FormattedMessage id="DeleteCompany" defaultMessage="Delete Company"/>}
                hamburgerLinkAction1={(e) => openDeleteCompanyAlert(e)}


            />
            <div className="inner-content inner-content-for-table">
                <div className="empty-div" style={{height: "25px"}}></div>
                {state.nfcs ?
                <Table
                    topActions={
                        <section className="top-actions">


                            <div className="form-group select-form-group">
                                <div className="row">
                                    <label className="label">
                                        <FormattedMessage id="FilterNFCsby" defaultMessage="FilterNFCsby"/>
                                    </label>

                                </div>
                                <div className="row">

                                            <Select
                                                className="select"
                                                options={getAvailableCompanies()}

                                                onChange={e => handleOnChange(e)}

                                                noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                                id="location"
                                                styles={selectStyles}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                            />


                                </div>
                            </div>


                            {type ==TYPE.SUPER_ADMIN && <button className="btn btn-success only-for-desktop" onClick={() => createNewNFC()}><FormattedMessage id="NewNFC" defaultMessage="NewNFC"/></button>}
                            {type ==TYPE.SUPER_ADMIN && <button className="btn btn-success only-for-small-screens" onClick={() => createNewNFC()}><img src={closeIcon}/></button>}

                        </section>
                    }
                    columns={columns}
                    data={state.nfcs}
                    onSelectedRowId={onSelectedRowId}
                    deleteAction={onDeleteRowId}
                    selectedItemId = {state.nfcDetailsId}
                    closeRightPanel={closeRightPanel}
                    searchBoxLabel={<FormattedMessage id="SearchNFC" defaultMessage="SearchNFC"/>}
                    filterAccessor={"location"}
                    filterValue = {state.selectedCompany? state.selectedCompany : null}
                />
                    :
                    <div className="no-data-yet-container">
                        <FormattedMessage id="NoNFCsYet" defaultMessage="NoNFCsYet"/>
                    </div>

                }

            </div>
            <RightPanel
                isOpened={state.rightPanelStyleName}
                closeRightPanel = {closeRightPanel}
                title={! !!state.nfcDetailsId?
                    <FormattedMessage id="NewNFC" defaultMessage="NewNFC"/>
                    :
                    <FormattedMessage id="EditNFC" defaultMessage="EditNFC"/>
                }
                hasHamburgerMenu={true}
                openHamburgerMenu={() => openHamburgerMenu()}
                isHamburgerMenuVisible={state.hamburgerMenuVisible}
                hamburgerLinkName1={<FormattedMessage id="DeleteNFC" defaultMessage="DeleteNFC"/>}
                hamburgerLinkAction1={(e) => openDeleteNfcAlert(e, state.nfcDetailsId)}
                children={
                    state.nfcDetailsId!==null ?
                    <NfcTagsGlobalDetails
                        nfc={state.nfc}
                        nfcDetailsId = {state.nfcDetailsId}
                        companyId = {state.selectedCompany}
                        companyName={state.allAvailableCompanies.filter(company=> company.id === state.selectedCompany).map(company=> company.name)}
                        closeRightPanel = {closeRightPanel}

                    /> : null
                }
            />

        </div>
    );
}

export default NfcTagsGlobalAll;
