import React from "react";
import {Document, Page, PDFViewer, Text, View} from "@react-pdf/renderer";

import { styles } from "./styles/PdfStyles";
import Footer from "./components/Footer";
import SignatureWrapper from "./components/SignatureWrapper";
import CompanyLogo from "./components/CompanyLogo";



function ParentalLeavePdfTemplate(props) {

    return (
        <>
            <PDFViewer style={{ width: props.width, height: props.height }}>
                <Document>
                    <Page size="A4" style={styles.page}>
                        <View>
                            <CompanyLogo/>
                        </View>
                        <View style={styles.header}>
                            <Text style={styles.semiBold}>Concediu pentru cresterea copilului</Text>
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.text}>
                                <Text style={styles.alineat}>Subsemnatul/a</Text>, <Text style={styles.underlined}>{props.name}</Text>, angajat/ă al/a firmei <Text >{props.companyInfo?.name}</Text>, prin prezenta vă rog să îmi aprobați suspendarea contractului individual de muncă, conform Codului Muncii, art. 51, alin. 1, lit. A, respectiv pentru creșterea copilului în vârstă de până la 2 ani, începând cu data de <Text style={styles.underlined}>{props.from}</Text> și până la data de <Text style={styles.underlined}>{props.to}</Text>.
                            </Text>
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.text}>
                                Anexez prezentei cereri o copie după certificatul de naștere al copilului.
                            </Text>
                        </View>

                        {props && <SignatureWrapper
                          {...props}
                       />}
                      <Footer/>
                    </Page>
                </Document>
            </PDFViewer>
        </>
    );

}

export default ParentalLeavePdfTemplate;
