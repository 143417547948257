const getItem = (key) => localStorage.getItem(key);

const setItem = (key, value) => localStorage.setItem(key, value);

const clearStorage = () => localStorage.clear();

const removeItem = key => localStorage.removeItem(key);

const updateItem = (key, property, value) => {
  let obj = JSON.parse(localStorage.getItem(key));
  obj[property] = value;
  localStorage.setItem(key, JSON.stringify(obj));
}

export {
  clearStorage,
  getItem,
  removeItem,
  setItem,
  updateItem
}