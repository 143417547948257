import React from "react";
import {Document, Font, G, Page, Path, PDFViewer, Text, View, Image} from "@react-pdf/renderer";

import { styles } from "./styles/PdfStyles";
import Footer from "./components/Footer";
import SignatureWrapper from "./components/SignatureWrapper";
import CompanyLogo from "./components/CompanyLogo";


function MaternityRiskLeavePdfTemplate(props) {

    return (
        <>
            <PDFViewer style={{ width: props.width, height: props.height }}>
                <Document>
                    <Page size="A4" style={styles.page}>
                        <View>
                            <CompanyLogo/>

                        </View>
                        <View style={styles.header}>
                            <Text style={styles.semiBold}>Cerere pentru concediu de risc maternal</Text>
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.text}>
                                <Text style={styles.alineat}>Subsemnatul/a</Text>, <Text style={styles.underlined}>{props.name}</Text>, angajat/ă al/a firmei <Text >{props.companyInfo?.name}</Text>, vă rog să-mi aprobați concediul pentru risc maternal în perioada <Text style={styles.underlined}>{props.from} &nbsp;-&nbsp; {props.to}</Text>.
                            </Text>
                        </View>

                        {props && <SignatureWrapper
                          {...props}
                       />}
                      <Footer/>
                    </Page>
                </Document>
            </PDFViewer>
        </>
    );

}

export default MaternityRiskLeavePdfTemplate;
