import React, {useContext, useEffect, useState} from "react";
import {FormattedDate, FormattedMessage, FormattedTime} from "react-intl";
import 'react-calendar/dist/Calendar.css';

import {
    deleteEmployeeLeaveByIdHandler,
    editEmployeeLeaveByIdHandler, getAllEmployeeLeavesCountHandler,
    getEmployeeLeaveByIdHandler
} from "../../handlers/vacationPlannerHandlers/HandlerVacationRequestDetails";
import {warningIcon} from "../../components/Icons";
import {APP_DOMAINS, getStatusMessage, LOCALSTORAGE_ITEMS, STATUSES, TYPES} from "../../util/Constants";
import {PendingLeavesContext} from "../../util/PendingLeavesContext";
import * as StorageService from "../../services/StorageServices";
import {getLeaveTypeName} from "../../util/common";
import LeavePdfRenderer from "../../components/PdfTemplates/components/LeavePdfRenderer";
import * as ClientConfig from "../../config/ClientConfig";


function VacationRequestDetails(props) {
    const userId = StorageService.getItem(LOCALSTORAGE_ITEMS.USER_ID) ? JSON.parse(StorageService.getItem(LOCALSTORAGE_ITEMS.USER_ID)) : null;
    const type = StorageService.getItem(LOCALSTORAGE_ITEMS.TYPE) || "Unknown";
    const companyInfo = StorageService.getItem(LOCALSTORAGE_ITEMS.COMPANY_INFO) ? JSON.parse(StorageService.getItem(LOCALSTORAGE_ITEMS.COMPANY_INFO)) : null;
    const [state, setState] = useState({
        errorMsg: null,
        employee: null,
        name: "",
        phone: "",
        response: "",
        request: "",
        from: null,
        to: null,
        status: null,
        created: null,
        createdBy: null,
        modified: null,
        modifiedBy: null,
        totalEmployeesInVacation: null,
        type: null,
        typeId: null,
    });
    const pendingLeaveContext = useContext(PendingLeavesContext);
    useEffect(() => {
        getFormData();

    }, [props.leaveId]);


    const getFormData = () => {

        getEmployeeLeaveByIdHandler(props.leaveId).then(employeeResponse => {
            getAllEmployeeLeavesCountHandler(props.companyId, employeeResponse.data.request.from, employeeResponse.data.request.to).then(countResponse => {
                const selectedEmployee = employeeResponse.data;

                const newSelectedEmployee = {
                    ...selectedEmployee,
                    leaveType: selectedEmployee.request.type,
                    leaveStatus: selectedEmployee.request.status,
                    leaveFrom: selectedEmployee.request.from,
                    leaveTo: selectedEmployee.request.to,
                    leaveTimePeriod: `${new Date(selectedEmployee.request.from).toLocaleDateString()} - ${new Date(selectedEmployee.request.to).toLocaleDateString()}`,
                    leaveRequest: selectedEmployee.request.request,
                    leaveResponse: selectedEmployee.request.response,
                    leaveCreated: selectedEmployee.request.created,
                    leaveCreatedBy: selectedEmployee.request.createdBy,
                    leaveModified: selectedEmployee.request.modified,
                    leaveModifiedBy: selectedEmployee.request.modifiedBy,
                }

                setState({
                    ...state,
                    from: employeeResponse.data.request.from,
                    to: employeeResponse.data.request.to,
                    request: employeeResponse.data.request.request,
                    response: employeeResponse.data.request.response ? employeeResponse.data.request.response : "",
                    status: employeeResponse.data.request.status,
                    type: getLeaveTypeName(employeeResponse.data.request.type),
                    typeId: employeeResponse.data.request.type,
                    employeesLeaves: newSelectedEmployee,
                    name: employeeResponse.data.request.employee.firstName + " " + employeeResponse.data.request.employee.lastName,
                    employee: employeeResponse.data.request.employee,
                    created: employeeResponse.data.request.created,
                    createdBy: employeeResponse.data.request.createdBy,
                    modified: employeeResponse.data.request.modified,
                    modifiedBy: employeeResponse.data.request.modifiedBy,
                    totalEmployeesInVacation: countResponse.data.allEmployeeLeavesCountResponse.count - 1,

                    errorMsg: null
                })
            })
        })
    }


    const handleSubmit = (action) => {

        if (action === "DELETE") {
            deleteEmployeeLeaveByIdHandler(props.leaveId).then(response => {

                if (response.data.error == null) {
                    setState({
                        ...state,
                        errorMsg: null,
                    });
                    props.closeRightPanel(true);
                    pendingLeaveContext.updatePendingLeavesCount();
                } else {
                    setState({
                        ...state,
                        errorMsg: response.data.error.message
                    });
                }
            })
        } else {
            let body = {
                "status": action,
                "response": state.response ? state.response : null
            }
            editEmployeeLeaveByIdHandler(props.leaveId, body).then(response => {
                if (response.data.error === null) {

                    setState({
                        ...state,
                        errorMsg: null,
                    });
                    props.closeRightPanel(true);
                    pendingLeaveContext.updatePendingLeavesCount();
                } else {

                    if (response.data.error.errorCode === 3) {
                        setState({
                            ...state,
                            errorMsg: <FormattedMessage id="TheFromDateWasPassed"
                                                        defaultMessage="TheFromDateWasPassed"/>
                        });
                    } else {
                        setState({
                            ...state,
                            errorMsg: response.data.error.message
                        });
                    }

                }
            })
        }
    }


    const setResponse = (e) => {
        setState({
            ...state,
            response: e.target.value
        })
    }


    const getButtons = () => {
        if (state.status === STATUSES.PENDING) {

            if (type === TYPES.ADMIN) {
                if (state.createdBy.id == userId) {
                    return (
                        <>
                            <button onClick={() => handleSubmit(STATUSES.APPROVED)} className="btn btn-success-green">
                                <FormattedMessage id="Approve"/></button>
                            <button onClick={() => handleSubmit("DELETE")} className="btn btn-danger"><FormattedMessage
                                id="Delete"/></button>
                        </>
                    )
                } else if (state.employee.type !== TYPES.ADMIN) {
                    return (
                        <>
                            <button onClick={() => handleSubmit(STATUSES.APPROVED)} className="btn btn-success-green">
                                <FormattedMessage id="Approve"/></button>
                            <button onClick={() => handleSubmit(STATUSES.DENIED)} className="btn btn-danger">
                                <FormattedMessage id="Deny"/></button>
                            <button onClick={() => handleSubmit(STATUSES.CANCELED)} className="btn btn-cancel">
                                <FormattedMessage id="Cancel"/></button>
                        </>
                    )


                }
            } else if (type === TYPES.MANAGER) {
                if (state.createdBy.id == userId) {
                    return (
                        <>
                        {state.employee.type === TYPES.WORKER && <button onClick={() => handleSubmit(STATUSES.APPROVED)} className="btn btn-success-green">
                                <FormattedMessage
                                    id="Approve"/></button>}
                            <button onClick={() => handleSubmit("DELETE")} className="btn btn-danger"><FormattedMessage
                                id="Delete"/></button>

                        </>

                    )
                } else if (state.employee.type === TYPES.WORKER) {
                    return (
                        <>
                            <button onClick={() => handleSubmit(STATUSES.APPROVED)} className="btn btn-success-green">
                                <FormattedMessage id="Approve"/></button>
                            <button onClick={() => handleSubmit(STATUSES.DENIED)} className="btn btn-danger">
                                <FormattedMessage id="Deny"/></button>
                            <button onClick={() => handleSubmit(STATUSES.CANCELED)} className="btn btn-cancel">
                                <FormattedMessage id="Cancel"/></button>
                        </>
                    )
                }
            }
        } else {
            if (type === TYPES.ADMIN) {
                if (state.createdBy.id == userId) {
                    return (
                        <>
                            {state.status !== STATUSES.APPROVED &&
                                <button onClick={() => handleSubmit(STATUSES.APPROVED)}
                                        className="btn btn-success-green"><FormattedMessage id="Approve"/></button>}

                            {state.status !== STATUSES.CANCELED &&
                                <button onClick={() => handleSubmit(STATUSES.CANCELED)} className="btn btn-cancel">
                                    <FormattedMessage id="Cancel"/></button>}
                        </>
                    )
                } else if (state.employee.type !== TYPES.ADMIN) {
                    return (
                        <>
                            {state.status !== STATUSES.APPROVED &&
                                <button onClick={() => handleSubmit(STATUSES.APPROVED)}
                                        className="btn btn-success-green"><FormattedMessage id="Approve"/></button>}
                            {state.status !== STATUSES.DENIED &&
                                <button onClick={() => handleSubmit(STATUSES.DENIED)} className="btn btn-danger">
                                    <FormattedMessage id="Deny"/></button>}
                            {state.status !== STATUSES.CANCELED &&
                                <button onClick={() => handleSubmit(STATUSES.CANCELED)} className="btn btn-cancel">
                                    <FormattedMessage id="Cancel"/></button>}
                        </>
                    )
                }
            } else if (type === TYPES.MANAGER) {

                if (state.createdBy.id == userId) {
                    if (state.status === STATUSES.APPROVED) {
                        return (
                            <>
                                <button onClick={() => handleSubmit(STATUSES.CANCELED)} className="btn btn-cancel">
                                    <FormattedMessage id="Cancel"/></button>
                            </>
                        )
                    } else {
                        return (
                            <button onClick={() => handleSubmit(STATUSES.APPROVED)}
                                    className="btn btn-success-green"><FormattedMessage id="Approve"/></button>
                        )
                    }

                } else if (state.employee.type === TYPES.WORKER) {
                    if (state.createdBy.type === TYPES.WORKER) {
                        return (
                            <>
                                {state.status !== STATUSES.APPROVED &&
                                    <button onClick={() => handleSubmit(STATUSES.APPROVED)}
                                            className="btn btn-success-green"><FormattedMessage id="Approve"/></button>}
                                {state.status !== STATUSES.DENIED &&
                                    <button onClick={() => handleSubmit(STATUSES.DENIED)} className="btn btn-danger">
                                        <FormattedMessage id="Deny"/></button>}
                                {state.status !== STATUSES.CANCELED &&
                                    <button onClick={() => handleSubmit(STATUSES.CANCELED)} className="btn btn-cancel">
                                        <FormattedMessage id="Cancel"/></button>}
                            </>
                        )
                    }
                }
            }
        }
    }

    return (
        <>

            <section className="form-container">
                <div className="details-form">

                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="name"> <FormattedMessage id="Name" defaultMessage="Name"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="Name" defaultMessage="Name">
                                {placeholder =>
                                    <input
                                        className="input"
                                        type="text"
                                        readOnly={true}

                                        maxLength={80}
                                        placeholder={placeholder}
                                        autoComplete="off"
                                        id="firstName"
                                        value={state.name || ""}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="requestType"> <FormattedMessage id="VacationType"
                                                                                              defaultMessage="VacationType"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="VacationType" defaultMessage="VacationType">
                                {placeholder =>
                                    <input
                                        className="input"
                                        type="text"
                                        readOnly={true}
                                        maxLength={80}
                                        placeholder={placeholder}
                                        autoComplete="off"
                                        id="requestType"
                                        value={state.type || ""}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="request"> <FormattedMessage id="OtherInformation"
                                                                                          defaultMessage="OtherInformation"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="OtherInformation" defaultMessage="OtherInformation">
                                {placeholder =>
                                    <textarea
                                        className="input"
                                        readOnly={true}
                                        maxLength={80}
                                        placeholder={placeholder}
                                        autoComplete="off"
                                        id="duration"
                                        value={state.request ? state.request : ""}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="timePeriod"> <FormattedMessage id="TimePeriod"
                                                                                             defaultMessage="TimePeriod"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="TimePeriod" defaultMessage="TimePeriod">
                                {placeholder =>
                                    <div
                                        className="input"
                                        placeholder={placeholder}
                                        id="timePeriod"
                                    >
                                        <FormattedDate
                                            value={state.from}
                                            day="numeric"
                                            month="short"
                                            year="numeric"
                                            timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                                            locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                        />

                                        &nbsp;-&nbsp;
                                        <FormattedDate
                                            value={state.to}
                                            day="numeric"
                                            month="short"
                                            year="numeric"
                                            timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                                            locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                        />
                                    </div>
                                }
                            </FormattedMessage>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="name"> <FormattedMessage id="AddedIntoSystemBy"
                                                                                       defaultMessage="AddedIntoSystemBy"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="AddedIntoSystemBy" defaultMessage="AddedIntoSystemBy">
                                {placeholder =>
                                    <input
                                        className="input"
                                        type="text"
                                        readOnly={true}
                                        maxLength={80}
                                        placeholder={placeholder}
                                        autoComplete="off"
                                        id="createdBy"
                                        value={state.createdBy?.firstName + " " + state.createdBy?.lastName}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="status"> <FormattedMessage id="Status"
                                                                                         defaultMessage="Status"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="Status" defaultMessage="Status">
                                {placeholder =>
                                    <div
                                        className={`input ${
                                            state.status === STATUSES.APPROVED
                                                ? "success"
                                                : state.status === STATUSES.PENDING
                                                    ? "warning"
                                                    : state.status === STATUSES.CANCELED
                                                        ? "canceled"
                                                        : "danger"
                                        }`}
                                        readOnly={true}
                                        id="status"
                                    >
                                        {state.status ? getStatusMessage(state.status) : null}
                                    </div>
                                }
                            </FormattedMessage>
                        </div>
                    </div>


                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="response"> <FormattedMessage id="Response"
                                                                                           defaultMessage="Response"/></label>
                        </div>
                        <div className="row">
                                    <textarea
                                        onChange={(e) => setResponse(e)}
                                        className="input"
                                        readOnly={(state.status === STATUSES.APPROVED || state.status === STATUSES.DENIED)}
                                        maxLength={80}

                                        autoComplete="off"
                                        id="response"
                                        value={state.response ? state.response : ""}
                                    />
                        </div>
                    </div>

                    {(state.status === "PENDING" && state.totalEmployeesInVacation > 0) &&
                        <>
                            <div className="row msg danger">
                                <br/>
                                <span className="warning-icon-container">{warningIcon}</span> <FormattedMessage
                                id="InThisPeriodThereAreXPeopleOnVacation"
                                values={{param: state.totalEmployeesInVacation}}/>
                            </div>
                            <br/>
                        </>

                    }

                    {state.modified && <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="name"> <FormattedMessage id="LastModified" defaultMessage="LastModified"/>:&nbsp;
                                <FormattedDate
                                    value={state.modified}
                                    hour="numeric"
                                    minute="numeric"
                                    day="numeric"
                                    month="short"
                                    year="numeric"
                                    timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                                    locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                />
                            </label>
                        </div>

                        {state.modifiedBy &&
                            <div className="row">
                                <label className="label" htmlFor="name"> <FormattedMessage id="ModifiedBy" defaultMessage="ModifiedBy"/>:&nbsp;{state.modifiedBy?.firstName + " " + state.modifiedBy?.lastName}
                                </label>
                            </div>
                        }

                    </div>}

                    {ClientConfig.APP_DOMAIN === APP_DOMAINS.APP_SOSITO_RO && <LeavePdfRenderer
                        state={state}
                        companyInfo={companyInfo}
                    />}
                    <br/>


                    <div className="row buttons-container three-buttons">
                        {state.status && getButtons()}
                    </div>
                    {state.errorMsg && <div className="error">&#9888;&nbsp;{state.errorMsg}</div>}
                </div>
            </section>
        </>
    );
}

export default VacationRequestDetails;
