import React, {useEffect, useRef, useState} from "react";
import {FormattedMessage} from "react-intl";

import {PATH} from "../../routes/routes.js";
import Header from "../../components/Header";
import {createFacilityHandler, setPictureHandler} from "../../handlers/companiesHandlers/HandlerCompaniesNew";
import uploadImgIcon from "../../assets/img/icon_add_img.svg";
import Select from "react-select";
import {getLocales, getTimeZones, selectStyles} from "../../util/common";
import * as StorageService from "../../services/StorageServices";
import {LOCALSTORAGE_ITEMS, TYPES} from "../../util/Constants";
import {useNavigate} from "react-router";

function CompaniesNew() {
    const navigateTo = useNavigate();

    const type = StorageService.getItem(LOCALSTORAGE_ITEMS.TYPE) || "Unknown";
    const [state, setState] = useState({
        loading: false,
        errorMsg: null,
        name: "",
        email: "",
        phone: null,
        address: "",
        pictureUrl: uploadImgIcon,
        pictureFile: null,
        timeZones: [],
        selectedTimeZone: null,
        locales: [],
        selectedLocale: null,
    });

    const fileRef = useRef(null);

    useEffect(() => {

        getFormData();


    }, []);

    const getFormData = () => {
        const timeZones = getTimeZones();
        const locales = getLocales();
            setState({
                ...state,
                timeZones: timeZones,
                locales:locales,
                errorMsg: null
            })

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateData()) {
            setPictureHandler(state.pictureFile).then(responseSetFile => {
                if (responseSetFile.data.error == null) {

                    const body = {
                        "name": state.name,
                        "email": state.email,
                        "phone": state.phone,
                        "picture": responseSetFile.data.name,
                        "timeZone": type===TYPES.SUPER_ADMIN?state.selectedTimeZone.value:null,
                        "locale": type===TYPES.SUPER_ADMIN?state.selectedLocale.value:null,
                    }
                    createFacilityHandler(body).then(response => {
                        if (response.data.error == null) {
                            toFacilitySettingsPage(response.data.id);
                        }else{
                            if (response.data.error === "Error createFacility") {
                                setState({
                                    ...state,
                                    errorMsg:<FormattedMessage id="PhoneOrFacilityNameAlreadyExists" defaultMessage="PhoneOrFacilityNameAlreadyExists"/>
                                })
                            }
                        }

                    })
                }
            })

        }
    }



    const validateData = () => {
        if (!state.name || !state.email || !state.phone || !state.address || !state.pictureFile ||
            (type===TYPES.SUPER_ADMIN && (!state.selectedLocale || !state.selectedTimeZone))) {
            setState({
                ...state,
                errorMsg: <FormattedMessage id="PleaseFillInAllFields" defaultMessage="PleaseFillInAllFields"/>
            })
            return false;
        }

        if (!state.phone.startsWith("+")) {
            setState({
                ...state,
                errorMsg: <FormattedMessage id="InvalidPhoneNumber" defaultMessage="InvalidPhoneNumber"/>
            })
            return false;

        }
        return true;
    }


    const toFacilitySettingsPage = (id) => {
        navigateTo(PATH.COMPANIES_SETTINGS(id))
    }

    const openFileInput = (e) => {
        e.preventDefault();
        fileRef.current.click();
    }

    const handleOnChange = (e, input) => {
        if (input === "name") {
            setState({
                ...state,
                name: e.target.value
            })
        } else if (input === "picture") {
            setState({
                ...state,
                pictureUrl: URL.createObjectURL(e.target.files[0]),
                pictureFile: e.target.files[0]
            })

        } else if (input === "email") {
            setState({
                ...state,
                email: e.target.value
            })
        } else if (input === "phone") {
            setState({
                ...state,
                phone: e.target.value
            })
        } else if (input === "address") {
            setState({
                ...state,
                address: e.target.value
            })
        } else if (input === "timeZone") {
            setState({
                ...state,
                selectedTimeZone: {
                    label: e.value,
                    value: e.value,
                }
            })
        } else if (input === "locale") {
            setState({
                ...state,
                selectedLocale: {
                    label: e.label,
                    value: e.value,
                }
            })
        }
    }

    return (
        <div className="content new-facility-page">

            <Header
                hasBackBtn={true}
                headerTitle={<FormattedMessage id="NewFacility" defaultMessage="NewFacility"/>}

            />
            <div className="inner-content with-padding-top">
                <section className="form-container">
                    <div className="form new-service-provider-form with-big-inputs">
                        <div className="form-group">
                            <div className="row">
                                <label className="label" htmlFor="name"> <FormattedMessage id="Name"
                                                                                           defaultMessage="Name"/></label>
                            </div>
                            <div className="row">
                                <FormattedMessage id="Name" defaultMessage="Name">
                                    {placeholder =>
                                        <input
                                            className="input"
                                            type="text"
                                            onChange={e => handleOnChange(e, "name")}
                                            maxLength={80}
                                            placeholder={placeholder}
                                            autoComplete="off"
                                            id="name"
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <label className="label" htmlFor="picture"> <FormattedMessage id="Picture" defaultMessage="Picture"/></label>
                            </div>
                            <div className="row">
                                <div onClick={(e) =>  openFileInput(e)} className="input upload-img-input">
                                    <img className={state.pictureUrl === uploadImgIcon ? "no-width" :""} src={state.pictureUrl} alt=""/>
                                </div>
                            </div>
                            <div className="row hide">
                                <FormattedMessage id="Picture" defaultMessage="Picture">
                                    {placeholder =>
                                        <input
                                            ref={fileRef}
                                            className="input file-input"
                                            type="file"
                                            onChange={e => handleOnChange(e, "picture")}
                                            maxLength={80}
                                            placeholder={placeholder}
                                            autoComplete="off"
                                            id="name"
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                        </div>


                        <div className="form-group">
                            <div className="row">
                                <label className="label" htmlFor="email">
                                    <FormattedMessage id="Email" defaultMessage="Email"/></label>
                            </div>
                            <div className="row">
                                <FormattedMessage id="Email" defaultMessage="Email">
                                    {placeholder =>
                                        <input
                                            className="input"
                                            type="text"
                                            onChange={e => handleOnChange(e, "email")}
                                            maxLength={100}
                                            placeholder={placeholder}
                                            autoComplete="off"
                                            id="email"
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                        </div>


                        <div className="form-group">
                            <div className="row">
                                <label className="label" htmlFor="phone"> <FormattedMessage id="PhoneNumber" defaultMessage="Phone Number"/></label>
                            </div>
                            <div className="row">
                                <FormattedMessage id="PhoneNumberFormat" defaultMessage="PhoneNumberFormat">
                                    {placeholder =>
                                        <input
                                            className="input"
                                            type="tel"
                                            onChange={e => handleOnChange(e, "phone")}
                                            maxLength={100}
                                            placeholder={placeholder}
                                            autoComplete="off"
                                            id="phone"
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <label className="label" htmlFor="address"> <FormattedMessage id="Address" defaultMessage="Address"/></label>
                            </div>
                            <div className="row">
                                <FormattedMessage id="Address" defaultMessage="Address">
                                    {placeholder =>
                                        <input
                                            className="input"
                                            type="text"
                                            onChange={e => handleOnChange(e, "address")}
                                            maxLength={100}
                                            placeholder={placeholder}
                                            autoComplete="off"
                                            id="address"
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <label className="label" htmlFor="timeZone">
                                    <FormattedMessage id="TimeZone"/>
                                </label>
                            </div>
                            <div className="row">
                                <FormattedMessage id="TimeZone">
                                    {placeholder =>
                                        <Select
                                            className="select"
                                            options={state.timeZones?.map(timeZone => {
                                                return {label: timeZone, value: timeZone}
                                            })}
                                            value={state.selectedTimeZone}
                                            onChange={e => handleOnChange(e, "timeZone")}
                                            placeholder={placeholder}
                                            noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                            id="timeZone"
                                            styles={selectStyles}
                                            isMulti={false}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            isDisabled={type !== TYPES.SUPER_ADMIN}
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                        </div>



                        <div className="form-group">
                            <div className="row">
                                <label className="label" htmlFor="locale">
                                    <FormattedMessage id="Locale"/>
                                </label>
                            </div>
                            <div className="row">
                                <FormattedMessage id="Locale">
                                    {placeholder =>
                                        <Select
                                            className="select"
                                            options={state.locales?.map(locale => {
                                                return {label: locale[0], value: locale[1]}
                                            })}
                                            value={state.selectedLocale}
                                            onChange={e => handleOnChange(e, "locale")}
                                            placeholder={placeholder}
                                            noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                            id="locale"
                                            styles={selectStyles}
                                            isMulti={false}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            isDisabled={type !== TYPES.SUPER_ADMIN}
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                        </div>



                        <div className="row">
                            <button onClick={(e) => handleSubmit(e)} className="btn btn-success"><FormattedMessage
                                id="Save" defaultMessage="Save"/></button>
                        </div>


                        {state.errorMsg && <div className="error">&#9888;&nbsp;{state.errorMsg}</div>}

                    </div>
                </section>
            </div>

        </div>

    );
}

export default CompaniesNew;
