import React from "react";

export const middotIcon = <svg className="icon icon-middot" width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="2.5" cy="3" r="2.5" fill="#696C81"/>
</svg>

export const closeIcon = <svg className="icon icon-close" width="14"
                              height="14" viewBox="0 0 14 14" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
    <path
        d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
        fill="#4D5062"/>
</svg>

export const closeIconRoundedRed = <svg className="icon icon-close-red" width="15" height="15" viewBox="0 0 15 15"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M7.5 2.98003e-07C3.35786 -6.41136e-08 -8.89561e-07 3.35786 -1.25168e-06 7.5C-1.61379e-06 11.6421 3.35786 15 7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35787 11.6421 6.6012e-07 7.5 2.98003e-07Z"
        fill="#FF6969"/>
    <path
        d="M10.3096 5.54085C10.4214 5.42704 10.4848 5.27213 10.4857 5.11022C10.4867 4.9483 10.4251 4.79264 10.3146 4.67747C10.204 4.5623 10.0536 4.49706 9.89632 4.4961C9.73906 4.49515 9.58787 4.55855 9.47602 4.67236L7.15889 7.05815C7.04979 7.17275 6.98869 7.32682 6.98869 7.48729C6.98869 7.64776 7.04979 7.80183 7.15889 7.91643L9.47602 10.292C9.52947 10.3539 9.59465 10.404 9.66756 10.439C9.74047 10.4741 9.81956 10.4934 9.89999 10.4958C9.98042 10.4983 10.0605 10.4838 10.1353 10.4532C10.2101 10.4226 10.278 10.3766 10.3349 10.318C10.3918 10.2594 10.4364 10.1895 10.4661 10.1125C10.4958 10.0355 10.5099 9.95308 10.5076 9.87026C10.5052 9.78745 10.4864 9.70601 10.4524 9.63094C10.4183 9.55587 10.3697 9.48876 10.3096 9.43373L8.41421 7.49751L10.3096 5.54085Z"
        fill="white"/>
    <path
        d="M4.70603 5.54085C4.59418 5.42704 4.53082 5.27213 4.52989 5.11022C4.52895 4.9483 4.59053 4.79264 4.70107 4.67747C4.81161 4.5623 4.96205 4.49706 5.11931 4.4961C5.27657 4.49515 5.42775 4.55855 5.5396 4.67236L7.85674 7.05815C7.96583 7.17275 8.02694 7.32682 8.02694 7.48729C8.02694 7.64776 7.96583 7.80183 7.85674 7.91643L5.5396 10.292C5.48615 10.3539 5.42097 10.404 5.34807 10.439C5.27516 10.4741 5.19606 10.4934 5.11563 10.4958C5.0352 10.4983 4.95514 10.4838 4.88036 10.4532C4.80557 10.4226 4.73765 10.3766 4.68075 10.318C4.62385 10.2594 4.57919 10.1895 4.54949 10.1125C4.5198 10.0355 4.5057 9.95308 4.50807 9.87026C4.51044 9.78745 4.52922 9.70601 4.56326 9.63094C4.5973 9.55587 4.64589 9.48876 4.70603 9.43373L6.60142 7.49751L4.70603 5.54085Z"
        fill="white"/>
</svg>

export const duplicateIcon = <svg className="icon icon-duplicate" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 2.98003e-07C3.35786 -6.41136e-08 -8.89561e-07 3.35786 -1.25168e-06 7.5C-1.61379e-06 11.6421 3.35786 15 7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35787 11.6421 6.6012e-07 7.5 2.98003e-07Z" fill="#FFA757"/>
    <path d="M9 6H8.25V7.125H7.125V7.875H8.25V9H9V7.875H10.125V7.125H9M3.75 7.5C3.75 6.45375 4.365 5.55 5.25 5.13V4.3125C3.9375 4.785 3 6.03375 3 7.5C3 8.96625 3.9375 10.215 5.25 10.6875V9.87C4.365 9.45 3.75 8.54625 3.75 7.5ZM8.625 4.125C6.765 4.125 5.25 5.64 5.25 7.5C5.25 9.36 6.765 10.875 8.625 10.875C10.485 10.875 12 9.36 12 7.5C12 5.64 10.485 4.125 8.625 4.125ZM8.625 10.125C7.1775 10.125 6 8.9475 6 7.5C6 6.0525 7.1775 4.875 8.625 4.875C10.0725 4.875 11.25 6.0525 11.25 7.5C11.25 8.9475 10.0725 10.125 8.625 10.125Z" fill="white"/>
</svg>



export const calendarIcon = <svg className="icon icon-calendar"
                                 width="16" height="15"
                                 viewBox="0 0 16 15" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
    <path
        d="M14.0001 10.8333V4.83329H4.66675V10.8333H14.0001ZM14.0001 1.49996C14.3537 1.49996 14.6928 1.64044 14.9429 1.89048C15.1929 2.14053 15.3334 2.47967 15.3334 2.83329V10.8333C15.3334 11.1869 15.1929 11.5261 14.9429 11.7761C14.6928 12.0262 14.3537 12.1666 14.0001 12.1666H4.66675C3.92675 12.1666 3.33341 11.5666 3.33341 10.8333V2.83329C3.33341 2.47967 3.47389 2.14053 3.72394 1.89048C3.97399 1.64044 4.31313 1.49996 4.66675 1.49996H5.33342V0.166626H6.66675V1.49996H12.0001V0.166626H13.3334V1.49996H14.0001ZM11.6867 6.87329L8.72675 9.83329L6.94008 8.04663L7.64675 7.33996L8.72675 8.41996L10.9801 6.16663L11.6867 6.87329ZM2.00008 13.5H11.3334V14.8333H2.00008C1.26008 14.8333 0.666748 14.2333 0.666748 13.5V5.49996H2.00008V13.5Z"
        fill="#696C81"/>
</svg>

export const downArrowIcon = <svg className="down-arrow" width="14" height="8" viewBox="0 0 14 8" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
    <path
        d="M7 8C7.27621 7.99216 7.51297 7.8903 7.72604 7.67875L13.7317 1.58276C13.9053 1.40255 14 1.19099 14 0.93242C14 0.407444 13.5896 5.47418e-07 13.0609 5.24538e-07C12.8083 5.1361e-07 12.5716 0.101861 12.3901 0.282077L7 5.7669L1.60992 0.282076C1.42841 0.101861 1.19955 1.12693e-08 0.939121 0C0.410372 -2.28802e-08 2.31802e-08 0.407444 0 0.932419C-1.10712e-08 1.18315 0.0947012 1.40255 0.26832 1.58276L6.28185 7.67875C6.48703 7.89814 6.72379 8 7 8Z"
        fill="white"/>
</svg>

export const sortDownIcon = <svg className="icon icon-sort-down" width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.5L5.5 6.5L11 0.5H0Z" fill="#4D5062"/>
</svg>
export const sortUpIcon = <svg className="icon icon-sort-up" width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.5L5.5 6.5L11 0.5H0Z" fill="#4D5062"/>
</svg>



export const adminIcon = <svg className="icon admin-icon" width="20" height="20" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 0.333313L0 5.66665V13.6666C0 21.0666 5.12 27.9866 12 29.6666C18.88 27.9866 24 21.0666 24 13.6666V5.66665L12 0.333313ZM12 5.66665C13.0609 5.66665 14.0783 6.08807 14.8284 6.83822C15.5786 7.58836 16 8.60578 16 9.66665C16 10.7275 15.5786 11.7449 14.8284 12.4951C14.0783 13.2452 13.0609 13.6666 12 13.6666C10.9391 13.6666 9.92172 13.2452 9.17157 12.4951C8.42143 11.7449 8 10.7275 8 9.66665C8 8.60578 8.42143 7.58836 9.17157 6.83822C9.92172 6.08807 10.9391 5.66665 12 5.66665ZM18.84 21.6666C17.2267 24.1333 14.8133 25.9866 12 26.8933C9.18667 25.9866 6.77333 24.1333 5.16 21.6666C4.70667 21 4.32 20.3333 4 19.6266C4 17.4266 7.61333 15.6266 12 15.6266C16.3867 15.6266 20 17.3866 20 19.6266C19.68 20.3333 19.2933 21 18.84 21.6666Z" fill="#20A1BE"/>
</svg>


export const alertIcon = <svg className="alert-icon" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.2983 13.625H11.2983V7.625H13.2983M13.2983 17.625H11.2983V15.625H13.2983M12.2983 2.625C10.9851 2.625 9.68476 2.88366 8.47151 3.3862C7.25825 3.88875 6.15586 4.62535 5.22727 5.55393C3.35191 7.4293 2.29834 9.97284 2.29834 12.625C2.29834 15.2772 3.35191 17.8207 5.22727 19.6961C6.15586 20.6247 7.25825 21.3612 8.47151 21.8638C9.68476 22.3663 10.9851 22.625 12.2983 22.625C14.9505 22.625 17.494 21.5714 19.3694 19.6961C21.2448 17.8207 22.2983 15.2772 22.2983 12.625C22.2983 11.3118 22.0397 10.0114 21.5371 8.79817C21.0346 7.58491 20.298 6.48252 19.3694 5.55393C18.4408 4.62535 17.3384 3.88875 16.1252 3.3862C14.9119 2.88366 13.6116 2.625 12.2983 2.625V2.625Z" fill="#FF6969"/>
</svg>

export const warningIcon = <svg  className="warning-icon"  width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 7.99618L16.0036 5.871L16.2818 3.05781L13.3282 2.43096L11.7818 0L9 1.1161L6.21818 0L4.67182 2.43096L1.71818 3.05017L1.99636 5.86335L0 7.99618L1.99636 10.1214L1.71818 12.9422L4.67182 13.569L6.21818 16L9 14.8763L11.7818 15.9924L13.3282 13.5614L16.2818 12.9345L16.0036 10.1214L18 7.99618ZM9.81818 11.8184H8.18182V10.2895H9.81818V11.8184ZM9.81818 8.76063H8.18182V4.17391H9.81818V8.76063Z" fill="#FF6969"/>
</svg>

export const holidayIcon = <svg className="icon holiday-icon" xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M80-201v-60q35-4 56.5-21.5T206-300q51 0 76 19.5t61 19.5q37 0 63-19.5t74-19.5q51 0 75.5 19.5T618-261q35 0 60-19.5t76-19.5q48 0 69.5 17.5T880-261v60q-39 0-67-19.5T753-240q-37 0-62 19.5T617-201q-50 0-74.5-19.5T480-240q-37 0-61.5 19.5T344-201q-50 0-74.5-19.5T207-240q-32 0-59 19.5T80-201Zm150-159q-22 0-42-8t-36-24l-48-48 42-42 48 47q8 8 17 11.5t19 3.5h60v-186l-61 44-35-48 286-210 286 210-35 49-61-45v186h60q11 0 19.5-3.5T766-435l48-47 42 42-48 48q-16 16-36 24t-42 8H230Zm220-60h60v-80h-60v80Z" fill="#4D5062"/></svg>

export const resetScheduleIcon = <svg className="icon reset-icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 -960 960 960"><path d="M477-120q-149 0-253-105.5T120-481h60q0 125 86 213t211 88q127 0 215-89t88-216q0-124-89-209.5T477-780q-68 0-127.5 31T246-667h105v60H142v-208h60v106q52-61 123.5-96T477-840q75 0 141 28t115.5 76.5Q783-687 811.5-622T840-482q0 75-28.5 141t-78 115Q684-177 618-148.5T477-120Zm128-197L451-469v-214h60v189l137 134-43 43Z" fill="#4D5062"/></svg>

export const scheduleIcon = <svg className="icon schedule-icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 -960 960 960"><path d="m627-287 45-45-159-160v-201h-60v225l174 181ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-82 31.5-155t86-127.5Q252-817 325-848.5T480-880q82 0 155 31.5t127.5 86Q817-708 848.5-635T880-480q0 82-31.5 155t-86 127.5Q708-143 635-111.5T480-80Z" fill="#4D5062"/></svg>


export const chevronLeft = <svg className="icon chevron" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z"/></svg>

export const chevronRight = <svg className="icon chevron"xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg>
