import React, {useEffect, useState, useContext} from "react";
import {useLocation} from "react-router-dom";
import {FormattedMessage} from "react-intl";

import {PATH} from "../../routes/routes";
import {AuthContext} from "../../util/AuthContext";
import {getCurrentUserHandler, loginHandler} from "../../handlers/authenticationHandlers/HandlerAuthentication";
import Header from "../../components/Header";

import pwdTogglerOffImg from "../../assets/img/pwd_toggler_off.svg";
import pwdTogglerOnImg from "../../assets/img/pwd_toggler_on.svg";
import {APP_DOMAINS, LOCALSTORAGE_ITEMS, TYPES} from "../../util/Constants";
import {getAllEmployeeLeavesTypesHandler} from "../../handlers/vacationPlannerHandlers/HandlerVacationRequestsAll";
import * as StorageService from "../../services/StorageServices";
import sositoLogo from "../../assets/img/logo.svg";
import innenuLogo from "../../assets/img/logo_innenu.svg";
import {useNavigate} from "react-router";
import * as ClientConfig from "../../config/ClientConfig";
import {APP_DOMAIN} from "../../config/ClientConfig";
import analyticsLogEvent from "../../services/FirebaseService";
import {getUserAgent} from "../../util/common";

function Login() {

    const navigateTo = useNavigate();
    const [state, setState] = useState({
        username: "",
        password: "",
        isPwdVisible: false,
        firstName: null,
        lastName: null,
        type: null,
        errorMsg: null,
    });


    let authContext = useContext(AuthContext);




    const onLogin = (e) => {
        e.preventDefault();
        let country;
        if (ClientConfig.APP_DOMAIN === APP_DOMAINS.APP_INNENU_SE) {
            country = "SE"
        }else{
            country = "RO"
        }

        loginHandler(state.username, state.password).then(loginResponse => {
            if (! !!loginResponse.data.error) {
                authContext.setIsLoggedIn(true,() => {
                    getCurrentUserHandler(loginResponse).then(currentUserResponse=> {
                         getAllEmployeeLeavesTypesHandler(country).then(leavesTypesResponse => {
                            StorageService.setItem(LOCALSTORAGE_ITEMS.LEAVE_TYPES, JSON.stringify(leavesTypesResponse.data.allLeavesTypes));
                            if (currentUserResponse.data.error !== null) {
                                setState({
                                    ...state,
                                    errorMsg: "Unauthorized"
                                })
                            } else {
                                analyticsLogEvent("login", {
                                    method: "UsernamePassword",
                                });

                                if (currentUserResponse.data.user.type === TYPES.SUPER_ADMIN) {
                                    navigateTo(PATH.ALL_COMPANIES);
                                } else {
                                    navigateTo(PATH.COMPANIES_EMPLOYEES(currentUserResponse.data.user.companyId));
                                }

                            }
                        })
                    })
                });
            } else {
                authContext.setIsLoggedIn(false,() => {
                    setState({
                        ...state,
                        errorMsg: loginResponse.data.error,
                    });
                });
            }
        })
    };

    const handleOnChange = (e, input) => {
        if (input === "username") {
            setState({
                ...state,
                username: e.target.value
            })
        } else if (input === "password") {
            setState({
                ...state,
                password: e.target.value
            })
        }
    }

    const toResetPasswordPage = (e) => {
        e.preventDefault();
        navigateTo(PATH.RESET_PASSWORD);
    }

    const toContactPage = (e) => {
        e.preventDefault();
        window.location = ("https://www.sosito.ro/#contact");
    }

    const togglePwdVisiblity = (e) => {
        e.preventDefault();
        setState({
            ...state,
            isPwdVisible:!state.isPwdVisible
        })
    }

    return (
        <div className="content login-content">
            <section className="logo">
                {ClientConfig.APP_DOMAIN === APP_DOMAINS.APP_SOSITO_RO ?
                    <img src={sositoLogo} alt="application logo"/>
                    :
                    <img src={innenuLogo} alt="application logo"/>
                }

            </section>
            <Header
                headerTitle={<FormattedMessage id="Login" defaultMessage="Login"/>}
            />
            <section className="form-container account-form-container">
                <div className="form login-form">
                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="username"> <FormattedMessage id="Username" defaultMessage="Username"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="Username" defaultMessage="Username">
                                {placeholder =>
                                    <input
                                        className="input"
                                        type="text"
                                        onChange={e => handleOnChange(e, "username")}
                                        maxLength={80}
                                        placeholder={placeholder}
                                        autoComplete="off"
                                        id="username"
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>


                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="password"> <FormattedMessage id="Password" defaultMessage="Password"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="Password" defaultMessage="Password">
                                {placeholder =>
                                    <input
                                        className="input"
                                        type={state.isPwdVisible? "text" :"password"}
                                        onChange={e => handleOnChange(e, "password")}
                                        maxLength={100}
                                        placeholder={placeholder}
                                        autoComplete="off"
                                        id="password"
                                    />
                                }
                            </FormattedMessage>
                            <img onClick={(e) => togglePwdVisiblity(e)}className="pwd-toggler" src={state.isPwdVisible?  pwdTogglerOnImg: pwdTogglerOffImg} alt={"show typed password toggle"}/>
                        </div>
                    </div>
                    <div className="row">
                        <a onClick={(e) => toResetPasswordPage(e)} className="link reset-password underlined"><FormattedMessage id="ResetPassword" defaultMessage="ResetPassword"/></a>
                    </div>

                    <div className="row">
                        <button onClick={(e) => onLogin(e)} className="btn btn-success"><FormattedMessage id="Login" defaultMessage="Login"/></button>
                    </div>

                    <div className="bottom-text">
                        <div className="row">
                            <p className="simple-text"><FormattedMessage id="DontHaveAnAccount" defaultMessage="DontHaveAnAccount"/>
                            </p>
                        </div>

                        <div className="row">
                            <a onClick={(e) => toContactPage(e)} className="link underlined">
                                <FormattedMessage id="ContactUs" defaultMessage="ContactUs"/>
                            </a>
                        </div>
                    </div>

                    {state.errorMsg && <div className="error">&#9888;&nbsp;{state.errorMsg}</div>}
                </div>
            </section>
        </div>
    );
}

export default Login;
