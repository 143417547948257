import Login from "../views/authentication/Login";
import ResetPassword from "../views/authentication/ResetPassword";

import CompaniesAll from "../views/companies/CompaniesAll";
import CompaniesNew from "../views/companies/CompaniesNew";
import CompaniesSettings from "../views/companies/CompaniesSettings";
import EmployeesAll from "../views/companies/employees/EmployeesAll";
import NfcAll from "../views/companies/nfc/NfcAll";
import Profile from "../views/profile/Profile";
import LocationsAll from "../views/companies/locations/LocationsAll";
import RolesAll from "../views/companies/roles/RolesAll";
import ClockingRecordsAll from "../views/liveTracker/ClockingRecordsAll";
import NewPassword from "../views/authentication/NewPassword";
import NfcTagsGlobalAll from "../views/nfc-tags-global/NfcTagsGlobalAll";
import ReportsAll from "../views/reports/ReportsAll";
import WorkHoursAll from "../views/scheduler/WorkHoursAll";
import SchedulerAll from "../views/scheduler/SchedulerAll";
import Statistics from "../views/statistics/Statistics";

import AiInsights from "../views/companies/ai-insights/AiInsights";
import RequestsAll from "../views/companies/requests/RequestsAll";
import VacationRequestsAll from "../views/vacation-planner/VacationRequestsAll";
import VacationCalendarAll from "../views/vacation-planner/VacationCalendarAll";


export const DEFAULT_PATHS = {
    COMPANIES: "companies",
    COMPANY: "company",
    NFC_TAGS: "nfc-tags",
    DEMO_STAFF_LOG: "demo-staff-log",
    STAFF_LOG: "staff-log",
    EMPLOYEES: "employees",
    REPORTS: "reports",
    DEMO_REPORTS: "demo-reports",
    SCHEDULER: "scheduler",
    LOCATIONS: "locations",
    STATISTICS: "statistics",
    AI_INSIGHTS: "ai-insights",
    REQUESTS: "requests",
    VACATION_PLANNER: "vacation-planner"
}

export const PATH = {
    HOME: "/",
    LOGIN: "/login",
    RESET_PASSWORD: "/reset-password",
    NEW_PASSWORD: "/new-password",
    PROFILE: "/profile",


    ALL_NFC_TAGS: "/nfc-tags/all",
    ALL_COMPANIES: "/companies/all",
    COMPANIES_LOCATIONS: (companyId) => "/companies/"+companyId+"/locations/all",
    COMPANIES_ROLES: (companyId) => "/companies/"+companyId+"/roles/all",
    COMPANIES_NFCs: (companyId) => "/companies/"+companyId+"/nfcs/all",
    COMPANIES_EMPLOYEES: (companyId) => "/companies/"+companyId+"/employees/all",
    COMPANIES_SETTINGS: (companyId) => "/companies/"+companyId+"/settings",
    COMPANIES_STATISTICS: (companyId) => "/companies/"+companyId+"/statistics",
    COMPANIES_AI_INSIGHTS: (companyId) => "/companies/"+companyId+"/ai-insights",
    COMPANIES_REQUESTS: (companyId) => "/companies/"+companyId+"/requests",
    NEW_COMPANY: "/companies/new",

    LIVE_TRACKER: (companyId) => "/live-tracker/"+companyId,

    REPORTS: (companyId) => "/reports/"+companyId,

    SCHEDULER_WORK_HOURS: (companyId) => "/scheduler/"+companyId + "/work-hours",
    SCHEDULER_SCHEDULES: (companyId) => "/scheduler/"+companyId+"/schedules",
    VACATION_REQUESTS: (companyId) => "/vacation-planner/"+companyId + "/requests",
    VACATION_PLANNER_CALENDAR: (companyId) => "/vacation-planner/"+companyId+"/calendar",




}


export const routes = [
    {
        path: PATH.HOME,
        name: "CompaniesAll",
        component: CompaniesAll,
    },

    {
        path: PATH.LOGIN,
        name: "Login",
        component: Login
    },
    {
        path: PATH.RESET_PASSWORD,
        name: "ResetPassword",
        component: ResetPassword
    },
    {
        path: PATH.NEW_PASSWORD,
        name: "NewPassword",
        component: NewPassword
    },
    {
        path: PATH.PROFILE,
        name: "Profile",
        component: Profile,
    },


    {
        path: PATH.ALL_NFC_TAGS,
        name: "NfcTagsAll",
        component: NfcTagsGlobalAll
    },

    {
        path: PATH.ALL_COMPANIES,
        name: "CompaniesAll",
        component: CompaniesAll
    },
    {
        path: PATH.NEW_COMPANY,
        name: "CompaniesNew",
        component: CompaniesNew
    },
    {
        path: PATH.COMPANIES_SETTINGS(":companyId"),
        name: "CompaniesSettings",
        component: CompaniesSettings
    },
    {
        path: PATH.COMPANIES_EMPLOYEES(":companyId"),
        name: "EmployeesAll",
        component: EmployeesAll
    },

    {
        path: PATH.COMPANIES_NFCs(":companyId"),
        name: "NfcAll",
        component: NfcAll
    },
    {
        path: PATH.COMPANIES_ROLES(":companyId"),
        name: "RolesAll",
        component: RolesAll
    },
    {
        path: PATH.COMPANIES_LOCATIONS(":companyId"),
        name: "LocationsAll",
        component: LocationsAll
    },
    {
        path: PATH.LIVE_TRACKER(":companyId"),
        name: "ClockingRecordsAll",
        component: ClockingRecordsAll
    },


    {
        path: PATH.REPORTS(":companyId"),
        name: "Reports",
        component: ReportsAll
    },

    {
        path: PATH.SCHEDULER_WORK_HOURS(":companyId"),
        name: "WorkHours",
        component: WorkHoursAll
    },
    {
        path: PATH.SCHEDULER_SCHEDULES(":companyId"),
        name: "Schedules",
        component: SchedulerAll
    },
    {
        path: PATH.COMPANIES_STATISTICS(":companyId"),
        name: "Statistics",
        component: Statistics
    },
    {
        path: PATH.COMPANIES_AI_INSIGHTS(":companyId"),
        name: "AiInsights",
        component: AiInsights
    },
    {
        path: PATH.COMPANIES_REQUESTS(":companyId"),
        name: "Requests",
        component: RequestsAll
    },
    {
        path: PATH.VACATION_REQUESTS(":companyId"),
        name: "VacationRequests",
        component: VacationRequestsAll
    },
    {
        path: PATH.VACATION_PLANNER_CALENDAR(":companyId"),
        name: "VacationCalendar",
        component: VacationCalendarAll
    },






];
