

import {getAllNFCs} from "../../services/nfcTagsServices/NfcTagsService";
import * as CompaniesService from "../../services/companiesServices/CompaniesService";
import * as ClientConfig from "../../config/ClientConfig";
import {getAllLocationsFromCompany} from "../../services/companiesServices/CompaniesLocationsService";
import {getAllNFCsFromCompany} from "../../services/companiesServices/CompaniesNFCsService";

export const getAllNFCsHandler = async () => {
    let error = null;
    const nfcResponse = await getAllNFCs();
    let companyResponse = null;
    if (nfcResponse.ok) {
        companyResponse =  {

            nfcs: nfcResponse.data.map(nfc=>{
                return  {
                    id: nfc.id,
                    name: nfc.name,
                }
            })
        }
    } else {
        error ="Error getAllNFCsByCompanyIdHandler";
    }
    return {
        data: {
            error: error,
            company: companyResponse
        }
    };
}

export const getAllNFCsByCompanyHandler = async (companyId) => {
    let error = null;
    const nfcResponse = await getAllNFCsFromCompany(companyId);
    let companyResponse = null;
    if (nfcResponse.ok) {
        companyResponse =  {

            nfcs: nfcResponse.data.map(nfc=>{
                return  {
                    id: nfc.id,
                    name: nfc.name,
                }
            })
        }
    } else {
        error ="Error getAllNFCsByCompanyIdHandler";
    }
    return {
        data: {
            error: error,
            company: companyResponse
        }
    };
}



export const getAllNFCLocationsHandler = async (companyId) => {
    let error = null;

    const response = await getAllLocationsFromCompany(companyId);
    let locationsResponse = null;
    if (response.ok) {

        locationsResponse = response.data.map(location=>{
            return  {
                id: location.id,
                name: location.name,
                address: location.address,
                places: location.places

            }
        })

    } else {
        error ="Error getAllEmployeesByFacilityIdHandler";
    }
    return {
        data: {
            error: error,
            locations: locationsResponse
        }
    };
}


export const getAllCompaniesHandler = async () => {
    const response = await CompaniesService.getAllCompanies();

    let allCompanies = null;
    let error = null;
    if (response.ok) {
        allCompanies = response.data.map(e => {
            return {
                id: e.id,
                name: e.name,
                address: e.address,
                picture: ClientConfig.URL_RESOURCE_SERVER + "/api/files/" + e.picture
            }
        });

    } else {
        error ="Error getAllServiceProvidersHandler";
    }

    return {
        data: {
            allCompanies: allCompanies
        }
    };
}

