import * as ClientConfig from "../../config/ClientConfig.js";
import * as StorageService from "../StorageServices.js";
import {LOCALSTORAGE_ITEMS} from "../../util/Constants";
import {handleAuthEmptyResponse, handleAuthResponse} from "../../util/api/restClient";


const getAllNFCs =  async () => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/nfc";

        return fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            }
        });
    });
}

const getAllNFCsFromCompany =  async (companyId) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/nfc?companyId=" + companyId;

        return fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            }
        });
    });
}

const createNFC =  async (body) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/nfc";
        const response = fetch(finalURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
            body: JSON.stringify(body),
        });
        return response;
    });
}

const getNFCbyId =  async (nfcId) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/nfc/" + encodeURIComponent(nfcId);
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
        });
        return response;
    });
}

const editNFCbyId =  async (nfcId, body) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/nfc/" + encodeURIComponent(nfcId);
        const response = fetch(finalURL, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
            body: JSON.stringify(body)
        });
        return response;
    });
}

const deleteNFCbyId =  async (nfcId) => {
    return handleAuthEmptyResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/nfc/" + encodeURIComponent(nfcId);
        const response = fetch(finalURL, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
        });
        return response;
    });
}


export {
    getAllNFCs,
    getAllNFCsFromCompany,
    createNFC,
    getNFCbyId,
    editNFCbyId,
    deleteNFCbyId
}
