import React, {useContext, useEffect, useRef, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useParams} from "react-router-dom";
import {PATH} from "../../routes/routes.js";

import Header from "../../components/Header";
import {
    autoClockOutAllEmployeesHandler,
    editCompanyHandler, getCompanyByIdHandler, setPictureHandler,
} from "../../handlers/companiesHandlers/HandlerCompaniesSettings";
import uploadImgIcon from "../../assets/img/icon_add_img.svg";

import {deleteCompanyHandler} from "../../handlers/companiesHandlers/HandlerCompaniesAll";
import * as StorageService from "../../services/StorageServices";
import {LOCALSTORAGE_ITEMS, TYPES} from "../../util/Constants";
import TabNavigation from "../../components/TabNavigation";
import {closeIcon} from "../../components/Icons";
import Select from "react-select";
import {getLocales, getTimeZones, getUserAgent, selectStyles} from "../../util/common";
import {LocaleContext} from "../../util/LocaleContext";
import LunchBreakOptionRow from "./LunchBreakOptionRow";
import {useNavigate} from "react-router";
import analyticsLogEvent from "../../services/FirebaseService";

function CompaniesSettings(props) {
    const type = StorageService.getItem(LOCALSTORAGE_ITEMS.TYPE) || "Unknown";
    const lastRowInList = useRef(null);
    const TEMPORARY_ID = "tmp";
    const {companyId} = useParams();
    const localeContext = useContext(LocaleContext);
    const navigateTo = useNavigate();
    const [state, setState] = useState({
        loading: false,
        errorMsg: null,
        name: "",
        email: "",
        phone: "",
        address: "",
        pictureUrl: uploadImgIcon,
        pictureFile: null,
        timeZones: [],
        selectedTimeZone: null,
        locales: [],
        selectedLocale: null,
        isDeleteSpAlertOpen: false,
        hamburgerMenuVisible: false,
        confirmationModalVisible: false,
        schedulerEnabledSwitch: false,
        autoClockoutEnabledSwitch: false,
        leaveModuleEnabledSwitch: false,


        allOptionRows: [],
        allOptionRowsModified: [],
        selectedOptionRow: null,
        selectedOptionRowIndex: null,
        optionRowId: null,
        optionRowWithErrors: null,
        deletedOptionRowIds: [],

    });

    const fileRef = useRef(null);

    useEffect(() => {
        analyticsLogEvent("page_view", {
            page_title: "CompanySettings"
        });
        getFormData();
    }, []);

    const getFormData = () => {
        const timeZones = getTimeZones();
        const locales = getLocales();

        getCompanyByIdHandler(companyId).then(response => {
            if (response.data.error) {
                return;
            }
            StorageService.setItem(LOCALSTORAGE_ITEMS.COMPANY_INFO, JSON.stringify(response.data.company));
            const callback = () => {
                setState({
                    ...state,
                    name: response.data.company.name,
                    email: response.data.company.email,
                    phone: response.data.company.phone,
                    pictureUrl: response.data.company.picture,
                    timeZones: timeZones,
                    selectedTimeZone: {
                        label: response.data.company.timeZone || null,
                        value: response.data.company.timeZone || null
                    },
                    locales: locales,
                    selectedLocale: {
                        label: response.data.company.locale ? locales.find(locale => locale[1] === response.data.company.locale)[0] : null,
                        value: response.data.company.locale || null
                    },
                    schedulerEnabledSwitch: response.data.company.schedulerEnabled,
                    autoClockoutEnabledSwitch: response.data.company.autoClockOut,
                    leaveModuleEnabledSwitch: response.data.company.leaveEnabled,

                    allOptionRows: response.data.company.pauseThresholds?
                        response.data.company.pauseThresholds?.map((pauseThreshold, index) => {
                        return {
                            threshold: pauseThreshold.threshold / (60000 * 60),
                            pause: pauseThreshold.pause / (60 * 1000)
                        }
                    }) : [],
                    allOptionRowsModified: response.data.company.pauseThresholds?
                        response.data.company.pauseThresholds?.map((pauseThreshold, index) => {
                        return {
                            threshold: pauseThreshold.threshold / (60000 * 60),
                            pause: pauseThreshold.pause / (60 * 1000),
                            id: index
                        }

                    }) : [],
                    errorMsg: null
                })
            };

            if (type !== TYPES.SUPER_ADMIN) {
                localeContext.setLocale(StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE), callback);
            } else {
                callback();
            }
        })
    }
    const executeScroll = () => {
        if (lastRowInList.current) {
            setTimeout(function () {
                lastRowInList.current.scrollIntoView(
                    {
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'nearest'
                    })
            }, 50);
        }
    }

    const addNewOptionRow = () => {
        executeScroll();
        const optionRowObj = {
            id: TEMPORARY_ID + Math.floor(Math.random() * Date.now()),
            threshold: null,
            pause: null

        }
        const allOptionRowsModified = [...state.allOptionRowsModified];
        allOptionRowsModified.push(optionRowObj);

        setState({
            ...state,
            optionRowId: TEMPORARY_ID,
            selectedOptionRow: TEMPORARY_ID,
            selectedOptionRowIndex: null,
            allOptionRowsModified: allOptionRowsModified
        })
    }


    const handleSubmit = () => {

        if (validateData()) {
            const pauseThresholds = state.allOptionRowsModified?.map(option => {
                if (option.threshold === null || option.pause ===  null) {
                    return null
                }else{
                    return {
                        threshold: option.threshold * 60 * 60 * 1000,
                        pause: option.pause * 60 * 1000
                    }
                }

            })
            const updateBody = {
                "companyId": companyId,
                "name": state.name,
                "email": state.email !== "" ? state.email : null,
                "phone": state.phone,
                "picture": null,
                "timeZone": type === TYPES.SUPER_ADMIN ? state.selectedTimeZone.value : null,// do not save
                "locale": type === TYPES.SUPER_ADMIN ? state.selectedLocale.value : null, // do not save
                "schedulerEnabled": type === TYPES.SUPER_ADMIN ? state.schedulerEnabledSwitch : null, // do not save
                "autoClockOut": type === TYPES.SUPER_ADMIN || type === TYPES.ADMIN ? state.autoClockoutEnabledSwitch : null, // do not save
                "leaveEnabled": type === TYPES.SUPER_ADMIN || type === TYPES.ADMIN ? state.leaveModuleEnabledSwitch : null, // do not save
                "pauseThresholds": type === TYPES.SUPER_ADMIN || type === TYPES.ADMIN ? pauseThresholds : null // do not save,
            }

            if (!state.pictureFile) {
                editCompanyHandler(companyId, updateBody).then(response => {
                    if (response.data.error == null) {
                        setState({
                            ...state,
                            confirmationModalVisible: true,
                            errorMsg: null,

                        })
                        setTimeout(function () {
                            getFormData();
                        }, 2000);
                    }
                })
            } else {
                setPictureHandler(state.pictureFile).then(responseSetFile => {
                    if (responseSetFile.data.error == null) {
                        updateBody["picture"] = responseSetFile.data.name;
                        editCompanyHandler(companyId, updateBody).then(response => {
                            if (response.data.error == null) {
                                setState({
                                    ...state,
                                    confirmationModalVisible: true,
                                    errorMsg: null,

                                })
                                setTimeout(function () {
                                    getFormData();
                                }, 2000);
                            }
                        })
                    }
                })
            }
        }
    }


    const validateData = () => {
        state.allOptionRowsModified?.map(option => {
            if (option.pause / 60 > option.threshold) {
                setState({
                    ...state,
                    errorMsg: <FormattedMessage id="InvalidLunchBreak" defaultMessage="InvalidLunchBreak"/>
                })
            } else if (option.pause < 0 || option.threshold < 0) {
                setState({
                    ...state,
                    errorMsg: <FormattedMessage id="InvalidLunchBreak" defaultMessage="InvalidLunchBreak"/>
                })
            }else if (option.pause === null || option.threshold === null) {
                setState({
                    ...state,
                    errorMsg: <FormattedMessage id="InvalidLunchBreak" defaultMessage="InvalidLunchBreak"/>
                })
            }
        })
        return true;
    }

    const openFileInput = (e) => {
        e.preventDefault();
        fileRef.current.click();
    }


    const handleOnChange = (e, input) => {
        if (input === "name") {
            setState({
                ...state,
                name: e.target.value
            })

        } else if (input === "picture") {
            setState({
                ...state,
                pictureUrl: URL.createObjectURL(e.target.files[0]),
                pictureFile: e.target.files[0]
            })
        } else if (input === "email") {
            setState({
                ...state,
                email: e.target.value
            })
        } else if (input === "phone") {
            setState({
                ...state,
                phone: e.target.value
            })
        } else if (input === "timeZone") {
            setState({
                ...state,
                selectedTimeZone: {
                    label: e.value,
                    value: e.value,
                }
            })
        } else if (input === "locale") {
            setState({
                ...state,
                selectedLocale: {
                    label: e.label,
                    value: e.value,
                }
            })
        }
    }


    const openDeleteFacilityAlert = (e) => {
        e.stopPropagation();
        setState({
            ...state,
            isDeleteFacilityAlertOpen: true,
        });
    }

    const hideDeleteFacilityAlert = () => {

        setState({
            ...state,
            isDeleteFacilityAlertOpen: false,
            hamburgerMenuVisible: false
        })
    }

    const deleteFacility = async (companyId) => {
        deleteCompanyHandler(companyId).then(() => {
            navigateTo(PATH.ALL_COMPANIES);
        })
    }

    const getDeleteFacilityAlert = () => {
        if (state.isDeleteFacilityAlertOpen) {
            return {
                title: <FormattedMessage id="DeleteParam" values={{
                    param: <FormattedMessage id="Facility" defaultMessage="Delete Facility"/>
                }}/>,
                contentRow1: <FormattedMessage id="DoYouWantToDeleteParam" values={{param: state.name}}/>,
                deleteAction1: () => deleteFacility(companyId),
                deleteText1: <FormattedMessage id="DeleteParam" values={{
                    param: <FormattedMessage id="Facility" defaultMessage="Delete Facility"/>
                }}/>,
                cancelAction: () => hideDeleteFacilityAlert(),
                cancelText: <FormattedMessage id="Cancel" defaultMessage="Cancel"/>,
                hideAlert: () => hideDeleteFacilityAlert()
            }
        }
    }

    const openHamburgerMenu = () => {
        setState({
            ...state,
            hamburgerMenuVisible: !state.hamburgerMenuVisible
        })
    }

    const closeConfirmationModal = () => {
        setState({
            ...state,
            confirmationModalVisible: false
        })
    }

    const toggleSchedulerSwitch = () => {
        setState({
            ...state,
            schedulerEnabledSwitch: !state.schedulerEnabledSwitch
        })
    }

    const toggleAutoClockoutSwitch = () => {
        setState({
            ...state,
            autoClockoutEnabledSwitch: !state.autoClockoutEnabledSwitch
        })
    }

    const toggleLeaveEnabledSwitch = () => {
        setState({
            ...state,
            leaveModuleEnabledSwitch: !state.leaveModuleEnabledSwitch
        })
    }

    const deleteOptionRow = (rowId) => {
        let allOptionRowsModified = [...state.allOptionRowsModified].filter((s) => s.id !== rowId);
        setState({
            ...state,
            allOptionRowsModified: allOptionRowsModified,
            deletedOptionRowIds: [...state.deletedOptionRowIds, rowId],
        })
    }

    const saveOptionRowChangesInState = (type, value, rowId, intervalIndex) => {

        const modifiedOptionRow = [...state.allOptionRowsModified].map(modifiedRow => {
            if (modifiedRow.id === rowId) {

                const copyOfModifiedRow = modifiedRow;


                if (type === "threshold") {

                    copyOfModifiedRow.threshold = value

                } else if (type === "pause") {

                    copyOfModifiedRow.pause = value
                }

                return copyOfModifiedRow;
            } else return modifiedRow;
        });
        setState({
            ...state,
            allOptionRowsModified: [...modifiedOptionRow],
        });
    }

    const autoClockOutAllEmployees = () => {
        autoClockOutAllEmployeesHandler().then((response) => {
            if (response.data.error == null) {
                setState({
                    ...state,
                    confirmationModalVisible: true,
                    modalMsg:<FormattedMessage id="EmployeesClockedOutSuccessfully"/>,
                    errorMsg: null,

                })
                setTimeout(function () {
                    setState({
                        ...state,
                        confirmationModalVisible: false,
                        modalMsg:null,
                        errorMsg: null,

                    })
                }, 2000);
            }

        })


    }


    return (
        <div className="content">

            <Header
                alert={getDeleteFacilityAlert()}
                companyId={companyId}
                hasBackBtn={true}
                headerTitle={state.name}
                hasHamburgerMenu={true}
                openHamburgerMenu={() => openHamburgerMenu()}
                isHamburgerMenuVisible={state.hamburgerMenuVisible}
                hamburgerLinkName1={<FormattedMessage id="DeleteCompany" defaultMessage="Delete Company"/>}
                hamburgerLinkAction1={(e) => openDeleteFacilityAlert(e)}
            >
            </Header>
            <div className="inner-content no-padding-left-for-tabs">
                {props.hasTabNavigation &&
                <TabNavigation
                    companyId={companyId}
                    activeTab={4}
                />
                }
                <section className="form-container">
                    <div className="form with-big-inputs has-full-width-form-group">
                        <div className="form-group with-max-width">
                            <div className="row">
                                <label className="label" htmlFor="name"> <FormattedMessage id="Name"
                                                                                           defaultMessage="Name"/></label>
                            </div>
                            <div className="row">
                                <FormattedMessage id="Name" defaultMessage="Name">
                                    {placeholder =>
                                        <input
                                            className="input"
                                            type="text"
                                            onChange={e => handleOnChange(e, "name")}
                                            maxLength={80}
                                            placeholder={placeholder}
                                            value={state.name}
                                            autoComplete="off"
                                            id="name"
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                        </div>


                        <div className="form-group with-max-width">
                            <div className="row">
                                <label className="label" htmlFor="picture"> <FormattedMessage id="Picture"
                                                                                              defaultMessage="Picture"/></label>
                            </div>
                            <div className="row">
                                <div onClick={(e) => openFileInput(e)} className="input upload-img-input">
                                    <img className={state.pictureUrl === uploadImgIcon ? "no-width" : ""}
                                         src={state.pictureUrl} alt=""/>
                                </div>
                            </div>
                            <div className="row hide">
                                <FormattedMessage id="Picture" defaultMessage="Picture">
                                    {placeholder =>
                                        <input
                                            ref={fileRef}
                                            className="input file-input"
                                            type="file"
                                            onChange={e => handleOnChange(e, "picture")}
                                            maxLength={80}
                                            placeholder={placeholder}

                                            autoComplete="off"
                                            id="name"
                                        />

                                    }
                                </FormattedMessage>
                            </div>
                        </div>


                        <div className="form-group with-max-width">
                            <div className="row">
                                <label className="label" htmlFor="email">
                                    <FormattedMessage id="Email" defaultMessage="Email"/></label>
                            </div>
                            <div className="row">
                                <FormattedMessage id="Email" defaultMessage="Email">
                                    {placeholder =>
                                        <input
                                            className="input"
                                            type="text"
                                            onChange={e => handleOnChange(e, "email")}
                                            maxLength={100}
                                            placeholder={placeholder}
                                            value={state.email}
                                            autoComplete="off"
                                            id="email"
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                        </div>


                        <div className="form-group with-max-width">
                            <div className="row">
                                <label className="label" htmlFor="phone"> <FormattedMessage id="PhoneNumber"
                                                                                            defaultMessage="Phone Number"/></label>
                            </div>
                            <div className="row">
                                <FormattedMessage id="PhoneNumber" defaultMessage="Phone Number">
                                    {placeholder =>
                                        <input
                                            className="input"
                                            type="tel"
                                            onChange={e => handleOnChange(e, "phone")}
                                            maxLength={100}
                                            placeholder={placeholder}
                                            value={state.phone}
                                            autoComplete="off"
                                            id="phone"
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                        </div>
                        <div className="form-group with-max-width">
                            <div className="row">
                                <label className="label" htmlFor="timeZone">
                                    <FormattedMessage id="TimeZone"/>
                                </label>
                            </div>
                            <div className="row">
                                <FormattedMessage id="TimeZone">
                                    {placeholder =>
                                        <Select
                                            className="select"
                                            options={state.timeZones?.map(timeZone => {
                                                return {label: timeZone, value: timeZone}
                                            })}
                                            value={state.selectedTimeZone}
                                            onChange={e => handleOnChange(e, "timeZone")}
                                            placeholder={placeholder}
                                            noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                            id="timeZone"
                                            styles={selectStyles}
                                            isMulti={false}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            isDisabled={type !== TYPES.SUPER_ADMIN}
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                        </div>
                        <div className="form-group with-max-width">
                            <div className="row">
                                <label className="label" htmlFor="locale">
                                    <FormattedMessage id="Locale"/>
                                </label>
                            </div>
                            <div className="row">
                                <FormattedMessage id="Locale">
                                    {placeholder =>
                                        <Select
                                            className="select"
                                            options={state.locales?.map(locale => {
                                                return {label: locale[0], value: locale[1]}
                                            })}
                                            value={state.selectedLocale}
                                            onChange={e => handleOnChange(e, "locale")}
                                            placeholder={placeholder}
                                            noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                            id="locale"
                                            styles={selectStyles}
                                            isMulti={false}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            isDisabled={type !== TYPES.SUPER_ADMIN}
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                        </div>

                        {type === TYPES.SUPER_ADMIN && <div className="form-group with-max-width">
                            <div className="row switch-row">
                                <label className="label" htmlFor="enableScheduler">
                                    <FormattedMessage id="EnableScheduler"/>
                                </label>
                                <div
                                    onClick={() => toggleSchedulerSwitch()}
                                    className={state.schedulerEnabledSwitch ? "ml-auto switch labeled on" : "ml-auto switch labeled off"}>
                                    <input type="text" tabIndex="-1"/>
                                    <div className="labels">
                                        <div className="dot"></div>
                                    </div>
                                </div>
                            </div>

                        </div>}

                        {(type === TYPES.SUPER_ADMIN) && <div className="form-group with-max-width">
                            <div className="row switch-row">
                                <label className="label" htmlFor="leaveEnabled">
                                    <FormattedMessage id="LeaveModule"/>
                                </label>
                                <div
                                    onClick={() => toggleLeaveEnabledSwitch()}
                                    className={state.leaveModuleEnabledSwitch ? "ml-auto switch labeled on": "ml-auto switch labeled off"}>
                                    <input type="text" tabIndex="-1"/>
                                    <div className="labels">
                                        <div className="dot"></div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {(type === TYPES.SUPER_ADMIN || type === TYPES.ADMIN) && <div className="form-group with-max-width">
                            <div className="row switch-row">
                                <label className="label" htmlFor="autoClockout">
                                    <FormattedMessage id="AdjustClockout"/>
                                </label>
                                <div
                                    onClick={() => toggleAutoClockoutSwitch()}
                                    className={state.autoClockoutEnabledSwitch ? "ml-auto switch labeled on": "ml-auto switch labeled off"}>
                                    <input type="text" tabIndex="-1"/>
                                    <div className="labels">
                                        <div className="dot"></div>
                                    </div>
                                </div>
                            </div>
                            {(type === TYPES.SUPER_ADMIN || type === TYPES.ADMIN) && state.autoClockoutEnabledSwitch &&
                                <button onClick={() => autoClockOutAllEmployees()}  className="ml-0 mt-10 btn btn-default"><FormattedMessage
                                    id="AutoClockoutAllEmployees" defaultMessage="AutoClockoutAllEmployees"/></button>
                            }

                        </div>}







                        {(type === TYPES.SUPER_ADMIN || type === TYPES.ADMIN) &&
                        <div className="form-group lunch-break-form-group">
                            <div className="row">
                                <label className="label" htmlFor="lunchBreak">
                                    <FormattedMessage id="LunchBreak"/>
                                </label>
                            </div>

                            {state.allOptionRowsModified?.length > 0 &&
                            <div className="field-rows-container">
                                {state.allOptionRowsModified?.map((optionRow, index) => {
                                    return (

                                        <LunchBreakOptionRow
                                            key={index}
                                            index={index}
                                            deleteOptionRow={deleteOptionRow}
                                            optionRowId={optionRow.id}
                                            threshold={optionRow.threshold}
                                            pause={optionRow.pause}
                                            rowErrorIndex={(state.optionRowWithErrors && state.optionRowWithErrors.scheduleIndex === index) ? state.optionRowWithErrors.dayIntervalsIndex : null}
                                            saveOptionRowChangesInState={saveOptionRowChangesInState}
                                        />
                                    );
                                })
                                }
                            </div>
                            }
                            <button tabIndex={-1} onClick={() => addNewOptionRow()}
                                    className={"btn add-btn ml-0 mt-0"}><span className="icon">+</span> &nbsp;
                                <FormattedMessage id="AddLunchBreak"
                                                  defaultMessage="AddLunchBreak"/></button>
                        </div>}

                        <div className="last-el" ref={lastRowInList}></div>



                        <div className="row">
                            <button onClick={() => handleSubmit()} className="ml-0 btn btn-success"><FormattedMessage
                                id="Save" defaultMessage="Save"/></button>
                        </div>

                        <div
                            className={state.confirmationModalVisible ? "visible modal confirmation-modal" : "modal confirmation-modal"}>
                               <span
                                   onClick={() => closeConfirmationModal()}
                                   className="ml-auto close-icon">
                                    {closeIcon}
                                </span>
                            <div className="modal-content">

                                {state.modalMsg ?
                                    <FormattedMessage
                                        id="EmployeesClockedOutSuccessfully"/>
                                    :
                                    <FormattedMessage
                                        id="ChangesSaved"/>

                                }
                            </div>

                        </div>

                        {state.errorMsg && <div className="ml-0 error">&#9888;&nbsp;{state.errorMsg}</div>}

                    </div>
                </section>
            </div>

        </div>


    );
}

export default CompaniesSettings;
