import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {PATH} from "../../routes/routes";
import Header from "../../components/Header";
import {
    requestVerificationCodeHandler
} from "../../handlers/authenticationHandlers/HandlerAuthentication";
import * as StorageService from "../../services/StorageServices";
import {LOCALSTORAGE_ITEMS} from "../../util/Constants";
import {useNavigate} from "react-router";

function ResetPassword() {

    const phoneInLocalStorage = StorageService.getItem(LOCALSTORAGE_ITEMS.USERNAME);
    const navigateTo = useNavigate();
    const [state, setState] = useState({
        errorMsg:null,
        phone: phoneInLocalStorage!==null ? phoneInLocalStorage: null,
    });

    useEffect(() => {

    }, []);

    const handleOnChange = (e, input) => {
        if (input === "phone") {
            setState({
                ...state,
                phone: e.target.value
            })
        }
    }

    const toLoginPage = () => {
        navigateTo(PATH.LOGIN);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateData()) {

            requestVerificationCodeHandler(state.phone).then(response => {
                if (response.data.error === null) {
                    StorageService.setItem(LOCALSTORAGE_ITEMS.RESET_PASSWORD_PHONE, state.phone);
                    navigateTo(PATH.NEW_PASSWORD);
                }else{
                    if (response.data.errorCode === 3 ) {
                        setState({
                            ...state,
                            errorMsg: response.data.message
                        })
                    }else if (response.data.errorCode === 1) {
                        setState({
                            ...state,
                            errorMsg: <FormattedMessage id="InvalidPhone" defaultMessage="InvalidPhone"/>
                        })
                    }else{
                        setState({
                            ...state,
                            errorMsg: <FormattedMessage id="SomethingWentWrong" defaultMessage="SomethingWentWrong"/>
                        })
                    }}
            })
        }
    }

    const validateData = () => {
        if (!!!state.phone ) {
            setState({
                ...state,
                errorMsg: <FormattedMessage id="PhoneInputRequired" defaultMessage="PhoneInputRequired"/>
            })
            return false;
        }
        return true;
    }

    return (
        <>
            <div className="content login-content">
                <Header
                    headerTitle={<FormattedMessage id="ResetPassword" defaultMessage="ResetPassword"/>}
                    hasMsg={true}
                    msg={<FormattedMessage id="EnterYourPhoneNr" defaultMessage="EnterYourPhoneNr"/>}
                />

                <section className="form-container account-form-container">

                    <div className="form login-form">
                        <div className="form-group">
                            <div className="row">
                                <label className="label" htmlFor="username">
                                    <FormattedMessage id="PhoneNumber" defaultMessage="PhoneNumber"/></label>
                            </div>
                            <div className="row">
                                <FormattedMessage id="PhoneNumber" defaultMessage="PhoneNumber">
                                    {placeholder =>
                                        <input
                                            className="input"
                                            type="tel"
                                            onChange={e => handleOnChange(e, "phone")}
                                            maxLength={80}
                                            defaultValue={state.phone}
                                            placeholder={placeholder}
                                            autoComplete="off"
                                            id="phone"
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                        </div>


                        <div className="row">
                            <button onClick={(e) => handleSubmit(e) } className="btn btn-success"><FormattedMessage id="Next"
                                                                                  defaultMessage="Next"/></button>
                        </div>

                        <div className="bottom-text">
                            <div className="row">
                                <p className="simple-text"><FormattedMessage id="RememberedYourPassword" defaultMessage="RememberedYourPassword"/>
                                </p>
                            </div>

                            <div className="row">
                                <a onClick={(e) => toLoginPage(e)} className="link underlined">
                                    <FormattedMessage id="Login" defaultMessage="Login"/>
                                </a>
                            </div>
                        </div>


                    </div>
                    {state.errorMsg && <div className="error mt-20">&#9888;&nbsp;{state.errorMsg}</div>}
                </section>
            </div>

        </>
    );
}

export default ResetPassword;
