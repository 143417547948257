import React, {useEffect, useState} from "react";
import {FormattedDate, FormattedMessage, FormattedTime} from "react-intl";
import 'react-calendar/dist/Calendar.css';
import {getReportedProblemByEmployeeIdHandler} from "../../handlers/reportedProblemsHandlers/HandlerReportedProblem";

import {TYPES, LOCALSTORAGE_ITEMS, APP_DOMAINS} from "../../util/Constants";
import * as StorageService from "../../services/StorageServices";
import * as ClientConfig from "../../config/ClientConfig";
import analyticsLogEvent from "../../services/FirebaseService";
import {getUserAgent} from "../../util/common";


function ClockingProblems(props) {



    const [state, setState] = useState({
        allProblems:null
    });

    useEffect(() => {
        analyticsLogEvent("page_view", {
            page_title: "LiveTrackerReportedProblems",
        });
        getFormData();
    }, [props.employeeDetailsId, props.from]);


    const getFormData = () => {
        getReportedProblemByEmployeeIdHandler(props.employeeDetailsId, props.from, props.to).then(allProblemsResponse => {
                setState({
                    ...state,
                    allProblems:allProblemsResponse.data.allProblems
                })
            })
        }

    return (
        <>
            {/*CLOCKING, // server*/}
            {/*USER_REPORTED_CLOCKING, // mobile app*/}
            {/*USER_REPORTED, // mobile app*/}
            <section className="form-container clocking-problems">
                <form className="details-form">
                    {

                        state.allProblems && state.allProblems.length > 0  ?

                            state.allProblems.map((problem, index) => (
                                <React.Fragment key={index}>
                                {problem.type === "USER_REPORTED" &&
                                    <div className="user-reported">
                                        <div className="danger">
                                             <span className="time">
                                            {
                                                <FormattedTime
                                                    value={problem.date}
                                                    hour="numeric"
                                                    minute="numeric"
                                                    timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                                                    locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                                />
                                            }
                                        </span>
                                            &nbsp;
                                            <span className="action">
                                            <FormattedMessage id="UserReportedProblem" defaultMessage="UserReportedProblem"/>
                                        </span>

                                            {problem.message && <span className="message">
                                            {": "+problem.message}
                                        </span>}
                                        </div>
                                    </div>
                                }


                                    {problem.type === "USER_REPORTED_CLOCKING" &&
                                        <div className="user-reported-clocking">
                                            <p className="headline"><FormattedMessage id="ClockinClockoutAttempt"/>
                                               {problem.attemptsCount > 1 && " (" + problem.attemptsCount + ")"}:
                                            </p>
                                            <p className="sub-headline">
                                                <span className="attempts-start-date">
                                                      <FormattedTime
                                                          value={problem.attemptsStartDate}
                                                          hour="numeric"
                                                          minute="numeric"
                                                          timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                                                          locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                                      />


                                                </span>

                                                {problem.attemptsCount > 1 && <>
                                                &nbsp;-&nbsp;
                                                <span className="attempts-end-date">
                                                       <FormattedTime
                                                           value={problem.attemptsEndDate}
                                                           hour="numeric"
                                                           minute="numeric"
                                                           timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                                                           locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                                       />
                                                </span>
                                                </>}
                                                ,&nbsp;
                                                <span className="day-month-year">
                                                       <FormattedDate
                                                           value={state.selectedDate}
                                                           day="numeric"
                                                           month="short"
                                                           year="numeric"
                                                           timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                                                           locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                                       />
                                                </span>
                                                </p>

                                            <p className="danger">
                                                  <span className="time">
                                            {

                                                <FormattedTime
                                                    value={problem.date}
                                                    hour="numeric"
                                                    minute="numeric"
                                                    timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                                                    locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                                />

                                            }
                                        </span>
                                                &nbsp;
                                                <span className="action">
                                            <FormattedMessage id="UserReportedProblem" defaultMessage="UserReportedProblem"/>
                                        </span>
                                                {problem.message && <span className="message">
                                            {": "+problem.message}
                                        </span>}
                                            </p>
                                            {props.type === TYPES.SUPER_ADMIN && <div className="device-information">
                                                <p><FormattedMessage id="Phone"/>: {problem.deviceModel}</p>
                                                <p>
                                                    {ClientConfig.APP_DOMAIN === APP_DOMAINS.APP_SOSITO_RO ?
                                                        <FormattedMessage id="MobileAppName"/>
                                                        :
                                                        <FormattedMessage id="MobileAppNameInnenu"/>
                                                    }

                                                    : {problem.mobileAppVersion}</p>
                                                <p><FormattedMessage id="PhoneOS"/>: {problem.deviceOs}</p>
                                            </div>}

                                        </div>
                                    }

                                </React.Fragment>

                            ))
                            :
                            <div className="no-information">
                                <FormattedMessage id="ThereAreNoAdditionalClockingDetailsToDisplay" defaultMessage="ThereAreNoAdditionalClockingDetailsToDisplay"/>
                            </div>
                    }


                    <div className="row buttons-container">
                        <button onClick={(e) => props.closeRightPanel()} className="btn btn-default"><FormattedMessage
                            id="Close" defaultMessage="Close"/></button>
                    </div>

                </form>
            </section>
        </>
    );
}

export default ClockingProblems;
