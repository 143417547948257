import React, {useEffect, useState, useRef, useContext} from "react";
import {FormattedMessage} from "react-intl";
import 'react-calendar/dist/Calendar.css';
import {
    getHourAndMinObjectFomMillis,
    getTotalHoursAndMinsFromMillisWithLabels
} from "../../util/common";


function HourlyReport(props) {

    const getFilteredEditedHourlyReports = props.allHourlyReports.map(hourlyReport=>{
            const mergedEmployeeClockingInfoList = {
                employeeId: hourlyReport.employeeId,
                locationId: hourlyReport.locationId,
                firstName: hourlyReport.firstName,
                lastName: hourlyReport.lastName,
                location: hourlyReport.location,
                totalHours: 0,
                isEdited: false,
                clockingInfo: []
            };

            const editedHourlyReports = props.allEditedHourlyReports.find(editedHourlyReport=>
                hourlyReport.employeeId === editedHourlyReport.employeeId &&
                hourlyReport.locationId === editedHourlyReport.locationId
            )

            if (editedHourlyReports) {
                mergedEmployeeClockingInfoList.isEdited = true;
                editedHourlyReports.clockingInfo.forEach((clockingInfoCell,index) => {
                    if (clockingInfoCell !== -1) {
                        mergedEmployeeClockingInfoList.totalHours += clockingInfoCell
                        mergedEmployeeClockingInfoList.clockingInfo.push({
                            hours:  clockingInfoCell,
                            isEdited: true
                        });
                    }else{
                        mergedEmployeeClockingInfoList.totalHours += hourlyReport.clockingInfo[index]
                        mergedEmployeeClockingInfoList.clockingInfo.push({
                            hours:  hourlyReport.clockingInfo[index],
                            isEdited: false
                        });
                    }
                });

                return mergedEmployeeClockingInfoList;
            }else{
                hourlyReport.clockingInfo.forEach(clockingInfoCell  => {
                    mergedEmployeeClockingInfoList.clockingInfo.push({
                        hours:  clockingInfoCell,
                        isEdited: false
                    });
                    mergedEmployeeClockingInfoList.totalHours += clockingInfoCell
                });

                return mergedEmployeeClockingInfoList;
            }
        })

    return (
        <table id="reports-table" className="table reports-table hourly-report-table">

            {/*---------------------------------start thead------------------------*/}
            <thead>
            <tr>
                <th className="name"><FormattedMessage id="Employee"/></th>
                {
                    Array.from(Array(props.numOfDaysInMonth)).map((day, index) => (
                        <th key={index}>{index + 1}</th>
                    ))
                }
                <th>Total hours</th>
            </tr>
            </thead>
            {/*---------------------------------end thead------------------------*/}


            {/*---------------------------------start tbody------------------------*/}


            <tbody>
            {getFilteredEditedHourlyReports.map((report, reportIndex) => (

                <tr className={reportIndex} key={reportIndex}>
                    <td className="name">
                        {report.firstName + " " + report.lastName} {props.selectedLocation === null ? "(" + report.location + ")" : null}
                    </td>
                    {

                        Array.from(Array(props.numOfDaysInMonth)).map((day, index) => (
                            <td
                                className={props.weekendDaysInMonth.includes(index + 1) ? "weekend" : ""}
                                tabIndex={props.getIndex(reportIndex, index)+1} key={index}
                                onKeyUp={() => props.showEditableInputsForSelectedDay(report.employeeId, index,  report.locationId)}
                                onClick={() => props.showEditableInputsForSelectedDay(report.employeeId, index, report.locationId)}
                            >
                                {report.clockingInfo[index]?.isEdited ?
                                    <span className="edited-time">
                                        {getTotalHoursAndMinsFromMillisWithLabels(report.clockingInfo[index].hours)}
                                    </span>
                                    :
                                    <span className="original-time">
                                        {getTotalHoursAndMinsFromMillisWithLabels(report.clockingInfo[index]?.hours)}
                                    </span>
                                }

                                {/* START EDITABLE INPUTS*/}
                                {
                                    props.selectedEmployee && props.selectedEmployee == report.employeeId && props.selectedEmployeeLocation === report.locationId && props.selectedDay === index &&
                                    <div
                                        className={props.hasErrors ? "row has-errors editable-time prevent-scroll" : "row editable-time prevent-scroll"}>
                                        <input
                                            autoFocus
                                            tabIndex={props.getIndex(reportIndex, index)+1}
                                            onBlur={(e) => props.handleHoursAndMinsChange(e, "hours")}
                                            onChange={(e) => props.handleOnChange(e, "hours")}
                                            onKeyDown={(e) => props.handleKeyDown(e, "hours")}
                                            placeholder={getHourAndMinObjectFomMillis(report.clockingInfo[index]?.hours).h}
                                            type="number"
                                            className="hours"/>
                                        <span className="middot">:</span>
                                        <input
                                            tabIndex={props.getIndex(reportIndex, index)+1}
                                            onBlur={(e) => props.handleHoursAndMinsChange(e, "minutes")}
                                            onChange={(e) => props.handleOnChange(e, "minutes")}
                                            onKeyDown={(e) => props.handleKeyDown(e, "minutes")}
                                            onMouseLeave={(e) => props.handleHoursAndMinsChange(e, "minutes")}
                                            placeholder={getHourAndMinObjectFomMillis(report.clockingInfo[index]?.hours).m}
                                            type="number"
                                            className="minutes"/>
                                    </div>
                                }
                                {/* END EDITABLE INPUTS*/}
                            </td>
                        ))

                    }
                    <td>
                        {report?.isEdited ?
                            <span className="edited-time">
                                        {getTotalHoursAndMinsFromMillisWithLabels(report.totalHours)}
                                    </span>
                            :
                            <span className="original-time">
                        {getTotalHoursAndMinsFromMillisWithLabels(report.totalHours)}
                                    </span>
                        }
                    </td>
                </tr>
            ))}
            </tbody>

            {/*---------------------------------end tbody------------------------*/}
        </table>

    );
}

export default HourlyReport;
