export const URL_RESOURCE_SERVER = process.env.REACT_APP_API_URL;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const APP_DOMAIN = process.env.REACT_APP_DOMAIN;

export const REACT_APP_FIREBASE_API_KEY=process.env.REACT_APP_FIREBASE_API_KEY;
export const REACT_APP_FIREBASE_AUTH_DOMAIN=process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const REACT_APP_FIREBASE_DATABASE_URL=process.env.REACT_APP_FIREBASE_DATABASE_URL;
export const REACT_APP_FIREBASE_PROJECT_ID=process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const REACT_APP_FIREBASE_STORAGE_BUCKET=process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const REACT_APP_FIREBASE_MESSAGING_SENDER_ID=process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const REACT_APP_FIREBASE_APP_ID=process.env.REACT_APP_FIREBASE_APP_ID;
export const REACT_APP_FIREBASE_MEASUREMENT_ID=process.env.REACT_APP_FIREBASE_MEASURMENT_ID;
