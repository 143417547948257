import {handleAuthFileResponse, handleAuthResponse} from "../util/api/restClient";
import * as ClientConfig from "../config/ClientConfig";
import * as StorageService from "./StorageServices";
import {LOCALSTORAGE_ITEMS} from "../util/Constants";

const insertFile =  async (fileInput) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/files";
        const formData = new FormData();
        formData.append("file", fileInput);
        const response = fetch(finalURL, {
            method: "POST",
            headers: {
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
            body: formData,
        });
        return response;
    });
}

const getFile =  async (fileName) => {
    return handleAuthFileResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/files/"+fileName;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            }
        });
        return response;
    });
}

export {
   insertFile,
    getFile
}
