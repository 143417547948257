import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import 'react-calendar/dist/Calendar.css';

import {
    createRoleHandler, editRoleByIdHandler, getAllEmployeesByCompanyIdHandler,
    getRoleByIdHandler
} from "../../../handlers/companiesHandlers/roleHandlers/HandlerRoleDetails";
import {editRoleById} from "../../../services/companiesServices/CompaniesRolesService";
import Select from "react-select";
import {editCompanyEmployeeByIdHandler} from "../../../handlers/companiesHandlers/employeesHandlers/HandlerEmployeesDetails";
import {getUserAgent, selectStyles} from "../../../util/common";
import analyticsLogEvent from "../../../services/FirebaseService";
import * as StorageService from "../../../services/StorageServices";
import {LOCALSTORAGE_ITEMS} from "../../../util/Constants";


function RoleDetails(props) {

    const openedRightPanelStyleName = "open";
    const closedRightPanelStyleName = "closed";

    const defaultFormFields = {
        name: "",
        selectedEmployees: null,
    };

    const [state, setState] = useState({
        rightPanelStyleName: closedRightPanelStyleName,
        errorMsg: null,
        employees: [],
        employeesInRole: [],
        ...defaultFormFields
    });

    useEffect(() => {
        analyticsLogEvent("page_view", {
            page_title: "RoleDetails"
        });
        getFormData();

    }, [props.roleDetailsId]);



    const handleOnChange = (e, input) => {
        if (input === "name") {
            setState({
                ...state,
                name: e.target.value,
                errorMsg: null,
            })
        } else if (input === "employees") {

            setState({
                ...state,
                errorMsg: null,
                selectedEmployees: e !== null ? e.map(employee => {
                    return employee?.value
                }) : [],
            })
        }
    }


    const selectAllEmployees = () => {
        setState({
            ...state,
            errorMsg: null,
            selectedEmployees: state.employees?.map(employee => employee.id)
        })
    }


    const getAllSelectedEmployeesSelectOptions = () => state.employees?.filter(employee => state.selectedEmployees?.includes(employee.id)).map(filteredEmployee => {
        return {
            label: filteredEmployee.firstName + " " +filteredEmployee.lastName,
            value: filteredEmployee.id
        }
    });
    const getFormData = () => {

            if (props.roleDetailsId === "") { // NEW ROLE
                getAllEmployeesByCompanyIdHandler(props.companyId).then(response=> {
                    setState({
                        ...state,
                        name: "",
                        employees: response.data.employees,
                        selectedEmployees: null,
                        rightPanelStyleName: openedRightPanelStyleName,
                        errorMsg: null
                    })
                })

            } else { //EDIT ROLE
                getRoleByIdHandler(props.roleDetailsId, props.companyId).then(response => {
                    const employeesInRole =  response.data.roleAndEmployees.employees.filter(employee => employee.roles?.includes(response.data.roleAndEmployees.id)).map(filteredEmployee => {
                        return filteredEmployee.id
                    });
                    setState({
                        ...state,

                        name: response.data.roleAndEmployees.name,
                        employees: response.data.roleAndEmployees.employees,
                        selectedEmployees: employeesInRole,
                        employeesInRole: employeesInRole,
                        rightPanelStyleName: openedRightPanelStyleName,
                        errorMsg: null
                    })
                })
            }

    }



    const bodyNewRole = {
        "companyId": props.companyId,
        "name": state.name,

    }

    const bodyEditRole = {
        "name": state.name
    }


    const createNewRole = async () => {
        if (validateData()) {
            let errorMsg = null;
            const createRoleResponse = await createRoleHandler(bodyNewRole);
                if (createRoleResponse.data.error == null) {
                    if (state.selectedEmployees !== null) {
                        const addedEmployeeRequestBody = state.selectedEmployees.map(selEmployee => {

                                const actualRoles = state.employees.find(e => e.id == selEmployee).roles;
                                actualRoles?.push(createRoleResponse.data.id);
                                return {
                                    "employeeId": selEmployee,
                                    "roles": actualRoles
                                }
                            });

                        if(addedEmployeeRequestBody.length > 0) {
                            const editEmployeesRoleResponse = await editCompanyEmployeeByIdHandler(addedEmployeeRequestBody)
                            if (editEmployeesRoleResponse.data.error != null) {
                                errorMsg = "ERROR update roles";
                            }
                        }

                    }
                }else{
                    errorMsg = "ERROR " + createRoleResponse.data;
                }


                if (createRoleResponse.data.error == null) {
                        setState({
                            ...state,
                            ...defaultFormFields,
                            rightPanelStyleName: openedRightPanelStyleName,
                            errorMsg: null
                        })
                    props.closeRightPanel(true);
                }else{
                    setState({
                        ...state,
                        errorMsg: createRoleResponse.data.message
                    })
                }

        }
    }

    const editExistingRole = async () => {

        if (validateData()) {
            let errorMsg = null;
            const editRoleResponse = await editRoleByIdHandler(props.roleDetailsId, bodyEditRole);

            if (editRoleResponse.data.error == null) {
                if (state.selectedEmployees !== null) {
                    const addedEmployeeRequestBody = state.selectedEmployees.filter(selEmployee => !state.employeesInRole.includes(selEmployee))
                        .map(selEmployee => {

                            const actualRoles = state.employees.find(e => e.id == selEmployee).roles;
                            actualRoles?.push(props.roleDetailsId);
                            return {
                                "employeeId": selEmployee,
                                "roles": actualRoles
                            }
                        });

                    const removedEmployeesFromRoleBody = state.employeesInRole.filter(selEmployee => !state.selectedEmployees.includes(selEmployee))
                        .map(selEmployee => {
                            const actualRoles = state.employees.find(e => e.id == selEmployee).roles;
                            return {
                                "employeeId": selEmployee,
                                "roles": actualRoles.filter(e =>  e != props.roleDetailsId)
                            }
                        });

                    const employeesRoleBody = addedEmployeeRequestBody.concat(removedEmployeesFromRoleBody);
                    if(employeesRoleBody.length > 0) {
                        const editEmployeesRoleResponse = await editCompanyEmployeeByIdHandler(employeesRoleBody)
                        if (editEmployeesRoleResponse.data.error != null) {
                            errorMsg = "ERROR update roles";
                        }
                    }

                }
            }else{
                errorMsg = "ERROR " + editRoleResponse.data;
            }



            setState({
                ...state,
                errorMsg: errorMsg
            })
            if(errorMsg == null) {
                props.closeRightPanel(true);
            }
        }
    }

    const handleSubmit = () => {
        if (props.roleDetailsId === "") {
            createNewRole();
        } else {
            editExistingRole();
        }
    }



    const validateData = () => {
        if (!!!state.name ) {
            setState({
                ...state,
                errorMsg: <FormattedMessage id="PleaseFillInAllFields" defaultMessage="PleaseFillInAllFields"/>
            })
            return false;
        }
        setState({
            ...state,
            errorMsg: null
        })
        return true;
    }




    return (

        <section className="form-container">
            <div className="details-form">
                <div className="form-group">
                    <div className="row">
                        <label className="label" htmlFor="name"> <FormattedMessage id="Name" defaultMessage="Name"/></label>
                    </div>
                    <div className="row">
                        <FormattedMessage id="Name" defaultMessage="Name">
                            {placeholder =>
                                <input
                                    className="input"
                                    type="text"
                                    onChange={e => handleOnChange(e, "name")}
                                    maxLength={80}
                                    placeholder={placeholder}
                                    autoComplete="off"
                                    id="name"
                                    value={state.name}
                                />
                            }
                        </FormattedMessage>
                    </div>

                </div>



                <div className="form-group">
                    <div className="row">
                        <label className="label" htmlFor="employees">
                            <FormattedMessage id="Employees" defaultMessage="Employees"/>
                        </label>
                        <a onClick={() => selectAllEmployees()} className="link">
                            <FormattedMessage id="SelectAll" defaultMessage="SelectAll"/>
                        </a>
                    </div>
                    <div className="row">
                        <FormattedMessage id="Search" defaultMessage="Search">
                            {placeholder =>
                                <Select
                                    className="select"
                                    options={state.employees?.map(employee => {
                                        return {label: employee.firstName + " " + employee.lastName, value: employee.id}
                                    })}
                                    value={getAllSelectedEmployeesSelectOptions()}
                                    onChange={e => handleOnChange(e, "employees")}
                                    placeholder={placeholder}
                                    noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                    id="employees"
                                    styles={selectStyles}
                                    isMulti={true}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                            }
                        </FormattedMessage>
                    </div>
                </div>

                <div className="row buttons-container">
                    <button onClick={() =>  props.closeRightPanel()}  className="btn btn-default"><FormattedMessage
                        id="Cancel" defaultMessage="Cancel"/></button>
                    <button onClick={() => handleSubmit()} className="btn btn-success"><FormattedMessage
                        id="Save" defaultMessage="Save"/></button>
                </div>

                {state.errorMsg && <div className="error">&#9888;&nbsp;{state.errorMsg}</div>}

            </div>
        </section>

    );
}

export default RoleDetails;
