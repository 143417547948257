import React, {useEffect, useState, useRef, useContext} from "react";
import {FormattedMessage} from "react-intl";
import 'react-calendar/dist/Calendar.css';
import {
    getDayName, getHourAndMinFromMillis, getTotalHoursFromIntervalMillis, roundToHoursAndMinutes,
} from "../../util/common";

import {
    adminIcon,
    alertIcon,
    closeIcon,
    closeIconRoundedRed,
    downArrowIcon,
    middotIcon,
    holidayIcon,
    scheduleIcon
} from "../../components/Icons";
import * as StorageService from "../../services/StorageServices";
import {LOCALSTORAGE_ITEMS, TABS, TYPES} from "../../util/Constants";


function WorkHoursTable(props) {
    let allEmployeeIds = [];
    props.allEmployees.forEach(employee => {
        if (props.employeeCanBeEdited(employee)) {
            allEmployeeIds.push(employee.id)
        }
    });
    let allEmployeeIdsWithoutDuplicates = [...new Set(allEmployeeIds)];

    const getDayWorkTimeFrom = (dayWork) => {
        if (dayWork.dayIntervals) {
            if (dayWork.dayIntervals.length > 0) {
                return getHourAndMinFromMillis(dayWork.dayIntervals[0].from);
            } else {
                return "none";
            }
        } else return "";
    }

    const getDayWorkTimeTo = (dayWork) => {
        if (dayWork.dayIntervals) {
            if (dayWork.dayIntervals.length > 0) {
                return getHourAndMinFromMillis(dayWork.dayIntervals[0].to);
            } else {
                return "none";
            }
        } else return "";
    }


    const onDeleteInterval = (e, id) => {
        e.stopPropagation();
        props.onDeleteInterval(id);
    }

    const onTabClick = (e, tab) => {
        e.stopPropagation();
        props.changeActiveTab(tab);
    }


    return (
        <>
            {props.assignWorkPatternPopupVisible &&
            <div
                style={{
                    left: props.assignWorkPatternPopupPosition.left + "px",
                    top: props.assignWorkPatternPopupPosition.top + "px"
                }}
                className="popup list-popup"
                onContextMenu={(e) => e.preventDefault()}>
                <div className="close-list-popup"
                     onContextMenu={(e) => e.preventDefault()}
                     onClick={(e) => props.closeWorkPatternPopup(e)}>
                         <span className="close-icon">
                             {closeIcon}
                         </span>
                </div>
                {/*<div className="tabs">*/}
                {/*    <div onClick={(e) => onTabClick(e, TABS.SCHEDULE_TAB)} className={"tab assign-pattern-tab" + (props.activeTab === TABS.SCHEDULE_TAB ? " active" : "")}>{scheduleIcon}<label>SCHEDULE</label></div>*/}
                {/*    <div onClick={(e) => onTabClick(e, TABS.HOLIDAY_TAB)} className={"tab assign-holiday-tab" + (props.activeTab === TABS.HOLIDAY_TAB ? " active" : "")}>{holidayIcon}<label>HOLIDAY</label></div>*/}
                {/*</div>*/}

                {props.activeTab === TABS.SCHEDULE_TAB && <>
                    <div onClick={(e) => {e.stopPropagation(); props.openDeletePatternAlert()}}className="row reset-pattern">&nbsp;<FormattedMessage id="ResetSchedule"/></div>

                    <div className="options">
                        {props.allSchedules.map((schedule, index) => (
                            <div key={index}
                                 onClick={() => props.openScheduleCalendar(schedule.id)}
                                 className={(schedule.id === props.selectedSchedule) || (schedule.id === props.activeScheduleForEmployee?.id) ? "row active" : "row"}>{schedule.name}</div>
                        ))}
                    </div>
                </>}

                {/*{props.activeTab === TABS.HOLIDAY_TAB && <>*/}
                {/*    <div onClick={(e) => {e.stopPropagation(); props.openDeletePatternAlert()}}className="row reset-pattern">&nbsp;<FormattedMessage id="ResetSchedule"/></div>*/}

                {/*    <div className="options">*/}
                {/*        <div*/}
                {/*             onClick={() => props.openScheduleCalendar(HOLIDAY_TYPES.CONCEDIU_MEDICAL)}*/}
                {/*             className={(props.selectedHolidayType === HOLIDAY_TYPES.CONCEDIU_MEDICAL)? "row active" : "row"}>Concediu medical</div>*/}
                {/*        <div*/}
                {/*            onClick={() => props.openScheduleCalendar(HOLIDAY_TYPES.CONCEDIU_DE_ODIHNA)}*/}
                {/*            className={(props.selectedHolidayType === HOLIDAY_TYPES.CONCEDIU_DE_ODIHNA)? "row active" : "row"}>Concediu de odihna</div>*/}
                {/*        <div*/}
                {/*            onClick={() => props.openScheduleCalendar(HOLIDAY_TYPES.CONCEDIU_FARA_PLATA)}*/}
                {/*            className={(props.selectedHolidayType === HOLIDAY_TYPES.CONCEDIU_FARA_PLATA)? "row active" : "row"}>Concediu fără plată</div>*/}
                {/*    </div>*/}
                {/*</>}*/}







                <div className="invisible-close-list-popup-overlay"
                     onClick={(e) => props.closeWorkPatternPopup(e)}
                     onContextMenu={(e) => props.closeWorkPatternPopup(e)}>

                </div>
            </div>}
            <table className="table reports-table work-hours-table editable">

                {/*---------------------------------start thead------------------------*/}
                <thead>
                <tr>
                    <th onContextMenu={(e) => e.preventDefault()}
                        onClick={(e) => props.onSelectAllRowsIds(e, allEmployeeIdsWithoutDuplicates)}
                        className="clickable name">
                        <div className="row">
                            <div className="checkbox">
                                <input type="checkbox" onChange={(e) => (e.stopPropagation())}
                                       checked={JSON.stringify(props.selectedRowIds) === JSON.stringify(allEmployeeIdsWithoutDuplicates) && props.allEmployees.length > 0 && allEmployeeIdsWithoutDuplicates.length > 0}/>
                                <div className="checksquare"></div>
                            </div>
                            <FormattedMessage id="SelectAll"/>
                        </div>

                    </th>
                    {
                        Array.from(Array(props.numOfDaysInMonth)).map((day, index) => (
                            <th key={index}>{index + 1} {getDayName(props.selectedDate, index + 1, StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE))}</th>
                        ))
                    }
                </tr>
                </thead>
                {/*---------------------------------end thead------------------------*/}


                {/*---------------------------------start tbody------------------------*/}
                <tbody>
                {props.allEmployees.length > 0 && props.allEmployees.map((employee, employeeIndex) => (
                    <tr className={props.selectedRowIds.includes(employee.id) ? "all-active " + employeeIndex : employeeIndex}
                        key={employeeIndex}>
                        <td
                            onContextMenu={(e) => props.openWorkPatternPopup(e, employee)}
                            onClick={() => props.onSelectSingleRowId(employee)}
                            className={
                                props.employeeCanBeEdited(employee) ?
                                    "clickable name"
                                    : "non-clickable name"
                            }>
                            <div className="row">
                                {props.listOfEditedEmployees.includes(employee.id) && <i className="middot-icon">
                                    {middotIcon}
                                </i>}
                                {props.employeeCanBeEdited(employee) ?
                                    <div className="checkbox">
                                        <input type="checkbox" onChange={(e) => (e.stopPropagation())}
                                               checked={props.selectedRowIds.includes(employee.id)}/>
                                        <div className="checksquare"></div>
                                    </div>
                                    :
                                    null
                                }

                                {employee.type === TYPES.ADMIN || employee.type === TYPES.MANAGER ? adminIcon : null}
                                {employee.name}
                                <i onClick={(e) => {
                                    e.stopPropagation();
                                    props.openWorkPatternPopup(e, employee)
                                }} className="icon">{downArrowIcon}</i>
                            </div>
                        </td>
                        {
                            employee.workSchedule.map((dayWork, index) => (
                                <td className={(props.weekendDaysInMonth.includes(index + 1) ? "weekend" : "") + (dayWork.isEdited ? " edited" : "") + (props.employeeCanBeEdited(employee, true) ? "" : " non-clickable")}
                                    tabIndex={props.getIndex(employeeIndex, index) + 1} key={index}
                                    onKeyUp={() => props.showEditableInputsForSelectedDay(employee, index)}
                                    onClick={() => props.showEditableInputsForSelectedDay(employee, index)}
                                >
                                    <span className="from">
                                        {
                                            getDayWorkTimeFrom(dayWork)
                                        }
                                    </span>&nbsp;-&nbsp;
                                    <span className="to">
                                        {
                                            getDayWorkTimeTo(dayWork)
                                        }
                                    </span>


                                    {/* START EDITABLE INPUTS*/}
                                    {
                                        props.selectedEmployee && props.selectedEmployee == employee.id && props.selectedDay === index &&
                                        <div
                                            className={props.hasErrors ? "row has-errors editable-time prevent-scroll" : "row editable-time prevent-scroll"}>
                                            {/*<span className="copy-value"></span>*/}
                                            <span className="from row">
                                                 <input
                                                     placeholder={
                                                         dayWork.dayIntervals && dayWork.dayIntervals.length > 0? roundToHoursAndMinutes(dayWork.dayIntervals[0].from).h : ""
                                                     }
                                                     autoFocus
                                                     tabIndex={props.getIndex(employeeIndex, index) + 1}
                                                     onChange={(e) => props.saveIntervalsInState(e, "hoursFrom")}
                                                     onKeyDown={(e) => props.handleKeyDown(e, "hoursFrom")}
                                                     type="number"
                                                     className="hours"/>
                                            <span className="middot">:</span>
                                            <input
                                                placeholder={dayWork.dayIntervals && dayWork.dayIntervals.length > 0? roundToHoursAndMinutes(dayWork.dayIntervals[0].from).m : ""}
                                                tabIndex={props.getIndex(employeeIndex, index) + 1}
                                                onChange={(e) => props.saveIntervalsInState(e, "minutesFrom")}
                                                onKeyDown={(e) => props.handleKeyDown(e, "minutesFrom")}
                                                type="number"
                                                className="minutes"/>
                                            </span>


                                            <span className="dash">-</span>
                                            <span className="to row">
                                                <input
                                                    placeholder={dayWork.dayIntervals && dayWork.dayIntervals.length > 0? roundToHoursAndMinutes(dayWork.dayIntervals[0].to).h : ""}
                                                    tabIndex={props.getIndex(employeeIndex, index) + 1}
                                                    onChange={(e) => props.saveIntervalsInState(e, "hoursTo")}
                                                    onKeyDown={(e) => props.handleKeyDown(e, "hoursTo")}
                                                    type="number"
                                                    className="hours"/>
                                            <span className="middot">:</span>
                                            <input
                                                placeholder={dayWork.dayIntervals && dayWork.dayIntervals.length > 0? roundToHoursAndMinutes(dayWork.dayIntervals[0].to).m : ""}
                                                tabIndex={props.getIndex(employeeIndex, index) + 1}
                                                onChange={(e) => props.saveIntervalsInState(e, "minutesTo")}
                                                onKeyDown={(e) => props.handleKeyDown(e, "minutesTo")}
                                                type="number"
                                                className="minutes"/>
                                            </span>

                                            {dayWork.isEdited &&
                                            <a onClick={(e) => {onDeleteInterval(e, dayWork.editedDateId)}}
                                               className="delete-btn">{closeIconRoundedRed}
                                            </a>}

                                            {props.hasErrors && <div className="tooltip cell-tooltip">
                                                <div className="arrow-up"></div>
                                                <div className="row colour-one">
                                                    <div className="col icon-col">{alertIcon}&nbsp;</div>
                                                    <div className="col text-col">
                                                        {props.errorMsg}
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    }
                                    {/*END EDITABLE INPUTS*/}
                                </td>
                            ))
                        }
                    </tr>
                ))}
                </tbody>
                {/*---------------------------------end tbody------------------------*/}
            </table>
        </>

    );
}

export default WorkHoursTable;
