import React from "react";



import ScrollContainer from "react-indiana-drag-scroll";
import {FormattedDate, FormattedMessage} from "react-intl";
import {getTotalHoursAndMinsFromMillisWithLabels,getDateObjectTmz, roundDateToMinutes} from "../util/common";
import {sortDownIcon, sortUpIcon, warningIcon} from "./Icons";
import * as StorageService from "../services/StorageServices";
import {LOCALSTORAGE_ITEMS} from "../util/Constants";

function ClockingTable(props) {
    const hourWidth = 50;

    const calculateRightColInnerWidth = () => {
        return props.hours.length * hourWidth;
    }


    const calculateInfoLogWidth = (clockedIn, clockedOut) => {
        if(props.hours.length > 0 && clockedIn !== null){        
            let clockedInDate = new Date(clockedIn);
            if (clockedInDate.getTime() < props.from.getTime()) {
                clockedInDate = props.from;
            }

            let clockedOutDate = null;
            if (clockedOut) {
                clockedOutDate = new Date(clockedOut);
                if (clockedOutDate.getTime() > props.to.getTime()) {
                    clockedOutDate = props.to;
                }
            } else {
                clockedOutDate = new Date();
            }

            let diffMilliseconds = (clockedOutDate.getTime() - clockedInDate.getTime());
            let diffMinutes = Math.floor((diffMilliseconds / 1000) / 60);

            return diffMinutes * hourWidth / 60;
        }else return 0;
    }

    const calculateInfoLogPosition = (clockedIn) => {
        if(props.hours.length > 0 && clockedIn !== null){
            let clockedInDate = new Date(clockedIn);
            if (clockedInDate.getTime() < props.from.getTime()) {
                clockedInDate = props.from;
            }
            const firstHour = props.hours[0];
            const startHourMinutes = firstHour.split(":");
            const startHour = startHourMinutes[0];
            const startMinute = startHourMinutes[1];
            const startTotalMinutes = parseInt(startHour) * 60 + parseInt(startMinute);

            const dtmzObj = getDateObjectTmz(clockedInDate);
            const hour = parseInt(dtmzObj.hour);
            const minutes = parseInt(dtmzObj.minute);

            const totalMinutes = hour * 60 + minutes;
            return (totalMinutes - startTotalMinutes) * hourWidth / 60 + hourWidth / 2;
        }else return 0;
    }

    const calculateCurrentTimePosition = () => {

        if (props.hours.length !== 0) {
            let currentTime = new Date();
            if (currentTime.getTime() > props.from.getTime() && currentTime.getTime() < props.to) {
                const firstHour = props.hours[0];
                const startHourMinutes = firstHour.split(":");
                const startHour = startHourMinutes[0];
                const startMinute = startHourMinutes[1];
                const startTotalMinutes = parseInt(startHour) * 60 + parseInt(startMinute);
                const dtmzObj = getDateObjectTmz(currentTime);
                const hour = parseInt(dtmzObj.hour);
                const minutes = parseInt(dtmzObj.minute);
                const totalMinutes = hour * 60 + minutes;
                return (totalMinutes - startTotalMinutes) * hourWidth / 60 + hourWidth / 2;
            } else {
                return null;
            }
        }

    }

    const infoIcon = <svg width="18" height="18" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.125 4.625H5.875V3.375H7.125V4.625ZM7.125 9.625H5.875V5.875H7.125V9.625ZM6.5 0.25C5.67924 0.25 4.86651 0.411661 4.10823 0.725753C3.34994 1.03984 2.66095 1.50022 2.08058 2.08058C0.90848 3.25269 0.25 4.8424 0.25 6.5C0.25 8.1576 0.90848 9.74732 2.08058 10.9194C2.66095 11.4998 3.34994 11.9602 4.10823 12.2742C4.86651 12.5883 5.67924 12.75 6.5 12.75C8.1576 12.75 9.74732 12.0915 10.9194 10.9194C12.0915 9.74732 12.75 8.1576 12.75 6.5C12.75 5.67924 12.5883 4.86651 12.2742 4.10823C11.9602 3.34994 11.4998 2.66095 10.9194 2.08058C10.3391 1.50022 9.65006 1.03984 8.89177 0.725753C8.13349 0.411661 7.32076 0.25 6.5 0.25Z"
            fill="#888BA1"/>
    </svg>


    const getClockingLocationInfo = (clockingLocation) => {
        if(clockingLocation.clockedIn){
        return  <>
            <div className="tooltip">  {getClockedIn(clockingLocation)} - {getClockedOut(clockingLocation)} - {clockingLocation.name}</div></>
        }else <>
        <div className="tooltip"> {clockingLocation.name} </div>
        </>
    }


    const handleSelectedRow = (e, selectedRow) => {
        e.stopPropagation();
        props.onSelectedRowId(selectedRow);
    }

    const getClockedIn = (clockingLocation) => {

        if (new Date(clockingLocation.clockedIn).getTime() < props.from.getTime()) {
            return <FormattedDate
                value={roundDateToMinutes(new Date(clockingLocation.clockedIn))}
                hour="numeric"
                minute="numeric"
                hour12={false}
                day="numeric"
                month="short"
                year="numeric"
                timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
            />



        } else {
            return <FormattedDate
                value={roundDateToMinutes(new Date(clockingLocation.clockedIn))}
                hour="numeric"
                minute="numeric"
                hour12={false}
                timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
            />
        }
    }

    const getClockedOut = (clockingLocation) => {
        if (clockingLocation.clockedOut) {
            if (new Date(clockingLocation.clockedOut).getTime() > props.to.getTime()) {
                return <FormattedDate
                    value={roundDateToMinutes(new Date(clockingLocation.clockedOut))}
                    hour="numeric"
                    minute="numeric"
                    hour12={false}
                    day="numeric"
                    month="short"
                    year="numeric"
                    timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                    locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                />

            } else {
                return  <FormattedDate
                    value={roundDateToMinutes(new Date(clockingLocation.clockedOut))}
                    hour="numeric"
                    minute="numeric"
                    hour12={false}
                    timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                    locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                />
            }
        } else {
            return <FormattedMessage id="Now" defaultMessage="Now"/>
        }
    }

    const getTotalHoursAndMinutes = (clockingLocations) => {
        let totalMillis = 0;
        clockingLocations.forEach(cl => {
            if(cl.clockedIn){
                let to = new Date().getTime();
                if (cl.clockedOut) {
                    to = new Date(cl.clockedOut).getTime();
                }
                const from = new Date(cl.clockedIn).getTime();
                const millis = to - from;
                totalMillis = totalMillis + millis;
            }
        });

        return getTotalHoursAndMinsFromMillisWithLabels(totalMillis, "");


    }

    const hasClockingForSomeLocations = (clockingInfo) => {
        return typeof(clockingInfo.clockingLocations.find(cl => cl.clockedIn !== null)) !== "undefined";
    };

    const getLocationsUniqueNames = (clockingLocations) => {
        const finalLocations = [];
        clockingLocations.forEach(cl => {
           if(!finalLocations.includes(cl.name)) finalLocations.push(cl.name);
        });

        return finalLocations;
    }

    const onEmployeeLabelClick = () => {
        if (props.sortedBy === "name_asc") {
            props.setSortedBy("name_desc");
        }else{
            props.setSortedBy("name_asc");
        }
    }

    const onLocationLabelClick = () => {
        if (props.sortedBy === "location_asc") {
            props.setSortedBy("location_desc");
        }else{
            props.setSortedBy("location_asc");
        }
    }

    return (
        <>
            {props.topActions}
            <section className="clocking-table">
                <div className="clocking-table-content">

                    <>
                        <div className="col left-col">
                            <div onClick={() => onEmployeeLabelClick()} className="clocking-table-header sortable">
                                <div className="col-label"><FormattedMessage id="Employee"/></div>
                                {
                                    props.sortedBy === null ?
                                        ""
                                        :
                                        props.sortedBy === "name_desc"?
                                            <i className="icon">{sortDownIcon}</i>
                                            :
                                            <i className="icon">{sortUpIcon}</i>
                                }
                            </div>
                            <div className="col-content">
                                {
                                    props.clockingInfo?.map((clockingInfo, index) => (

                                        <div
                                            onClick={(e) => handleSelectedRow(e, clockingInfo.employeeId)}
                                            key={index}
                                            className={props.employeeDetailsId === clockingInfo.employeeId ? "card-row active" : "card-row "}>

                                            <div
                                                className="card mini-card transparent">
                                                <div className="row">
                                                    <div className="col logo">
                                                        <div
                                                            className={clockingInfo.isDeleted ? "colored-div disabled" : "colored-div"}>
                                                            <p>
                                                                {clockingInfo.firstName[0] ?
                                                                    <span>{clockingInfo.firstName[0]}</span> : null}
                                                                {clockingInfo.firstName[1] ?
                                                                    <span>{clockingInfo.firstName[1]}</span> : null}
                                                                {clockingInfo.lastName[0] ?
                                                                    <span>{clockingInfo.lastName[0]}</span> : null}
                                                                {clockingInfo.lastName[1] ?
                                                                    <span>{clockingInfo.lastName[1]}</span> : null}

                                                            </p>
                                                        </div>

                                                    </div>
                                                    <div className="col text">
                                                        <p>{clockingInfo.firstName}</p>
                                                        <p>{clockingInfo.lastName}</p>
                                                    </div>

                                                </div>
                                            </div>
                                            {props.allProblems?.find(problem => problem.employee.id === clockingInfo.employeeId) && <span className="warning-icon-container">{warningIcon}</span>}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className="col center-col">
                            <ScrollContainer
                                hideScrollbars={false}
                                className="scroll-container">
                                <div style={{minWidth: calculateRightColInnerWidth() + "px"}}
                                     className="center-col-inner">
                                    {calculateCurrentTimePosition() && <div style={{
                                        left: calculateCurrentTimePosition() + "px",
                                    }} className="line"></div>}
                                    <div className="clocking-table-header">
                                        <div className="col hours-row">
                                            {props.hours.map((hour, index) => (
                                                <span key={index} className="hour">{hour.slice(0,2)}</span>
                                            ))}
                                        </div>


                                    </div>

                                    {props.clockingInfo?.map((clockingInfo, index) => (

                                        <div key={index}
                                             className={props.employeeDetailsId === clockingInfo.employeeId ? "clocking-info-row active" : "clocking-info-row"}>
                                            {
                                                hasClockingForSomeLocations(clockingInfo)?
                                                    clockingInfo.clockingLocations.map((clockingLocation, index) => (
                                                        <div key={index} style={{
                                                            left: calculateInfoLogPosition(clockingLocation.clockedIn) + "px",
                                                            width: calculateInfoLogWidth(clockingLocation.clockedIn, clockingLocation.clockedOut) + "px",
                                                            zIndex: index,
                                                        }} className="clocking-info-log">
                                                        {getClockingLocationInfo(clockingLocation)}
                                                        </div>
                                                    ))
                                                :
                                                <></>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </ScrollContainer>
                        </div>
                        <div className="col right-col">
                            <div onClick={() => onLocationLabelClick()}  className="clocking-table-header sortable">
                                <div className="col-label"><FormattedMessage id="LocationAndClockedHours"/></div>
                                {
                                    props.sortedBy === null ?
                                        ""
                                        :
                                        props.sortedBy === "location_desc"?
                                            <i className="icon">{sortDownIcon}</i>
                                            :
                                            <i className="icon">{sortUpIcon}</i>
                                }
                            </div>
                            <div className="col-content">
                                {
                                    props.clockingInfo?.map((clockingInfo, index) => (

                                        <div key={index} className="card-row">
                                            <div className="row">
                                                {
                                                    clockingInfo.clockingLocations && clockingInfo.clockingLocations.length > 0 && (getLocationsUniqueNames(clockingInfo.clockingLocations).length === 1 ?
                                                        <span>
                                                                    {getTotalHoursAndMinutes(clockingInfo.clockingLocations)} - {clockingInfo.clockingLocations[0].name}
                                                                </span>
                                                        :
                                                        <>
                                                            {getTotalHoursAndMinutes(clockingInfo.clockingLocations)}
                                                            -  {clockingInfo.clockingLocations[0].name}...&nbsp;
                                                            {infoIcon}

                                                            <div className="tooltip">
                                                                {
                                                                    getLocationsUniqueNames(clockingInfo.clockingLocations).map((name, index) =>

                                                                        (
                                                                            <span key={index}>{ (index ? ', ' : '') + name }</span>
                                                                        )
                                                                    )
                                                                }

                                                            </div>

                                                        </>)
                                                }
                                            </div>

                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                    </>

                </div>


            </section>
        </>
    )


}

export default ClockingTable;
