import * as ReportsService from "../../services/reportsServices/ReportsService";

export const generateReportHandler = async (fileName, language, companyId, year, month, locationId, roleId) => {
    let error = null;
    const response = await ReportsService.generateReport(fileName, language, companyId, year, month, locationId, roleId);

    if (!response.ok) {
        return {
            data: {
                error: "Error generateQrCodesHandler",
            }
        }
    }
    return {
        data: {
            error: error,
            report: response.data,
        }
    };
}

export const generateFromDayToDayReportHandler = async (fileName, language, companyId, year, month, fromDay, toDay, locationId, roleId) => {
    let error = null;
    const response = await ReportsService.generateFromDayToDayReport(fileName, language, companyId, year, month, fromDay, toDay, locationId, roleId);

    if (!response.ok) {
        return {
            data: {
                error: "Error generateQrCodesHandler",
            }
        }
    }
    return {
        data: {
            error: error,
            report: response.data,
        }
    };
}


export const generateReportWithIntervalsHandler = async (fileName, language, companyId, year, month, locationId, fromDay, toDay, edited, roleId) => {
    let error = null;

    const response = await ReportsService.generateReportWithIntervals(fileName, language, companyId, year, month, locationId, fromDay, toDay, edited, roleId);

    if (!response.ok) {
        return {
            data: {
                error: "Error generateQrCodesHandler",
            }
        }
    }
    return {
        data: {
            error: error,
            report: response.data,
        }
    };
}
