
import {
    createRole,
    deleteRoleById,
    editRoleById,
    getRoleById
} from "../../../services/companiesServices/CompaniesRolesService";
import {getAllEmployeesFromCompany} from "../../../services/companiesServices/CompaniesEmployeesService";


export const getAllEmployeesByCompanyIdHandler = async (companyId) => {
    let error = null;

    const allEmployeesResponse = await getAllEmployeesFromCompany(companyId);
    let employees = null;
    if (allEmployeesResponse.ok) {
        employees = allEmployeesResponse.data

    } else {
        error ="Error getAllEmployeesByFacilityIdHandler";
    }

    return {
        data: {
            error: error,

            employees: employees
        }
    };
}



export const getRoleByIdHandler = async (id, companyId) => {
    let error = null;
    const allEmployeesResponse = await getAllEmployeesFromCompany(companyId);
    const roleResponse = await getRoleById(id);
    if (!roleResponse.ok) {
        return {
            data: {
                error: "Error getRoleByIdHandler",
            }
        }
    }
    const roleAndEmployees = {
        id: roleResponse.data.id,
        name: roleResponse.data.name,
        employees: allEmployeesResponse.data
    }


    return {
        data: {
            error: null,
            roleAndEmployees:roleAndEmployees
        }

    };
}



export const createRoleHandler = async (data) => {
    const body = {
        "companyId": data.companyId,
        "name": data.name,
    }
    const response = await createRole(body);
    let roleId = null;
    let error = null;
    if (response.ok) {
        roleId = response.data.id
    } else {
        error ="Error createRoleHandler";
    }

    return {
        data: {
            error: error,
            message: response.data.message,
            id: roleId
        }
    };

}

export const editRoleByIdHandler = async (roleId, data) => {
    let error = null;
    const body = {
        "companyId": data.companyId,
        "name": data.name,
        "employees": data.employees
    }
    const response = await editRoleById(roleId, body);
    let id = null;
    if (response.ok) {
        id = response.data.id
    } else {
        error ="Error editRoleByIdHandler";
    }
    return {
        data: {
            id: id,
            error: error
        }

        // data: {
        //     id: "2",
        //     error: error
        // }
    };
}



export const deleteRoleHandler = async (roleId) => {
    let error = null;

    const response = await deleteRoleById(roleId);
    if (response.ok) {

    } else {
        error ="Error deleteRoleHandler";
    }
    return {
        data: {
            id: roleId,
            error: error
        }
    };

}










