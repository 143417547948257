import * as ClientConfig from "../../config/ClientConfig.js";
import * as StorageService from "../StorageServices.js";
import {LOCALSTORAGE_ITEMS} from "../../util/Constants";
import {handleAuthEmptyResponse, handleAuthFileResponse, handleAuthResponse} from "../../util/api/restClient";


const getAllCompanies =  async () => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/companies";
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN),
            }
        });
        return response;
    });
}

const getCompanyById =  async (companyId) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/companies/" + companyId;

        return fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            }
        });
    }).then(response=>{
        let locale =
            (navigator.languages && navigator.languages[0]) ||
            navigator.language ||
            navigator.userLanguage;
        let timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (response.ok) {
            if(response.data){
                if(response.data.timeZone) {
                    timeZone = response.data.timeZone;
                }
                if(response.data.locale){
                    locale = response.data.locale;
                }
            }
        }

        StorageService.setItem(LOCALSTORAGE_ITEMS.TIMEZONE, timeZone);
        StorageService.setItem(LOCALSTORAGE_ITEMS.LOCALE, locale);
        return response;
    });
}


const createCompany =  async (req) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/companies";
        const response = fetch(finalURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN),
            },
            body: JSON.stringify(req),
        });
        return response;
    });
}


const editCompanyById =  async (companyId, body) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/companies/" + companyId;
        const response = fetch(finalURL, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
            body: JSON.stringify(body)
        });
        return response;
    });
}



const deleteCompanyById =  async (companyId) => {
    return handleAuthEmptyResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/companies/" + companyId;
        const response = fetch(finalURL, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
        });
        return response;
    });
}


const generateQrCodes =  async (companyId, places) => {
    return handleAuthFileResponse(() => {
        const placesParams = places?places.map(p => {
            return "places="+p;
        }):[];
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/places/facility/" + companyId + "/qr-codes"+(placesParams.length>0?("?"+placesParams.join("&")):"");

        const response =  fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            }
        });
        return response;
    });
}

const generateQrCodeForSinglePlace =  async (companyId, locationId) => {
    return handleAuthFileResponse(() => {
        const placesParams = "?places="+locationId;
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/places/company/" + companyId + "/qr-codes"+placesParams;

        const response =  fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            }
        });
        return response;
    });
}




export {
    getAllCompanies,
    createCompany,
    getCompanyById,
    editCompanyById,
    deleteCompanyById,
    generateQrCodes,
    generateQrCodeForSinglePlace

}
