import React from "react";
import {Document, Page, PDFViewer, Text, View} from "@react-pdf/renderer";

import { styles } from "./styles/PdfStyles";
import Footer from "./components/Footer";
import SignatureWrapper from "./components/SignatureWrapper";
import CompanyLogo from "./components/CompanyLogo";



function OncologyCareLeavePdfTemplate(props) {

    return (
        <>
            <PDFViewer style={{ width: props.width, height: props.height }}>
                <Document>
                    <Page size="A4" style={styles.page}>
                        <View>
                            <CompanyLogo/>
                        </View>
                        <View style={styles.header}>
                            <Text style={styles.semiBold}>Cerere de concediu pentru îngrijirea pacientului cu afecțiuni oncologice</Text>
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.text}>
                                <Text style={styles.alineat}>Subsemnatul/a</Text>, <Text style={styles.underlined}>{props.name}</Text>, angajat/ă al/a firmei <Text >{props.companyInfo?.name}</Text>, vă rog să-mi aprobați concediul medical pentru îngrijirea pacientului cu afecțiuni oncologice în perioada <Text style={styles.underlined}>{props.from} &nbsp;-&nbsp; {props.to}</Text>.
                            </Text>
                        </View>


                        <View style={styles.section}>
                            <Text style={styles.text}>
                                Declar pe propria răspundere că în termen de cel mult 30 de zile lucrătoare de la data prezentei mă oblig să depun la DRU toate documentele prin care se face dovada faptului că persoana căreia îi ofer îngrijire sau sprijin este rudă sau o persoană care locuiește în aceeași gospodărie cu mine, precum și a existenței problemei medicale grave care a determinat solicitarea concediului de îngrijitor.
                            </Text>
                        </View>



                        {props && <SignatureWrapper
                          {...props}
                       />}
                      <Footer/>
                    </Page>
                </Document>
            </PDFViewer>
        </>
    );

}

export default OncologyCareLeavePdfTemplate;
