import React, { useMemo } from "react";
import {FormattedDate, FormattedMessage} from "react-intl";
import 'react-calendar/dist/Calendar.css';
import { adminIcon, closeIcon, downArrowIcon } from "../../components/Icons";
import {LOCALSTORAGE_ITEMS, STATUSES, TYPES} from "../../util/Constants";
import {
    getClassByType,
    getFirstDayOfNextMonthDate,
    getLeaveTypeName,
    getTotalDaysInMonth,
    getWeekends,
    resetTime
} from "../../util/common";
import * as StorageService from "../../services/StorageServices";


const getLeaveForDayMapping = (allEmployeesLeaves) => {
    const mapping = {};
    Object.entries(allEmployeesLeaves).forEach(([employeeId, leaves]) => {
        leaves.forEach(leave => {
            const leaveFromDate = resetTime(new Date(leave.from));
            const leaveToDate = resetTime(new Date(leave.to));
            const currentDate = new Date(leaveFromDate);
            while (currentDate <= leaveToDate) {
                const key = `${employeeId}|${currentDate.getDate()}|${currentDate.getMonth()}|${currentDate.getFullYear()}`;
                mapping[key] = leave;
                currentDate.setDate(currentDate.getDate() + 1);
            }
        });
    });
    return mapping;
};

function VacationCalendarAllTable(props) {
    const {
        allEmployees,
        allEmployeesLeaves,
        allLeavesTypes,
        selectedDate,
        assignVacationPopupVisible,
        assignVacationPopupPosition,
        closeVacationPopup,
        openVacationPopup,
        openScheduleCalendar,
        selectedHolidayType,
        selectedRowIds,
        onSelectSingleRowId,
        onSelectAllRowsIds,
        locale
    } = props;

    console.log("allLeavesTypes", allLeavesTypes);

    const getDaysForMonth = (date) => {
        const daysInMonth = getTotalDaysInMonth(date);
        return Array.from({ length: daysInMonth }, (_, index) => index + 1);
    };

    const currentMonthDate = selectedDate;
    const nextMonthDate = getFirstDayOfNextMonthDate(currentMonthDate);

    const currentMonthDays = getDaysForMonth(currentMonthDate);
    const nextMonthDays = getDaysForMonth(nextMonthDate);

    const currentMonthWeekends = useMemo(() => new Set(getWeekends(currentMonthDate)), [currentMonthDate]);
    const nextMonthWeekends = useMemo(() => new Set(getWeekends(nextMonthDate)), [nextMonthDate]);
    const isWeekend = (day, month, year) => {
        if (month === currentMonthDate.getMonth() && year === currentMonthDate.getFullYear()) {
            return currentMonthWeekends.has(day);
        } else if (month === nextMonthDate.getMonth() && year === nextMonthDate.getFullYear()) {
            return nextMonthWeekends.has(day);
        }
        return false;
    };

    const allEmployeeIds = allEmployees.map(employee => employee.id);
    const allEmployeeIdsWithoutDuplicates = [...new Set(allEmployeeIds)];

    // Memoize leave mapping
    const leaveMapping = useMemo(() => getLeaveForDayMapping(allEmployeesLeaves), [allEmployeesLeaves]);

    return (
        <>
            {assignVacationPopupVisible &&
                <div
                    style={{
                        left: assignVacationPopupPosition.left + "px",
                        top: assignVacationPopupPosition.top + "px"
                    }}
                    className="popup list-popup"
                    onContextMenu={(e) => e.preventDefault()}>
                    <div className="close-list-popup"
                         onContextMenu={(e) => e.preventDefault()}
                         onClick={(e) => closeVacationPopup(e)}>
                        <span className="close-icon">
                            {closeIcon}
                        </span>
                    </div>
                    <h3 className="legend-title"><FormattedMessage id="SelectType"/> </h3>
                    <div className="options">
                        {allLeavesTypes.map(type => (
                            <div
                                key={type.id}
                                onClick={() => openScheduleCalendar(type.id)}
                                className={(selectedHolidayType === type.id) ? "row active" : "row"}
                            >
                                <div className={`legend ${type.id.replace(/_/g, "-").toLowerCase()}`}></div>
                                {getLeaveTypeName(type.id, allLeavesTypes, locale)}
                            </div>
                        ))}
                    </div>
                    <div className="invisible-close-list-popup-overlay"
                         onClick={(e) => closeVacationPopup(e)}
                         onContextMenu={(e) => closeVacationPopup(e)}></div>
                </div>}
            <table id="vacation-calendar-table" className="table reports-table vacation-calendar-table">
                <thead>
                <tr>
                    <td className="clickable-name empty-td"></td>
                    <td colSpan={currentMonthDays.length} className="month-label">
                        <FormattedDate
                            value={currentMonthDate}
                            month="long"
                            timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                            locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                        />
                    </td>
                    <td colSpan={nextMonthDays.length} className="month-label">
                        <FormattedDate
                            value={nextMonthDate}
                            month="long"
                            timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                            locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                        />
                    </td>
                </tr>
                <tr>
                    <th
                        onContextMenu={(e) => e.preventDefault()}
                        onClick={(e) => onSelectAllRowsIds(e, allEmployeeIdsWithoutDuplicates)}
                        className="clickable name"
                    >
                        <div className="row">
                            <div className="checkbox">
                                <input
                                    type="checkbox"
                                    onChange={(e) => e.stopPropagation()}
                                    checked={JSON.stringify(selectedRowIds) === JSON.stringify(allEmployeeIdsWithoutDuplicates) && allEmployees.length > 0 && allEmployeeIdsWithoutDuplicates.length > 0}
                                />
                                <div className="checksquare"></div>
                            </div>
                            <FormattedMessage id="SelectAll"/>
                        </div>
                    </th>
                    {currentMonthDays.concat(nextMonthDays).map((day, index) => {
                        if (index === currentMonthDays.length) {
                            return (
                                <React.Fragment key={`header-${index}`}>
                                    <th className="month-separator"></th>
                                    <th className="next-month">{day}</th>
                                </React.Fragment>
                            );
                        }
                        return (
                            <th key={`header-${index}`} className={index >= currentMonthDays.length ? "next-month" : ""}>
                                {day}
                            </th>
                        );
                    })}
                </tr>
                </thead>
                <tbody>
                {allEmployees.length > 0 && allEmployees.map((employee, employeeIndex) => (
                    <tr className={selectedRowIds.includes(employee.id) ? "all-active " + employeeIndex : employeeIndex} key={employeeIndex}>
                        <td
                            onContextMenu={(e) => openVacationPopup(e, employee)}
                            onClick={(e) => onSelectSingleRowId(employee, employeeIndex, e)}
                            className="clickable name"
                        >
                            <div className="row">
                                <div className="checkbox">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => e.stopPropagation()}
                                        checked={selectedRowIds.includes(employee.id)}
                                    />
                                    <div className="checksquare"></div>
                                </div>

                                {
                                    employee.type === TYPES.ADMIN || employee.type === TYPES.MANAGER
                                        ? employee.type === TYPES.MANAGER
                                            ? <span className="manager">{adminIcon}</span>
                                            : <span className="admin">{adminIcon}</span>
                                        : null
                                }
                                {employee.name}
                                <i
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openVacationPopup(e, employee);
                                    }}
                                    className="icon down-arrow-icon"
                                >
                                    {downArrowIcon}
                                </i>
                            </div>
                        </td>
                        {currentMonthDays.concat(nextMonthDays).map((day, dayIndex) => {
                            const dayOfMonth = dayIndex < currentMonthDays.length ? dayIndex + 1 : dayIndex - currentMonthDays.length + 1;
                            const isNextMonth = dayIndex >= currentMonthDays.length;
                            const month = isNextMonth ? nextMonthDate.getMonth() : currentMonthDate.getMonth();
                            const year = isNextMonth ? nextMonthDate.getFullYear() : currentMonthDate.getFullYear();

                            const leave = leaveMapping[`${employee.id}|${dayOfMonth}|${month}|${year}`];
                            if (dayIndex === currentMonthDays.length) {
                                return (
                                    <React.Fragment key={dayIndex}>
                                        <td className="month-separator"></td>
                                        <td className={(isWeekend(dayOfMonth, month, year) ? "weekend" : "") + (isNextMonth ? " next-month" : "")}>
                                            {leave && (
                                                <div onClick={() => props.openRightPanel(leave, employee, leave.type)} className="vacation-log-row">
                                                    <div
                                                        style={{
                                                            left: "0px",
                                                            right: "0px",
                                                            margin: "auto",
                                                            width: "70%",
                                                            zIndex: dayIndex,
                                                        }}
                                                        className={`vacation-log ${getClassByType(leave.type)} ${leave.status.toLowerCase() === STATUSES.PENDING.toLowerCase() ? leave.status.toLowerCase() : ''}`}

                                                    ></div>
                                                    {leave.type && (
                                                        <div className="tooltip">
                                                            {getLeaveTypeName(leave.type)}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </td>
                                    </React.Fragment>
                                );
                            }

                            return (
                                <td
                                    className={(isWeekend(dayOfMonth, month, year) ? "weekend" : "") + (isNextMonth ? " next-month" : "")}
                                    key={dayIndex}
                                >
                                    {leave && (
                                        <div onClick={() => props.openRightPanel(leave.id, employee, leave.type)} className="vacation-log-row">
                                            <div
                                                style={{
                                                    left: "0px",
                                                    right: "0px",
                                                    margin: "auto",
                                                    width: "70%",
                                                    zIndex: dayIndex,
                                                }}
                                                className={`vacation-log ${getClassByType(leave.type)} ${leave.status.toLowerCase() === STATUSES.PENDING.toLowerCase() ? leave.status.toLowerCase() : ''}`}
                                            ></div>
                                            {leave.type && (
                                                <div className="tooltip">
                                                    {getLeaveTypeName(leave.type)}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </td>
                            );
                        })}
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    );
}

export default VacationCalendarAllTable;
