import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import 'react-calendar/dist/Calendar.css';

import {getEmployeeLeaveByIdHandler} from "../../../handlers/vacationPlannerHandlers/HandlerVacationRequestDetails";


function RequestDetails(props) {

    const openedRightPanelStyleName = "open";
    const closedRightPanelStyleName = "closed";

    const defaultFormFields = {
        firstName: "",
        lastName: "",
        phone: "",
        password: "",
        selectedClockingLocations: null,
        selectedRoles: null,
        selectedType: null,
        selectedManagedLocations: null,
        selectedManagedRoles: null,
        deviceId:null,


        from: null,
        to: null,
        status:null,
        userType:null,

    };

    const [state, setState] = useState({
        rightPanelStyleName: closedRightPanelStyleName,
        errorMsg: null,
        clockingLocations: [],
        roles:[],

        managedLocations:null,
        managedRoles:[],
        includeInReportsSwitchEnabled:true,
        ...defaultFormFields
    });

    useEffect(() => {
        getFormData();
    }, [props.employeeDetailsId]);






    const getFormData = () => {

        getEmployeeLeaveByIdHandler(props.employeeDetailsId).then(employeeResponse => {

            setState({
                ...state,
                userType: employeeResponse.data.request.userType,
                type: employeeResponse.data.request.type,
                timePeriod: employeeResponse.data.request.timePeriod,
                duration:employeeResponse.data.request.duration,
                status:employeeResponse.data.request.status,
                firstName: employeeResponse.data.request.firstName,
                lastName: employeeResponse.data.request.lastName,
                phone: employeeResponse.data.request.phone,


                selectedRoles: employeeResponse.data.request.roles,




                rightPanelStyleName: openedRightPanelStyleName,
                errorMsg: null
            })
        })

    }

    let bodyNewAccount;

    bodyNewAccount = {
        "companyId": props.companyId,
        "firstName": state.firstName,
        "lastName": state.lastName,
        "phone": state.phone,
        "password": state.password,
        "clockingLocations": state.selectedClockingLocations,
        "roles": state.selectedRoles,
        "type": "WORKER",
        "managedLocations":state.selectedManagedLocations,
        "managedRoles":state.selectedManagedRoles,
        "includeInReports": state.includeInReportsSwitchEnabled
    }

    let bodyEditAccount;

    bodyEditAccount = {
        "employeeId": props.employeeDetailsId,
        "companyId": props.companyId,
        "firstName": state.firstName,
        "lastName": state.lastName,
        "phone": state.phone,
        "clockingLocations": state.selectedClockingLocations,
        "roles": state.selectedRoles,
        "type": state.selectedType,
        "managedLocations":state.selectedManagedLocations,
        "managedRoles":state.selectedManagedRoles,
        "deviceId":state.deviceId,
        "includeInReports": state.includeInReportsSwitchEnabled
    }

    const createNewAccount = () => {
        setState({
            ...state,
            errorMsg: null,
            ...defaultFormFields
        })
        props.closeRightPanel(false);
    }

    const editExistingAccount = () => {

        setState({
            ...state,
            errorMsg: null
        })
        props.closeRightPanel(false);
    }

    const handleSubmit = () => {
        if (props.employeeDetailsId === "") {
            createNewAccount();
        } else {
            editExistingAccount();
        }
    }



    const validateData = () => {
        if (!state.firstName ||
            !state.lastName ||
            !state.phone ||
            !props.employeeDetailsId && (state.password.length === 0 || state.password === "")
        ) {
            setState({
                ...state,
                errorMsg: <FormattedMessage id="PleaseFillInAllFields" defaultMessage="PleaseFillInAllFields"/>
            })
            return false;
        }

        if (!state.phone.startsWith("+")) {
            setState({
                ...state,
                errorMsg: <FormattedMessage id="InvalidPhoneNumber" defaultMessage="InvalidPhoneNumber"/>
            })
            return false;
        }


        setState({
            ...state,
            errorMsg: null
        })
        return true;
    }



    return (
        <>
            <section className="form-container">
                <div className="details-form">

                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="requestType"> <FormattedMessage id="RequestType"
                                                                                           defaultMessage="RequestType"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="RequestType" defaultMessage="RequestType">
                                {placeholder =>
                                    <input
                                        className="input"
                                        type="text"
                                        readOnly={true}
                                        maxLength={80}
                                        placeholder={placeholder}
                                        autoComplete="off"
                                        id="requestType"
                                        value={state.type ? state.type : null}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="duration"> <FormattedMessage id="Duration"
                                                                                             defaultMessage="Duration"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="Duration" defaultMessage="Duration">
                                {placeholder =>
                                    <input
                                        className="input"
                                        type="text"
                                        readOnly={true}
                                        maxLength={80}
                                        placeholder={placeholder}
                                        autoComplete="off"
                                        id="duration"
                                        value={state.duration}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="timePeriod"> <FormattedMessage id="TimePeriod"
                                                                                              defaultMessage="TimePeriod"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="TimePeriod" defaultMessage="TimePeriod">
                                {placeholder =>
                                    <input
                                        className="input"
                                        type="text"
                                        readOnly={true}
                                        maxLength={80}
                                        placeholder={placeholder}
                                        autoComplete="off"
                                        id="timePeriod"
                                        value={state.timePeriod}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>







                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="firstName"> <FormattedMessage id="FirstName" defaultMessage="FirstName"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="FirstName" defaultMessage="FirstName">
                                {placeholder =>
                                    <input
                                        className="input"
                                        type="text"
                                        readOnly={true}

                                        maxLength={80}
                                        placeholder={placeholder}
                                        autoComplete="off"
                                        id="firstName"
                                        value={state.firstName}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>


                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="lastName">
                                <FormattedMessage id="LastName" defaultMessage="LastName"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="LastName" defaultMessage="LastName">
                                {placeholder =>
                                    <input
                                        className="input"
                                        type="text"
                                        readOnly={true}

                                        maxLength={100}
                                        placeholder={placeholder}
                                        autoComplete="off"
                                        id="lastName"
                                        value={state.lastName}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>


                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="phone"> <FormattedMessage id="PhoneNumber"
                                                                                        defaultMessage="Phone Number"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="PhoneNumberFormat" defaultMessage="PhoneNumberFormat">
                                {placeholder =>
                                    <input
                                        autoComplete="new-password"
                                        className="input"
                                        readOnly={true}
                                        type="tel"
                                        maxLength={100}
                                        placeholder={placeholder}

                                        id="phone"
                                        value={state.phone}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>


                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="userType"> <FormattedMessage id="UserType"
                                                                                           defaultMessage="UserType"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="UserType" defaultMessage="UserType">
                                {placeholder =>
                                    <input
                                        className="input"
                                        type="text"
                                        readOnly={true}
                                        maxLength={80}
                                        placeholder={placeholder}
                                        autoComplete="off"
                                        id="firstName"
                                        value={state.userType ? state.userType : null}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="roles">
                                <FormattedMessage id="Roles" defaultMessage="Roles"/>
                            </label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="Roles" defaultMessage="Roles">
                                {placeholder =>
                                    <input
                                        autoComplete="new-password"
                                        className="input"
                                        readOnly={true}
                                        type="tel"
                                        maxLength={100}
                                        placeholder={placeholder}
                                        id="phone"
                                        value={state.selectedRoles}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="row">
                            <label className="label" htmlFor="status"> <FormattedMessage id="Status"
                                                                                              defaultMessage="Status"/></label>
                        </div>
                        <div className="row">
                            <FormattedMessage id="Status" defaultMessage="Status">
                                {placeholder =>
                                    <input
                                        className={`input ${state.status === "APROBAT" ? "success" : "danger"}`}
                                        type="text"
                                        readOnly={true}
                                        maxLength={80}
                                        placeholder={placeholder}
                                        autoComplete="off"
                                        id="status"
                                        value={state.status ? state.status : null}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>


                    <div className="row buttons-container">
                        {state.status === "APROBAT" ?
                            <button onClick={() => handleSubmit()} className="btn btn-default">Închide</button>
                            :
                            <button onClick={() => handleSubmit()} className="btn btn-success">Aprobat</button>
                        }

                    </div>

                    {state.errorMsg && <div className="error">&#9888;&nbsp;{state.errorMsg}</div>}
                </div>
            </section>
        </>
    );
}

export default RequestDetails;
