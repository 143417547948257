import React, {useContext, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";

import {PATH} from "../../routes/routes.js";
import Header from "../../components/Header";
import {deleteCompanyHandler, getAllCompaniesHandler} from "../../handlers/companiesHandlers/HandlerCompaniesAll";
import closeIcon from "../../assets/img/icon_close.svg";
import * as StorageService from "../../services/StorageServices";
import {LOCALSTORAGE_ITEMS, TYPES} from "../../util/Constants";
import {useNavigate} from "react-router";

function CompaniesAll() {
    const navigateTo = useNavigate();
    const type = StorageService.getItem(LOCALSTORAGE_ITEMS.TYPE) || "Unknown";
    const companyInfo = StorageService.getItem(LOCALSTORAGE_ITEMS.COMPANY_INFO) ? JSON.parse(StorageService.getItem(LOCALSTORAGE_ITEMS.COMPANY_INFO)) : null;
    const [state, setState] = useState({
        loading: false,
        errorMsg: null,
        allFacilities: [],
        isDeleteFacilityAlertOpen: false,
        selectedFacility: null,
    });

     useEffect( () => {

        if ((StorageService.getItem(LOCALSTORAGE_ITEMS.TYPE)) !== TYPES.SUPER_ADMIN) {
            navigateTo(PATH.COMPANIES_EMPLOYEES(companyInfo?.id));
        } else {
             getPageContent();
        }


    }, []);


    const getPageContent = async () => {

         getAllCompaniesHandler().then(response => {
             setState({
                 ...state,
                 allFacilities: response.data.allFacilities
             })
        });

    }

    const getSelectedFacilityName = () => state.allFacilities.filter(e => state.selectedFacility?.includes(e.id)).map(e => {
        return e.name;
    });


    const deleteCompany = async (companyId) => {
        deleteCompanyHandler(companyId).then(()=>{
            getAllCompaniesHandler().then(response => {
                setState({
                    ...state,
                    loading: false,
                    allFacilities: response.data.allFacilities,
                    isDeleteFacilityAlertOpen: false,
                })
            });
        })
    }


    const openDeleteAlert = (e, companyId) => {
        e.stopPropagation();
        setState({
            ...state,
            isDeleteFacilityAlertOpen: true,
            selectedFacility: companyId,
        });
    }

    const hideAlert = () => {

        setState({
            ...state,
            isDeleteFacilityAlertOpen: false,
            selectedFacility: null
        })
    }


    const getAlert = () =>{
        if(state.isDeleteFacilityAlertOpen) {
            return {
                title: <FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="Facility" defaultMessage="Facility"/> }} />,
                contentRow1: <FormattedMessage id="DoYouWantToDeleteParam" values={{ param: getSelectedFacilityName() }} />,
                deleteAction1: () => deleteCompany(state.selectedFacility),
                deleteText1: <FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="Facility" defaultMessage="Facility"/> }} />,
                cancelAction: () => hideAlert(),
                cancelText: <FormattedMessage id="Cancel" defaultMessage="Cancel"/>,
                hideAlert: () => hideAlert()
            }
        }
    }
    const toEmployeesPage = (id) => {

    navigateTo(PATH.COMPANIES_EMPLOYEES(id));
    }
    const toCreateFacilityPage = () => {
        navigateTo(PATH.NEW_COMPANY);
    }

    const toProfilePage = () => {
        navigateTo(PATH.PROFILE);
    }


    return (
        <div className="content">

            <Header
                alert={getAlert()}
                hasProfileBtn={type===TYPES.SUPER_ADMIN ? false:true}
                headerTitle={ <FormattedMessage id="Facilities" defaultMessage="Facilities"/>}
                headerTitleOnlyForSmallScreens={true}
                toProfilePage={() => toProfilePage()}
            />
            <div className="inner-content with-padding-top">
                <section className="top-actions">
                    <button className="btn btn-success only-for-desktop" onClick={() => toCreateFacilityPage()}><FormattedMessage id="NewFacility" defaultMessage="NewFacility"/></button>
                    <button className="btn btn-success only-for-small-screens" onClick={() => toCreateFacilityPage()}> <img src={closeIcon}/></button>
                </section>
                <section className="cards">

                    {

                        state.allFacilities ?

                        state.allFacilities.map((facility, index) => (

                        <div onClick={() => toEmployeesPage(facility.id)} key={"productIndex"+ index} className="card">
                            <div className="row">
                                <div className="col logo">
                                    <img src={facility.picture} alt=""/>


                                </div>
                                <div className="col text">
                                    <div className="row">
                                        <p data-title={facility.name}>{facility.name}</p>
                                    </div>
                                    <div className="row faded-text">
                                        <p data-title={facility.address}>{facility.address}</p>
                                    </div>
                                    <div className="row buttons-row only-for-desktop hidden">
                                        <a id="delete" className="link danger" onClick={(e) => openDeleteAlert(e, facility.id)}><FormattedMessage id="Delete" defaultMessage="Delete"/></a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    ))
                            : <FormattedMessage id="NoCompaniesYet" defaultMessage="NoCompaniesYet"/>
                    }
                </section>
            </div>

        </div>
    );
}

export default CompaniesAll;
