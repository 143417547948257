import React from "react";

import backImg from "../assets/img/icon_back.svg";
import profilePicture from "../assets/img/profile.png";
import TabNavigation from "./TabNavigation";
import {PATH} from "../routes/routes";
import Alert from "./Alert";
import {useNavigate} from "react-router";


function Header(props) {
    const navigateTo = useNavigate();

    const goBack = () => {
        navigateTo(PATH.ALL_COMPANIES);
    }

    const toProfilePage = () => {
        navigateTo(PATH.PROFILE);
    }

    const dot = <svg className="dot" width="3" height="3" viewBox="0 0 3 3" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="1.5" cy="1.5" r="1.5" fill="#4D5062"/>
    </svg>


    return (
        <>

            <header className="header">
                <div className="top-banner">
                    {
                    <div className="row">
                        {props.hasProfileBtn &&
                        <a className="logo only-for-small-screens" onClick={(e)=> toProfilePage()}>
                            <img src={profilePicture}/>
                        </a>
                        }
                        {props.hasBackBtn &&
                        <a className="btn-back" onClick={goBack}>
                            <img src={backImg}/>
                        </a>
                        }
                        {props.headerTitle && <>
                            <h1 className={props.headerTitleOnlyForSmallScreens? "only-for-small-screens": ""}>{props.headerTitle}</h1>
                        </>
                        }


                        {props.hasLink && <>
                            <a onClick={props.handleLinkClick} className="link right-aligned">{props.linkName}</a>
                        </>

                        }

                        {props.hasHamburgerMenu &&
                            <>
                            {props.isHamburgerMenuVisible &&<div className="close-nav-element" onClick={props.openHamburgerMenu}></div>}
                            <div className="hamburger-container only-for-small-screens">
                                <div onClick={props.openHamburgerMenu} className="hamburger-dots">{dot}{dot}{dot}</div>
                                {props.isHamburgerMenuVisible &&<div className="hamburger-content">
                                    <a onClick={props.hamburgerLinkAction1}>{props.hamburgerLinkName1}</a>
                                </div>}
                            </div>
                            </>
                        }
                    </div>
                    }

                    {props.hasMsg &&  <div className="row msg">
                        <p>
                            {props.msg}
                        </p>
                    </div>}
                    {props.children}



                </div>
                {props.alert &&  <Alert
                    {...props.alert}
                />}
                {props.alert2 &&  <Alert
                    {...props.alert2}
                />}

            </header>
        </>
    );

}

export default Header;
