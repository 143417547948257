import React, {useState} from "react";
import {FormattedDate, FormattedMessage} from "react-intl";

import 'react-calendar/dist/Calendar.css';
import {
    getTotalHoursAndMinsFromIntervalMillis,
    getTotalHoursAndMinsFromMillisWithLabels,
} from "../../util/common";
import {calendarIcon, closeIcon} from "../../components/Icons";
import * as StorageService from "../../services/StorageServices";
import {LOCALSTORAGE_ITEMS} from "../../util/Constants";

function ClockingsReport(props) {
    const [state, setState] = useState({
        intervalPopupVisible: false,
        selectedDay: null,
    });

    const toggleIntervalPopupVisibility = (selectedEmployee, selectedLocation, index) => {
        setState({
            ...state,
            intervalPopupVisible: !state.intervalPopupVisible,
            selectedEmployeeId: selectedEmployee,
            selectedLocationId: selectedLocation,
            selectedDay: index
        })
    }






    return (
        <>
            <table className="table reports-table clocking-reports-table">

                {/*---------------------------------start thead------------------------*/}
                <thead>
                <tr>
                    <th className="name"><FormattedMessage id="Employee"/></th>
                    {
                        Array.from(Array(props.numOfDaysInMonth)).map((day, index) => (
                            <th key={index}>{index + 1}</th>
                        ))
                    }
                </tr>
                </thead>
                {/*---------------------------------end thead------------------------*/}



                {/*---------------------------------start tbody------------------------*/}
                <tbody>
                {props.allClockingsReports.map((report, reportIndex) => (
                    <tr className={reportIndex} key={reportIndex}>
                        <td className="name">
                            {report.firstName + " " + report.lastName} {props.selectedLocation === null ? "(" + report.location + ")" : null}
                        </td>
                        {
                            Array.from(Array(props.numOfDaysInMonth)).map((day, index) => (
                                <td className={props.weekendDaysInMonth.includes(index + 1) ? "weekend" : ""}
                                    tabIndex={props.getIndex(reportIndex, index)}
                                    key={index}
                                >
                                    <span className="from">
                                        {
                                            getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[index]) !== null &&
                                            getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[index])?.from !== null ?
                                                getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[index]).from
                                                : "none "
                                        }
                                    </span>&nbsp;-&nbsp;
                                    <span className="to">
                                        {getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[index]) !== null &&
                                        getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[index])?.to !== null ?
                                            getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[index]).to
                                            : "none "
                                        }
                                    </span>

                                    {getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[index]) !== null &&
                                    getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[index])?.totalTime !== null &&
                                        <span className="total-hours">&nbsp;&nbsp;|&nbsp;&nbsp;
                                        {getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[index]).totalTime}
                                        {report.daysClockingIntervals[index].length > 1 &&
                                            <span onClick={() => toggleIntervalPopupVisibility(report.employeeId, report.locationId, index)}
                                                  className="prevent-scroll">&nbsp;&nbsp;{calendarIcon}</span>
                                        }
                                    </span>}


                                    {/* START EDITABLE INPUTS*/}
                                    {
                                        props.selectedEmployee && props.selectedEmployee == report.employeeId && props.selectedEmployeeLocation === report.locationId && props.selectedDay === index &&
                                        <div
                                            className={props.hasErrors ? "row has-errors editable-time prevent-scroll" : "row editable-time prevent-scroll"}>
                                            <input
                                                autoFocus
                                                tabIndex={props.getIndex(reportIndex, index)+1}
                                                onBlur={(e) => props.handleHoursAndMinsChange(e, "hours")}
                                                onChange={(e) => props.handleOnChange(e, "hours")}
                                                onKeyDown={(e) => props.handleKeyDown(e, "hours")}
                                                type="number"
                                                className="hours"/>
                                            <span className="middot">:</span>
                                            <input
                                                tabIndex={props.getIndex(reportIndex, index)+1}
                                                onBlur={(e) => props.handleHoursAndMinsChange(e, "minutes")}
                                                onChange={(e) => props.handleOnChange(e, "minutes")}
                                                onKeyDown={(e) => props.handleKeyDown(e, "minutes")}
                                                onMouseLeave={(e) => props.handleHoursAndMinsChange(e, "minutes")}
                                                type="number"
                                                className="minutes"/>
                                        </div>
                                    }
                                    {/*END EDITABLE INPUTS*/}
                                </td>
                            ))

                        }
                    </tr>
                ))}
                </tbody>
                {/*---------------------------------end tbody------------------------*/}
            </table>





            {/*---------------------------------START INTERVAL POPUP------------------------*/}
            {state.intervalPopupVisible && <>
                <div
                    onClick={() => toggleIntervalPopupVisibility(null, null, null)}
                    className="blurry-background">
                </div>
                {props.allClockingsReports.filter(report => report.employeeId === state.selectedEmployeeId &&
                    report.locationId === state.selectedLocationId).map((report, reportIndex) => (
                    <div className="interval-popup-cont">
                        <div key={reportIndex}
                             className="intervals-popup">
                            <div className="row title-row">
                                <FormattedMessage id="ParamsShift"
                                                  values={{param: report.firstName + " " + report.lastName}}>
                                </FormattedMessage>
                                &nbsp;-&nbsp;
                                {state.selectedDay + 1}&nbsp;
                                {
                                    <FormattedDate
                                        value={props.selectedDate}
                                        month="long"
                                        timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                                        locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                    />
                                }.
                                <span
                                    onClick={() => toggleIntervalPopupVisibility(null, null, null)}
                                    className="ml-auto close-icon">
                                    {closeIcon}
                                </span>
                            </div>
                            <div className="mini-table-container">
                                <table className="mini-clocking-table">
                                    <thead>
                                    <tr>
                                        <th><FormattedMessage id="Nr" defaultMessage="Nr."/></th>
                                        <th><FormattedMessage id="Clockin" defaultMessage="Clockin"/></th>
                                        <th><FormattedMessage id="Clockout" defaultMessage="Clockout"/></th>
                                        <th><FormattedMessage id="WorkedHours" defaultMessage="WorkedHours"/></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {report.daysClockingIntervals[state.selectedDay].map((clocking, index) => (
                                        <tr key={index}>
                                            <td className="number-td">{report.daysClockingIntervals[state.selectedDay].indexOf(clocking) + 1}.</td>
                                            <td>{getTotalHoursAndMinsFromIntervalMillis([clocking]).from}</td>
                                            <td>{getTotalHoursAndMinsFromIntervalMillis([clocking]).to}</td>
                                            <td>{getTotalHoursAndMinsFromMillisWithLabels(clocking.workedTime)}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>

                            </div>
                            <div className="row total-row">
                                <FormattedMessage
                                    id="TotalWorkedHours"
                                    defaultMessage="TotalWorkedHours"/>
                                <span className="ml-auto">
                                    {
                                        getTotalHoursAndMinsFromIntervalMillis(report.daysClockingIntervals[state.selectedDay]).totalTime
                                    }
                               </span>
                            </div>
                        </div>
                    </div>
                ))}
            </>
            }
            {/*---------------------------------END INTERVAL POPUP------------------------*/}
        </>

    );
}

export default ClockingsReport;
