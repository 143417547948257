
import {
    getFirstDayOfMonth,
    getHourAndMinFromMillis,
    getTotalDaysInMonth,
    addDateWithFields, resetTime
} from "../../util/common";
import {
    deleteDateWorkTimeIntervalsById, editDateWorkTimeIntervals,
    getAllDateWorkTimeIntervalsForCompany
} from "../../services/workHoursService/WorkHoursService";
import {editEmployeeById, getAllEmployeesFromCompany} from "../../services/companiesServices/CompaniesEmployeesService";
import {DAY_MILLIS} from "../../util/Constants";
import {updateScheduleNotifications} from "../../services/schedulesService/SchedulesService";


export const getAllEmployeesByCompanyIdHandler = async (companyId, date) => {
    const totalDaysInMonth = getTotalDaysInMonth(date);
    const startDateMillis = getFirstDayOfMonth(date).getTime();

    let error = null;

    const allEmployeesResponse = await getAllEmployeesFromCompany(companyId);

    let employeesResponse = null;

    if (allEmployeesResponse.ok) {
        employeesResponse =  {
            employees: allEmployeesResponse.data.map(employee=>{
                const startDate = new Date(startDateMillis);
                const workSchedule = Array.from(Array(totalDaysInMonth)).map((day, index) => {
                    const dateMillis = addDateWithFields(startDate, {"day":index}).getTime();

                    let dayIntervals = null;
                    if(employee.workSchedule) {

                        const workScheduleDateMillis = new Date(employee.workSchedule.date).getTime();
                        if(dateMillis >= workScheduleDateMillis){
                            let diff = dateMillis - workScheduleDateMillis;
                            if(!Number.isInteger(diff / DAY_MILLIS)){
                                diff = Math.round(diff / DAY_MILLIS) * DAY_MILLIS
                            }
                            const dayIntervalsIndex = (diff/DAY_MILLIS) % employee.workSchedule.workTimeIntervals.daysIntervals.length;
                            dayIntervals = employee.workSchedule.workTimeIntervals.daysIntervals[dayIntervalsIndex].dayIntervals;
                        }
                    }

                    return {
                        "date": new Date(dateMillis),
                        "dayIntervals": dayIntervals,
                    };
                });
                return  {
                    id: employee.id,
                    roles: employee.roles,
                    phone: employee.phone,
                    name:  employee.firstName + " " + employee.lastName,
                    type: employee.type,
                    clockingLocations: employee.clockingLocations? employee.clockingLocations : [],
                    workSchedule: workSchedule,
                    workScheduleDetails: {
                        id: employee.workSchedule?.workTimeIntervals?.id,
                        name: employee.workSchedule?.workTimeIntervals?.name
                    }
                }
            })

        }
        employeesResponse.employees.sort(function(a, b){return (a.name).localeCompare(b.name)});

    } else {
        error ="Error getAllEmployeesByCompanyIdHandler";
    }
    return {
        data: {
            error: error,
            allEmployees: employeesResponse
        }
    };
}



export const editCompanyEmployeeByIdHandler = async (dataList) => {

    let error = null;
    const body = dataList.map(data => {
        return {
            "employeeId":data.employeeId,
            "firstName": data.firstName,
            "lastName": data.lastName,
            "phone":data.phone,
            "email":data.email,
            "password": data.password,
            "clockingLocations": data.clockingLocations,
            "roles": data.roles,
            "type": data.type,
            "managedLocations":data.managedLocations,
            "managedRoles":data.managedRoles,
            "workSchedule":data.workSchedule
        }});
    const response = await editEmployeeById(body);
    if (response.ok) {
    } else {
        error ="Error editCompanyEmployeeByIdHandler";
    }
    return {
        data: {
            error: error,
            message: response.data.message
        }
    };
}



export const getDateWorkTimeIntervalsFromCompanyHandler = async (companyId, from, to) => {

    let error = null;

    const response = await getAllDateWorkTimeIntervalsForCompany(companyId, from, to);
    let dateWorkTimeIntervalsResponse = null;
    if (response.ok) {

        dateWorkTimeIntervalsResponse = response.data.map(interval=>{
            return  {
                id: interval.id,
                companyId: interval.companyId,
                employeeId: interval.employeeId,
                date: interval.date,
                dayIntervals: interval.dayIntervals
            }
        })

    } else {
        error ="Error getDateWorkTimeIntervalsFromCompanyHandler";
    }
    return {
        data: {
            error: error,
            schedules: dateWorkTimeIntervalsResponse
        }
    };
}




export const editDateWorkTimeIntervalsHandler = async (data) => {

    let error = null;
    const body = {
        "companyId": data.companyId,
        "timeZone":data.timeZone,
        "datesWorkTimeIntervals": data.datesWorkTimeIntervals.map(dateWorkTimeInterval => {
            return {
                employeeId: dateWorkTimeInterval.employeeId,
                date: dateWorkTimeInterval.date,
                // dayIntervals: []
                dayIntervals: dateWorkTimeInterval.dayIntervals.map(dayInterval => {
                    return {
                        from: dayInterval.from,
                        to: dayInterval.to
                    };
                })
            }
        })
    }

    const response = await editDateWorkTimeIntervals(body);
    if (response.ok) {
    } else {
        error = response.data.message;
    }
    return {
        data: {
            error: error
        }
    };
}




export const deleteDateWorkTimeIntervalsByIdHandler = async (id) => {
    const response = await deleteDateWorkTimeIntervalsById(id);
    let error = null;
    if (response.ok) {
    } else {
        error ="Error deleteDateWorkTimeIntervalsByIdHandler";
    }
    return {
        data: {
            error: null
        }
    };
}


export const updateScheduleNotificationsHandler = async (dataList) => {

    let error = null;
    const body = dataList.map(data => {
        return {
            "companyId":data.companyId,
            "employeeId":data.employeeId,

        }});
    const response = await updateScheduleNotifications(body);
    if (response.ok) {
    } else {
        error ="Error updateScheduleNotificationsHandler";
    }
    return {
        data: {
            error: error,
        }
    };
}


