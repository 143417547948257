import React from "react";
import {getDateObjectTmz,newDateTmzWithFields,getFirstDayOfMonth} from "../util/common";
import 'react-calendar/dist/Calendar.css';
import Calendar from "react-calendar";

function CalendarTmz(props){
    const tz = props.tz || new Intl.DateTimeFormat().resolvedOptions().timeZone;
    const getDtmz = (e) =>{

      if (Array.isArray(e)) {

          const dtmz1 = newDateTmzWithFields(e[0], tz,{
              year:e[0].getFullYear(),
              month:e[0].getMonth()+1,
              day:e[0].getDate(),
              hour:"0",
              minute:"0",
              second:"0"});

          const dtmz2 = newDateTmzWithFields(e[1], tz,{
              year:e[1].getFullYear(),
              month:e[1].getMonth()+1,
              day:e[1].getDate(),
              hour:"0",
              minute:"0",
              second:"0"});
          return [dtmz1, dtmz2];
        }

        const dtmz = newDateTmzWithFields(e, tz,{
            year:e.getFullYear(),
            month:e.getMonth()+1,
            day:e.getDate(),
            hour:"0",
            minute:"0",
            second:"0"});
        return dtmz;
    };

    const onChange = (e) => {
        if(typeof(props.onChange) !== "undefined"){

            if (Array.isArray(e)) {
                const dtmz1 = getDtmz(e[0]);
                const dtmz2 = getDtmz(e[1]);
                props.onChange([dtmz1, dtmz2]);
            }else{
                const dtmz = getDtmz(e);
                props.onChange(dtmz);
            }

        }
    };


    const onClickMonth = (e) => {
        if(typeof(props.onClickMonth) !== "undefined"){
            const dtmz = getDtmz(e);

            props.onClickMonth(getFirstDayOfMonth(dtmz));
        }
    };

    const onClickDay = (e) => {
        if(typeof(props.onClickDay) !== "undefined"){
            const dtmz = getDtmz(e);
            props.onClickDay(dtmz);
        }
    };

    const onClickYear = (e) => {
        console.error("Year not supported yet!!!")

    };

    const dtmz = getDateObjectTmz(props.defaultValue, tz);
    const defaultValue = newDateTmzWithFields(props.defaultValue,
        new Intl.DateTimeFormat().resolvedOptions().timeZone,
        {year:dtmz.year,month:dtmz.month,day:dtmz.day,hour:"0",minute:"0",second:"0"});


    return <Calendar {...props}
            onClickMonth={onClickMonth}
            onClickDay={onClickDay}
            onChange = {onChange}
            onClickYear={onClickYear}
        />

}

export default CalendarTmz;
