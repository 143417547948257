import * as ClientConfig from "../../config/ClientConfig.js";
import * as StorageService from "../StorageServices.js";
import {LOCALSTORAGE_ITEMS} from "../../util/Constants";
import {handleAuthEmptyResponse, handleAuthResponse} from "../../util/api/restClient";


const getAllRoles =  async () => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/company-locations";

        return fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            }
        });
    });
}

const getAllRolesFromCompany =  async (companyId) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/company-roles/company/" + companyId;

        return fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            }
        });
    });
}

const createRole =  async (body) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/company-roles";
        const response = fetch(finalURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
            body: JSON.stringify(body),
        });
        return response;
    });
}

const getRoleById =  async (locationId) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/company-roles/" + locationId;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
        });
        return response;
    });
}

const editRoleById =  async (locationId, body) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/company-roles/" + locationId;
        const response = fetch(finalURL, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
            body: JSON.stringify(body)
        });
        return response;
    });
}

const deleteRoleById =  async (locationId) => {
    return handleAuthEmptyResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/company-roles/" + locationId;
        const response = fetch(finalURL, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
        });
        return response;
    });
}


export {
    getAllRoles,
    getAllRolesFromCompany,
    createRole,
    getRoleById,
    editRoleById,
    deleteRoleById
}
