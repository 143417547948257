
import {
    getFirstDayOfMonth,
    getHourAndMinFromMillis,
    getTotalDaysInMonth,
    addDateWithFields, resetTime
} from "../../util/common";
import {
    deleteDateWorkTimeIntervalsById, editDateWorkTimeIntervals,
    getAllDateWorkTimeIntervalsForCompany
} from "../../services/workHoursService/WorkHoursService";
import {editEmployeeById, getAllEmployeesFromCompany} from "../../services/companiesServices/CompaniesEmployeesService";
import {DAY_MILLIS} from "../../util/Constants";
import {updateScheduleNotifications} from "../../services/schedulesService/SchedulesService";
import {
    createEmployeeLeave,
    editEmployeeLeaveById,
    getAllEmployeeLeaves
} from "../../services/vacationPlannerServices/LeavesService";
import {getAllRoles, getAllRolesFromCompany} from "../../services/companiesServices/CompaniesRolesService";

export const getAllEmployeesByCompanyIdHandler = async (companyId, date) => {
    const totalDaysInMonth = getTotalDaysInMonth(date);
    const startDateMillis = getFirstDayOfMonth(date).getTime();

    let error = null;

    const allEmployeesResponse = await getAllEmployeesFromCompany(companyId);
    const rolesResponse = await getAllRolesFromCompany(companyId);
    let employeesResponse = null;

    if (allEmployeesResponse.ok) {
        employeesResponse =  {
            allRoles: rolesResponse.data,
            employees: allEmployeesResponse.data.map(employee=>{
                const startDate = new Date(startDateMillis);
                const workSchedule = Array.from(Array(totalDaysInMonth)).map((day, index) => {
                    const dateMillis = addDateWithFields(startDate, {"day":index}).getTime();

                    let dayIntervals = null;
                    if(employee.workSchedule) {

                        const workScheduleDateMillis = new Date(employee.workSchedule.date).getTime();
                        if(dateMillis >= workScheduleDateMillis){
                            let diff = dateMillis - workScheduleDateMillis;
                            if(!Number.isInteger(diff / DAY_MILLIS)){
                                diff = Math.round(diff / DAY_MILLIS) * DAY_MILLIS
                            }
                            const dayIntervalsIndex = (diff/DAY_MILLIS) % employee.workSchedule.workTimeIntervals.daysIntervals.length;
                            dayIntervals = employee.workSchedule.workTimeIntervals.daysIntervals[dayIntervalsIndex].dayIntervals;
                        }
                    }

                    return {
                        "date": new Date(dateMillis),
                        "dayIntervals": dayIntervals,
                    };
                });
                return  {
                    id: employee.id,
                    roles: employee.roles,
                    phone: employee.phone,
                    name:  employee.firstName + " " + employee.lastName,
                    type: employee.type,
                    clockingLocations: employee.clockingLocations? employee.clockingLocations : [],
                    vacationDays: workSchedule,
                    workSchedule: workSchedule,
                    workScheduleDetails: {
                        id: employee.workSchedule?.workTimeIntervals?.id,
                        name: employee.workSchedule?.workTimeIntervals?.name
                    }
                }
            })

        }
        employeesResponse.employees.sort(function(a, b){return (a.name).localeCompare(b.name)});

    } else {
        error ="Error getAllEmployeesByCompanyIdHandler";
    }
    return {
        data: {
            error: error,
            allEmployees: employeesResponse

        }
    };
}


export const getAllLeavesByCompanyIdHandler = async (companyId, locationsIds, rolesIds, from, to, createdFrom, createdTo, leaveStatus, page, size, sort) => {

    const allEmployeesLeavesResponse = await getAllEmployeeLeaves(companyId, locationsIds, rolesIds, from, to, createdFrom, createdTo, leaveStatus, page, size, sort);
    if (allEmployeesLeavesResponse.ok) {
        return allEmployeesLeavesResponse.data.content;
    }
};

export const createEmployeeLeaveHandler = async (employeesArray) => {
    let error = null;
    const body = employeesArray.map(data => {
        return {
            "employeeId": data.employeeId,
            "from": data.from,
            "to": data.to,
            "typeId": data.typeId,
            "request": data.request,
            "status":data.status
        }});
    const response = await createEmployeeLeave(body);
    if (response.ok) {
    } else {
        error ="Error createEmployeeLeaveHandler";
    }
    return {
        data: {
            error: response.data.errorCode || response.data.status || response.data.message ? {
                errorCode: response.data.errorCode? response.data.errorCode: null,
                status: response.data.status? response.data.status: null,
                message: response.data.message? response.data.message: null
            }
            :null,
            updatedEmployees: response.data
        }
    };
}



export const editEmployeeLeaveByIdHandler = async (leaveId, data) => {
    let error = null;
    const body = {
        "from": data.from,
        "to": data.to,
        "typeId": data.typeId,
        "status": data.status,
        "request": data.request,
        "response": data.response
    }
    const response = await editEmployeeLeaveById(leaveId, body);
    let id = null;
    if (response.ok) {
        id = response.data.id
    } else {
        error ="Error editEmployeeLeaveById";
    }
    return {
        data: {
            id: id,
            error: error
        }

        // data: {
        //     id: "2",
        //     error: error
        // }
    };
}

export const editVacationDaysForEmployeeHandler = async (dataList) => {

    let error = null;
    const body = dataList.map(data => {
        return {
            "employeeId":data.employeeId,
            "firstName": data.firstName,
            "lastName": data.lastName,
            "phone":data.phone,
            "email":data.email,
            "password": data.password,
            "clockingLocations": data.clockingLocations,
            "roles": data.roles,
            "type": data.type,
            "managedLocations":data.managedLocations,
            "managedRoles":data.managedRoles,
            "workSchedule":data.workSchedule
        }});
    const response = await editEmployeeById(body);
    if (response.ok) {
    } else {
        error ="Error editCompanyEmployeeByIdHandler";
    }
    return {
        data: {
            error: error,
            message: response.data.message
        }
    };
}





export const deleteDateWorkTimeIntervalsByIdHandler = async (id) => {
    const response = await deleteDateWorkTimeIntervalsById(id);
    let error = null;
    if (response.ok) {
    } else {
        error ="Error deleteDateWorkTimeIntervalsByIdHandler";
    }
    return {
        data: {
            error: null
        }
    };
}


export const updateScheduleNotificationsHandler = async (dataList) => {

    let error = null;
    const body = dataList.map(data => {
        return {
            "companyId":data.companyId,
            "employeeId":data.employeeId,

        }});
    const response = await updateScheduleNotifications(body);
    if (response.ok) {
    } else {
        error ="Error updateScheduleNotificationsHandler";
    }
    return {
        data: {
            error: error,
        }
    };
}


