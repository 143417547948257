import React from "react";


import {FormattedMessage} from "react-intl";

import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {PATH} from "../routes/routes";
import {LOCALSTORAGE_ITEMS, TYPES, TYPES as TYPE} from "../util/Constants";
import * as StorageServices from "../services/StorageServices";
import {useNavigate} from "react-router";


function TabNavigation(props) {
    const navigateTo = useNavigate();
    const type = StorageServices.getItem(LOCALSTORAGE_ITEMS.TYPE);


    const toWorkHoursPage = () => {
        navigateTo(PATH.SCHEDULER_WORK_HOURS(props.companyId));
    }

    const toSchedulesPage = () => {
        navigateTo(PATH.SCHEDULER_SCHEDULES(props.companyId));
    }

    const toCompaniesEmployeesPage = () => {
        navigateTo(PATH.COMPANIES_EMPLOYEES(props.companyId));
    }

    const toCompaniesRolesPage = () => {
        navigateTo(PATH.COMPANIES_ROLES(props.companyId));
    }

    const toCompaniesNFCsPage = () => {
        navigateTo(PATH.COMPANIES_NFCs(props.companyId));
    }
    const toCompaniesLocationsPage = () => {
        navigateTo(PATH.COMPANIES_LOCATIONS(props.companyId));
    }

    const toCompaniesSettingsPage = () => {
        navigateTo(PATH.COMPANIES_SETTINGS(props.companyId));
    }

    const toLiveTrackerPage = () => {
        navigateTo(PATH.LIVE_TRACKER(props.companyId));
    }

    const toSchedulerPage = () => {
        navigateTo(PATH.SCHEDULER_WORK_HOURS(props.companyId));
    }

    const toVacationRequestsPage = () => {
        navigateTo(PATH.VACATION_REQUESTS(props.companyId));
    }

    const toVacationCalendarPage = () => {
        navigateTo(PATH.VACATION_PLANNER_CALENDAR(props.companyId));
    }

    if (props.page === "vacation-planner" && type!==TYPE.SUPER_ADMIN) {

        return (
            <div className="row tab-navigation-container">
                <Tabs
                    className="tab-navigation"
                    defaultIndex={props.activeTab}
                    selectedTabClassName="active"
                >
                    <TabList className="row">
                        <Tab onClick={() => toVacationRequestsPage()}><FormattedMessage id="VacationRequests" defaultMessage="VacationRequests"/>
                            {props.pendingLeavesCount > 0 && <span className="notifications">{props.pendingLeavesCount}</span>}
                        </Tab>
                        <Tab onClick={() => toVacationCalendarPage()}><FormattedMessage id="VacationCalendar" defaultMessage="VacationCalendar"/></Tab>

                    </TabList>

                    <TabPanel>
                    </TabPanel>

                    <TabPanel>
                    </TabPanel>

                </Tabs>
            </div>

        );
    }else if (props.page === "scheduler" && type!==TYPE.SUPER_ADMIN) {

        return (
            <div className="row tab-navigation-container">
                <Tabs
                    className="tab-navigation"
                    defaultIndex={props.activeTab}
                    selectedTabClassName="active"
                >
                    <TabList className="row">
                        <Tab onClick={() => toWorkHoursPage()}><FormattedMessage id="WorkHours" defaultMessage="WorkHours"/></Tab>
                        <Tab onClick={() => toSchedulesPage()}><FormattedMessage id="Schedules" defaultMessage="Schedules"/></Tab>

                    </TabList>

                    <TabPanel>
                    </TabPanel>

                    <TabPanel>
                    </TabPanel>

                </Tabs>
            </div>

        );
    } else if (type!==TYPE.MANAGER && type!==TYPE.WORKER) {

        return (
            <div className="row tab-navigation-container">
                <Tabs
                    className="tab-navigation"
                    defaultIndex={props.activeTab}
                    selectedTabClassName="active"
                >
                    <TabList className="row">
                        <Tab onClick={() => toCompaniesEmployeesPage()}><FormattedMessage id="Employees" defaultMessage="Employees"/></Tab>
                        <Tab onClick={() => toCompaniesRolesPage()}><FormattedMessage id="Roles" defaultMessage="Roles"/></Tab>
                        <Tab onClick={() => toCompaniesLocationsPage()}><FormattedMessage id="Locations" defaultMessage="Locations"/></Tab>
                        <Tab onClick={() => toCompaniesNFCsPage()}><FormattedMessage id="NFCs" defaultMessage="NFCs"/></Tab>
                        <Tab onClick={() => toCompaniesSettingsPage()}><FormattedMessage id="Settings" defaultMessage="Settings"/></Tab>
                        {type === TYPES.SUPER_ADMIN && <Tab onClick={() => toLiveTrackerPage()}><FormattedMessage id="StaffLog" defaultMessage="StaffLog"/></Tab>}
                        {type === TYPES.SUPER_ADMIN && <Tab onClick={() => toSchedulerPage()}><FormattedMessage id="Scheduler" defaultMessage="Scheduler"/></Tab>}
                        {type === TYPES.SUPER_ADMIN && <Tab onClick={() => toVacationRequestsPage()}><FormattedMessage id="VacationRequests" defaultMessage="VacationRequests"/></Tab>}
                        {type === TYPES.SUPER_ADMIN && <Tab onClick={() => toVacationCalendarPage()}><FormattedMessage id="VacationCalendar" defaultMessage="VacationCalendar"/></Tab>}
                    </TabList>

                    <TabPanel>
                    </TabPanel>

                    <TabPanel>
                    </TabPanel>

                    <TabPanel>
                    </TabPanel>

                    <TabPanel>
                    </TabPanel>

                    <TabPanel>
                    </TabPanel>

                    {type === TYPES.SUPER_ADMIN && <>
                    <TabPanel>
                    </TabPanel>
                    <TabPanel>
                    </TabPanel>
                    <TabPanel>
                    </TabPanel>
                    <TabPanel>
                    </TabPanel>
                    </>
                    }
                </Tabs>
            </div>

        );
    }else{
        return (
           <div className="empty-div" style={{height: "25px"}}></div>
        )
    }


}

export default TabNavigation;
