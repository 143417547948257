import React from "react";
import {StyleSheet, Text, View} from "@react-pdf/renderer";
import SositoLogo from "./SositoLogo";
import { styles } from "../styles/PdfStyles";
function Footer(props) {

    return (
        <>
            <View style={styles.footer}>
                <Text style={styles.footerText}>

                </Text><SositoLogo />
            </View>
        </>
    );

}

export default Footer;
