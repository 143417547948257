import * as ClientConfig from "../../config/ClientConfig";
import {EXCEPTION_MESSAGES, LOCALSTORAGE_ITEMS} from "../Constants";
import * as StorageService from "../../services/StorageServices";
import {PATH} from "../../routes/routes";



const handleResponse = async (callback) => {
    const response = await callback();
    return response.json()
        .then(async responseJson => {
            return {
                ok: response.status === 200,
                data:responseJson
            }
        });
}


const handleAuthResponse = async (callback) => {
    const response = await callback();
    return response.json()
        .then(async responseJson => {
            let ok = response.status === 200;
            if(response.status === 401){
                const tokenResponse = await refreshToken();
                if (tokenResponse.ok) {
                    const response2 = await callback();
                    return response2.json().then(async responseJson2 => {
                        return {
                            ok: response2.status === 200,
                            data:responseJson2
                        }
                    });
                }else return tokenResponse;
            }
            return {
                ok: ok,
                data:responseJson
            }
        });
}

const handleAuthFileResponse = async (callback) => {
    const response = await callback();
    if(response.status === 401){
        const tokenResponse = await refreshToken();
        if (tokenResponse.ok) {
            const response2 = await callback();
            if(response2 == 200){
                return response2.blob().then(async responseBlob => {
                    return {
                        ok: true,
                        data:URL.createObjectURL(responseBlob)
                    };
                });
            }else  {
                return response2.json().then(async response2Json => {
                    return {
                        ok: false,
                        data: response2Json
                    }
                });
            }
        }else{
            return tokenResponse;
        }
    }else if(response.status === 200){
        return response.blob().then(async responseBlob => {
            return {
                ok: true,
                data:URL.createObjectURL(responseBlob)
            };
        });
    }else {
        return response.json().then(async responseJson => {
            return {
                ok: false,
                data: responseJson
            };
        });
    }
}

const handleAuthEmptyResponse = async (callback) => {
    const response = await callback();
    if(response.status === 401){
        const tokenResponse = await refreshToken();
        if (tokenResponse.ok) {
            const response2 = await callback();
            if(response2 == 200){
                return {
                    ok: true
                };
            }else  {
                return response2.json().then(async response2Json => {
                    return {
                        ok: false,
                        data: response2Json
                    }
                });
            }
        }else{
            return tokenResponse;
        }
    }else if(response.status === 200){
        return {
            ok: true
        };
    }else {
        return response.json().then(async responseJson => {
            return {
                ok: false,
                data: responseJson
            };
        });
    }
}

async function refreshToken() {
    const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/refresh-token";

    const req = {
        "refresh_token": StorageService.getItem(LOCALSTORAGE_ITEMS.REFRESH_TOKEN)
    }

    return fetch(finalURL, {
            method: "POST",
            body: JSON.stringify(req),
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(async (response) => {
            return response.json().then(
                responseJson => {
                    if (response.status === 200) {
                        StorageService.setItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN, responseJson.access_token);
                        StorageService.setItem(LOCALSTORAGE_ITEMS.REFRESH_TOKEN, responseJson.refresh_token);
                        return {
                            ok: true,
                            data: responseJson
                        };
                    }else{
                        StorageService.removeItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN);
                        StorageService.removeItem(LOCALSTORAGE_ITEMS.REFRESH_TOKEN);
                        StorageService.removeItem(LOCALSTORAGE_ITEMS.USERNAME);
                        window.location.href = "#" + PATH.LOGIN;

                        return {
                            ok: false,
                            data: responseJson
                        };
                    }
                }
            )
        });
}

export {
    handleResponse,
    handleAuthResponse,
    handleAuthFileResponse,
    handleAuthEmptyResponse
}
