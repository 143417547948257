import React from "react";
import {Text, View} from "@react-pdf/renderer";
import {STATUSES, TYPES} from "../../../util/Constants";
import { styles } from "../styles/PdfStyles";


function SignatureWrapper(props) {
    if (!props) {
        return;
    }
    return (
        <>
            <View style={styles.signatureWrapper}>
                <View style={styles.signatureSection}>
                    <View style={styles.signatureColumn}>
                        <Text  style={styles.semiBold}>
                            Data: {props?.created}
                        </Text>
                    </View>
                    <View style={styles.signatureColumn}>
                        <Text style={styles.semiBold}>
                            APROBAT,
                        </Text>

                        <Text>
                            {props?.employeeLeaves?.leaveModifiedBy?.firstName + " " + props?.employeeLeaves?.leaveModifiedBy?.lastName},
                        </Text>
                        <Text>
                            {props?.employeeLeaves?.leaveModifiedBy?.type === TYPES.ADMIN
                            ? "Administrator,"
                            : props?.employeeLeaves?.leaveModifiedBy?.type === TYPES.MANAGER
                            ? "Manager,"
                            : props?.employeeLeaves?.leaveModifiedBy?.type
                            }
                        </Text>
                        <Text>
                            {props?.modified}
                        </Text>
                    </View>

                </View>
                {/*<View style={styles.signatureSection}>*/}
                {/*    <View style={styles.signatureColumn}>*/}
                {/*        <Text>*/}
                {/*            Semnatura ________________*/}
                {/*        </Text>*/}
                {/*    </View>*/}
                {/*    <View style={styles.signatureColumn}>*/}
                {/*        <Text>*/}
                {/*            ________________*/}
                {/*        </Text>*/}
                {/*    </View>*/}
                {/*</View>*/}
            </View>
        </>
    );

}

export default SignatureWrapper;
