import * as FacilitiesPlacesService from "../../../services/companiesServices/CompaniesLocationsService";
import {
    createLocation,
    deleteLocationById,
    editLocationById,
    getLocationById
} from "../../../services/companiesServices/CompaniesLocationsService";

export const getLocationByIdHandler = async (id) => {
    const response = await getLocationById(id);
    if (!response.ok) {
        return {
            data: {
                error: "Error getLocationByIdHandler",
            }
        }
    }
    const location = {
        id: response.data.id,
        name: response.data.name,
        address:response.data.address,
        places: response.data.places
    }

    return {
        data: {
            error: null,
            location: location
        }
    };
}



export const createLocationHandler = async (data) => {
    const body = {
        "companyId": data.companyId,
        "name": data.name,
        "address":data.address
    }
    const response = await createLocation(body);
    let locationId = null;
    let error = null;
    if (response.ok) {
        locationId = response.data.id
    } else {
        error ="Error createLocationHandler";
    }

    return {
        data: {
            error: error,
            message: response.data.message,
            id: locationId
        }
    };

}

export const editLocationByIdHandler = async (locationId, data) => {
    let error = null;
    const body = {
        "companyId": data.companyId,
        "name": data.name,
        "address": data.address
    }
    const response = await editLocationById(locationId, body);
    if (response.ok) {

    } else {
        error ="Error editServiceProviderHandler";
    }
    return {
        data: {
            id: locationId,
            error: error
        }
    };
}



export const deleteLocationHandler = async (locationId) => {
    let error = null;

    const response = await deleteLocationById(locationId);
    if (response.ok) {

    } else {
        error ="Error deletePlaceByIdHandler";
    }
    return {
        data: {
            id: locationId,
            error: error
        }
    };

}










