import React, {useContext, useEffect, useRef, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useParams} from "react-router-dom";
import Header from "../../components/Header";
import TabNavigation from "../../components/TabNavigation";
import SchedulerPanel from "./SchedulerPanel";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    createCompanyWorkTimeIntervalsHandler, deleteCompanyWorkTimeIntervalsHandler, editCompanyWorkTimeIntervalsHandler,
    getAllCompanyWorkTimeIntervalsHandler
} from "../../handlers/schedulerHandlers/HandlerSchedulesAll";
import {closeIcon} from "../../components/Icons";
import {LOCALSTORAGE_ITEMS, TYPES} from "../../util/Constants";
import * as StorageService from "../../services/StorageServices";
import {LocaleContext} from "../../util/LocaleContext";
import analyticsLogEvent from "../../services/FirebaseService";
import {getUserAgent} from "../../util/common";

function SchedulerAll(props) {
    const {companyId} = useParams();
    const TEMPORARY_ID = "temporaryId";
    const type = StorageService.getItem(LOCALSTORAGE_ITEMS.TYPE) || "Unknown";
    const isViewOnlyMode = type === TYPES.MANAGER;
    const lastSchedulerInListRef = useRef(null);
    const localeContext = useContext(LocaleContext);
    const [state, setState] = useState({
        loading: false,
        errorMsg: null,
        schedulerWithErrors:null,
        allSchedulers: [],
        allSchedulersModified: [],
        selectedScheduler: null,
        selectedIntervalIndex: null,
        schedulerId: null,
        showEditableInputs: false,
        deletedSchedulersIds: [],
        confirmationModalVisible: false,
    });


    useEffect(() => {
        analyticsLogEvent("page_view", {
            page_title: "SchedulerAll",
        });
        getPageContent();
    }, []);


    const getPageContent = () => {
        getAllCompanyWorkTimeIntervalsHandler(companyId).then(response => {
            const callback = () => {
                setState({
                    ...state,
                    allSchedulers: response.data.schedules,
                    allSchedulersModified: response.data.schedules,
                    deletedSchedulersIds:[],
                    errorMsg:null,
                    schedulerWithErrors: null,
                    loading:false,
                })
            }

            if (type !== TYPES.SUPER_ADMIN) {
                localeContext.setLocale(StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE), callback);
            }else{
                callback();
            }

        })
    }



    const addNewScheduler = () => {
        executeScroll();
        if (state.allSchedulersModified.find(schedule => schedule.id === TEMPORARY_ID)) {
            return;
        }
        const newSchedulerObj = {
            id: TEMPORARY_ID,
            name: "",
            companyId: companyId,
            daysIntervals: [{dayIntervals: []}]
            // daysIntervals: [{dayIntervals: [{from: "hh:mm", to: "hh:mm"}]}]
        }
        const allSchedulersModified = [...state.allSchedulersModified];
        allSchedulersModified.push(newSchedulerObj);

        setState({
            ...state,
            schedulerId: TEMPORARY_ID,
            selectedScheduler: TEMPORARY_ID,
            selectedIntervalIndex: null,
            allSchedulersModified: allSchedulersModified
        })
    }


    const saveSchedulerChangesInState = (type, value, schedulerId, intervalIndex) => {
        if (type !== "schedulerName" && value.length > 2) {
            return;
        }
        const modifiedSchedulers = [...state.allSchedulersModified].map(scheduler => {
            if (scheduler.id === schedulerId) {
                const changedScheduler = {
                    ...scheduler
                };

                const modifiedDaysIntervals = [...changedScheduler.daysIntervals];
                const prev = modifiedDaysIntervals[intervalIndex]?.dayIntervals[0];

                if (type === "hoursFrom") {
                    if (value.length <= 2) {
                        modifiedDaysIntervals[intervalIndex] = {
                            dayIntervals: [{
                                ...prev,
                                from: value + ":" + ((modifiedDaysIntervals[intervalIndex].dayIntervals.length > 0 && modifiedDaysIntervals[intervalIndex].dayIntervals[0].from) ? modifiedDaysIntervals[intervalIndex].dayIntervals[0].from.split(":")[1] : ""),
                            }]
                        };
                    }


                } else if (type === "minutesFrom") {
                    if (value.length <= 2) {
                        modifiedDaysIntervals[intervalIndex] = {
                            dayIntervals: [{
                                ...prev,
                                from: ((prev && prev.from) ? (prev.from.split(":")[0]) : "") + ":" + value,
                            }]
                        };
                    }

                } else if (type === "hoursTo") {
                    modifiedDaysIntervals[intervalIndex] = {
                        dayIntervals: [{
                            ...prev,
                            to: value + ":" + ((modifiedDaysIntervals[intervalIndex].dayIntervals.length > 0 && modifiedDaysIntervals[intervalIndex].dayIntervals[0].to) ? modifiedDaysIntervals[intervalIndex].dayIntervals[0].to.split(":")[1] : "")
                        }]
                    };
                } else if (type === "minutesTo") {
                    modifiedDaysIntervals[intervalIndex] = {
                        dayIntervals: [{
                            ...prev,
                            to: (modifiedDaysIntervals[intervalIndex].dayIntervals[0] && modifiedDaysIntervals[intervalIndex].dayIntervals[0].to?modifiedDaysIntervals[intervalIndex].dayIntervals[0].to.split(":")[0]:"") + ":" + value
                        }]
                    };

                } else if (type === "schedulerName") {
                    changedScheduler.name = value;
                }

                changedScheduler.daysIntervals = modifiedDaysIntervals
                return changedScheduler;
            } else return scheduler;
        });
        setState({
            ...state,
            allSchedulersModified: [...modifiedSchedulers],
        });
    }

    const addSchedulerInterval = (schedulerId, index, type, from, to) => {
        const modifiedSchedulers = [...state.allSchedulersModified].map(scheduler => {
            if (scheduler.id === schedulerId) {
                const modifiedDaysIntervals = [...scheduler.daysIntervals];
                if (type === "duplicate") {
                    // push obj at index
                    modifiedDaysIntervals.splice(index + 1, 0,  {dayIntervals: [{from: from, to: to}]});

                    // push obj last
                    // modifiedDaysIntervals.push({
                    //     // dayIntervals: [{from: "hh:mm", to: "hh:mm"}]
                    // });

                }else{
                    modifiedDaysIntervals.push({
                        dayIntervals: []
                    });
                }

                return {
                    ...scheduler,
                    daysIntervals: modifiedDaysIntervals
                };
            } else return scheduler;
        });
        setState({
            ...state,
            allSchedulersModified: [...modifiedSchedulers],
        });
    }


    const deleteSchedulerInterval = (schedulerId, index) => {
        const modifiedSchedulers = [...state.allSchedulersModified].map(scheduler => {
            if (scheduler.id === schedulerId) {
                const modifiedDaysIntervals = [...scheduler.daysIntervals];
                modifiedDaysIntervals.splice(index, 1);
                return {
                    ...scheduler,
                    daysIntervals: modifiedDaysIntervals
                };
            } else return scheduler;
        });
        setState({
            ...state,
            allSchedulersModified: [...modifiedSchedulers]
        });
    }


    const deleteScheduler = (schedulerId) => {
        let allSchedulersModified = [...state.allSchedulersModified].filter((s) => s.id !== schedulerId);
        setState({
            ...state,
            allSchedulersModified: allSchedulersModified,
            deletedSchedulersIds: [...state.deletedSchedulersIds, schedulerId]
        })
    }


    const setSelectedScheduler = (selectedSchedulerId) => {
        setState({
            ...state,
            showEditableInputs: false,
            selectedScheduler: selectedSchedulerId
        })
    }

    const showEditableInputsForInterval = (selectedSchedulerId, selectedIntervalIndex) => {
        setState({
            ...state,
            showEditableInputs: true,
            selectedScheduler: selectedSchedulerId,
            selectedIntervalIndex: selectedIntervalIndex
        })
    }


    const unselectInterval = () => {
        setState({
            ...state,
            selectedIntervalIndex: null,
            showEditableInputs: false,
        })
    }


    const cancelChanges = () => {
        if (JSON.stringify(state.allSchedulers) !== JSON.stringify(state.allSchedulersModified)) {
            setState({
                ...state,
                allSchedulersModified: state.allSchedulers,
                errorMsg: null,
                selectedScheduler: null,
                selectedIntervalIndex: null,
                schedulerId: null,
                showEditableInputs: false,
            })
        }
    }

    const saveChanges = async () => {
        let error = null;
        if (JSON.stringify(state.allSchedulers) === JSON.stringify(state.allSchedulersModified)) {
            return;
        } else {
            const errorIndexes = validateData();
            if (errorIndexes !== null) {
                setState({
                    ...state,
                    schedulerWithErrors: errorIndexes,
                    errorMsg: <FormattedMessage id="InvalidTimeInterval"/>
                })
                return;
            }

            if (state.deletedSchedulersIds.length > 0 ) {
                state.deletedSchedulersIds.forEach(schedulerId=>{
                    deleteCompanyWorkTimeIntervalsHandler(schedulerId).then(r=>{
                        error = r.data.error;
                    });
                })
            }

            for (let i = 0; i < state.allSchedulersModified.length; i++) {
                // if scheduler was not modified
                const currentScheduler = state.allSchedulersModified[i];


                if (i < state.allSchedulers.length && JSON.stringify(currentScheduler) === JSON.stringify(state.allSchedulers[i])) {
                } else {
                    const daysIntervals = currentScheduler.daysIntervals.map(dayIntervals => {
                         if(dayIntervals && dayIntervals.dayIntervals && dayIntervals.dayIntervals.length > 0){
                             if(dayIntervals.dayIntervals[0].from === ":" || dayIntervals.dayIntervals[0].to === ":"){
                                 return {
                                     dayIntervals: []
                                 };
                             }
                         }
                         return dayIntervals;
                       });
                    const changedSchedule = {
                        ...currentScheduler,
                        daysIntervals: daysIntervals
                    };

                    let response;
                    if (changedSchedule?.id.indexOf(TEMPORARY_ID) >= 0) {
                        response = await createCompanyWorkTimeIntervalsHandler(changedSchedule);
                    } else {
                        response = await editCompanyWorkTimeIntervalsHandler(changedSchedule.id, changedSchedule);

                    }
                    if (response.data.error !== null) {
                        error = response.data.error;
                        break;
                    }
                }
            }

            if (error !== null) {
                setState({
                    ...state,
                    errorMsg: error,
                })
            } else {
                setState({
                    ...state,
                    loading:true,
                    confirmationModalVisible: true,
                    showEditableInputs:false,
                    errorMsg:null,
                    schedulerWithErrors:null
                })
                setTimeout(function () {
                    getPageContent();
                }, 2000);

            }
        }


    }

    const closeConfirmationModal = () => {
        setState({
            ...state,
            confirmationModalVisible: false
        })
    }

    const executeScroll = () => {
        if (lastSchedulerInListRef.current) {
            setTimeout(function () {
                lastSchedulerInListRef.current.scrollIntoView(
                    {
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'nearest'
                    })
            }, 50);

        }
    }

    const validateData = () => {
        let errorIndexes = null;
        state.allSchedulersModified.forEach((schedule,indexSchedule) => {
            schedule.daysIntervals.forEach((dayIntervals, indexDayIntervals) => {
                if (dayIntervals && dayIntervals.dayIntervals && dayIntervals.dayIntervals.length > 0) {
                    if (typeof(dayIntervals.dayIntervals[0].from) === "undefined" || dayIntervals.dayIntervals[0].from === null || typeof(dayIntervals.dayIntervals[0].to) === "undefined" || dayIntervals.dayIntervals[0].to === null) {
                        errorIndexes = {
                            scheduleIndex: indexSchedule,
                            dayIntervalsIndex: indexDayIntervals
                        };
                        return;
                    };
                    const valsFrom = dayIntervals.dayIntervals[0].from.split(":");
                    if (valsFrom[0].length > 0 && valsFrom[1].length === 0 || valsFrom[0].length === 0 && valsFrom[1].length > 0) {
                        errorIndexes = {
                            scheduleIndex: indexSchedule,
                            dayIntervalsIndex: indexDayIntervals
                        };
                        return;
                    }
                    const valsTo = dayIntervals.dayIntervals[0].to.split(":");
                    if (valsTo[0].length > 0 && valsTo[1].length === 0 || valsTo[0].length === 0 && valsTo[1].length > 0) {
                        errorIndexes = {
                            scheduleIndex: indexSchedule,
                            dayIntervalsIndex: indexDayIntervals
                        };
                        return;
                    }

                    if(dayIntervals.dayIntervals[0].from === ":" && dayIntervals.dayIntervals[0].to !== ":" ||
                        dayIntervals.dayIntervals[0].from !== ":" && dayIntervals.dayIntervals[0].to === ":"
                    ){
                        errorIndexes = {
                            scheduleIndex: indexSchedule,
                            dayIntervalsIndex: indexDayIntervals
                        };
                        return;
                    }
                }
            });
        });
        return errorIndexes;
    }




    return (

        <div className="content">
            <Header
                companyId={companyId}
                hasBackBtn={false}
                headerTitle={<FormattedMessage id="Scheduler" defaultMessage="Scheduler"/>}
            >
            </Header>
            <div className="inner-content inner-content-for-table inner-content-for-schedules">
                {props.hasTabNavigation &&
                <TabNavigation
                    companyId={companyId}
                    activeTab={2}
                    page="scheduler"
                />
                }

                {isViewOnlyMode === false && <section className="row top-actions-row">
                    <div className="row mr-auto buttons-with-margin">
                        <button tabIndex={-1} onClick={(e) => addNewScheduler()}
                                className={state.allSchedulersModified.find(scheduler => scheduler.id === TEMPORARY_ID) ? "btn btn-disabled" : "btn btn-success"}>
                            <FormattedMessage id="NewSchedule" defaultMessage="NewSchedule"/></button>
                    </div>
                </section>}
                <ScrollContainer
                    ignoreElements={".prevent-scroll"}
                    hideScrollbars={false}
                    vertical={false}
                    className="scroll-container">
                    <div className="schedules-list">
                        {state.allSchedulersModified.map((scheduler, index) => (

                            <SchedulerPanel
                                key={index}
                                index={index}

                                setSelectedScheduler={setSelectedScheduler}
                                deleteScheduler={deleteScheduler}
                                selectedScheduler={state.selectedScheduler}
                                selectedIntervalIndex={state.selectedIntervalIndex}
                                schedulerId={scheduler.id}
                                schedulerName={scheduler.name}
                                schedulerIntervals={scheduler.daysIntervals}
                                showEditableInputs={state.showEditableInputs}
                                daysIntervalsErrorIndex={(state.schedulerWithErrors && state.schedulerWithErrors.scheduleIndex === index)?state.schedulerWithErrors.dayIntervalsIndex: null}
                                showEditableInputsForInterval={showEditableInputsForInterval}
                                saveIntervalsInState={saveSchedulerChangesInState}
                                addSchedulerInterval={addSchedulerInterval}
                                unselectInterval={unselectInterval}
                                deleteSchedulerInterval={deleteSchedulerInterval}
                                isViewOnlyMode={isViewOnlyMode}
                            />
                        ))}
                        <div className="last-el" ref={lastSchedulerInListRef}></div>
                    </div>

                </ScrollContainer>

                <div className="page-footer">
                    {state.errorMsg && <div className="error">&#9888;&nbsp;{state.errorMsg}</div>}
                    <div className="row buttons-container">
                        <button onClick={(e) => {
                            e.stopPropagation();
                            cancelChanges();
                        }} className="btn btn-default"><FormattedMessage
                            id="Cancel" defaultMessage="Cancel"/>
                        </button>
                        <button onClick={() => saveChanges()} className={(JSON.stringify(state.allSchedulers) === JSON.stringify(state.allSchedulersModified)) || state.loading ? "btn btn-disabled" : "btn btn-success"}><FormattedMessage
                            id="Save" defaultMessage="Save"/></button>
                    </div>

                    <div
                        className={state.confirmationModalVisible ? "visible modal confirmation-modal" : "modal confirmation-modal"}>
                               <span
                                   onClick={() => closeConfirmationModal()}
                                   className="ml-auto close-icon">
                                    {closeIcon}
                                </span>
                        <div className="modal-content"><FormattedMessage
                            id="SchedulerSaved"/></div>
                    </div>

                </div>
            </div>

        </div>


    );
}

export default SchedulerAll;
