
import {
    createNFC,
    deleteNFCbyId,
    editNFCbyId,
    getNFCbyId
} from "../../services/companiesServices/CompaniesNFCsService";
import {getAllLocationsFromCompany} from "../../services/companiesServices/CompaniesLocationsService";
import * as CompaniesService from "../../services/companiesServices/CompaniesService";
import * as ClientConfig from "../../config/ClientConfig";

export const getNFCByIdHandler = async (id) => {
    let error = null;
    const nfcDetailsResponse = await getNFCbyId(id);
    let nfcResponse = null;


    if (nfcDetailsResponse.ok) {
        nfcResponse =  {
                    id: nfcDetailsResponse.data.id,
                    name: nfcDetailsResponse.data.name,
        }
    } else {
        error ="Error getAllEmployeesByFacilityIdHandler";
    }
    return {
        data: {
            error: error,
            nfc: nfcResponse
        }
    };
}


export const getAllNFCLocationsHandler = async (companyId) => {
    let error = null;

    const response = await getAllLocationsFromCompany(companyId);
    let locationsResponse = null;
    if (response.ok) {

        locationsResponse = response.data.map(location=>{
            return  {
                id: location.id,
                name: location.name,
                address: location.address,
                places: location.places

            }
        })

    } else {
        error ="Error getAllEmployeesByFacilityIdHandler";
    }
    return {
        data: {
            error: error,
            locations: locationsResponse
        }
    };
}


export const createNFCHandler = async (data) => {
    const body = {
        "companyId": data.companyId,
        "nfcId":data.id,
        "nfcName": data.name,
        "locationId": data.location,
        "placeName":data.placement,

    }
    const response = await createNFC(body);
    let nfcId = null;
    let error = null;
    if (response.ok) {

        nfcId = response.data.id
    } else {

        error =response.data.message;
    }

    return {
        data: {
            error: error,
            id: nfcId
        }
    };
}

export const editNFCHandler = async (nfcId, data) => {
    let error = null;
    const body = {
        "companyId": data.companyId,
        "nfcName": data.name,
        "locationId": data.location,
        "placeName":data.placement,
    }

    const response = await editNFCbyId(nfcId, body);
    if (response.ok) {

    } else {
        error = response.data.message;
    }
    return {
        data: {
            error: error
        }
    };
}


export const deleteNfcHandler = async (nfcId) => {
    const response = await deleteNFCbyId(nfcId);
    let error = null;
    if (response.ok) {

    } else {
        error ="Error deleteNfcHandler";
    }

    return {
        data: {
            error: error
        }
    };
}


export const getAllCompaniesHandler = async () => {
    const response = await CompaniesService.getAllCompanies();

    let allCompanies = null;
    let error = null;
    if (response.ok) {
        allCompanies = response.data.map(e => {
            return {
                id: e.id,
                name: e.name,
                address: e.address,
                picture: ClientConfig.URL_RESOURCE_SERVER + "/api/files/" + e.picture
            }
        });

    } else {
        error ="Error getAllServiceProvidersHandler";
    }

    return {
        data: {
            allCompanies: allCompanies
        }
    };
}











