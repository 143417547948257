import * as FacilitiesService from "../../services/companiesServices/CompaniesService";
import * as FileService from "../../services/FilesService";

export const setPictureHandler = async (pictureFile) => {
    const returnedImage = await FileService.insertFile(pictureFile);
    if (!returnedImage.ok) {
        return {
            data: {
                error: "Error setPicture",
            }
        }
    }

    return {
        data : {
            name: returnedImage.data.name,
            url: returnedImage.data.url,
        }

    }
}

export const createFacilityHandler = async (data) => {
    const body = {
        "name": data.name,
        "email": data.email,
        "phone":data.phone,
        "imageName": data.picture,
        "timeZone":data.timeZone,
        "locale":data.locale
    }

    const response = await FacilitiesService.createCompany(body);
    let facilityId = null;
    let error = null;
    if (response.ok) {
        facilityId = response.data.id        
    } else {
        error ="Error createFacility";
    }
    return {
        data: {
            error: error,
            id: facilityId
        }
    };
}



