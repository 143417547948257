import React from "react";


function Alert(props) {

    return (
        <>
            <div className="blurry-background" onClick={() => props.hideAlert()}></div>
    <div className="alert">
        <div className="alert-title">
            {props.title}
        </div>
        <div className="alert-content">
            <div className="row">
                {props.contentRow1}
            </div>

        </div>
        <div className="alert-action-buttons">
            {props.deleteText1 && <div className="row">
                <button className="btn btn-danger" onClick={() => props.deleteAction1()}>{props.deleteText1}</button>
            </div>}
            <div className="row">
                <button className="btn btn-default" onClick={() => props.cancelAction()}>{props.cancelText}</button>
            </div>
        </div>
    </div>
        </>
    );

}

export default Alert;
