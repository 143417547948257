
import {closeIconRoundedRed, duplicateIcon} from "../../components/Icons";

function SchedulerPanelInterval(props) {
    const onInputKeyDown = (e) => {
        e.stopPropagation();
        if (e.key === 'Escape') {
            props.unselectInterval();
        }
    };

    const onInputChange = (inputType, e) => {
        e.stopPropagation();
        props.saveIntervalsInState(inputType, e.target.value, props.schedulerId, props.index)
    }

    const onClickInterval = (e) => {
        if (props.isViewOnlyMode) {
            return;
        }
        e.stopPropagation();
        props.showEditableInputsForInterval(props.schedulerId, props.index, props.selectedIntervalIndex);
    }


    const onDeleteInterval = (e) => {
        e.stopPropagation();
        props.deleteSchedulerInterval(props.schedulerId, props.index);
    }

    const onDuplicateInterval = (e) => {
        e.stopPropagation();
        props.addSchedulerInterval(props.schedulerId, props.index, "duplicate", props.from, props.to);
    }



    return (
        <>

            <div className="interval" onClick={(e) => onClickInterval(e)}>

                <span className="from">
                        {
                            props.from?
                                props.from
                                : "none "
                        }
                </span>&nbsp;-&nbsp;
                <span className="to">
                        {
                            props.to?
                                props.to
                                : "none "
                        }
                </span>

                <span className="interval-nr"> {props.index + 1}</span>


                {/* START EDITABLE INPUTS*/}
                    {
                    props.selectedScheduler &&
                    props.selectedScheduler == props.schedulerId &&
                    props.showEditableInputs &&
                    props.selectedIntervalIndex === props.index &&
                    <div
                        className={props.hasErrors ? "row has-errors editable-time prevent-scroll" : "row editable-time prevent-scroll"}>
                    <span className="from row">
                        <input
                            onKeyDown={onInputKeyDown}
                            onChange={(e) => onInputChange("hoursFrom",e)}
                            placeholder={"hh"}
                            value={props.from?props.from.split(":")[0]: ""}
                            autoFocus
                            type="number"
                            className="hours"/>
                        <span className="middot">:</span>
                        <input
                            onKeyDown={onInputKeyDown}
                            onChange={(e) => onInputChange("minutesFrom",e)}
                            placeholder={"mm"}
                            value={props.from?props.from.split(":")[1]: ""}
                            type="number"
                            className="minutes"/>
                    </span>

                        <span className="dash">-</span>
                        <span className="to row">
                        <input
                            onKeyDown={onInputKeyDown}
                            onChange={(e) => onInputChange("hoursTo",e)}
                            placeholder={"hh"}
                            value={props.to?props.to.split(":")[0]:""}
                            type="number"
                            className="hours"/>
                        <span className="middot">:</span>
                        <input
                            onKeyDown={onInputKeyDown}
                            onChange={(e) => onInputChange("minutesTo",e)}
                            placeholder={"mm"}
                            value={props.to?props.to.split(":")[1]:""}
                            type="number"
                            className="minutes"/>
                    </span>

                    </div>
                }
                {/*END EDITABLE INPUTS*/}


                {props.isViewOnlyMode === false && <a onClick={(e) => {onDeleteInterval(e)}} className="delete-btn">
                    {closeIconRoundedRed}
                </a>}

                {props.isViewOnlyMode === false && <a onClick={(e) => {onDuplicateInterval(e)}} className="duplicate-btn">
                    {duplicateIcon}
                </a>}

            </div>

        </>

    );
}


export default SchedulerPanelInterval;
