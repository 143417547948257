import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {PATH} from "../../routes/routes";
import Header from "../../components/Header";
import {
    resetPasswordHandler
} from "../../handlers/authenticationHandlers/HandlerAuthentication";
import * as StorageService from "../../services/StorageServices";
import {LOCALSTORAGE_ITEMS} from "../../util/Constants";
import pwdTogglerOffImg from "../../assets/img/pwd_toggler_off.svg";
import pwdTogglerOnImg from "../../assets/img/pwd_toggler_on.svg";
import {useNavigate} from "react-router";


function NewPassword() {
    const resetPasswordPhone = StorageService.getItem(LOCALSTORAGE_ITEMS.RESET_PASSWORD_PHONE);
    const navigateTo = useNavigate();
    const [state, setState] = useState({
        verificationCode: null,
        newPassword: null,
        confirmNewPassword: null,
        isPwdVisible: false,
        isConfirmPwdVisible: false,
        success: false,
        errorMsg: null,
    });

    const toLoginPage = () => {
        navigateTo(PATH.LOGIN);

    }

    const toLoginPageWithDelay = () => {
        let timer1 = setTimeout(() =>  navigateTo(PATH.LOGIN), 3000);
        return () => {
            clearTimeout(timer1);
        };

    }
    const handleOnChange = (e, input) => {
        if (input === "verificationCode") {
            setState({
                ...state,
                verificationCode: e.target.value
            })
        }else if (input === "newPassword") {
            setState({
                ...state,
                newPassword: e.target.value
            })
        }else if (input === "confirmNewPassword") {
            setState({
                ...state,
                confirmNewPassword: e.target.value
            })
        }
    }

    const togglePwdVisiblity = (e, input) => {
        e.preventDefault();
        if (input === "newPassword") {
            setState({
                ...state,
                isPwdVisible:!state.isPwdVisible
            })
        }else{
            setState({
                ...state,
                isConfirmPwdVisible:!state.isConfirmPwdVisible
            })
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (validateData()) {

            resetPasswordHandler(resetPasswordPhone, state.verificationCode, state.newPassword, state.confirmNewPassword).then(response => {
                if (response.data.error !== null) {
                    if (response.data.errorCode === 4) {
                        setState({
                            ...state,
                            errorMsg: response.data.message
                        })
                    }else{
                        setState({
                            ...state,
                            errorMsg: <FormattedMessage id="SomethingWentWrong" defaultMessage="SomethingWentWrong"/>
                        })
                    }

                }else{
                    setState({
                        ...state,
                        success:true
                    })
                    StorageService.removeItem(LOCALSTORAGE_ITEMS.RESET_PASSWORD_PHONE);
                    toLoginPageWithDelay();
                }
            })
        }
    }

    const validateData = () => {
        if (!!!state.verificationCode || !!!state.newPassword || !!!state.confirmNewPassword ) {
            setState({
                ...state,
                errorMsg: <FormattedMessage id="PleaseFillInAllFields" defaultMessage="PleaseFillInAllFields"/>
            })
            return false;
        }
        return true;
    }

    return (
        <>
            <div className="content login-content">
                <Header
                    headerTitle={<FormattedMessage id="NewPassword" defaultMessage="NewPassword"/>}

                />

                <section className="form-container account-form-container">
                    <div className="top-text">
                        <div className="row">
                            <FormattedMessage id="TheVerificationCodeHasBeenSent" defaultMessage="TheVerificationCodeHasBeenSent"/>
                        </div>
                        <div className="row">
                            <div className="value">{resetPasswordPhone}</div>
                        </div>


                    </div>
                    <div className="form login-form">
                        <div className="form-group">
                            <div className="row">
                                <label className="label" htmlFor="verificationCode">
                                    <FormattedMessage id="VerificationCode" defaultMessage="VerificationCode"/></label>
                            </div>
                            <div className="row">
                                <FormattedMessage id="VerificationCode" defaultMessage="VerificationCode">
                                    {placeholder =>
                                        <input
                                            className="input"
                                            type={"text"}
                                            onChange={e => handleOnChange(e, "verificationCode")}
                                            maxLength={80}
                                            placeholder={placeholder}
                                            autoComplete="off"
                                            id="username"
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                        </div>


                        <div className="form-group">
                            <div className="row">
                                <label className="label" htmlFor="newPassword"> <FormattedMessage id="NewPassword" defaultMessage="NewPassword"/></label>
                            </div>
                            <div className="row">
                                <FormattedMessage id="NewPassword" defaultMessage="NewPassword">
                                    {placeholder =>
                                        <input
                                            className="input"
                                            type={state.isPwdVisible? "text" :"password"}
                                            onChange={e => handleOnChange(e, "newPassword")}
                                            maxLength={100}
                                            placeholder={placeholder}
                                            autoComplete="off"
                                            id="newPassword"
                                        />
                                    }
                                </FormattedMessage>
                                <img onClick={(e) => togglePwdVisiblity(e, "newPassword")}className="pwd-toggler" src={state.isPwdVisible?  pwdTogglerOnImg: pwdTogglerOffImg}  alt={"show typed password toggle"}/>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <label className="label" htmlFor="confirmNewPassword"> <FormattedMessage id="ConfirmNewPassword" defaultMessage="ConfirmNewPassword"/></label>
                            </div>
                            <div className="row">
                                <FormattedMessage id="ConfirmNewPassword" defaultMessage="ConfirmNewPassword">
                                    {placeholder =>
                                        <input
                                            className="input"
                                            type={state.isConfirmPwdVisible? "text" :"password"}
                                            onChange={e => handleOnChange(e, "confirmNewPassword")}
                                            maxLength={100}
                                            placeholder={placeholder}
                                            autoComplete="off"
                                            id="confirmNewPassword"
                                        />
                                    }
                                </FormattedMessage>
                                <img onClick={(e) => togglePwdVisiblity(e, "confirmNewPassword")}className="pwd-toggler" src={state.isConfirmPwdVisible?  pwdTogglerOnImg: pwdTogglerOffImg}/>
                            </div>
                        </div>




                        {!state.success && <div className="row">
                            <button onClick={(e) => handleSubmit(e)} className="btn btn-success"><FormattedMessage
                                id="ResetPassword"
                                defaultMessage="ResetPassword"/></button>
                        </div>
                        }


                        <div className="bottom-text">
                            <div className="row">
                                <p className="simple-text"><FormattedMessage id="RememberedYourPassword" defaultMessage="RememberedYourPassword"/>
                                </p>
                            </div>

                            <div className="row">
                                <a onClick={(e) => toLoginPage(e)} className="link underlined">
                                    <FormattedMessage id="Login" defaultMessage="Login"/>
                                </a>
                            </div>
                        </div>


                    </div>
                    {state.errorMsg && <div className="error mt-20">{state.errorMsg}</div>}
                    {state.success && <div className="error success mt-20"><FormattedMessage
                        id="PasswordResetSuccessful"
                        defaultMessage="PasswordResetSuccessful"/></div>}
                </section>
            </div>

        </>
    );
}

export default NewPassword;
