import {
    deleteAccount,
    getUserMe,
    login,
    requestVerificationCode,
    resetPassword
} from "../../services/AuthenticationService";
import * as StorageService from "../../services/StorageServices";
import {TYPES, LOCALSTORAGE_ITEMS} from "../../util/Constants";

    export const loginHandler = async (username, password) => {
        const response = await login(username, password);

        if(response.ok){
            StorageService.setItem(LOCALSTORAGE_ITEMS.USERNAME, username);
            StorageService.setItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN, response.data.access_token);
            StorageService.setItem(LOCALSTORAGE_ITEMS.REFRESH_TOKEN, response.data.refresh_token);
        }

        return {
            data: {
                error: response.ok?null:"Error login"
            }
        };
    }

export const getCurrentUserHandler = async () => {

    const response = await getUserMe();
    if(response.ok){
        StorageService.setItem(LOCALSTORAGE_ITEMS.FIRST_NAME, response.data.firstName);
        StorageService.setItem(LOCALSTORAGE_ITEMS.LAST_NAME, response.data.lastName);
        StorageService.setItem(LOCALSTORAGE_ITEMS.USER_ID, response.data.id);
        if (response.data.type === "SUPER_ADMIN") {
            StorageService.setItem(LOCALSTORAGE_ITEMS.TYPE, TYPES.SUPER_ADMIN);
        }else{
            StorageService.setItem(LOCALSTORAGE_ITEMS.TYPE, response.data.type);
            StorageService.setItem(LOCALSTORAGE_ITEMS.COMPANY_INFO, JSON.stringify(response.data.company));
        }
    }
    return {
        data: {
            user: {
                id: response.data.id,
                firstName: response.data.firstName,
                lastName: response.data.lastName,
                phone: response.data.phone,
                type: response.data.type,
                company: response.data.company,
                companyId: response.data.companyId ? response.data.companyId : null
            },
            error: response.ok?null:"Error Getting Current User..."
        }
    };
}


export const resetPasswordHandler = async (phone, validCode, newPassword, newPasswordConfirmation) => {
    const response = await resetPassword(phone, validCode, newPassword, newPasswordConfirmation);
    if (!response.ok) {
        return {
            data: {
                error: "Error resetPasswordHandler",
                errorCode: response.data.errorCode,
                message: response.data.message
            }
        }
    }

    return {
        data: {
            error: null,
        }
    };
}


export const requestVerificationCodeHandler = async (phone) => {
    const response = await requestVerificationCode(phone);
    if (!response.ok) {
        return {
            data: {
                error: "Error requestVerificationCodeHandler",
                errorCode: response.data.errorCode,
                message: response.data.message
            }
        }
    }

    return {
        data: {
            error: null,
        }
    };
}


export const deleteAccountHandler = async () => {
    const response = await deleteAccount();
    if (!response.ok) {
        return {
            data: {
                error: "Error deleteAccountHandler",
                errorCode: response.data.errorCode,
                message: response.data.message
            }
        }
    }

    return {
        data: {
            error: null,
        }
    };
}









