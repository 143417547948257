import * as CompaniesEmployeeService from "../../../services/companiesServices/CompaniesEmployeesService";
import * as FacilitiesService from "../../../services/companiesServices/CompaniesService";
import * as CompaniesEmployeesService from "../../../services/companiesServices/CompaniesEmployeesService";
import {getAllLocationsFromCompany} from "../../../services/companiesServices/CompaniesLocationsService";
import {getAllRolesFromCompany} from "../../../services/companiesServices/CompaniesRolesService";
import {
    editEmployeeById,
    getAllEmployeesFromCompany,
    getEmployeeById
} from "../../../services/companiesServices/CompaniesEmployeesService";



export const getAllAvailableFacilityEmployeesHandler = async (facilityId) => {
    const response = await CompaniesEmployeesService.getAllEmployees();
    let allFacilityEmployees = null;
    let error = null;
    if (response.ok) {
        allFacilityEmployees = response.data.filter(e => e.facilities.indexOf(facilityId) < 0).map(r => {
            return {
                id: r.id,
                firstName: r.firstName,
                lastName: r.lastName,
                phone: r.phone,
                email: r.email,
                facility: r.facility,
                type: r.type,
            }

        });
    } else {
        error = "Error getAllServiceProvidersHandler";
    }

    return {
        data: {
            error: error,
            allFacilityEmployees: allFacilityEmployees
        }
    };


}

export const getCompanyByIdHandler = async (facilityId) => {
    const response = await FacilitiesService.getCompanyById(facilityId);

    let facility = null;
    let error = null;
    if (response.ok) {
        facility = {
            id: response.data.id,
            name: response.data.name,
            employees: response.data.employees.map(employee => {
                return {
                    id: employee.id,
                    firstName: employee.firstName,
                    lastName: employee.lastName,
                    roles: [employee.type]
                }
            })

        }

    } else {
        error = "Error getAllEmployeesByFacilityIdHandler";
    }

    return {
        data: {
            error: error,
            facility: facility
        }
    };
}


export const getAllLocationsForCompanyHandler = async (companyId) => {
    let error = null;
    const response = await getAllLocationsFromCompany(companyId);
    if (!response.ok) {
        return {
            data: {
                error: "Error getEmployeeByIdHandler",
            }
        }
    }

    const locations = response.data;
    return {
        data: {
            error: null,
            locations: locations
        }
    };

}

export const getDEMO_AllLocationsForCompanyHandler = async (companyId) => {
    let error = null;
    const response = {
        ok:true,
        data:[
            {
                "id": "26",
                "companyId": "21",
                "name": "Cora Cluj",
                "address": "Bulevardul 1 Decembrie 1918 142",
                "places": [
                    {
                        "name": "Intrare 010",
                        "nfcId": "040b394a387381"
                    },
                    {
                        "name": "Intrare 001",
                        "nfcId": "0414394a387381"
                    }
                ]
            },
            {
                "id": "27",
                "companyId": "21",
                "name": "Iulius Mall Cluj",
                "address": "Str. Al. Vaida Voievod nr. 53-55",
                "places": [
                    {
                        "name": "Intrare 002",
                        "nfcId": "0413394a387381"
                    }
                ]
            },
            {
                "id": "35",
                "companyId": "21",
                "name": "NOU",
                "address": "Str. Huedinului nr. 5",
                "places": []
            },
            {
                "id": "37",
                "companyId": "21",
                "name": "Militari",
                "address": "Militari",
                "places": [
                    {
                        "name": "Intrare 027",
                        "nfcId": "04f9394a387380"
                    }
                ]
            },
            {
                "id": "38",
                "companyId": "21",
                "name": "Barbu Vacarescu",
                "address": "Barbu",
                "places": [
                    {
                        "name": "Intrare 026",
                        "nfcId": "04fa394a387380"
                    }
                ]
            },
            {
                "id": "39",
                "companyId": "21",
                "name": "Veranda",
                "address": "Veranda",
                "places": [
                    {
                        "name": "Intrare 025",
                        "nfcId": "04fb394a387380"
                    }
                ]
            },
            {
                "id": "40",
                "companyId": "21",
                "name": "Mega Mall",
                "address": "Mega Mall",
                "places": [
                    {
                        "name": "Intrare 024",
                        "nfcId": "04fc394a387380"
                    }
                ]
            },
            {
                "id": "41",
                "companyId": "21",
                "name": "Auchan Titan",
                "address": "B-dul 1 Decembrie 1918, Nr 33A, Sector 3, Bucuresti",
                "places": [
                    {
                        "name": "Intrare 023",
                        "nfcId": "04fd394a387380"
                    }
                ]
            },
            {
                "id": "42",
                "companyId": "21",
                "name": "Apărătorii Patriei",
                "address": "Apărători",
                "places": [
                    {
                        "name": "Intrare 022",
                        "nfcId": "04fe394a387380"
                    }
                ]
            },
            {
                "id": "43",
                "companyId": "21",
                "name": "Crângași",
                "address": "Crângași",
                "places": [
                    {
                        "name": "Intrare 021",
                        "nfcId": "04ff394a387380"
                    }
                ]
            },
            {
                "id": "44",
                "companyId": "21",
                "name": "Drumul Taberei",
                "address": "Drumul Taberei ",
                "places": [
                    {
                        "name": "Intrare 020",
                        "nfcId": "0400394a387381"
                    }
                ]
            },
            {
                "id": "45",
                "companyId": "21",
                "name": "Park Lake",
                "address": "Park Lake ",
                "places": [
                    {
                        "name": "Intrare 019",
                        "nfcId": "0401394a387381"
                    }
                ]
            },
            {
                "id": "46",
                "companyId": "21",
                "name": "Răcari",
                "address": "Răcari",
                "places": [
                    {
                        "name": "Intrare 018",
                        "nfcId": "0402394a387381"
                    }
                ]
            },
            {
                "id": "47",
                "companyId": "21",
                "name": "Sun Plaza",
                "address": "Sun Plaza",
                "places": [
                    {
                        "name": "Intrare 017",
                        "nfcId": "0403394a387381"
                    }
                ]
            },
            {
                "id": "48",
                "companyId": "21",
                "name": "Drumul Binelui",
                "address": "Drumul Binelui",
                "places": [
                    {
                        "name": "Intrare 016",
                        "nfcId": "0404394a387381"
                    }
                ]
            },
            {
                "id": "49",
                "companyId": "21",
                "name": "Cora Alexandriei",
                "address": "Alexandriei",
                "places": [
                    {
                        "name": "Intrare 015",
                        "nfcId": "0405394a387381"
                    }
                ]
            },
            {
                "id": "50",
                "companyId": "21",
                "name": "Vulcan",
                "address": "Vulcan",
                "places": [
                    {
                        "name": "Intrare 014",
                        "nfcId": "0406394a387381"
                    }
                ]
            },
            {
                "id": "51",
                "companyId": "21",
                "name": "Obor",
                "address": "Obor",
                "places": [
                    {
                        "name": "Intrare 013",
                        "nfcId": "0407394a387381"
                    }
                ]
            },
            {
                "id": "52",
                "companyId": "21",
                "name": "Brâncuși",
                "address": "Brâncuși ",
                "places": [
                    {
                        "name": "Intrare 012",
                        "nfcId": "0408394a387381"
                    }
                ]
            },
            {
                "id": "53",
                "companyId": "21",
                "name": "Cora Lujerului",
                "address": "Lujerului",
                "places": [
                    {
                        "name": "Intrare 011",
                        "nfcId": "0409394a387381"
                    }
                ]
            },
            {
                "id": "54",
                "companyId": "21",
                "name": "DN1",
                "address": "DN1 value Center",
                "places": [
                    {
                        "name": "Interior 028",
                        "nfcId": "04f8394a387380"
                    }
                ]
            },
            {
                "id": "62",
                "companyId": "21",
                "name": "Baia Mare Auchan",
                "address": "Blv. Bucuresti nr. 144",
                "places": [
                    {
                        "name": "Intrare 029",
                        "nfcId": "04f7394a387380"
                    }
                ]
            },
            {
                "id": "63",
                "companyId": "21",
                "name": "Baia Mare Value Center",
                "address": "Blv. Bucuresti nr. 53",
                "places": [
                    {
                        "name": "Intrare 041",
                        "nfcId": "04eb394a387380"
                    }
                ]
            },
            {
                "id": "64",
                "companyId": "21",
                "name": "Baia Mare Kaufland",
                "address": "Blv. Unirii nr. 21A",
                "places": [
                    {
                        "name": "Intrare 042",
                        "nfcId": "04ea394a387380"
                    }
                ]
            },
            {
                "id": "65",
                "companyId": "21",
                "name": "Kaufland Zalau",
                "address": "Blv. Mihai Viteazu nr. 49",
                "places": [
                    {
                        "name": "Intrarea 047",
                        "nfcId": "04e5394a387380"
                    }
                ]
            },
            {
                "id": "66",
                "companyId": "21",
                "name": "Zalau Value Center",
                "address": "Blv. Mihai Viteazu nr. 58",
                "places": [
                    {
                        "name": "Intrarea 046",
                        "nfcId": "04e6394a387380"
                    }
                ]
            },
            {
                "id": "67",
                "companyId": "21",
                "name": "Satu Mare Auchan",
                "address": "Str. Careiului 77-79",
                "places": [
                    {
                        "name": "Intrare 043",
                        "nfcId": "04e9394a387380"
                    }
                ]
            },
            {
                "id": "68",
                "companyId": "21",
                "name": "Satu Mare Shopping City",
                "address": "Str. Careiului nr. 3-5",
                "places": [
                    {
                        "name": "Intrarea 044",
                        "nfcId": "04e8394a387380"
                    }
                ]
            },
            {
                "id": "69",
                "companyId": "21",
                "name": "Satu Mare Kaufland",
                "address": "Str. Careiului nr. 9",
                "places": [
                    {
                        "name": "Intrarea 045",
                        "nfcId": "04e7394a387380"
                    }
                ]
            },
            {
                "id": "70",
                "companyId": "21",
                "name": "Oradea Iosia",
                "address": "Str. Stefan Octavian Iosif nr. 3",
                "places": [
                    {
                        "name": "Intrarea 049",
                        "nfcId": "04e3394a387380"
                    }
                ]
            },
            {
                "id": "71",
                "companyId": "21",
                "name": "Oradea Borsului",
                "address": "Calea Borsului nr. 6",
                "places": [
                    {
                        "name": "Intrarea 050",
                        "nfcId": "04e2394a387380"
                    }
                ]
            },
            {
                "id": "72",
                "companyId": "21",
                "name": "Oradea Velenta",
                "address": "Str. Seleusului 22-24",
                "places": [
                    {
                        "name": "Intrarea 051",
                        "nfcId": "04e1394a387380"
                    }
                ]
            },
            {
                "id": "73",
                "companyId": "21",
                "name": "Targu Mures Kaufland",
                "address": "Str. Gheorghe Doja nr. 66",
                "places": [
                    {
                        "name": "Intrarea 052",
                        "nfcId": "04e0394a387380"
                    }
                ]
            },
            {
                "id": "74",
                "companyId": "21",
                "name": "Targu Mures Mall",
                "address": "Blv. 1 Decembrie nr. 242",
                "places": [
                    {
                        "name": "Intrarea 053",
                        "nfcId": "04de394a387380"
                    }
                ]
            },
            {
                "id": "75",
                "companyId": "21",
                "name": "Alba Iulia Kaufland",
                "address": "Calea Motilor nr. 120",
                "places": [
                    {
                        "name": "Intrarea 054",
                        "nfcId": "04dc394a387380"
                    }
                ]
            },
            {
                "id": "76",
                "companyId": "21",
                "name": "Kaufland Brasov",
                "address": "Str. Avram Iancu nr. 68",
                "places": [
                    {
                        "name": "Intrarea 055",
                        "nfcId": "04db394a387380"
                    }
                ]
            },
            {
                "id": "77",
                "companyId": "21",
                "name": "Kaufland Resita",
                "address": "Blv. Muncii nr. 21",
                "places": [
                    {
                        "name": "Intrarea 056",
                        "nfcId": "04da394a387380"
                    }
                ]
            },
            {
                "id": "78",
                "companyId": "21",
                "name": "Felicia Iasi",
                "address": "Str. Bucium nr. 36",
                "places": [
                    {
                        "name": "Intrarea 058",
                        "nfcId": "04d8394a387380"
                    }
                ]
            },
            {
                "id": "79",
                "companyId": "21",
                "name": "Kaufland Iasi",
                "address": "Str. Mitropolit Varlaam, Nr. 54",
                "places": [
                    {
                        "name": "Intrarea 057",
                        "nfcId": "04d9394a387380"
                    }
                ]
            },
            {
                "id": "80",
                "companyId": "21",
                "name": "Chimiei Iasi",
                "address": "Str. Chimiei nr. 2",
                "places": [
                    {
                        "name": "Intrarea 060",
                        "nfcId": "04d6394a387380"
                    }
                ]
            },
            {
                "id": "81",
                "companyId": "21",
                "name": "Pacurari Iasi",
                "address": "Str Pacurari nr. 95",
                "places": [
                    {
                        "name": "Intrarea 061",
                        "nfcId": "04d5394a387380"
                    }
                ]
            },
            {
                "id": "82",
                "companyId": "21",
                "name": "Kaufland Tatarasi",
                "address": "Str. Aurel Vlaicu nr. 54A",
                "places": [
                    {
                        "name": "Intrarea 062",
                        "nfcId": "04d4394a387380"
                    }
                ]
            },
            {
                "id": "83",
                "companyId": "21",
                "name": "Bucium Family Market",
                "address": "Sos. Bucium nr. 37",
                "places": [
                    {
                        "name": "Intrarea 059",
                        "nfcId": "04d7394a387380"
                    }
                ]
            },
            {
                "id": "84",
                "companyId": "21",
                "name": "Suceava Carrefour",
                "address": "Calea Unirii 27B",
                "places": [
                    {
                        "name": "Intrarea 063",
                        "nfcId": "04d3394a387380"
                    }
                ]
            },
            {
                "id": "85",
                "companyId": "21",
                "name": "Iulius Suceava ",
                "address": "Calea Unirii 222",
                "places": [
                    {
                        "name": "Intrarea 064",
                        "nfcId": "04d2394a387380"
                    }
                ]
            },
            {
                "id": "86",
                "companyId": "21",
                "name": "Kaufland Piatra Neamt",
                "address": "Strada Obor, nr. 2",
                "places": [
                    {
                        "name": "Intrarea 066",
                        "nfcId": "04d0394a387380"
                    }
                ]
            },
            {
                "id": "87",
                "companyId": "21",
                "name": "Piatra Neamt Shopping",
                "address": "Bd. Decebal Nr. 79",
                "places": [
                    {
                        "name": "Intrarea 065",
                        "nfcId": "04d1394a387380"
                    }
                ]
            },
            {
                "id": "88",
                "companyId": "21",
                "name": "Roman Value Center",
                "address": "Str. Mihai Viteazul 3-5",
                "places": [
                    {
                        "name": "Intrarea 067",
                        "nfcId": "04cf394a387380"
                    }
                ]
            },
            {
                "id": "89",
                "companyId": "21",
                "name": "Bacau Centru",
                "address": "Str Nicu Enea nr. 3",
                "places": [
                    {
                        "name": "Intrarea 069",
                        "nfcId": "04cd394a387380"
                    }
                ]
            },
            {
                "id": "90",
                "companyId": "21",
                "name": "Bacau Milcov",
                "address": "Str. Milcov nr. 3-5",
                "places": [
                    {
                        "name": "Intrarea 068",
                        "nfcId": "04ce394a387380"
                    }
                ]
            },
            {
                "id": "91",
                "companyId": "21",
                "name": "Kaufland Rmn. Valcea Nord",
                "address": "Str. Barajului nr. 32-34",
                "places": [
                    {
                        "name": "Intrarea 074",
                        "nfcId": "04c8394a387380"
                    }
                ]
            },
            {
                "id": "92",
                "companyId": "21",
                "name": "Valcea City",
                "address": "Str. Ferdinand nr. 38A",
                "places": [
                    {
                        "name": "Intrarea 076",
                        "nfcId": "04c6394a387380"
                    }
                ]
            },
            {
                "id": "93",
                "companyId": "21",
                "name": "Kaufland Rmn. Valcea Libertatii",
                "address": "Str. Gib. Mihaescu nr. 10",
                "places": [
                    {
                        "name": "Intrarea 075",
                        "nfcId": "04c7394a387380"
                    }
                ]
            },
            {
                "id": "94",
                "companyId": "21",
                "name": "Craiova 1 Mai",
                "address": "Str. Doctor Nicolae Ionescu Sisești 23",
                "places": [
                    {
                        "name": "Intrarea 077",
                        "nfcId": "04c5394a387380"
                    }
                ]
            },
            {
                "id": "95",
                "companyId": "21",
                "name": "Craiova Valea Rosie",
                "address": "Calea Bucuresti nr. 142                                            ",
                "places": [
                    {
                        "name": "Intrarea 078",
                        "nfcId": "04c4394a387380"
                    }
                ]
            },
            {
                "id": "96",
                "companyId": "21",
                "name": "Cora Drobeta",
                "address": "Str. Constructorului Nr. 1",
                "places": [
                    {
                        "name": "Intrarea 079",
                        "nfcId": "04c3394a387380"
                    }
                ]
            },
            {
                "id": "97",
                "companyId": "21",
                "name": "Kaufland Targu Jiu",
                "address": "Str. Termocentralei nr. 2A",
                "places": [
                    {
                        "name": "Intrarea 080",
                        "nfcId": "04c2394a387380"
                    }
                ]
            },
            {
                "id": "98",
                "companyId": "21",
                "name": "Kaufland Targoviste",
                "address": "Str. LT Stancu Ion nr. 2C",
                "places": [
                    {
                        "name": "Intrarea 087",
                        "nfcId": "04bb394a387380"
                    }
                ]
            },
            {
                "id": "99",
                "companyId": "21",
                "name": "Targoviste Mall",
                "address": "Bd. Regele Carol I nr. 51",
                "places": [
                    {
                        "name": "Intrarea 088",
                        "nfcId": "04ba394a387380"
                    }
                ]
            },
            {
                "id": "100",
                "companyId": "21",
                "name": "Kaufland Buzau",
                "address": "Str. Frasinet nr. 15",
                "places": [
                    {
                        "name": "Intrare 092",
                        "nfcId": "04b6394a387380"
                    }
                ]
            },
            {
                "id": "101",
                "companyId": "21",
                "name": "Ploiesti Vest",
                "address": "Soseaua Vestului nr. 9",
                "places": [
                    {
                        "name": "Intrarea 089",
                        "nfcId": "04b9394a387380"
                    }
                ]
            },
            {
                "id": "102",
                "companyId": "21",
                "name": "Ploiesti Value Center",
                "address": "Str. 1 Decembrie 1918, nr.1",
                "places": [
                    {
                        "name": "Intrare 091",
                        "nfcId": "04b7394a387380"
                    }
                ]
            },
            {
                "id": "103",
                "companyId": "21",
                "name": "Ploiesti Sud",
                "address": "Piata 1 Decembrie 1918 nr. 1A",
                "places": [
                    {
                        "name": "Intrarea 090",
                        "nfcId": "04b8394a387380"
                    }
                ]
            },
            {
                "id": "104",
                "companyId": "21",
                "name": "City Park Constanta",
                "address": "Blv. Alexandru Lăpușneanu nr. 116C",
                "places": [
                    {
                        "name": "Intrarea 071",
                        "nfcId": "04cb394a387380"
                    }
                ]
            },
            {
                "id": "105",
                "companyId": "21",
                "name": "Faleza Sud Constanta",
                "address": "Soseaua Mangaliei numarul nr. 195 A",
                "places": [
                    {
                        "name": "Intrarea 072",
                        "nfcId": "04ca394a387380"
                    }
                ]
            },
            {
                "id": "106",
                "companyId": "21",
                "name": "Cora Constanta",
                "address": "Str. Cumpenei Numarul nr. 2",
                "places": [
                    {
                        "name": "Intrarea 070",
                        "nfcId": "04cc394a387380"
                    }
                ]
            },
            {
                "id": "107",
                "companyId": "21",
                "name": "Kaufland Tulcea",
                "address": "Str. Barajului nr. 11",
                "places": [
                    {
                        "name": "Intrarea 073",
                        "nfcId": "04c9394a387380"
                    }
                ]
            },
            {
                "id": "108",
                "companyId": "21",
                "name": "Kaufland Dej",
                "address": "Strada Vâlcele nr. 2B",
                "places": [
                    {
                        "name": "Intrarea 048",
                        "nfcId": "04e4394a387380"
                    }
                ]
            },
            {
                "id": "109",
                "companyId": "21",
                "name": "Baia Mare Centru",
                "address": "Str. George Cosbuc nr. 38",
                "places": [
                    {
                        "name": "Intrare 093",
                        "nfcId": "04b5394a387380"
                    }
                ]
            },
            {
                "id": "110",
                "companyId": "21",
                "name": "Caransebes",
                "address": "Str. Ardealului nr. 125",
                "places": [
                    {
                        "name": "Intrare 094",
                        "nfcId": "04b4394a387380"
                    }
                ]
            },
            {
                "id": "111",
                "companyId": "21",
                "name": "Galati Micro ",
                "address": "Str. Henri Coanda nr. 5",
                "places": [
                    {
                        "name": "Intrare 095",
                        "nfcId": "04b3394a387380"
                    }
                ]
            }
        ]
    };
    if (!response.ok) {
        return {
            data: {
                error: "Error getEmployeeByIdHandler",
            }
        }
    }

    const locations = response.data;
    return {
        data: {
            error: null,
            locations: locations
        }
    };

}

export const getAllRolesForCompany = async (companyId) => {
    let error = null;
    const rolesResponse = await getAllRolesFromCompany(companyId);

    if (!rolesResponse.ok) {
        return {
            data: {
                error: "Error getAllRolesForCompany",
            }
        }
    }
    const roles = rolesResponse.data.map(role => {
        return {
            id: role.id,
            companyId: role.companyId,
            name: role.name,
        }
    })

    return {
        data: {
            error: null,
            roles: roles
        }
    };
}

export const getAllLocationsAndRolesForCompanyHandler = async (companyId) => {
    let error = null;
    const rolesResponse = await getAllRolesFromCompany(companyId);
    const locationsResponse = await getAllLocationsFromCompany(companyId);
    if (!rolesResponse.ok || !locationsResponse.ok) {
        return {
            data: {
                error: "Error getAllLocationsAndRolesForCompany",
            }
        }
    }
    const roles = rolesResponse.data.map(role => {
        return {
            id: role.id,
            companyId: role.companyId,
            name: role.name,
        }
    })

    const locations = locationsResponse.data.map(location => {
        return {
            id: location.id,
            companyId: location.companyId,
            name: location.name,
            address: location.address,
            places: location.places,
        }
    })


    return {
        data: {
            error: null,
            roles: roles,
            locations: locations

        }
    };
}


export const getCompanyEmployeeByIdHandler = async (id) => {
    let error = null;
    const response = await getEmployeeById(id);
    if (!response.ok) {
        return {
            data: {
                error: "Error getEmployeeByIdHandler",
            }
        }
    }
    const account = {
        id: response.data.id,
        type: response.data.type,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        email:response.data.email,
        phone: response.data.phone,
        managedRoles: response.data.managedRoles,
        managedLocations: response.data.managedLocations,
        clockingLocations: response.data.clockingLocations,
        roles: response.data.roles,
        includeInReports: typeof(response.data.includeInReports) === "undefined"? true:response.data.includeInReports,
        deviceId:response.data.deviceId
    }

    return {
        data: {
            error: null,
            account:account
        }
    };
}


export const createCompanyEmployeeHandler = async (data) => {
    let error = null;

    const body = {
        "companyId": data.companyId,
        "firstName": data.firstName,
        "lastName": data.lastName,
        "phone": data.phone,
        "password": data.password,
        "clockingLocations": data.clockingLocations,
        "roles": data.roles,
        "type": data.type,
        "managedLocations": data.managedLocations,
        "managedRoles": data.managedRoles,
        "includeInReports":data.includeInReports
    }
    const response = await CompaniesEmployeeService.createEmployee(body);
    let employeeId = null;

    if (response.ok) {
        employeeId = response.data.id
    } else {
        error = response.data.error;

    }

    return {
        data: {
            error: error,
            message:response.data.message,
            id: employeeId
        }
    };
}

export const editCompanyEmployeeByIdHandler = async (employeesArray) => {
    let error = null;
    const body = employeesArray.map(data => {
        return {
            "employeeId":data.employeeId,
            "firstName": data.firstName,
            "lastName": data.lastName,
            "phone":data.phone,
             "email":data.email,
            "password": data.password,
            "clockingLocations": data.clockingLocations,
            "roles": data.roles,
            "type": data.type,
            "managedLocations":data.managedLocations,
            "managedRoles":data.managedRoles,
            "includeInReports":data.includeInReports
    }});
    const response = await editEmployeeById(body);
    if (response.ok) {
    } else {
        error ="Error editCompanyEmployeeByIdHandler";
    }
    return {
        data: {
            error: error,
            message: response.data.message
        }
        // data: {
        //     error: error,
        //     message: "Success"
        // }
    };
}

export const removeDeviceIdFromEmployeeHandler = async (employeeId, data) => {
    let error = null;
    const body = {
        "deviceId": data.deviceId,
    }
    const response = await CompaniesEmployeeService.removeDeviceIdFromEmployee(employeeId, body);
    if (response.ok) {

    } else {
        error ="Error editServiceProviderHandler";
    }
    return {
        data: {
            error: error
        }
    };
}













