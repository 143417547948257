import {getAllNFCsFromCompany} from "../../../services/companiesServices/CompaniesNFCsService";
import {getCompanyById} from "../../../services/companiesServices/CompaniesService";


export const getAllNFCsByCompanyIdHandler = async (companyId) => {
    let error = null;
    const nfcResponse = await getAllNFCsFromCompany(companyId);

    let companyResponse = null;
    if (nfcResponse.ok) {
        companyResponse = {
            nfcs: nfcResponse.data.map(nfc => {
                return {
                    id: nfc.id,
                    name: nfc.name,
                }
            })
        }
    } else {
        error = "Error getAllNFCsByCompanyIdHandler";
    }
    return {
        data: {
            error: error,
            company: companyResponse
        }
    };
}
