import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import 'react-calendar/dist/Calendar.css';

import {
    createPlace,
    createLocationHandler,
    editPlace, editLocationByIdHandler,
    getLocationByIdHandler
} from "../../../handlers/companiesHandlers/locationHandlers/HandlerLocationDetails";
import analyticsLogEvent from "../../../services/FirebaseService";
import * as StorageService from "../../../services/StorageServices";
import {LOCALSTORAGE_ITEMS} from "../../../util/Constants";
import {getUserAgent} from "../../../util/common";


function LocationDetails(props) {

    const openedRightPanelStyleName = "open";
    const closedRightPanelStyleName = "closed";

    const defaultFormFields = {
        name: "",
        address:""
    };

    const [state, setState] = useState({
        rightPanelStyleName: closedRightPanelStyleName,
        errorMsg: null,
        ...defaultFormFields
    });

    useEffect(() => {
        analyticsLogEvent("page_view", {
            page_title: "LocationDetails"
        });
        getFormData();
    }, [props.locationDetailsId]);


    const handleOnChange = (e, input) => {
        if (input === "name") {
            setState({
                ...state,
                name: e.target.value,
                errorMsg: null,
            })
        }
        if (input === "address") {
            setState({
                ...state,
                address: e.target.value,
                errorMsg: null,
            })
        }
    }

    const getFormData = () => {

            if (props.locationDetailsId === "") {
                setState({
                    ...state,
                    name: "",
                    address: "",
                    rightPanelStyleName: openedRightPanelStyleName,
                    errorMsg: null
                })
            } else {
                getLocationByIdHandler(props.locationDetailsId).then(response => {
                    setState({
                        ...state,
                        name: response.data.location.name,
                        address: response.data.location.address,
                        rightPanelStyleName: openedRightPanelStyleName,
                        errorMsg: null
                    })
                })
            }

    }


    const bodyNewLocation = {
        "companyId": props.companyId,
        "name": state.name,
        "address":state.address
    }

    const bodyEditLocation = {
        "companyId": props.companyId,
        "name": state.name,
        "address":state.address
    }

    const createNewLocation = () => {
        if (validateData()) {
            createLocationHandler(bodyNewLocation).then(response => {
                if (response.data.error == null) {
                        setState({
                            ...state,
                            name: "",
                            address:"",
                            rightPanelStyleName: openedRightPanelStyleName,
                            errorMsg: null
                        })
                    props.closeRightPanel(true);
                }else{
                    setState({
                        ...state,
                        errorMsg: response.data.message
                    })
                }
            })
        }
    }

    const editExistingLocation = () => {
        if (validateData()) {
            editLocationByIdHandler(props.locationDetailsId, bodyEditLocation).then(response => {
                if (response.data.error == null) {
                    setState({
                        ...state,
                        errorMsg: null
                    })
                    props.closeRightPanel(true);

                }
            })
        }
    }

    const handleSubmit = () => {
        if (props.locationDetailsId === "") {
            createNewLocation();
        } else {
            editExistingLocation();
        }
    }



    const validateData = () => {
        if (!!!state.name) {
            setState({
                ...state,
                errorMsg: <FormattedMessage id="PleaseFillInAllFields" defaultMessage="PleaseFillInAllFields"/>
            })
            return false;
        }
        setState({
            ...state,
            errorMsg: null
        })
        return true;
    }


    return (

        <section className="form-container">
            <div className="details-form">
                <div className="form-group">
                    <div className="row">
                        <label className="label" htmlFor="name"> <FormattedMessage id="Name" defaultMessage="Name"/></label>
                    </div>
                    <div className="row">
                        <FormattedMessage id="Name" defaultMessage="Name">
                            {placeholder =>
                                <input
                                    className="input"
                                    type="text"
                                    onChange={e => handleOnChange(e, "name")}
                                    maxLength={80}
                                    placeholder={placeholder}
                                    autoComplete="off"
                                    id="name"
                                    value={state.name}
                                />
                            }
                        </FormattedMessage>
                    </div>

                </div>

                <div className="form-group">
                    <div className="row">
                        <label className="label" htmlFor="address"> <FormattedMessage id="Address" defaultMessage="Address"/></label>
                    </div>
                    <div className="row">
                        <FormattedMessage id="Address" defaultMessage="Address">
                            {placeholder =>
                                <input
                                    className="input"
                                    type="text"
                                    onChange={e => handleOnChange(e, "address")}
                                    maxLength={80}
                                    placeholder={placeholder}
                                    autoComplete="off"
                                    id="name"
                                    value={state.address}
                                />
                            }
                        </FormattedMessage>
                    </div>

                </div>



                <div className="row buttons-container">
                    <button onClick={() => props.closeRightPanel()}  className="btn btn-default"><FormattedMessage
                        id="Cancel" defaultMessage="Cancel"/></button>
                    <button onClick={() => handleSubmit()} className="btn btn-success"><FormattedMessage
                        id="Save" defaultMessage="Save"/></button>
                </div>
                {state.errorMsg && <div className="error">&#9888;&nbsp;{state.errorMsg}</div>}
            </div>
        </section>

    );
}

export default LocationDetails;
