import {getClockInOut} from "../../services/clockingRecordServices/ClockingRecordService";
import {getCompanyById} from "../../services/companiesServices/CompaniesService";

import {getDateObjectTmz} from "../../util/common"

export const getClockInOutHandler = async (from, to, companyId) => {
    let error = null;
    const companyDetailsResponse = await getCompanyById(companyId);
    const clockInOutResponse = await getClockInOut(from, to, companyId);
    let clockingInfo = null;
    const hours= [];
    const currentDate = new Date();
    if (clockInOutResponse.ok) {
        const employeeClockingLocationsMap = {};
        const clockingHours = [];
        clockInOutResponse.data.content.forEach(e => {
            let existentEmployee = employeeClockingLocationsMap[e.employee.id];
            if(!!!existentEmployee){
                existentEmployee = {
                    id: e.employee.id,
                    employeeId: e.employee.id,
                    isDeleted: e.employee.isDeleted,
                    type: e.employee.type,
                    firstName: e.employee.firstName,
                    lastName: e.employee.lastName,
                    roles: e.employee.roles,
                    clockingLocations: []
                };
                employeeClockingLocationsMap[e.employee.id] = existentEmployee;
            }

            existentEmployee.clockingLocations.push({
                id: e.location.id,
                name: e.location.name,
                clockedIn: e.clockedIn,
                clockedOut: e.clockedOut
            });

            let clockingInHour = 0;
            if(new Date(e.clockedIn).getTime() >= from.getTime()){
                clockingInHour = parseInt(getDateObjectTmz(new Date(e.clockedIn)).hour);
            }

            let clockingOutHour = 0;
            if(e.clockedOut){
                if(new Date(e.clockedOut).getTime() > to.getTime()){
                    if(parseInt(getDateObjectTmz(to).hour) == 0){
                        clockingOutHour = 23;
                    }else {
                        clockingOutHour = parseInt(getDateObjectTmz(to).hour)-1;
                    }
                }else {
                    clockingOutHour = parseInt(getDateObjectTmz(new Date(e.clockedOut)).hour)
                }
            } else {
                if(to.getTime() < currentDate.getTime()){
                    clockingOutHour = 23;
                }else {
                    if(from.getTime() <= currentDate.getTime()){
                        clockingOutHour = parseInt(getDateObjectTmz(currentDate).hour);
                    }
                }
            }

            // const clockingInHour = new Date(e.clockedIn).getTime() < from.getTime()?0:new Date(e.clockedIn).getHours();
            // const clockingOutHour = e.clockedOut?(new Date(e.clockedOut).getTime() > to.getTime()?(to.getHours() == 0?23:to.getHours()-1):new Date(e.clockedOut).getHours()):currentDate.getHours();

            // const clockingInHour = new Date(e.clockedIn).getTime() < from.getTime()?0:parseInt(getDateObjectTmz(new Date(e.clockedIn)).hour);
            // const clockingOutHour = e.clockedOut?(new Date(e.clockedOut).getTime() > to.getTime()?
            //         (parseInt(getDateObjectTmz(to).hour) == 0?23:parseInt(getDateObjectTmz(to).hour)-1)
            //         :parseInt(getDateObjectTmz(new Date(e.clockedOut)).hour)):parseInt(getDateObjectTmz(currentDate).hour);

            clockingHours.push(clockingInHour);
            clockingHours.push(clockingOutHour);
        });

        if(currentDate.getTime() >= from.getTime()){
            const clockingMinHour =  Math.min(...clockingHours);
            const clockingMaxHour = Math.max(...clockingHours);
            for(let i = clockingMinHour-1; i <= clockingMaxHour +1;i++){
                if(i >= 0 && i<=24) {
                    hours.push(i < 10?"0"+i+":00":i+":00");
                }
            }
        }

        clockingInfo = Object.keys(employeeClockingLocationsMap).map((id, i) => {
           return {
               ...employeeClockingLocationsMap[id],
              clockingLocations: employeeClockingLocationsMap[id].clockingLocations.sort((a, b) => new Date(a.clockedIn).getUTCMilliseconds() > new Date(b.clockedOut).getUTCMilliseconds() ? 1 : -1)
           };
        });

    } else {
        error ="Error getAllEmployeesByFacilityIdHandler";
    }

    return {

        data : {
            error: error,
            companyName: companyDetailsResponse.data?.name,
            hours: hours,
            clockingInfo: clockingInfo
        }
    }

}


export const getDEMO_ClockInOutHandler = async (from, to, companyId) => {
    let error = null;
    const companyDetailsResponse = await getCompanyById(companyId);
    const ziuaCareOvreiPentruPontaj = new Date().getDate();
    const clockInOutResponse = {
        ok:true,
        data:{
            "content": [
                {
                    "id": "21519",
                    "employee": {
                        "id": "544",
                        "firstName": "Cleaning (ProClean)",
                        "lastName": "",
                        "numOfEmployees": "2/3",
                        "serviceType":"CLEANING",
                        "roles": [
                            {
                                "id": "61",
                                "name": "Agent comercial"
                            }
                        ],
                        "clockingLocations": [
                            {
                                "id": "76",
                                "name": "Kaufland Brasov"
                            }
                        ],
                        "type": "WORKER",
                        "phone": "+40737259868",
                        "includeInReports": true
                    },
                    "author": {
                        "id": "544",
                        "firstName": "Sicaru",
                        "lastName": "Alexandru",
                        "roles": [
                            {
                                "id": "61",
                                "name": "Agent comercial"
                            }
                        ],
                        "clockingLocations": [
                            {
                                "id": "76",
                                "name": "Kaufland Brasov"
                            }
                        ],
                        "type": "WORKER",
                        "phone": "+40737259868",
                        "includeInReports": true
                    },
                    "place": {
                        "name": "Intrarea 055",
                        "nfcId": "04db394a387380"
                    },
                    "location": {
                        "id": "76",
                        "name": "Kaufland Brasov"
                    },
                    "company": {
                        "id": "21",
                        "name": "Silca"
                    },
                    "clockedIn": "2023-07-"+ziuaCareOvreiPentruPontaj+"T05:26:37.306Z",
                    "clockedOut": "2023-07-"+ziuaCareOvreiPentruPontaj+"T14:30:00.000Z",
                    "manualClockedIn": false,
                    "manualClockedOut": true,
                    "created": "2023-07-"+ziuaCareOvreiPentruPontaj+"T05:26:37.306Z",
                    "lastModified": "2023-07-"+ziuaCareOvreiPentruPontaj+"T14:30:13.319Z"
                },
                {
                    "id": "21518",
                    "employee": {
                        "id": "389",
                        "firstName": "Postal Work (Fastmail SRL)",
                        "lastName": "",
                        "numOfEmployees": "1/1",
                        "serviceType":"MAIL",
                        "roles": [
                            {
                                "id": "61",
                                "name": "Agent comercial"
                            }
                        ],
                        "clockingLocations": [
                            {
                                "id": "94",
                                "name": "Craiova 1 Mai"
                            }
                        ],
                        "type": "WORKER",
                        "phone": "+40791213522",
                        "includeInReports": true
                    },
                    "author": {
                        "id": "389",
                        "firstName": "Razvan",
                        "lastName": "Putinelu",
                        "roles": [
                            {
                                "id": "61",
                                "name": "Agent comercial"
                            }
                        ],
                        "clockingLocations": [
                            {
                                "id": "94",
                                "name": "Craiova 1 Mai"
                            }
                        ],
                        "type": "WORKER",
                        "phone": "+40791213522",
                        "includeInReports": true
                    },
                    "place": {
                        "name": "Intrarea 077",
                        "nfcId": "04c5394a387380"
                    },
                    "location": {
                        "id": "94",
                        "name": "Craiova 1 Mai"
                    },
                    "company": {
                        "id": "21",
                        "name": "Silca"
                    },
                    "clockedIn": "2023-07-"+ziuaCareOvreiPentruPontaj+"T05:25:34.572Z",
                    "clockedOut": "2023-07-"+ziuaCareOvreiPentruPontaj+"T17:35:46.706Z",
                    "manualClockedIn": false,
                    "manualClockedOut": false,
                    "created": "2023-07-"+ziuaCareOvreiPentruPontaj+"T05:25:34.572Z",
                    "lastModified": "2023-07-"+ziuaCareOvreiPentruPontaj+"T17:35:46.706Z"
                },
                {
                    "id": "21517",
                    "employee": {
                        "id": "477",
                        "firstName": "Electricity (NV Energy)",
                        "lastName": "",
                        "numOfEmployees": "1/1",
                        "serviceType":"ELECTRICITY",
                        "roles": [
                            {
                                "id": "61",
                                "name": "Agent comercial"
                            }
                        ],
                        "clockingLocations": [
                            {
                                "id": "110",
                                "name": "Caransebes"
                            },
                            {
                                "id": "77",
                                "name": "Kaufland Resita"
                            }
                        ],
                        "type": "WORKER",
                        "phone": "+40759509169",
                        "includeInReports": true
                    },
                    "author": {
                        "id": "477",
                        "firstName": "Cezar",
                        "lastName": "Banuca",
                        "roles": [
                            {
                                "id": "61",
                                "name": "Agent comercial"
                            }
                        ],
                        "clockingLocations": [
                            {
                                "id": "110",
                                "name": "Caransebes"
                            },
                            {
                                "id": "77",
                                "name": "Kaufland Resita"
                            }
                        ],
                        "type": "WORKER",
                        "phone": "+40759509169",
                        "includeInReports": true
                    },
                    "place": {
                        "name": "Intrarea 056",
                        "nfcId": "04da394a387380"
                    },
                    "location": {
                        "id": "77",
                        "name": "Kaufland Resita"
                    },
                    "company": {
                        "id": "21",
                        "name": "Silca"
                    },
                    "clockedIn": "2023-07-"+ziuaCareOvreiPentruPontaj+"T05:24:41.726Z",
                    "clockedOut": "2023-07-"+ziuaCareOvreiPentruPontaj+"T17:30:44.069Z",
                    "manualClockedIn": false,
                    "manualClockedOut": false,
                    "created": "2023-07-"+ziuaCareOvreiPentruPontaj+"T05:24:41.726Z",
                    "lastModified": "2023-07-"+ziuaCareOvreiPentruPontaj+"T17:30:44.069Z"
                },
                {
                    "id": "21516",
                    "employee": {
                        "id": "530",
                        "firstName": "Electricity (Electro SRL)",
                        "lastName": "",
                        "numOfEmployees": "1/1",
                        "serviceType":"ELECTRICITY",
                        "roles": [
                            {
                                "id": "61",
                                "name": "Agent comercial"
                            }
                        ],
                        "clockingLocations": [
                            {
                                "id": "109",
                                "name": "Baia Mare Centru"
                            },
                            {
                                "id": "62",
                                "name": "Baia Mare Auchan"
                            },
                            {
                                "id": "63",
                                "name": "Baia Mare Value Center"
                            },
                            {
                                "id": "64",
                                "name": "Baia Mare Kaufland"
                            }
                        ],
                        "type": "WORKER",
                        "phone": "+40759653471",
                        "includeInReports": true
                    },
                    "author": {
                        "id": "530",
                        "firstName": "Sorin",
                        "lastName": "Silaghi",
                        "roles": [
                            {
                                "id": "61",
                                "name": "Agent comercial"
                            }
                        ],
                        "clockingLocations": [
                            {
                                "id": "109",
                                "name": "Baia Mare Centru"
                            },
                            {
                                "id": "62",
                                "name": "Baia Mare Auchan"
                            },
                            {
                                "id": "63",
                                "name": "Baia Mare Value Center"
                            },
                            {
                                "id": "64",
                                "name": "Baia Mare Kaufland"
                            }
                        ],
                        "type": "WORKER",
                        "phone": "+40759653471",
                        "includeInReports": true
                    },
                    "place": {
                        "name": "Intrare 093",
                        "nfcId": "04b5394a387380"
                    },
                    "location": {
                        "id": "109",
                        "name": "Baia Mare Centru"
                    },
                    "company": {
                        "id": "21",
                        "name": "Silca"
                    },
                    "clockedIn": "2023-07-"+ziuaCareOvreiPentruPontaj+"T05:24:39.513Z",
                    "clockedOut": "2023-07-"+ziuaCareOvreiPentruPontaj+"T17:38:56.651Z",
                    "manualClockedIn": false,
                    "manualClockedOut": false,
                    "created": "2023-07-"+ziuaCareOvreiPentruPontaj+"T05:24:39.513Z",
                    "lastModified": "2023-07-"+ziuaCareOvreiPentruPontaj+"T17:38:56.651Z"
                },

                {
                    "id": "21513",
                    "employee": {
                        "id": "378",
                        "firstName": "Air Conditioning (Airfix SRL)",
                        "lastName": "",
                        "numOfEmployees": "1/1",
                        "serviceType":"AIR_CONDITIONING",
                        "roles": [
                            {
                                "id": "61",
                                "name": "Agent comercial"
                            }
                        ],
                        "clockingLocations": [
                            {
                                "id": "71",
                                "name": "Oradea Borsului"
                            }
                        ],
                        "type": "WORKER",
                        "phone": "+40736449986",
                        "includeInReports": true
                    },
                    "author": {
                        "id": "378",
                        "firstName": "Andrei",
                        "lastName": "Dogaru",
                        "roles": [
                            {
                                "id": "61",
                                "name": "Agent comercial"
                            }
                        ],
                        "clockingLocations": [
                            {
                                "id": "71",
                                "name": "Oradea Borsului"
                            }
                        ],
                        "type": "WORKER",
                        "phone": "+40736449986",
                        "includeInReports": true
                    },
                    "place": {
                        "name": "Intrarea 050",
                        "nfcId": "04e2394a387380"
                    },
                    "location": {
                        "id": "71",
                        "name": "Oradea Borsului"
                    },
                    "company": {
                        "id": "21",
                        "name": "Silca"
                    },
                    "clockedIn": "2023-07-"+ziuaCareOvreiPentruPontaj+"T05:22:02.977Z",
                    "clockedOut": "2023-07-"+ziuaCareOvreiPentruPontaj+"T17:30:09.742Z",
                    "manualClockedIn": false,
                    "manualClockedOut": false,
                    "created": "2023-07-"+ziuaCareOvreiPentruPontaj+"T05:22:02.977Z",
                    "lastModified": "2023-07-"+ziuaCareOvreiPentruPontaj+"T17:30:09.742Z"
                },

                {
                    "id": "21506",
                    "employee": {
                        "id": "447",
                        "firstName": "Water (Aquapro)",
                        "lastName": "",
                        "numOfEmployees": "1/1",
                        "serviceType":"WATER",
                        "roles": [
                            {
                                "id": "61",
                                "name": "Agent comercial"
                            }
                        ],
                        "clockingLocations": [
                            {
                                "id": "69",
                                "name": "Satu Mare Kaufland"
                            }
                        ],
                        "type": "WORKER",
                        "phone": "+40746460872",
                        "includeInReports": true
                    },
                    "author": {
                        "id": "447",
                        "firstName": "Angelo",
                        "lastName": "Huci",
                        "roles": [
                            {
                                "id": "61",
                                "name": "Agent comercial"
                            }
                        ],
                        "clockingLocations": [
                            {
                                "id": "69",
                                "name": "Satu Mare Kaufland"
                            }
                        ],
                        "type": "WORKER",
                        "phone": "+40746460872",
                        "includeInReports": true
                    },
                    "place": {
                        "name": "Intrarea 045",
                        "nfcId": "04e7394a387380"
                    },
                    "location": {
                        "id": "69",
                        "name": "Satu Mare Kaufland"
                    },
                    "company": {
                        "id": "21",
                        "name": "Silca"
                    },
                    "clockedIn": "2023-07-"+ziuaCareOvreiPentruPontaj+"T05:20:10.639Z",
                    "clockedOut": "2023-07-"+ziuaCareOvreiPentruPontaj+"T17:35:40.479Z",
                    "manualClockedIn": false,
                    "manualClockedOut": false,
                    "created": "2023-07-"+ziuaCareOvreiPentruPontaj+"T05:20:10.639Z",
                    "lastModified": "2023-07-"+ziuaCareOvreiPentruPontaj+"T17:35:40.479Z"
                },

                {
                    "id": "21503",
                    "employee": {
                        "id": "322",
                        "firstName": "Elevator (Adonis SRL)",
                        "lastName": "",
                        "numOfEmployees": "3/3",
                        "serviceType":"ELEVATOR",
                        "roles": [
                            {
                                "id": "61",
                                "name": "Agent comercial"
                            }
                        ],
                        "clockingLocations": [
                            {
                                "id": "81",
                                "name": "Pacurari Iasi"
                            }
                        ],
                        "type": "WORKER",
                        "phone": "+40757169346",
                        "includeInReports": true
                    },
                    "author": {
                        "id": "322",
                        "firstName": "SC Cleaning Service",
                        "lastName": "Vitan",
                        "roles": [
                            {
                                "id": "61",
                                "name": "Agent comercial"
                            }
                        ],
                        "clockingLocations": [
                            {
                                "id": "81",
                                "name": "Pacurari Iasi"
                            }
                        ],
                        "type": "WORKER",
                        "phone": "+40757169346",
                        "includeInReports": true
                    },
                    "place": {
                        "name": "Intrarea 061",
                        "nfcId": "04d5394a387380"
                    },
                    "location": {
                        "id": "81",
                        "name": "Pacurari Iasi"
                    },
                    "company": {
                        "id": "21",
                        "name": "Silca"
                    },
                    "clockedIn": "2023-07-"+ziuaCareOvreiPentruPontaj+"T05:15:05.253Z",
                    "clockedOut": "2023-07-"+ziuaCareOvreiPentruPontaj+"T10:31:13.166Z",
                    "manualClockedIn": false,
                    "manualClockedOut": false,
                    "created": "2023-07-"+ziuaCareOvreiPentruPontaj+"T05:15:05.253Z",
                    "lastModified": "2023-07-"+ziuaCareOvreiPentruPontaj+"T10:31:13.166Z",
                    "daysWorkTimeIntervals": [
                        [
                            {
                                "from": 30600000,
                                "to": 73800000
                            }
                        ]
                    ]
                },
                {
                    "id": "21502",
                    "employee": {
                        "id": "441",
                        "firstName": "Waste disposal (Salub SRL)",
                        "lastName": "",
                        "numOfEmployees": "3/3",
                        "serviceType":"WASTE_DISPOSAL",
                        "roles": [
                            {
                                "id": "61",
                                "name": "Agent comercial"
                            }
                        ],
                        "clockingLocations": [
                            {
                                "id": "103",
                                "name": "Ploiesti Sud"
                            }
                        ],
                        "type": "WORKER",
                        "phone": "+40721447839",
                        "includeInReports": true
                    },
                    "author": {
                        "id": "441",
                        "firstName": "Gabriel-Alexandru",
                        "lastName": "Ionescu",
                        "roles": [
                            {
                                "id": "61",
                                "name": "Agent comercial"
                            }
                        ],
                        "clockingLocations": [
                            {
                                "id": "103",
                                "name": "Ploiesti Sud"
                            }
                        ],
                        "type": "WORKER",
                        "phone": "+40721447839",
                        "includeInReports": true
                    },
                    "place": {
                        "name": "Intrarea 090",
                        "nfcId": "04b8394a387380"
                    },
                    "location": {
                        "id": "103",
                        "name": "Ploiesti Sud"
                    },
                    "company": {
                        "id": "21",
                        "name": "Silca"
                    },
                    "clockedIn": "2023-07-"+ziuaCareOvreiPentruPontaj+"T05:15:04.434Z",
                    "clockedOut": "2023-07-"+ziuaCareOvreiPentruPontaj+"T17:29:57.000Z",
                    "manualClockedIn": false,
                    "manualClockedOut": false,
                    "created": "2023-07-"+ziuaCareOvreiPentruPontaj+"T05:15:04.434Z",
                    "lastModified": "2023-07-"+ziuaCareOvreiPentruPontaj+"T17:29:59.317Z"
                },

            ],
            "page": 0,
            "size": 100000,
            "totalElements": 97,
            "totalPages": 1,
            "first": true,
            "last": true,
            "empty": false
        }
    }
    let clockingInfo = null;
    const hours= [];
    const currentDate = new Date();
    if (clockInOutResponse.ok) {
        const employeeClockingLocationsMap = {};
        const clockingHours = [];
        clockInOutResponse.data.content.forEach(e => {
            let existentEmployee = employeeClockingLocationsMap[e.employee.id];
            if(!!!existentEmployee){
                existentEmployee = {
                    id: e.employee.id,
                    employeeId: e.employee.id,
                    isDeleted: e.employee.isDeleted,
                    type: e.employee.type,
                    firstName: e.employee.firstName,
                    lastName: e.employee.lastName,
                    numOfEmployees: e.employee.numOfEmployees,
                    serviceType:e.employee.serviceType,
                    roles: e.employee.roles,
                    clockingLocations: []
                };
                employeeClockingLocationsMap[e.employee.id] = existentEmployee;
            }

            existentEmployee.clockingLocations.push({
                id: e.location.id,
                name: e.location.name,
                clockedIn: e.clockedIn,
                clockedOut: e.clockedOut
            });

            let clockingInHour = 0;
            if(new Date(e.clockedIn).getTime() >= from.getTime()){
                clockingInHour = parseInt(getDateObjectTmz(new Date(e.clockedIn)).hour);
            }

            let clockingOutHour = 0;
            if(e.clockedOut){
                if(new Date(e.clockedOut).getTime() > to.getTime()){
                    if(parseInt(getDateObjectTmz(to).hour) == 0){
                        clockingOutHour = 23;
                    }else {
                        clockingOutHour = parseInt(getDateObjectTmz(to).hour)-1;
                    }
                }else {
                    clockingOutHour = parseInt(getDateObjectTmz(new Date(e.clockedOut)).hour)
                }
            } else {
                if(to.getTime() < currentDate.getTime()){
                    clockingOutHour = 23;
                }else {
                    if(from.getTime() <= currentDate.getTime()){
                        clockingOutHour = parseInt(getDateObjectTmz(currentDate).hour);
                    }
                }
            }

            // const clockingInHour = new Date(e.clockedIn).getTime() < from.getTime()?0:new Date(e.clockedIn).getHours();
            // const clockingOutHour = e.clockedOut?(new Date(e.clockedOut).getTime() > to.getTime()?(to.getHours() == 0?23:to.getHours()-1):new Date(e.clockedOut).getHours()):currentDate.getHours();

            // const clockingInHour = new Date(e.clockedIn).getTime() < from.getTime()?0:parseInt(getDateObjectTmz(new Date(e.clockedIn)).hour);
            // const clockingOutHour = e.clockedOut?(new Date(e.clockedOut).getTime() > to.getTime()?
            //         (parseInt(getDateObjectTmz(to).hour) == 0?23:parseInt(getDateObjectTmz(to).hour)-1)
            //         :parseInt(getDateObjectTmz(new Date(e.clockedOut)).hour)):parseInt(getDateObjectTmz(currentDate).hour);

            clockingHours.push(clockingInHour);
            clockingHours.push(clockingOutHour);
        });

        if(currentDate.getTime() >= from.getTime()){
            const clockingMinHour =  Math.min(...clockingHours);
            const clockingMaxHour = Math.max(...clockingHours);
            for(let i = clockingMinHour-1; i <= clockingMaxHour +1;i++){
                if(i >= 0 && i<=24) {
                    hours.push(i < 10?"0"+i+":00":i+":00");
                }
            }
        }

        clockingInfo = Object.keys(employeeClockingLocationsMap).map((id, i) => {
            return {
                ...employeeClockingLocationsMap[id],
                clockingLocations: employeeClockingLocationsMap[id].clockingLocations.sort((a, b) => new Date(a.clockedIn).getUTCMilliseconds() > new Date(b.clockedOut).getUTCMilliseconds() ? 1 : -1)
            };
        });

    } else {
        error ="Error getAllEmployeesByFacilityIdHandler";
    }

    return {

        data : {
            error: error,
            companyName: companyDetailsResponse.data?.name,
            hours: hours,
            clockingInfo: clockingInfo
        }
    }

}



