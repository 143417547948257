import React, {useEffect, useState, useContext} from "react";
import {FormattedDate, FormattedMessage} from "react-intl";
import {useLocation, useParams} from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import Header from "../../../components/Header";
import Spinner from "../../../components/Spinner";
import RightPanel from "../../../components/RightPanel";
import {
    deleteFacilityEmployeeHandler,
    getAllEmployeesByCompanyIdHandler
} from "../../../handlers/companiesHandlers/employeesHandlers/HandlerEmployeesAll";
import EmployeeDetails from "./EmployeeDetails";
import {PATH} from "../../../routes/routes";

import {
    getAllLocationsForCompanyHandler,
    getCompanyEmployeeByIdHandler
} from "../../../handlers/companiesHandlers/employeesHandlers/HandlerEmployeesDetails";
import {
    deleteCompanyHandler,
} from "../../../handlers/companiesHandlers/HandlerCompaniesAll";
import closeIcon from "../../../assets/img/icon_close.svg";
import Table from "../../../components/Table";
import Select from "react-select";
import TabNavigation from "../../../components/TabNavigation";
import * as StorageServices from "../../../services/StorageServices";
import {LOCALSTORAGE_ITEMS, TYPES, TYPES as TYPE} from "../../../util/Constants";
import {getUserAgent, selectStyles} from "../../../util/common";
import {adminIcon} from "../../../components/Icons";
import {LocaleContext} from "../../../util/LocaleContext";
import * as StorageService from "../../../services/StorageServices";

import {useNavigate} from "react-router";
import analyticsLogEvent from "../../../services/FirebaseService";

function EmployeesAll(props) {
    let location = useLocation();
    const {companyId} = useParams();
    const companyInfo = StorageService.getItem(LOCALSTORAGE_ITEMS.COMPANY_INFO) ? JSON.parse(StorageService.getItem(LOCALSTORAGE_ITEMS.COMPANY_INFO)) : null;
    const type = StorageServices.getItem(LOCALSTORAGE_ITEMS.TYPE);
    const openedRightPanelStyleName = "open";
    const closedRightPanelStyleName = "closed";
    const navigateTo = useNavigate();
    const localeContext = useContext(LocaleContext);
    const [state, setState] = useState({
        loading: true,
        name: "",
        employees: [],
        employeeDetailsId: null,
        rightPanelStyleName: closedRightPanelStyleName,
        isDeleteCompanyAlertOpen:false,
        isDeleteEmployeeAlertOpen:false,
        hamburgerMenuVisible:false,
        selectedEmployee: null,
        allLocations: null,
        allRoles: null,
        selectedLocation: {
            label: <FormattedMessage id="All" defaultMessage="All" />,
            value: null
        },
    });



    useEffect(() => {
        getPageContent();
        analyticsLogEvent("page_view", {
           page_title: "EmployeesAll"
        });
    }, []);


    const columns = [
        {
            Header: <FormattedMessage id="Name" defaultMessage="Name" />,
            accessor: "name",
            isSortable: true,
            sortType: (rowA, rowB, id, desc) => {
                const rv1 = rowA.original[id];
                const rv2 = rowB.original[id];
                const loc1 = typeof(rv1) !== "undefined"?rv1.toLowerCase():"";
                const loc2 = typeof(rv2) !== "undefined"?rv2.toLowerCase():"";
                if (loc1 > loc2) return 1;
                if (loc2 > loc1) return -1;
                return 0;
            },
            hasIcon: true
        },
        {
            Header: <FormattedMessage id="Type" defaultMessage="Type" />,
            accessor: "type",
            isSortable: true,
        },
        {
            Header: <FormattedMessage id="PhoneNumber" defaultMessage="PhoneNumber" />,
            accessor: "phone",
            isSortable: true,
        },
        {
            Header: <FormattedMessage id="Roles" defaultMessage="Roles" />,
            accessor: "roles",
            isSortable: true,
        },
        {
            Header: <FormattedMessage id="Locations" defaultMessage="Locations" />,
            accessor: "clockingLocations",
            isSortable: false,
        },
        {
            Header: <FormattedMessage id="ClockedLocations" defaultMessage="ClockedLocations" />,
            accessor: "clockedLocations",
            isSortable: true,
            sortType: (rowA, rowB, id, desc) => {
                const rv1 = rowA.original[id][0];
                const rv2 = rowB.original[id][0];
                const loc1 = typeof(rv1) !== "undefined"?rv1.toLowerCase():"";
                const loc2 = typeof(rv2) !== "undefined"?rv2.toLowerCase():"";
                if (loc1 > loc2) return 1;
                if (loc2 > loc1) return -1;
                return 0;
            },
            Cell: (row) => <div>{
                    row.value.join(", ")
            }</div>,
        },
        {
            Header: "Actions",
            accessor: "actions",
            isSortable: false,
            filterable: false,

        },
    ];

    if (type === TYPES.SUPER_ADMIN) {
        columns.splice(0, 0,
            {
                Header: '#',
                id: 'index',
                filterable: false,
                isSortable: false,
            })

        columns.splice(columns.length - 1, 0,
            {
                Header: <FormattedMessage id="Created" defaultMessage="Created"/>,
                accessor: "created",
                isSortable: true,
                disableFilters: true,
                Cell: (row) => <div>{
                    <>
                        <FormattedDate
                            value={row.value}
                            hour="numeric"
                            minute="numeric"
                            hour12={false}
                            day="numeric"
                            month="short"
                            year="numeric"
                            timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                            locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                        />

                    </>


                }</div>,
            })
    }


    const getEmployees = (employees, locations, roles) => {
        const locationsMap = {};
        const rolesMap = {};
        locations?.forEach(l => {
           locationsMap[l.id] = l.name;
        });
        roles?.forEach(r => {
            rolesMap[r.id] = r.name;
        })

       return employees?.map(e => {
            const eClockingLocationsNames = e.clockingLocations?.map(l => locationsMap[l]).join(", ");
            const eRolesNames = e.roles?.filter(r=> rolesMap[r])?.map(r => rolesMap[r]).join(", ");
            return {
                ...e,
                roles: eRolesNames,
                clockingLocations: eClockingLocationsNames
            };
        })
    }

    const getPageContent = () => {
        getAllEmployeesByCompanyIdHandler(companyId).then(response => {
            getAllLocationsForCompanyHandler(companyId).then(locationsResponse=>{
                const callback = () => {
                    setState({
                        ...state,
                        loading: false,
                        name: response.data.allCompanyAndEmployees.name,
                        employees: getEmployees(response.data.allCompanyAndEmployees.employees, locationsResponse.data.locations, response.data.allCompanyAndEmployees.allRoles),
                        allLocations: locationsResponse.data.locations,
                        allRoles: response.data.allCompanyAndEmployees.allRoles,
                        selectedLocation: location.state?.selectedLocation ?
                            {
                                label: location.state?.selectedLocation.label,
                                value: location.state?.selectedLocation.value
                            }
                            :
                            {
                                    label: <FormattedMessage id="All" defaultMessage="All" />,
                                    value: null
                            }
                    })
                };

                if (type !== TYPES.SUPER_ADMIN) {
                    localeContext.setLocale(StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE), callback);
                }else{
                    callback();
                }
            })
        });
    }


    const getAvailableLocations = () => {

        const locs = state.allLocations.map(location => {
            return {
                label: location.name,
                value: location.id
            }
        });
        locs.unshift({
            label: <FormattedMessage id="All" defaultMessage="All" />,
            value: null
        });
        return locs;
    }

    const openRightPanel = (accountId) => {
        if (accountId) {
            setState({
                ...state,
                employeeDetailsId: accountId,
                rightPanelStyleName: openedRightPanelStyleName,

            });
        }else{
            setState({
                ...state,
                employeeDetailsId: "",
                rightPanelStyleName: openedRightPanelStyleName,
            });
        }
    }

    const closeRightPanel = (isSaveBtnClicked) => {
        if (isSaveBtnClicked) {
            getAllEmployeesByCompanyIdHandler(companyId).then(response => {
                setState({
                    ...state,
                    loading: false,
                    rightPanelStyleName: closedRightPanelStyleName,
                    employees: getEmployees(response.data.allCompanyAndEmployees.employees, state.allLocations, state.allRoles),
                    employeeDetailsId: '',
                })
            });
        }else{
            setState({
                ...state,
                rightPanelStyleName: closedRightPanelStyleName,
                employeeDetailsId: '',
            });
        }
    }

    const openDeleteFacilityAlert = (e) => {
        e.stopPropagation();
        setState({
            ...state,
            isDeleteCompanyAlertOpen: true,
        });
    }

    const hideDeleteFacilityAlert = () => {
        setState({
            ...state,
            isDeleteCompanyAlertOpen: false,
            hamburgerMenuVisible: false
        })
    }

    const getDeleteCompanyAlert = () =>{
        if(state.isDeleteCompanyAlertOpen) {
            return {
                title: <FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="Facility" defaultMessage="Delete Facility"/> }} />,
                contentRow1: <FormattedMessage id="DoYouWantToDeleteParam" values={{ param: state.name }} />,
                deleteAction1: () => deleteFacility(companyId),
                deleteText1: <FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="Facility" defaultMessage="Delete Facility"/> }} />,
                cancelAction: () => hideDeleteFacilityAlert(),
                cancelText: <FormattedMessage id="Cancel" defaultMessage="Cancel"/>,
                hideAlert: () => hideDeleteFacilityAlert()
            }
        }
    }


    const deleteFacility = async (companyId) => {
        deleteCompanyHandler(companyId).then(deleteResponse=>{
            navigateTo(PATH.ALL_COMPANIES);
        })
    }

    const openDeleteEmployeeAlert = (accountId) => {
        getCompanyEmployeeByIdHandler(accountId).then(response => {
            setState({
                ...state,
                isDeleteEmployeeAlertOpen: true,
                selectedEmployee: accountId,
            });
        })
    }


    const hideDeleteEmployeeAlert = () => {
        setState({
            ...state,
            isDeleteEmployeeAlertOpen: false,
            hamburgerMenuVisible:false,
            selectedEmployee: null
        })
    }

    const getDeleteEmployeeAlert = () =>{
        if(state.isDeleteEmployeeAlertOpen) {

            return {
                title: <FormattedMessage id="DeleteAccount" defaultMessage="DeleteAccount"/>,
                contentRow1: <FormattedMessage id="DoYouWantToDeleteParamAccount" values={{ param: getSelectedEmployeeName() }} />,
                deleteAction1: () => deleteEmployee(state.selectedEmployee),
                deleteText1: <FormattedMessage id="DeleteAccount" defaultMessage="DeleteAccount"/>,
                cancelAction: () => hideDeleteEmployeeAlert(),
                cancelText: <FormattedMessage id="Cancel" defaultMessage="Cancel"/>,
                hideAlert: () => hideDeleteEmployeeAlert()
            }
        }
    }

    const deleteEmployee = async (accountId) => {
        deleteFacilityEmployeeHandler(accountId).then(deleteResponse=>{
            getAllEmployeesByCompanyIdHandler(companyId).then(response => {
                setState({
                    ...state,
                    loading: false,
                    name: response.data.allCompanyAndEmployees.name,
                    employees: getEmployees(response.data.allCompanyAndEmployees.employees, state.allLocations, state.allRoles),
                    isDeleteEmployeeAlertOpen: false,
                    rightPanelStyleName:closedRightPanelStyleName,
                    hamburgerMenuVisible:false
                })
            });
        })
    }


    const openHamburgerMenu = () => {
        setState({
            ...state,
            hamburgerMenuVisible:!state.hamburgerMenuVisible
        })
    }

    const getSelectedEmployeeName = () => state.employees.filter(e => state.selectedEmployee?.includes(e.id)).map(e => {
        return e.name;
    });


    const onSelectedRowId = (rowId) => {
        openRightPanel(state.employees[rowId].id);
    };

    const onDeleteRowId = (rowId) => {
        openDeleteEmployeeAlert(state.employees[rowId].id);
    };

    const handleOnChange = (e, input) => {
        if (input === "location") {
            setState({
                ...state,
                errorMsg: null,
                selectedLocation: {
                    label: e.label,
                    value: e.value
                }
            })

        }
    }





    return state.loading?
        <Spinner/>
        : (
        <div className="content">
            <Header
                alert={getDeleteEmployeeAlert()}
                alert2={getDeleteCompanyAlert()}
                companyId={companyId}
                hasProfileBtn={true}
                hasBackBtn={type !=TYPE.MANAGER && type!=TYPE.ADMIN? true: false}
                headerTitle={state.name}
                hasHamburgerMenu={type !== TYPES.MANAGER && type!==TYPES.WORKER}
                openHamburgerMenu={() => openHamburgerMenu()}
                isHamburgerMenuVisible={state.hamburgerMenuVisible}
                hamburgerLinkName1={<FormattedMessage id="DeleteCompany" defaultMessage="Delete Company"/>}
                hamburgerLinkAction1={(e) => openDeleteFacilityAlert(e)}
            />
            <div className="inner-content inner-content-for-table">
                {props.hasTabNavigation &&
                    <TabNavigation
                        companyId={companyId}
                        activeTab={0}
                    />
                }
                {state.employees ?
                    <Table
                        topActions={
                            <section className="top-actions">
                                    <div className="form-group select-form-group">
                                        <div className="row">
                                            <label className="label">
                                                <FormattedMessage id="Location"/>
                                            </label>
                                        </div>
                                        <div className="row">

                                            <FormattedMessage id="All" defaultMessage="All">
                                                {placeholder =>
                                                    <Select
                                                        className="select"
                                                        options={getAvailableLocations()}
                                                        onChange={e => handleOnChange(e, "location")}
                                                        defaultValue={location.state?.selectedLocation}
                                                        placeholder={location.state?.selectedLocation ? location.state?.selectedLocation.label: placeholder}
                                                        noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                                        id="location"
                                                        styles={selectStyles}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                    />
                                                }
                                            </FormattedMessage>
                                        </div>
                                    </div>
                                <button className="btn btn-success only-for-desktop" onClick={() => openRightPanel()}><FormattedMessage id="NewEmployee" defaultMessage="NewEmployee"/></button>
                                <button className="btn btn-success only-for-small-screens" onClick={() => openRightPanel()}>{
                                    <img src={closeIcon} alt={"close icon"}/>}</button>

                            </section>
                        }
                        columns={columns}
                        data={state.employees}
                        onSelectedRowId={onSelectedRowId}
                        deleteAction={onDeleteRowId}
                        selectedItemId = {state.employeeDetailsId}
                        searchBoxLabel={<FormattedMessage id="SearchEmployee" defaultMessage="SearchEmployee"/>}
                        filterAccessor={"clockingLocations"}
                        filterValue = {state.selectedLocation? state.selectedLocation : null}
                        filterFunction = {(tv, v) => tv && tv.indexOf(v) >= 0}
                        icon={adminIcon}
                        closeRightPanel={closeRightPanel}
                        extraClassName={"employees-table"}
                        multiFilter={true}
                    />
                    :
                    <div className="no-data-yet-container"> <FormattedMessage id="NoEmployeesYet" defaultMessage="NoEmployeesYet"/></div>


                }
            </div>
            <RightPanel
                isOpened={state.rightPanelStyleName}
                closeRightPanel={closeRightPanel}
                title={

                    ! !!state.employeeDetailsId?
                    <FormattedMessage id="NewEmployee" defaultMessage="NewEmployee"/>
                    :
                    <FormattedMessage id="EditEmployee" defaultMessage="EditEmployee"/>

                }

                hasHamburgerMenu={type !== TYPES.MANAGER && type!==TYPES.WORKER}
                openHamburgerMenu={() => openHamburgerMenu()}
                isHamburgerMenuVisible={state.hamburgerMenuVisible}
                hamburgerLinkName1={<FormattedMessage id="DeleteEmployee" defaultMessage="Delete Employee"/>}
                hamburgerLinkAction1={() => openDeleteEmployeeAlert(state.employeeDetailsId)}
                accountDetailsId={state.employeeDetailsId}
                    children={

                        state.employeeDetailsId !== null ?
                            <EmployeeDetails
                                companyId={companyId}
                                employeeDetailsId={state.employeeDetailsId}
                                closeRightPanel={closeRightPanel}
                            /> : null

                }
            />

        </div>
    );
}

export default EmployeesAll;
