import {
    editHourlyReports, editReportsIntervals, getAllEditedHourlyReports, getAllEditedReportsIntervals,
    getAllHourlyReports,
    getAllReportsIntervals
} from "../../services/reportsServices/ReportsService";
import {editLocationById} from "../../services/companiesServices/CompaniesLocationsService";


export const getAllHourlyReportsHandler = async (companyId, year, month, locationId, roleId) => {



    const response = await getAllHourlyReports(companyId, year, month, locationId, roleId);

    let allReports = null;
    let error = null;
    if (response.ok) {
        allReports = response.data.map(e => {
            return {
                employeeId: e.employeeId,
                firstName: e.firstName,
                lastName: e.lastName,
                location: e.location,
                locationId: e.locationId,
                clockingInfo: e.daysClockingTime,
                totalHours: e.totalClocking,
            }
        });
        allReports.sort(function(a, b){return (a.firstName+a.lastName).localeCompare(b.firstName+b.lastName)});
    } else {
        error = "Error getAllServiceProvidersHandler";
    }

    return {
        data: {
            allReports: allReports
        }
    }
}

export const getDEMO_AllHourlyReportsHandler = async (companyId, year, month, locationId) => {



    const response = {
        ok:true,
        data:[
            {
                "employeeId": "77",
                "firstName": "Mental health",
                "lastName": "provider",
                "daysClockingTime": [
                    43891817,
                    36322489,
                    0,
                    43755348,
                    0,
                    43278137,
                    43717135,
                    0,
                    0,
                    44089776,
                    0,
                    43738023,
                    0,
                    0,
                    43379688,
                    36193193,
                    0,
                    43710802,
                    46425368,
                    43726492,
                    43351221,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ],
                "daysScheduleWorkTime": [
                    43200000,
                    36000000,
                    -1,
                    43200000,
                    -1,
                    43013343,
                    43200000,
                    -1,
                    -1,
                    43200000,
                    -1,
                    43200000,
                    -1,
                    -1,
                    39559388,
                    32400000,
                    -1,
                    39600000,
                    39105368,
                    39600000,
                    39492334,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1
                ],
                "totalClocking": 555579489,
                "locationId": "26",
                "location": "Cora Cluj",
                "includeInReports": true
            },
            {
                "employeeId": "72",
                "firstName": "Electricity",
                "lastName": "provider",
                "daysClockingTime": [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ],
                "daysScheduleWorkTime": [
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1
                ],
                "totalClocking": 0,
                "locationId": "26",
                "location": "Cora Cluj",
                "includeInReports": false
            },
            {
                "employeeId": "72",
                "firstName": "Cleaning",
                "lastName": "provider",
                "daysClockingTime": [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ],
                "daysScheduleWorkTime": [
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1
                ],
                "totalClocking": 0,
                "locationId": "26",
                "location": "Cora Cluj",
                "includeInReports": false
            },
            {
                "employeeId": "545",
                "firstName": "Water",
                "lastName": "provider",
                "daysClockingTime": [
                    0,
                    0,
                    0,
                    0,
                    44285193,
                    0,
                    0,
                    43995023,
                    36484994,
                    0,
                    43888413,
                    0,
                    43884451,
                    43836304,
                    0,
                    0,
                    28980000,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ],
                "daysScheduleWorkTime": [
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    39600000,
                    -1,
                    -1,
                    28800000,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1
                ],
                "totalClocking": 285354378,
                "locationId": "26",
                "location": "Cora Cluj",
                "includeInReports": true
            },
            {
                "employeeId": "537",
                "firstName": "Cooking",
                "lastName": "provider",
                "daysClockingTime": [
                    0,
                    0,
                    43620000,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    43920000,
                    0,
                    43800000,
                    44160000,
                    0,
                    0,
                    29040000,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ],
                "daysScheduleWorkTime": [
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    39600000,
                    -1,
                    -1,
                    28800000,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1
                ],
                "totalClocking": 204540000,
                "locationId": "26",
                "location": "Cora Cluj",
                "includeInReports": true
            },
            {
                "employeeId": "304",
                "firstName": "Gas",
                "lastName": "provider",
                "daysClockingTime": [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ],
                "daysScheduleWorkTime": [
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1
                ],
                "totalClocking": 0,
                "locationId": "26",
                "location": "Cora Cluj",
                "includeInReports": true
            },
            {
                "employeeId": "76",
                "firstName": "Food",
                "lastName": "Provider",
                "daysClockingTime": [
                    0,
                    0,
                    43600833,
                    0,
                    44260800,
                    0,
                    0,
                    43999102,
                    36636539,
                    0,
                    43888450,
                    0,
                    43906520,
                    44118575,
                    0,
                    0,
                    46991924,
                    0,
                    0,
                    0,
                    0,
                    9334408,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ],
                "daysScheduleWorkTime": [
                    -1,
                    -1,
                    43200000,
                    -1,
                    43200000,
                    -1,
                    -1,
                    43200000,
                    36000000,
                    -1,
                    43200000,
                    -1,
                    43200000,
                    39600000,
                    -1,
                    -1,
                    39600000,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1
                ],
                "totalClocking": 356737151,
                "locationId": "26",
                "location": "Cora Cluj"
            },
            {
                "employeeId": "513",
                "firstName": "Gardening",
                "lastName": "provider",
                "daysClockingTime": [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ],
                "daysScheduleWorkTime": [
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1
                ],
                "totalClocking": 0,
                "locationId": "26",
                "location": "Cora Cluj",
                "includeInReports": false
            },
            {
                "employeeId": "81",
                "firstName": "Sunglasses",
                "lastName": "provider",
                "daysClockingTime": [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ],
                "daysScheduleWorkTime": [
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1,
                    -1
                ],
                "totalClocking": 0,
                "locationId": "26",
                "location": "Cora Cluj",
                "includeInReports": true
            }
        ]
    }

    let allReports = null;
    let error = null;
    if (response.ok) {
        allReports = response.data.map(e => {
            return {
                employeeId: e.employeeId,
                firstName: e.firstName,
                lastName: e.lastName,
                location: e.location,
                locationId: e.locationId,
                clockingInfo: e.daysClockingTime,
                totalHours: e.totalClocking,
            }
        });
        allReports.sort(function(a, b){return (a.firstName+a.lastName).localeCompare(b.firstName+b.lastName)});
    } else {
        error = "Error getAllServiceProvidersHandler";
    }

    return {
        data: {
            allReports: allReports
        }
    }
}


export const getAllEditedHourlyReportsHandler = async (companyId, year, month, locationId) => {



    const response = await getAllEditedHourlyReports(companyId, year, month, locationId);

    let allReports = null;
    let error = null;
    if (response.ok) {
        allReports = response.data.map(e => {
            return {
                employeeId: e.employeeId,
                firstName: e.firstName,
                lastName: e.lastName,
                locationId: e.locationId,
                clockingInfo: e.daysClockingTime,

            }
        });

    } else {
        error = "Error getAllServiceProvidersHandler";
    }

    return {
        data: {
            allReports: allReports
        }
    }
}

export const editHourlyReportsHandler = async (data) => {
    let error = null;

    const body = {
        "companyId": data.companyId,
        "employeeId": data.employeeId,
        "locationId": data.locationId,
        "year": data.year,
        "month": data.month,
        "day": data.day,
        "workedTime": data.workedTime
    }
    const response = await editHourlyReports(body);
    if (response.ok) {

    } else {
        error ="Error editHourlyReportsHandler";
    }
    return {
        data: {
            error: error
        }
    };
}

export const getAllReportsIntervalsHandler = async (companyId, year, month, locationId, roleId) => {

    const response = await getAllReportsIntervals(companyId, year, month, locationId, roleId);

    let allReportsIntervals = null;
    let error = null;
    if (response.ok) {
        allReportsIntervals = response.data.map(e => {
            return {
                employeeId: e.employeeId,
                firstName: e.firstName,
                lastName: e.lastName,
                daysClockingIntervals: e.daysClockingIntervals,
                location:e.location,
                locationId: e.locationId
            }
        });
        allReportsIntervals.sort(function(a, b){return (a.firstName+a.lastName).localeCompare(b.firstName+b.lastName)});
    } else {
        error = "Error getAllServiceProvidersHandler";
    }

    return {
        data: {
            allReports: allReportsIntervals
        }
    }
}

export const getAllEditedReportsIntervalHandler = async (companyId, year, month, locationId) => {



    const response = await getAllEditedReportsIntervals(companyId, year, month, locationId);

    let allReportsIntervals = null;
    let error = null;
    if (response.ok) {
        allReportsIntervals = response.data.map(e => {
            return {
                employeeId: e.employeeId,
                daysClockingIntervals: e.daysClockingIntervals,
                // firstName: e.firstName,
                // lastName: e.lastName,
                // location:e.location,
                locationId: e.locationId
            }
        });
    } else {
        error = "Error getAllServiceProvidersHandler";
    }

    return {
        data: {
            allReports: allReportsIntervals
        }
    }
}


export const editReportsIntervalHandler = async (data) => {
    let error = null;
    const body = {
        "companyId": data.companyId,
        "employeeId": data.employeeId,
        "locationId": data.locationId,
        "year": data.year,
        "month": data.month,
        "day": data.day,
        "clockingIntervals": data.clockingIntervals,

    }

    const response = await editReportsIntervals(body);
    if (response.ok) {

    } else {
        error ="Error editHourlyReportsHandler";
    }
    return {
        data: {
            error: error
        }
    };
}





