import React, {useEffect, useRef, useState} from "react";
import {useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce} from 'react-table'
import {FormattedMessage} from "react-intl";
import {LOCALSTORAGE_ITEMS, TYPES} from "../util/Constants";
import * as StorageService from "../services/StorageServices";
import ScrollContainer from "react-indiana-drag-scroll";


function Table({
                   selectedItemId,
                   deleteAction,
                   onSelectedRowId,
                   topActions,
                   isNfcTable,
                   extraClassName,
                   searchBoxLabel,
                   filterAccessor,
                   filterValue,
                   filterFunction,
                   icon,
                   closeRightPanel,
                   columns,
                   data

               }) {
    const typeOfAccount = StorageService.getItem(LOCALSTORAGE_ITEMS.TYPE) || "Unknown";
    const [selectedRow, setSelectedRow] = useState();


    useEffect(() => {
        if (selectedItemId === "" || selectedItemId === null) {
            setSelectedRow(null);
        }
    }, [selectedItemId]);


    useEffect(() => {
        if (filterValue) {
            setFilter(filterAccessor, filterValue);
        }
    }, [filterValue, closeRightPanel]);


    const customFilterFunction = (rows, id, filterValue) => {
        return rows.filter((row) => {
            const check = filterValue.value && filterFunction ? filterFunction(row.original[filterAccessor], filterValue.label) : row.original[filterAccessor] == filterValue.label;
            return filterValue == null || filterValue.value == null || check;
        });
    }


    const handleSelectedRow = (e, selectedRow) => {
        e.stopPropagation();
        setSelectedRow(selectedRow);
        onSelectedRowId(selectedRow);
    }

    const handleDeleteRow = (e, selectedRow) => {
        e.stopPropagation();
        deleteAction(selectedRow);
    }

    function GlobalFilter({globalFilter, setGlobalFilter}) {
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)

        return (

            <div className="form-group">
                <div className="row">
                    <label className="label">
                        {searchBoxLabel}
                    </label>
                </div>
                <div className="row">
                    <FormattedMessage id="Search" defaultMessage="Search">
                        {placeholder =>
                            <input
                                className="input"
                                value={value || ""}
                                autoFocus
                                onChange={e => {
                                    setValue(e.target.value);
                                    onChange(e.target.value);
                                }}

                                placeholder={placeholder}
                            />
                        }
                    </FormattedMessage>
                </div>
            </div>


        )
    }

    const newColumns = React.useMemo(
        () => columns.map(column => {
            if (column.accessor === filterAccessor) {
                return {
                    ...column,
                    filter: customFilterFunction
                };
            } else return column;
        }), []);

    const table = useTable(
        {
            columns: newColumns,
            data
        },

        useGlobalFilter,
        useFilters,
        useSortBy,
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        state,
        setFilter,
    } = table


    return (
        <>
            <div className="row top-actions-row">
                <div className="search">
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                </div>
                {topActions}
            </div>


            <ScrollContainer
                hideScrollbars={false}
                horizontal={false}
                className="scroll-container">
                <div className="table-container">
                    <table
                        className={extraClassName}
                        {...getTableProps()}>
                        <thead>
                        {headerGroups.map((headerGroup) => {
                            let props = headerGroup.getHeaderGroupProps();
                            const key = props.key;
                            delete props.key;
                            return (
                                <tr
                                    key={key}
                                    {...props}
                                >
                                    {headerGroup.headers.map(column => {
                                        let props = column.getHeaderProps(column.isSortable ? column.getSortByToggleProps() : "");
                                        const key = props.key;
                                        delete props.key;
                                        return (
                                            <th
                                                key={key}
                                                {...props}
                                                className={column.Header === "Actions" ? "hidden" : null}
                                            >
                                                {column.render('Header')}
                                                <span>
                                            {
                                                column.isSorted ?
                                                    column.isSortedDesc ?
                                                        <svg className="arrow-icon down-arrow" width="15" height="15"
                                                             viewBox="0 0 10 5"
                                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M0.833984 0.333328L5.00065 4.5L9.16732 0.333328H0.833984Z"
                                                                fill="#4D5062"/>
                                                        </svg>

                                                        : <svg className="arrow-icon up-arrow" width="15" height="15"
                                                               viewBox="0 0 10 5"
                                                               fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M0.833984 0.333328L5.00065 4.5L9.16732 0.333328H0.833984Z"
                                                                fill="#4D5062"/>
                                                        </svg>
                                                    : ''

                                            }
                                        </span>
                                            </th>
                                        )
                                    })}
                                </tr>
                            )
                        })
                        }

                        </thead>
                        <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {

                            prepareRow(row)
                            let props = row.getRowProps();
                            const key = props.key;
                            delete props.key;

                            return (
                                <tr
                                    className={selectedRow === row.id ? "active" : null}
                                    id={row.id}
                                    data-qa={row.original.name}
                                    onClick={(e) => handleSelectedRow(e, row.id)}
                                    key={key}
                                    {...props}>
                                    {row.cells.map(cell => {
                                        let props = cell.getCellProps();
                                        const key = props.key;
                                        delete props.key;

                                        const roles = cell.row.original.roles || [];
                                        const type = cell.row.original.type || [];

                                        const shouldShowIcon = [TYPES.ADMIN, TYPES.MANAGER].some(role =>
                                            roles.includes(role) || type.includes(role)
                                        );

                                        const isManager = roles.includes(TYPES.MANAGER) || type.includes(TYPES.MANAGER);


                                        if (cell.column.Header === "Name" || cell.column.Header.props?.defaultMessage === "Name") {
                                            return (
                                                <td className="primary-td" key={key} {...props}>
                                                    {shouldShowIcon && (
                                                        isManager ?
                                                            <span className="manager">{icon}</span> :
                                                            <span className="admin">{icon}</span>
                                                    )}
                                                    {cell.column.id === 'index' ? i + 1 : cell.render('Cell')}
                                                </td>
                                            );

                                        } else if ((cell.column.Header === "Actions") && (typeOfAccount === TYPES.SUPER_ADMIN || (typeOfAccount === TYPES.ADMIN && !isNfcTable))) {
                                            return (
                                                <td onClick={(e) => handleDeleteRow(e, row.id)} key={i}>
                                                    <div onClick={(e) => handleDeleteRow(e, row.id)}
                                                         className="row buttons-row only-for-desktop hidden">
                                                        <a id="delete" className="link danger"><FormattedMessage
                                                            id="Delete"
                                                            defaultMessage="Delete"/></a>
                                                    </div>
                                                </td>
                                            )
                                        } else {

                                            let props = cell.getCellProps();
                                            const key = props.key;
                                            delete props.key;
                                            return (
                                                <td
                                                    key={key}
                                                    {...props}>
                                                    {cell.column.id === 'index' ? i + 1 : cell.render('Cell')}

                                                </td>
                                            )
                                        }
                                    })}

                                </tr>
                            )
                        })}

                        </tbody>
                    </table>
                </div>
            </ScrollContainer>
        </>

    )
}

export default Table;
