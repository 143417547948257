import * as FacilitiesService from "../../../services/companiesServices/CompaniesService";
import {getAllLocationsFromCompany} from "../../../services/companiesServices/CompaniesLocationsService";
import {getCompanyById} from "../../../services/companiesServices/CompaniesService";
import {getAllNFCsFromCompany} from "../../../services/companiesServices/CompaniesNFCsService";
import {getAllEmployeesFromCompany} from "../../../services/companiesServices/CompaniesEmployeesService";
import {resetTime} from "../../../util/common";
import {getClockInOutHandler} from "../../clockingRecordHandlers/HandlerClockingRecordsAll";
import {getClockInOut} from "../../../services/clockingRecordServices/ClockingRecordService";

export const getAllCompanyLocationsByIdHandler = async (companyId) => {
    let error = null;
    const today = resetTime(new Date());
    const endOfDay = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    const clockedEmployeesResponse = await getClockInOut(today, endOfDay, companyId);

    const locationsResponse = await getAllLocationsFromCompany(companyId);
    const allEmployeesResponse = await getAllEmployeesFromCompany(companyId);
    let companyResponse = null;
    if (locationsResponse.ok && allEmployeesResponse.ok && clockedEmployeesResponse.ok) {
        companyResponse = {
            locations: locationsResponse.data.map(location => {
                const clockedNr = clockedEmployeesResponse.data.content.filter(employee => employee.location.id === location.id).length;
                const totalNr = allEmployeesResponse.data.filter(employee => employee.clockingLocations?.includes(location.id)).length;
                return {
                    id: location.id,
                    name: location.name,
                    address: location.address,
                    clockedEmployees: {
                        clockedNr: clockedNr,
                        totalNr: totalNr,
                        selectedLocation: {
                            label: location.name,
                            value: location.id
                        },
                        hasWarningIcon: clockedNr !== totalNr && clockedNr === 0
                    },
                }
            })
        }
    } else {
        error = "Error getAllCompanyLocationsByIdHandler";
    }
    return {
        data: {
            error: error,
            company: companyResponse
        }
    };
}



