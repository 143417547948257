import React, {useContext, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useParams} from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import Aside from "../../../components/Aside";
import Header from "../../../components/Header";
import Spinner from "../../../components/Spinner";
import RightPanel from "../../../components/RightPanel";
import RoleDetails from "./RoleDetails";
import {PATH} from "../../../routes/routes";

import closeIcon from "../../../assets/img/icon_close.svg";
import {deleteCompanyHandler} from "../../../handlers/companiesHandlers/HandlerCompaniesAll";
import {
    getCompanyDetailsHandler,
    getEmployeesAndRolesHandler
} from "../../../handlers/companiesHandlers/roleHandlers/HandlerRolesAll";
import {deleteRoleHandler} from "../../../handlers/companiesHandlers/roleHandlers/HandlerRoleDetails";
import Table from "../../../components/Table";
import TabNavigation from "../../../components/TabNavigation";
import * as StorageService from "../../../services/StorageServices";
import {LOCALSTORAGE_ITEMS, TYPES} from "../../../util/Constants";
import {LocaleContext} from "../../../util/LocaleContext";
import * as StorageServices from "../../../services/StorageServices";
import {useNavigate} from "react-router";
import analyticsLogEvent from "../../../services/FirebaseService";
import {getUserAgent} from "../../../util/common";


function RolesAll(props) {

    const {companyId} = useParams();
    const companyInfo = StorageService.getItem(LOCALSTORAGE_ITEMS.COMPANY_INFO) ? JSON.parse(StorageService.getItem(LOCALSTORAGE_ITEMS.COMPANY_INFO)) : null;
    const navigateTo = useNavigate();
    const openedRightPanelStyleName = "open";
    const closedRightPanelStyleName = "closed";
    const localeContext = useContext(LocaleContext);
    const type = StorageServices.getItem(LOCALSTORAGE_ITEMS.TYPE);
    const [state, setState] = useState({
        loading: true,
        name: "",
        roles: [],
        roleDetailsId: null,
        rightPanelStyleName: closedRightPanelStyleName,
        isDeleteCompanyAlertOpen:false,
        isDeleteRoleAlertOpen:false,
        hamburgerMenuVisible:false,
        selectedRole: null
    });

    useEffect(() => {
        analyticsLogEvent("page_view", {
            page_title: "RolesAll"
        });
        getPageContent();
    }, []);


    const getPageContent = async () => {

        getEmployeesAndRolesHandler(companyId).then(employeesAndRolesResponse => {
            const callback = () => {
                setState({
                    ...state,
                    loading: false,
                    name: companyInfo?.name,
                    rolesAndEmployees: employeesAndRolesResponse.data.allCompanyRolesAndEmployees,
                    roles: getRoles(employeesAndRolesResponse)
                })
            }

            if (type !== TYPES.SUPER_ADMIN) {
                localeContext.setLocale(StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE), callback);
            }else{
                callback();
            }

        });

    }

    const getRoles = (response) => {
        return response.data.allCompanyRolesAndEmployees.allRoles.map(role=> {
            let employeesList = ""
            response.data.allCompanyRolesAndEmployees.allEmployees.filter(employee=> employee.roles?.includes(role.id)).forEach(filteredEmployee => employeesList += filteredEmployee.firstName + " " + filteredEmployee.lastName + ", ")
            if(employeesList !== "") {
                employeesList = employeesList.slice(0,-2);
            }
            return {
                id: role.id,
                name: role.name,
                employees: employeesList
            }
        })
    }


    const columns = [
        {
            Header: <FormattedMessage id="Name" defaultMessage="Name" />,
            accessor: "name",
            isSortable: true,
        },

        {
            Header: <FormattedMessage id="Employees" defaultMessage="Employees" />,
            accessor: "employees",
            isSortable: true,
        },
        {
            Header: "Actions",
            accessor: "actions",
            isSortable: false,

        },
    ];


    const createNewRole = () => {
        setState({
            ...state,
            roleDetailsId: "",
            rightPanelStyleName: openedRightPanelStyleName
        });
    }

    const editRole = (roleId) => {
        setState({
            ...state,
            roleDetailsId: roleId,
            rightPanelStyleName: openedRightPanelStyleName
        });

    }

    const closeRightPanel = (isSaveBtnClicked) => {

        if (isSaveBtnClicked) {

            getEmployeesAndRolesHandler(companyId).then(response => {
                setState({
                    ...state,
                    loading: false,
                    roles: getRoles(response),
                    rightPanelStyleName: closedRightPanelStyleName,
                    roleDetailsId: ''
                })
            });
        }else{
            setState({
                ...state,
                rightPanelStyleName: closedRightPanelStyleName,
                roleDetailsId: ''
            });
        }
    }

    const openDeleteRoleAlert = (roleId) => {

        setState({
            ...state,
            isDeleteRoleAlertOpen: true,
            selectedRole: roleId,
        });
    }


    const hideDeleteRoleAlert = () => {
        setState({
            ...state,
            isDeleteRoleAlertOpen: false,
            hamburgerMenuVisible:false,
            selectedRole: null
        })
    }


    const openDeleteCompanyAlert = (e) => {
        e.stopPropagation();
        setState({
            ...state,
            isDeleteCompanyAlertOpen: true,
        });
    }

    const hideDeleteCompanyAlert = () => {
        setState({
            ...state,
            isDeleteCompanyAlertOpen: false,
            hamburgerMenuVisible: false
        })
    }


    const deleteCompany = async (companyId) => {
        deleteCompanyHandler(companyId).then(()=>{
            navigateTo(PATH.ALL_COMPANIES);
        })
    }

    const deleteRole = async (roleId) => {
        deleteRoleHandler(roleId).then(()=>{
            getEmployeesAndRolesHandler(companyId).then(response => {
                setState({
                    ...state,
                    loading: false,
                    roles: getRoles(response),
                    isDeleteRoleAlertOpen: false,
                    rightPanelStyleName:closedRightPanelStyleName,
                    hamburgerMenuVisible:false
                })
            });
        })
    }


    const getDeleteRoleAlert = () =>{
        if(state.isDeleteRoleAlertOpen) {
            return {
                title: <FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="Role" defaultMessage="Role"/> }} />,
                contentRow1: <FormattedMessage id="DoYouWantToDeleteParam" values={{ param: getSelectedRoleName() }} />,
                deleteAction1: () => deleteRole(state.selectedRole),
                deleteText1: <FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="Role" defaultMessage="Role"/> }} />,
                cancelAction: () => hideDeleteRoleAlert(),
                cancelText: <FormattedMessage id="Cancel" defaultMessage="Cancel"/>,
                hideAlert: () => hideDeleteRoleAlert()
            }
        }
    }


    const getDeleteCompanyAlert = () =>{
        if(state.isDeleteCompanyAlertOpen) {
            return {
                title: <FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="Company" defaultMessage="Delete Company"/> }} />,
                contentRow1: <FormattedMessage id="DoYouWantToDeleteParam" values={{ param: state.name }} />,
                deleteAction1: () => deleteCompany(companyId),
                deleteText1: <FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="Company" defaultMessage="Delete Company"/> }} />,
                cancelAction: () => hideDeleteCompanyAlert(),
                cancelText: <FormattedMessage id="Cancel" defaultMessage="Cancel"/>,
                hideAlert: () => hideDeleteCompanyAlert()
            }
        }
    }

    const openHamburgerMenu = () => {
        setState({
            ...state,
            hamburgerMenuVisible:!state.hamburgerMenuVisible
        })
    }

    const getSelectedRoleName = () => state.roles.filter(e => state.selectedRole?.includes(e.id)).map(e => {
        return e.name;
    });

    const onSelectedRowId = (rowId) => {
        editRole(state.roles[rowId].id);
    };

    const onDeleteRowId = (rowId) => {
        openDeleteRoleAlert(state.roles[rowId].id);
    };


    return state.loading?
        <Spinner/>
        : (
            <div className="content">

                <Header
                    alert={getDeleteRoleAlert()}
                    alert2={getDeleteCompanyAlert()}
                    companyId={companyId}
                    hasBackBtn={true}
                    headerTitle={state.name}
                    hasHamburgerMenu={true}
                    openHamburgerMenu={() => openHamburgerMenu()}
                    isHamburgerMenuVisible={state.hamburgerMenuVisible}
                    hamburgerLinkName1={<FormattedMessage id="DeleteCompany" defaultMessage="Delete Company"/>}
                    hamburgerLinkAction1={(e) => openDeleteCompanyAlert(e)}

                />
                <div className="inner-content inner-content-for-table">
                    {props.hasTabNavigation &&
                    <TabNavigation
                        companyId={companyId}
                        activeTab={1}
                    />
                    }
                    {state.roles?
                        <Table
                            topActions={
                                <section className="top-actions">
                                    <button className="btn btn-success only-for-desktop" onClick={() => createNewRole()}><FormattedMessage id="NewRole" defaultMessage="NewRole"/></button>
                                    <button className="btn btn-success only-for-small-screens" onClick={() => createNewRole()}><img src={closeIcon}/></button>
                                </section>
                            }
                            columns={columns}
                            data={state.roles}
                            onSelectedRowId={onSelectedRowId}
                            deleteAction={onDeleteRowId}
                            selectedItemId = {state.roleDetailsId}
                            closeRightPanel={closeRightPanel}
                            searchBoxLabel={<FormattedMessage id="SearchRole" defaultMessage="SearchRole"/>}

                        />
                        :
                        <div className="no-data-yet-container"> <FormattedMessage id="NoRolesYet" defaultMessage="NoRolesYet"/></div>

                    }

                </div>
                <RightPanel
                    isOpened={state.rightPanelStyleName}
                    closeRightPanel = {closeRightPanel}

                    title={! !!state.roleDetailsId?
                        <FormattedMessage id="NewRole" defaultMessage="New Role"/>
                        :
                        <FormattedMessage id="EditRole" defaultMessage="Edit Role"/>
                    }
                    hasHamburgerMenu={true}
                    openHamburgerMenu={() => openHamburgerMenu()}
                    isHamburgerMenuVisible={state.hamburgerMenuVisible}
                    hamburgerLinkName1={<FormattedMessage id="DeleteRole" defaultMessage="Delete Role"/>}
                    hamburgerLinkAction1={(e) => openDeleteRoleAlert(e, state.roleDetailsId)}
                    children={
                        state.roleDetailsId!==null ?
                            <RoleDetails
                                roleDetailsId = {state.roleDetailsId}
                                companyId={companyId}
                                closeRightPanel = {closeRightPanel}
                            />:null
                    }
                />

            </div>
        );
}

export default RolesAll;
