import React, {useContext, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useParams} from "react-router-dom";
import 'react-calendar/dist/Calendar.css';

import Header from "../../../components/Header";
import Spinner from "../../../components/Spinner";
import RightPanel from "../../../components/RightPanel";
import LocationDetails from "./LocationDetails";
import {
    getAllCompanyLocationsByIdHandler
} from "../../../handlers/companiesHandlers/locationHandlers/HandlerLocationsAll";
import {PATH} from "../../../routes/routes";
import {deleteLocationHandler} from "../../../handlers/companiesHandlers/locationHandlers/HandlerLocationDetails";
import closeIcon from "../../../assets/img/icon_close.svg";
import {deleteCompanyHandler} from "../../../handlers/companiesHandlers/HandlerCompaniesAll";
import Table from "../../../components/Table";
import TabNavigation from "../../../components/TabNavigation";
import * as StorageService from "../../../services/StorageServices";
import {LOCALSTORAGE_ITEMS, TYPES} from "../../../util/Constants";
import {warningIcon} from "../../../components/Icons";
import {LocaleContext} from "../../../util/LocaleContext";
import {useNavigate} from "react-router";
import analyticsLogEvent from "../../../services/FirebaseService";
import {getUserAgent} from "../../../util/common";


function LocationsAll(props) {

    const {companyId} = useParams();
    const navigateTo = useNavigate();
    const openedRightPanelStyleName = "open";
    const closedRightPanelStyleName = "closed";
    const type = StorageService.getItem(LOCALSTORAGE_ITEMS.TYPE) || "Unknown";
    const companyInfo = StorageService.getItem(LOCALSTORAGE_ITEMS.COMPANY_INFO) ? JSON.parse(StorageService.getItem(LOCALSTORAGE_ITEMS.COMPANY_INFO)) : null;
    const localeContext = useContext(LocaleContext);
    const [state, setState] = useState({
        loading: true,
        name: "",
        locations: [],
        locationDetailsId: null,
        rightPanelStyleName: closedRightPanelStyleName,
        isDeleteCompanyAlertOpen: false,
        isDeleteLocationAlertOpen: false,
        hamburgerMenuVisible: false,
        selectedLocation: null,
        hasWarning:false
    });

    useEffect(() => {
        analyticsLogEvent("page_view", {
            page_title: "LocationsAll"
        });
        getPageContent();
    }, []);


    const getPageContent = async () => {
        getAllCompanyLocationsByIdHandler(companyId).then(response => {
            const callback = () => {
                setState({
                    ...state,
                    loading: false,
                    name: companyInfo?.name,
                    locations: response.data.company.locations,
                })
            }

            if (type !== TYPES.SUPER_ADMIN) {
                localeContext.setLocale(StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE), callback);
            }else{
                callback();
            }

        });
    }

    const toEmployeesPage = (e, selectedLocation) => {
        e.preventDefault();
        navigateTo(PATH.COMPANIES_EMPLOYEES(companyId), {
            state: {
                selectedLocation: {
                    label: selectedLocation.label,
                    value: selectedLocation.value
                }
            }
        });
    }


    const columns = [
        {
            Header: <FormattedMessage id="Name" defaultMessage="Name" />,
            accessor: "name",
            isSortable: true,
        },
        {
            Header: <FormattedMessage id="ClockedEmployees" defaultMessage="ClockedEmployees" />,
            accessor: "clockedEmployees",
            Cell: ({ cell }) =>
                <div onClick={(e) => {toEmployeesPage(e, cell.value.selectedLocation) }} className="row clocked-employees-row">
                    <span style={{marginRight: "5px"}}>{cell.value.clockedNr+"/"+cell.value.totalNr} </span>
                    {cell.value.hasWarningIcon && <>{warningIcon}</>}
                </div>,
            isSortable: true,
            sortType: (rowA, rowB, id, desc) => {
                const clockedNr1 = rowA.original[id].clockedNr;
                const clockedNr2 = rowB.original[id].clockedNr;
                if (clockedNr1 > clockedNr2) return 1;
                if (clockedNr2 > clockedNr1) return -1;

                const totalNr1 = rowA.original[id].totalNr;
                const totalNr2 = rowB.original[id].totalNr;
                if (totalNr1 > totalNr2) return 1;
                if (totalNr2 > totalNr1) return -1;

                return 0;
            }
        },
        {
            Header: <FormattedMessage id="Address" defaultMessage="Address" />,
            accessor: "address",
            isSortable: true,
            sortType: (rowA, rowB, id, desc) => {
                const rv1 = rowA.original[id];
                const rv2 = rowB.original[id];
                const addr1 = typeof(rv1) !== "undefined"?rv1.toLowerCase():"";
                const addr2 = typeof(rv2) !== "undefined"?rv2.toLowerCase():"";
                if (addr1 > addr2) return 1;
                if (addr2 > addr1) return -1;
                return 0;
            },
        },
        {
            Header: "Actions",
            accessor: "actions",
            isSortable: false,
        },
    ];

    const createNewLocation = () => {
        setState({
            ...state,
            locationDetailsId: "",
            rightPanelStyleName: openedRightPanelStyleName
        });
    }

    const editLocation = (locationId) => {
            if (type === TYPES.MANAGER) {
                return;
            }
            setState({
                ...state,
                locationDetailsId: locationId,
                rightPanelStyleName: openedRightPanelStyleName
            });

    }

    const closeRightPanel = (isSaveBtnClicked) => {

        if (isSaveBtnClicked) {

            getAllCompanyLocationsByIdHandler(companyId).then(response => {
                setState({
                    ...state,
                    loading: false,
                    locations: response.data.company.locations,
                    rightPanelStyleName: closedRightPanelStyleName,
                    locationDetailsId: ''
                })
            });
        }else{
            setState({
                ...state,
                rightPanelStyleName: closedRightPanelStyleName,
                locationDetailsId: ''
            });
        }
    }

    const openDeleteLocationAlert = (locationId) => {

        setState({
            ...state,
            isDeleteLocationAlertOpen: true,
            selectedLocation: locationId,
        });
    }


    const hideDeleteLocationAlert = () => {
        setState({
            ...state,
            isDeleteLocationAlertOpen: false,
            hamburgerMenuVisible:false,
            selectedLocation: null
        })
    }


    const openDeleteCompanyAlert = (e) => {
        e.stopPropagation();
        setState({
            ...state,
            isDeleteCompanyAlertOpen: true,
        });
    }

    const hideDeleteCompanyAlert = () => {
        setState({
            ...state,
            isDeleteCompanyAlertOpen: false,
            hamburgerMenuVisible: false
        })
    }


    const deleteCompany = async (companyId) => {
        deleteCompanyHandler(companyId).then(()=>{
            navigateTo(PATH.ALL_COMPANIES);
        })
    }

    const deleteLocation = async (locationId) => {
        deleteLocationHandler(locationId).then(()=>{
            getAllCompanyLocationsByIdHandler(companyId).then(response => {
                setState({
                    ...state,
                    loading: false,
                    locations:  response.data.company.locations,
                    isDeleteLocationAlertOpen: false,
                    rightPanelStyleName:closedRightPanelStyleName,
                    hamburgerMenuVisible:false
                })
            });
        })
    }


    const getDeleteLocationAlert = () =>{
        if(state.isDeleteLocationAlertOpen) {
            return {
                title: <FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="Location" defaultMessage="Location"/> }} />,
                contentRow1: <FormattedMessage id="DoYouWantToDeleteParam" values={{ param: getSelectedLocationName() }} />,
                deleteAction1: () => deleteLocation(state.selectedLocation),
                deleteText1: <FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="Location" defaultMessage="Location"/> }} />,
                cancelAction: () => hideDeleteLocationAlert(),
                cancelText: <FormattedMessage id="Cancel" defaultMessage="Cancel"/>,
                hideAlert: () => hideDeleteLocationAlert()
            }
        }
    }


    const getDeleteCompanyAlert = () =>{
        if(state.isDeleteCompanyAlertOpen) {
            return {
                title: <FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="Company" defaultMessage="Delete Company"/> }} />,
                contentRow1: <FormattedMessage id="DoYouWantToDeleteParam" values={{ param: state.name }} />,
                deleteAction1: () => deleteCompany(companyId),
                deleteText1: <FormattedMessage id="DeleteParam" values={{ param: <FormattedMessage id="Company" defaultMessage="Delete Company"/> }} />,
                cancelAction: () => hideDeleteCompanyAlert(),
                cancelText: <FormattedMessage id="Cancel" defaultMessage="Cancel"/>,
                hideAlert: () => hideDeleteCompanyAlert()
            }
        }
    }

    const openHamburgerMenu = () => {
        setState({
            ...state,
            hamburgerMenuVisible:!state.hamburgerMenuVisible
        })
    }



    const getSelectedLocationName = () => state.locations.filter(e => state.selectedLocation?.includes(e.id)).map(e => {
        return e.name;
    });

    const onSelectedRowId = (rowId) => {
        editLocation(state.locations[rowId].id);
    };

    const onDeleteRowId = (rowId) => {
        openDeleteLocationAlert(state.locations[rowId].id);
    };



    return state.loading?
        <Spinner/>
        : (
        <div className="content">

            <Header
                alert={getDeleteLocationAlert()}
                alert2={getDeleteCompanyAlert()}
                companyId={companyId}
                hasBackBtn={type!==TYPES.MANAGER}
                headerTitle={state.name}
                hasHamburgerMenu={true}
                openHamburgerMenu={() => openHamburgerMenu()}
                isHamburgerMenuVisible={state.hamburgerMenuVisible}
                hamburgerLinkName1={<FormattedMessage id="DeleteCompany" defaultMessage="Delete Company"/>}
                hamburgerLinkAction1={(e) => openDeleteCompanyAlert(e)}

            />
            <div className="inner-content inner-content-for-table">
                {props.hasTabNavigation &&
                    <TabNavigation
                        companyId={companyId}
                        activeTab={2}
                    />
                }

                {state.locations ?

                    <Table
                        topActions={
                            <section className="top-actions">
                                {type !== TYPES.MANAGER && <button className="btn btn-success only-for-desktop" onClick={() => createNewLocation()}><FormattedMessage id="NewLocation" defaultMessage="NewLocation"/></button>}
                                <button className="btn btn-success only-for-small-screens" onClick={() => createNewLocation()}><img src={closeIcon}/></button>

                            </section>
                        }
                        columns={columns}
                        data={state.locations}
                        onSelectedRowId={onSelectedRowId}
                        deleteAction={onDeleteRowId}
                        selectedItemId = {state.locationDetailsId}
                        closeRightPanel={closeRightPanel}
                        searchBoxLabel={<FormattedMessage id="SearchLocation" defaultMessage="SearchLocation"/>}


                    />
                    :
                    <div className="no-data-yet-container">
                        <FormattedMessage id="NoLocationsYet" defaultMessage="NoLocationsYet"/>
                    </div>

                }


            </div>
            <RightPanel
                isOpened={state.rightPanelStyleName}
                closeRightPanel = {closeRightPanel}

                title={! !!state.locationDetailsId?
                    <FormattedMessage id="NewLocation" defaultMessage="New Location"/>
                    :
                    <FormattedMessage id="EditLocation" defaultMessage="Edit Location"/>
                }
                hasHamburgerMenu={true}
                openHamburgerMenu={() => openHamburgerMenu()}
                isHamburgerMenuVisible={state.hamburgerMenuVisible}
                hamburgerLinkName1={<FormattedMessage id="DeletePlace" defaultMessage="Delete Place"/>}
                hamburgerLinkAction1={(e) => openDeleteLocationAlert(e, state.locationDetailsId)}
                children={
                    state.locationDetailsId!==null ?
                    <LocationDetails
                        locationDetailsId = {state.locationDetailsId}
                        companyId={companyId}
                        closeRightPanel = {closeRightPanel}
                    />:null
                }
            />

        </div>
    );
}

export default LocationsAll;
