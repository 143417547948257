import React, {useEffect, useState, useRef, useContext} from "react";
import {FormattedDate, FormattedMessage} from "react-intl";
import { useParams} from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import Header from "../../components/Header";
import Spinner from "../../components/Spinner";
import RightPanel from "../../components/RightPanel";
import CalendarTmz from "../../components/CalendarTmz";

import {
    getAllLocationsForCompanyHandler
} from "../../handlers/companiesHandlers/employeesHandlers/HandlerEmployeesDetails";

import TabNavigation from "../../components/TabNavigation";
import * as StorageServices from "../../services/StorageServices";
import {LOCALSTORAGE_ITEMS, TYPES, TYPES as TYPE} from "../../util/Constants";
import ClockingTable from "../../components/ClockingTable";
import Select from "react-select";
import {getClockInOutHandler} from "../../handlers/clockingRecordHandlers/HandlerClockingRecordsAll";
import {getUserAgent, resetTime, selectStyles} from "../../util/common";
import * as StorageService from "../../services/StorageServices";
import ClockingProblems from "./ClockingProblems";
import {getAllReportedProblemsByCompanyIdHandler} from "../../handlers/reportedProblemsHandlers/HandlerReportedProblem";
import {LocaleContext} from "../../util/LocaleContext";
import analyticsLogEvent from "../../services/FirebaseService";


function ClockingRecordsAll(props) {
    const {companyId} = useParams();
    const type = StorageServices.getItem(LOCALSTORAGE_ITEMS.TYPE);
    const openedRightPanelStyleName = "open";
    const closedRightPanelStyleName = "closed";
    const localeContext = useContext(LocaleContext);
    const today = resetTime(new Date());
    const [state, setState] = useState({
        loading: true,
        calendarIsVisible: false,
        name: "",
        employeeDetailsId: null,
        selectedEmployeeDetails: null,
        rightPanelStyleName: closedRightPanelStyleName,
        selectedEmployee: null,
        searchedEmployee: null,
        allLocations: null,
        selectedLocation: null,
        allProblems: null,
        hours: null,
        clockingInfo: null,
        sortedBy: null,
        hiddenRows: null,
        selectedDate: today
    });


    useEffect(() => {
        analyticsLogEvent("page_view", {
            page_title: "LiveTrackerAll"

        });
        getPageContent();
    }, [state.selectedDate]);

    const getPageContent = () => {
        getClockInOutHandler(state.selectedDate, new Date(state.selectedDate.getTime() + 24 * 60 * 60 * 1000), companyId).then(clockinOutResponse => {

            getAllLocationsForCompanyHandler(companyId).then(allLocationsResponse => {
                getAllReportedProblemsByCompanyIdHandler(companyId, state.selectedDate, new Date(state.selectedDate.getTime() + 24 * 60 * 60 * 1000)).then(allProblemsResponse => {
                    const clockingInfo = clockinOutResponse.data.clockingInfo;
                    allProblemsResponse?.data?.allProblems.forEach(prob => {
                        const existentEmployee = clockingInfo.find(e => e.employeeId === prob.employee.id);
                        if (!!!existentEmployee) {
                            clockingInfo.push({
                                ...prob.employee,
                                "employeeId": prob.employee.id,
                                "clockingLocations": []
                            });
                        }
                    });

                    const callback = () => {
                        setState({
                            ...state,
                            loading: false,
                            name: clockinOutResponse.data.companyName,
                            hours: clockinOutResponse.data.hours,
                            clockingInfo: clockingInfo,
                            allLocations: allLocationsResponse.data.locations,
                            allProblems: allProblemsResponse?.data?.allProblems
                        })
                    };

                    if (type !== TYPES.SUPER_ADMIN) {
                        localeContext.setLocale(StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE), callback);
                    }else{
                        callback();
                    }


                })
            })
        });
    }

    const getAvailableLocations = () => {

        const locs = state.allLocations.map(employee => {
            return {
                label: employee.name,
                value: employee.id
            }
        });
        locs.unshift({
            label: <FormattedMessage id="All" defaultMessage="All" />,
            value: null
        });
        return locs;
    }


    const editEmployee = (accountId) => {
        setState({
            ...state,
            employeeDetailsId: accountId,
            selectedEmployeeDetails: state.clockingInfo.find(clockingInfo => clockingInfo.employeeId === accountId),
            rightPanelStyleName: openedRightPanelStyleName,

        });
    }

    const closeRightPanel = () => {
        setState({
            ...state,
            rightPanelStyleName: closedRightPanelStyleName,
            employeeDetailsId: '',
            selectedEmployeeDetails: null

        });
    }

    const onSelectedRowId = (rowId) => {
        editEmployee(rowId);
    };


    const handleOnChange = (e, input) => {

        if (input === "searchEmployee") {
            setState({
                ...state,
                searchedEmployee: e.target.value
            })
        }

        if (input === "location") {
            setState({
                ...state,
                selectedLocation: e !== null && e.value !== null ? {
                    label: e.label,
                    value: e.value
                } : null
            })
        }

    }


    const getFilteredClockingInfo = () => {

        const clockingInfosFilteredByName = state.clockingInfo.filter(e => state.searchedEmployee == null || state.searchedEmployee.length == 0 || ((e.firstName ? e.firstName : "") + (e.lastName ? e.lastName : "")).toLowerCase().indexOf(state.searchedEmployee.toLowerCase()) >= 0);
        const clockingInfosFinalFiltered = clockingInfosFilteredByName.map(e => {
            return {
                ...e,
                clockingLocations: e.clockingLocations?.filter(e => state.selectedLocation == null || e.id.includes(state.selectedLocation.value))
            }
        });//.filter(e => e.clockingLocations.length > 0);
        
        if (state.sortedBy) {
            if (state.sortedBy === "name_asc") {
                return clockingInfosFinalFiltered.sort((a, b) => (a.firstName + "" + a.lastName > b.firstName + "" + b.lastName) ? 1 : ((b.firstName + "" + b.lastName > a.firstName + "" + a.lastName) ? -1 : 0));
            } else if (state.sortedBy === "name_desc") {
                return clockingInfosFinalFiltered.sort((a, b) => (b.firstName + "" + b.lastName > a.firstName + "" + a.lastName) ? 1 : ((a.firstName + "" + a.lastName > b.firstName + "" + b.lastName) ? -1 : 0));
            } else if (state.sortedBy === "location_asc") {
                return clockingInfosFinalFiltered.sort((a, b) => (a.clockingLocations[0]?.name > b.clockingLocations[0]?.name) ? 1 : ((b.clockingLocations[0]?.name > a.clockingLocations[0]?.name) ? -1 : 0));
            } else if (state.sortedBy === "location_desc") {
                return clockingInfosFinalFiltered.sort((a, b) => (b.clockingLocations[0]?.name > a.clockingLocations[0]?.name) ? 1 : ((a.clockingLocations[0]?.name > b.clockingLocations[0]?.name) ? -1 : 0));
            }
        } else {
            return clockingInfosFinalFiltered;
        }

    }

    const setSortedBy = (type) => {
        setState({
            ...state,
            sortedBy: type
        })
    }

    const handleDateChange = () => {
        setState({
            ...state,
            calendarIsVisible: true
        })
    }

    const closeCalendar = () => {
        setState({
            ...state,
            calendarIsVisible: false
        })
    }

    const handleCalendarPick = (e) => {
        setState({
            ...state,
            selectedDate: e,
            calendarIsVisible: false,
            loading: true
        })
    }


    return state.loading ?
        <Spinner/>
        : (
            <div className="content">
                <Header
                    companyId={companyId}
                    hasBackBtn={type === TYPES.SUPER_ADMIN}
                    headerTitle={type === TYPES.SUPER_ADMIN ? state.name :
                        <FormattedMessage id="StaffLog" defaultMessage="StaffLog"/>}
                />
                <div className="inner-content inner-content-for-table">
                    {props.hasTabNavigation && type !== TYPE.ADMIN &&
                    <TabNavigation
                        companyId={companyId}
                        activeTab={type === TYPE.SUPER_ADMIN ? 5 : 0}
                    />
                    }
                    {type === TYPE.ADMIN && <div className="empty-div" style={{height: "25px"}}></div>}

                    <ClockingTable
                        from={state.selectedDate}
                        to={new Date(state.selectedDate.getTime() + 24 * 60 * 60 * 1000)}
                        hours={state.hours}
                        clockingInfo={getFilteredClockingInfo()}
                        setSortedBy={setSortedBy}
                        sortedBy={state.sortedBy}
                        allProblems={state.allProblems}
                        onSelectedRowId={onSelectedRowId}
                        employeeDetailsId={state.employeeDetailsId}
                        topActions={
                            <section className="row top-actions-row">
                                <div className="form-group">
                                    <div className="row">
                                        <label className="label">
                                            <FormattedMessage id="SearchEmployee" defaultMessage="SearchEmployee"/>
                                        </label>

                                    </div>
                                    <div className="row">
                                        <FormattedMessage id="Search" defaultMessage="Search">
                                            {placeholder =>
                                                <input
                                                    className="input"
                                                    placeholder={placeholder}
                                                    onChange={e => handleOnChange(e, "searchEmployee")}

                                                />
                                            }
                                        </FormattedMessage>
                                    </div>
                                </div>


                                <div className="form-group select-form-group">
                                    <div className="row">
                                        <label className="label">
                                            <FormattedMessage id="Location"/>
                                        </label>

                                    </div>
                                    <div className="row">
                                        <FormattedMessage id="Select" defaultMessage="Select">
                                            {placeholder =>
                                                <Select
                                                    className="select"
                                                    options={getAvailableLocations()}
                                                    defaultValue={{"label": <FormattedMessage id="All" defaultMessage="All" />, "value": null}}
                                                    onChange={e => handleOnChange(e, "location")}
                                                    placeholder={placeholder}
                                                    noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                                    id="location"
                                                    styles={selectStyles}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                />
                                            }
                                        </FormattedMessage>
                                    </div>
                                </div>

                                <div className="form-group calendar-form-group">
                                    <div className="row centered-x">
                                        <label className="label">
                                            <FormattedMessage id="Date"/>
                                        </label>
                                    </div>
                                    <div className="row">
                                        <a onClick={handleDateChange} className="link calendar-link">
                                            {state.selectedDate.getDate() === today.getDate() ?
                                                <FormattedMessage id="Today" defaultMessage="Today"/>
                                                :
                                                <FormattedDate
                                                    value={state.selectedDate}
                                                    day="numeric"
                                                    month="short"
                                                    year="numeric"
                                                    timeZone={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                                                    locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                                                />
                                            }
                                        </a>
                                    </div>
                                </div>

                            </section>

                        }/>
                </div>
                <RightPanel
                    isOpened={state.rightPanelStyleName}
                    closeRightPanel={closeRightPanel}
                    title={<FormattedMessage id="ClockingDetails" defaultMessage="ClockingDetails"/>}
                    children={
                        state.employeeDetailsId && <ClockingProblems
                            companyId={companyId}
                            employeeDetailsId={state.employeeDetailsId}
                            selectedEmployeeDetails={state.selectedEmployeeDetails}
                            closeRightPanel={closeRightPanel}
                            from={state.selectedDate}
                            to={new Date(state.selectedDate.getTime() + 24 * 60 * 60 * 1000)}
                            type={type}
                        />

                    }
                />
                {state.calendarIsVisible &&
                <>

                    <div onClick={closeCalendar}
                         className={!state.calendarIsVisible ? "blurry-background hidden" : "blurry-background"}></div>
                    <div className="calendar-container">
                        <CalendarTmz
                            onChange={handleCalendarPick}
                            showNavigation={true}
                            locale={StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE)}
                            className={"custom-calendar"}
                            defaultValue={state.selectedDate}
                            tz={StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE)}
                        />
                    </div>
                </>
                }

            </div>
        );
}

export default ClockingRecordsAll;
