import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import 'react-calendar/dist/Calendar.css';
import {
    createNFCHandler, editNFCHandler, getAllNFCLocationsHandler, getNFCByIdHandler
} from "../../../handlers/companiesHandlers/nfcHandlers/HandlerNFCDetails";
import Select from "react-select";
import * as StorageService from "../../../services/StorageServices";
import {LOCALSTORAGE_ITEMS, TYPES} from "../../../util/Constants";
import {getUserAgent, selectStyles} from "../../../util/common";
import analyticsLogEvent from "../../../services/FirebaseService";


function NfcDetails(props) {

    const openedRightPanelStyleName = "open";
    const closedRightPanelStyleName = "closed";

    const defaultFormFields = {
        id:"",
        name: "",
        selectedCompany: null,
        location: null,
        placement: "",
    };


    const [state, setState] = useState({
        rightPanelStyleName: closedRightPanelStyleName,
        errorMsg: null,
        availableLocations: [],
        ...defaultFormFields

    });

    useEffect(() => {
        analyticsLogEvent("page_view", {
            page_title: "NfcDetails"
        });
        getFormData();
    }, [props.nfcDetailsId]);



    const handleOnChange = (e, input) => {
        if (input === "id") {
            if (props.nfcDetailsId === "") {
                setState({
                    ...state,
                    id: e.target.value,
                    errorMsg: null,
                })
            }
        }
        else if (input === "name") {
            setState({
                ...state,
                name: e.target.value,
                errorMsg: null,
            })
        } else if (input === "location") {
            setState({
                ...state,
                location:  {
                    "id" : e !== null ? e.value : null,
                    "name" : e !== null ? e.label : null
                },
                errorMsg: null,
            })
        } else if (input === "placement") {
            setState({
                ...state,
                placement:   e !== null ? e.target.value : null,
                errorMsg: null,
            })
        }
    }



    const getFormData = () => {
        getAllNFCLocationsHandler(props.companyId).then(locationsResponse => {

            if (props.nfcDetailsId === "") { // IS NEW
                setState({
                    ...state,
                    name: "",
                    availableLocations: locationsResponse.data.locations.map(location=> location),
                    location: "",
                    placement: "",
                    enabled: null,
                    rightPanelStyleName: openedRightPanelStyleName,
                    errorMsg: null
                })
            } else {  //is EDIT
                getNFCByIdHandler(props.nfcDetailsId).then(response => {
                    const location = locationsResponse.data.locations?.filter(location => location.places?.map(place=> place.nfcId).includes(props.nfcDetailsId))[0];
                    setState({
                        ...state,
                        name: response.data.nfc.name,
                        availableLocations: locationsResponse.data.locations,
                        location: location,
                        placement: location?.places?.filter(place => place.nfcId === props.nfcDetailsId)[0].name,
                        enabled: response.data.nfc.enabled,
                        rightPanelStyleName: openedRightPanelStyleName,
                        errorMsg: null
                    })
                })
            }
        })
    }


    const bodyNewNfc = {
        "companyId": state.location=== "" ? null :props.companyId,
        "id": state.id.trim(),
        "name": state.name,
        "location": state.location?.id,
        "placement": state.placement,

    }

    const bodyEditNfc = {
        "companyId": props.companyId ? props.companyId : null,
        "name": state.name,
        "location": state.location?.id,
        "placement": state.placement,
    }

    const createNewNFC = () => {

        if (validateData()) {
            createNFCHandler(bodyNewNfc).then(response => {
                if (response.data.error == null) {
                    getAllNFCLocationsHandler(props.companyId).then(locationsResponse => {
                        setState({
                            ...state,
                            ...defaultFormFields,
                            availableLocations: locationsResponse.data.locations.map(location=> location),
                            rightPanelStyleName: openedRightPanelStyleName,
                            errorMsg: null
                        })

                    })
                    props.closeRightPanel(true);
                }else{
                    setState({
                        ...state,
                        errorMsg: response.data.error
                    })
                }
            })
        }
    }

    const editExistingNFC = () => {
        if (validateData()) {
            editNFCHandler(props.nfcDetailsId, bodyEditNfc).then(response => {
                if (response.data.error == null) {
                    setState({
                        ...state,
                        errorMsg: null
                    })
                    props.closeRightPanel(true);

                }
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (props.nfcDetailsId === "") {
            createNewNFC();
        } else {
            editExistingNFC();
        }
    }



    const validateData = () => {
        if (StorageService.getItem(LOCALSTORAGE_ITEMS.TYPE) ===  TYPES.SUPER_ADMIN) {
            if (!!!state.name) {
                setState({
                    ...state,
                    errorMsg: <FormattedMessage id="PleaseFillInAllFields" defaultMessage="PleaseFillInAllFields"/>
                })
                return false;
            }

            if (state.location && !!!state.placement) {
                setState({
                    ...state,
                    errorMsg: <FormattedMessage id="PleaseFillInAllFields" defaultMessage="PleaseFillInAllFields"/>
                })
                return false;
            }
        }else{
            if (!!!state.name || !!!state.location || !!!state.placement) {
                setState({
                    ...state,
                    errorMsg: <FormattedMessage id="PleaseFillInAllFields" defaultMessage="PleaseFillInAllFields"/>
                })
                return false;
            }
        }

        setState({
            ...state,
            errorMsg: null
        })
        return true;
    }


    return (

        <section className="form-container">
            <div className="details-form">

                <div className="form-group">
                    <div className="row">
                        <label className="label" htmlFor="id"> <FormattedMessage id="Id" defaultMessage="Id"/></label>
                    </div>
                    <div className="row">
                        <FormattedMessage id="Id" defaultMessage="Id">
                            {placeholder =>
                                <input
                                    className={props.nfcDetailsId === "" ? "input" : "input disabled"}
                                    type="text"
                                    onChange={e => handleOnChange(e, "id")}
                                    readOnly={props.nfcDetailsId === "" ? false : true}
                                    maxLength={80}
                                    placeholder={placeholder}
                                    autoComplete="off"
                                    id="id"
                                    value={props.nfcDetailsId !== "" ? props.nfcDetailsId : state.id}
                                />
                            }
                        </FormattedMessage>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <label className="label" htmlFor="name"> <FormattedMessage id="Name" defaultMessage="Name"/></label>
                    </div>
                    <div className="row">
                        <FormattedMessage id="Name" defaultMessage="Name">
                            {placeholder =>
                                <input
                                    className={StorageService.getItem(LOCALSTORAGE_ITEMS.TYPE) !== TYPES.SUPER_ADMIN? "input disabled" : "input"}
                                    type="text"
                                    onChange={e => handleOnChange(e, "name")}
                                    readOnly={StorageService.getItem(LOCALSTORAGE_ITEMS.TYPE) !== TYPES.SUPER_ADMIN ? true : false}
                                    maxLength={80}
                                    placeholder={placeholder}
                                    autoComplete="off"
                                    id="name"
                                    value={state.name}
                                />
                            }
                        </FormattedMessage>
                    </div>
                </div>


                <div className="form-group">
                    <div className="row">
                        <label className="label" htmlFor="location"> <FormattedMessage id="Location"
                                                                                   defaultMessage="Location"/></label>
                    </div>
                    <div className="row">
                        <FormattedMessage id="Location" defaultMessage="Location">
                            {placeholder =>
                                <Select
                                    className="select"
                                    options={state.availableLocations?.map(availableLocation => {
                                        return {label: availableLocation.name, value: availableLocation.id}
                                    })}
                                    value={{label: state.location?.name, value: state.location?.id}}
                                    onChange={e => handleOnChange(e, "location")}
                                    placeholder={placeholder}
                                    noOptionsMessage={() => <FormattedMessage id="NoOptionsAvailable"/>}
                                    id="location"
                                    styles={selectStyles}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                            }
                        </FormattedMessage>
                    </div>
                </div>


                <div className="form-group">
                    <div className="row">
                        <label className="label" htmlFor="placement"> <FormattedMessage id="NFCPlacement" defaultMessage="NFCPlacement"/></label>
                    </div>
                    <div className="row">
                        <FormattedMessage id="NFCPlacement" defaultMessage="NFCPlacement">
                            {placeholder =>
                                <input
                                    className="input"
                                    type="text"
                                    onChange={e => handleOnChange(e, "placement")}
                                    maxLength={80}
                                    placeholder={placeholder}
                                    autoComplete="off"
                                    id="placement"
                                    value={state.placement ? state.placement : ""}
                                />
                            }
                        </FormattedMessage>
                    </div>
                </div>

                <div className="row buttons-container">
                    <button onClick={() => props.closeRightPanel()}  className="btn btn-default"><FormattedMessage
                        id="Cancel" defaultMessage="Cancel"/></button>
                    <button onClick={(e) => handleSubmit(e)} className="btn btn-success"><FormattedMessage
                        id="Save" defaultMessage="Save"/></button>
                </div>

                {state.errorMsg && <div className="error">&#9888;&nbsp;{state.errorMsg}</div>}

            </div>
        </section>

    );
}

export default NfcDetails;
