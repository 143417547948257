import React from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Header from "../../components/Header";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend, Cell, PieChart,Pie
} from "recharts";

function Statistics(props) {
    const { providerId } = useParams();

    const data = [
        {
            name: "Andrei Cocis",
            location: "GEBS Cluj-Napoca",
            hoursWorked: 140,
            nfcCount: 5,
            manualCount: 47,
            totalWorkHours: 160,
            hoursWasted: 0
        },
        {
            name: "Florin Pasca",
            location: "Cora Cluj-Napoca",
            hoursWorked: 161,
            nfcCount: 11,
            manualCount: 3,
            totalWorkHours: 160,
            hoursWasted: 2
        },
        {
            name: "Andrei Pop",
            location: "Cora Ilfov",
            hoursWorked: 163,
            nfcCount: 42,
            manualCount: 13,
            totalWorkHours: 160,
            hoursWasted: 3

        },
        {
            name: "Maria Ionescu",
            location: "Cora Cluj-Napoca",
            hoursWorked: 175,
            nfcCount: 45,
            manualCount: 43,
            totalWorkHours: 175,
            hoursWasted: 1
        },
        {
            name: "Ioan Radu",
            location: "Cora Cluj-Napoca",
            hoursWorked: 147,
            nfcCount: 38,
            manualCount: 37,
            totalWorkHours: 146,
            hoursWasted: 0
        },
        {
            name: "Elena Vasile",
            location: "Cora Sibiu",
            hoursWorked: 189,
            nfcCount: 47,
            manualCount: 48,
            totalWorkHours: 180,
            hoursWasted: 1
        },
        {
            name: "Mihai G.",
            hoursWorked: 152,
            location: "Cora Cluj-Napoca",
            nfcCount: 40,
            manualCount: 38,
            totalWorkHours: 152,
            hoursWasted: 0
        },
        {
            name: "Ana Stanescu",
            location: "Cora Titan",
            hoursWorked: 168,
            nfcCount: 44,
            manualCount: 45,
            totalWorkHours: 168,
            hoursWasted: 0
        },
    ];





    function calculateOvertimeHoursForIndividual(clockingInfoLog) {
        if (clockingInfoLog.hoursWorked > clockingInfoLog.totalWorkHours) {
            const overtimeHours = clockingInfoLog.hoursWorked - clockingInfoLog.totalWorkHours;
            return overtimeHours;
        } else {
            return 0;
        }
    }


    const overtimeData = data.map(clockingInfoLog => ({
        name: clockingInfoLog.name,
        overtimeHours: calculateOvertimeHoursForIndividual(clockingInfoLog)
    }));

    const locationOvertimeData = data.reduce((result, current) => {
        const location = current.location;
        const overtimeHours = calculateOvertimeHoursForIndividual(current);

        if (!result[location]) {
            result[location] = 0;
        }
        result[location] += overtimeHours;
        return result;
    }, {});

    const locationOvertimeChartData = Object.keys(locationOvertimeData).map((location) => ({
        location,
        overtimeHours: locationOvertimeData[location],
    }));


    const locationHoursWastedData = data.reduce((result, current) => {
        const location = current.location;
        const hoursWasted = current.hoursWasted;

        if (!result[location]) {
            result[location] = 0;
        }

        result[location] += hoursWasted;
        return result;
    }, {});

    const locationHoursWastedChartData = Object.keys(locationHoursWastedData).map((location) => ({
        location,
        hoursWasted: locationHoursWastedData[location],
    }));

    const PieChartColours = ["#ffa757", "#8c91db", "#677294", "#8c91db", "#007cd6", "#ff9e40", "#6273cc", "#4f5a7f", "#746fb0", "#0093e0"];
    const legendCSSConfig = {
        legendWrapperStyle: {
            fontWeight: "600", // Make legend text bold
            fontSize: "16px",  // Set the font size for the legend text
        },
    };
    return (
        <div className="content">
            <Header
                companyId={providerId}
                hasBackBtn={false}
                headerTitle={<FormattedMessage id="Statistics" />}
            />
            <div className="inner-content">
                <div className="empty-div" style={{ height: "25px" }}></div>
                <section className="form-container">


                    <div>
                        <h1>Ore suplimentare per angajat (Aug. 2023)</h1><br/>
                        <BarChart width={1500} height={250} data={overtimeData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend wrapperStyle={legendCSSConfig.legendWrapperStyle} />

                            <Bar dataKey="overtimeHours" name="Ore suplimentare" fill="#8c91db" />
                        </BarChart>
                    </div>


                    <br/><br/>
                    <div>
                        <h1>Pontaje NFC si Pontaje Manuale per angajat (Aug. 2023)</h1><br/>

                        <BarChart width={1500} height={250} data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend wrapperStyle={legendCSSConfig.legendWrapperStyle} />
                            <Bar dataKey="nfcCount" name="Pontaje prin NFC" fill="#ffa757" />
                            <Bar dataKey="manualCount" name="Pontaje manuale" fill="#8c91db" />
                        </BarChart>
                    </div>

                    <br/><br/>
                    <div>
                        <h1>Întârzieri și plecări înainte de program (ore) (Aug. 2023)</h1><br/>
                        <BarChart width={1500} height={400} data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend wrapperStyle={legendCSSConfig.legendWrapperStyle} />
                            <Bar dataKey="hoursWasted" name="Întârzieri și plecări înainte de program (ore)" fill="#8c91db" />
                        </BarChart>
                    </div>


                    <br/><br/>
                    <div>
                        <h1>Întârzieri și plecări înainte de program (ore) per locație (Aug. 2023)</h1><br />
                        <PieChart width={800} height={400}>
                            <Tooltip />
                            <Legend wrapperStyle={legendCSSConfig.legendWrapperStyle} />
                            <Pie
                                data={locationHoursWastedChartData}
                                dataKey="hoursWasted"
                                nameKey="location"
                                cx="50%"
                                cy="50%"
                                outerRadius={120}
                                fill="#82ca9d"
                                label
                            >
                                {locationHoursWastedChartData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={PieChartColours [index % PieChartColours .length]} />
                                ))}
                            </Pie>
                        </PieChart>
                    </div>


                    <br/><br/>
                    <div>
                        <h1>Ore suplimentare în funcție de locație (Aug. 2023)</h1><br />
                        <PieChart width={800} height={400}>
                            <Tooltip />
                            <Legend wrapperStyle={legendCSSConfig.legendWrapperStyle} />
                            <Pie
                                data={locationOvertimeChartData}
                                dataKey="overtimeHours"
                                nameKey="location"
                                cx="50%"
                                cy="50%"
                                outerRadius={120}
                                fill="#8884d8"
                                label
                            >
                                {locationOvertimeChartData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={PieChartColours [index % PieChartColours .length]} />
                                ))}
                            </Pie>
                        </PieChart>
                    </div>


                </section>
            </div>
        </div>
    );
}


export default Statistics;

