import * as ClientConfig from "../../config/ClientConfig.js";
import * as StorageService from "../StorageServices.js";
import * as RestClient from "../../util/api/restClient"
import {LOCALSTORAGE_ITEMS} from "../../util/Constants";
import {handleAuthEmptyResponse, handleAuthFileResponse, handleAuthResponse} from "../../util/api/restClient";



const getAllCompanyWorkTimeIntervalsFromCompany =  async (companyId) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/company-work-time-intervals/company/" + companyId;
        return fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            }
        });
    });
}



const getCompanyWorkTimeIntervalsById =  async (id) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/company-work-time-intervals/" + id;

        return fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            }
        });
    });
}


const createCompanyWorkTimeIntervals =  async (body) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/company-work-time-intervals";
        const response = fetch(finalURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN),
            },
            body: JSON.stringify(body),
        });
        return response;
    });
}


const editCompanyWorkTimeIntervalsById =  async (id, body) => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/company-work-time-intervals/" + id;
        const response = fetch(finalURL, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
            body: JSON.stringify(body)
        });
        return response;
    });
}



const deleteCompanyWorkTimeIntervalsById =  async (id) => {
    return handleAuthEmptyResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/company-work-time-intervals/" + id;
        const response = fetch(finalURL, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
        });
        return response;
    });
}

const updateScheduleNotifications =  async (body) => {
    return handleAuthEmptyResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/company-employees/updateScheduleNotifications";
        const response = fetch(finalURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN)
            },
            body: JSON.stringify(body)
        });
        return response;
    });
}


export {
    getAllCompanyWorkTimeIntervalsFromCompany,
    getCompanyWorkTimeIntervalsById,
    createCompanyWorkTimeIntervals,
    editCompanyWorkTimeIntervalsById,
    deleteCompanyWorkTimeIntervalsById,
    updateScheduleNotifications

}
