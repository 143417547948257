import React, {useState} from "react";
import closeIcon from "../assets/img/icon_close.svg";
import backImg from "../assets/img/icon_back.svg";


function RightPanel(props) {

    const dot = <svg className="dot" width="3" height="3" viewBox="0 0 3 3" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="1.5" cy="1.5" r="1.5" fill="#4D5062"/>
    </svg>

    return (
        <div className={"right-panel " + props.isOpened}>
            <div className="right-panel-header">
                <div className="row">
                    {props.hasBackBtn &&
                    <a className="btn-back only-for-small-screens" onClick={(e) => props.closeRightPanel()}>
                        <img src={backImg}/>
                    </a>
                    }
                    <h1>{props.title}</h1>
                    <a className="btn-close only-for-desktop" onClick={(e) => props.closeRightPanel()}>
                        <img src={closeIcon}/>
                    </a>

                    {props.accountDetailsId!=="" && props.hasHamburgerMenu && <div className="hamburger-container only-for-small-screens">
                        <div onClick={props.openHamburgerMenu} className="hamburger-dots">{dot}{dot}{dot}</div>
                        {props.isHamburgerMenuVisible &&<div className="hamburger-content higher-index">
                            <a onClick={props.hamburgerLinkAction1}>{props.hamburgerLinkName1}</a>
                        </div>}
                    </div>}

                </div>


            </div>
            {props.children}
        </div>


    );

}

export default RightPanel;
