
import {
    getAllEmployeesFromCompany
} from "../../services/companiesServices/CompaniesEmployeesService";

import {getAllRolesFromCompany} from "../../services/companiesServices/CompaniesRolesService";

import {
    getAllEmployeeLeaves,
    getAllEmployeeLeaveTypes
} from "../../services/vacationPlannerServices/LeavesService";



export const getAllEmployeeLeavesByCompanyIdHandler = async (companyId, locationsIds, rolesIds, from, to, createdFrom, createdTo, leaveStatus, page, size, sort) => {
    let error = null;
    const allEmployeesLeavesResponse = await getAllEmployeeLeaves(companyId, locationsIds, rolesIds, from, to, createdFrom, createdTo, leaveStatus, page, size, sort);


    let companyAndEmployeesResponse = null;

    if (allEmployeesLeavesResponse.ok) {

        companyAndEmployeesResponse = {

            allEmployeesLeavesResponse: allEmployeesLeavesResponse.data,
            employees: allEmployeesLeavesResponse.data.content.map(employeeLeave => {
                return {
                    id: employeeLeave.employee.id,
                    roles: employeeLeave.employee.roles || [],
                    name: employeeLeave.employee.firstName + " " + employeeLeave.employee.lastName,
                    firstName: employeeLeave.employee.firstName,
                    lastName: employeeLeave.employee.lastName,
                    clockingLocations: employeeLeave.employee.clockingLocations || [],
                    type: employeeLeave.employee.type,
                    phone: employeeLeave.employee.phone,
                    workSchedule: employeeLeave.employee.workSchedule || {},
                    companyId: employeeLeave.employee.companyId,
                    leaveId: employeeLeave.id,
                    leaveType: employeeLeave.type,
                    leaveStatus: employeeLeave.status,
                    leaveFrom: employeeLeave.from,
                    leaveTo: employeeLeave.to,
                    leaveTimePeriod: `${new Date(employeeLeave.from).toLocaleDateString()} - ${new Date(employeeLeave.to).toLocaleDateString()}`,
                    leaveRequest: employeeLeave.request,
                    leaveResponse: employeeLeave.response,
                    leaveCreated: employeeLeave.created,
                    leaveCreatedBy: employeeLeave.createdBy,
                    leaveModified: employeeLeave.modified,
                    leaveModifiedBy: employeeLeave.modifiedBy,
                    employee: employeeLeave.employee
                };
            })
        };

        companyAndEmployeesResponse.employees.sort(function (a, b) {
            return (b.leaveCreated).localeCompare(a.leaveCreated);
        });

    } else {
        error = "Error getAllEmployeeLeavesByCompanyIdHandler";
    }

    return {
        data: {
            error: error,
            allCompanyAndEmployees: companyAndEmployeesResponse
        }
    };
};



export const getAllEmployeesByCompanyIdHandler = async (companyId) => {
    let error = null;
    const allEmployeesResponse = await getAllEmployeesFromCompany(companyId);
    const rolesResponse = await getAllRolesFromCompany(companyId);

    let companyAndEmployeesResponse = null;

    if (allEmployeesResponse.ok) {

        companyAndEmployeesResponse = {

            allRoles: rolesResponse.data,
            employees: allEmployeesResponse.data.map(employee => {
                return {
                    id: employee.id,
                    roles: employee.roles,
                    phone: employee.phone,
                    name:  employee.firstName + " " + employee.lastName,
                    firstName: employee.firstName,
                    lastName: employee.lastName,
                    clockingLocations: employee.clockingLocations,
                    email: employee.email,
                    managedRoles: employee.managedRoles,
                    managedLocations: employee.managedLocations,
                    type: employee.type,
                    created:  employee.created
                }
            })
        }

        companyAndEmployeesResponse.employees.sort(function(a, b){return (a.name).localeCompare(b.name)});

    } else {
        error ="Error getAllEmployeesByFacilityIdHandler";
    }
    return {
        data: {
            error: error,
            allCompanyAndEmployees: companyAndEmployeesResponse
        }
    };
}

export const getAllEmployeeLeavesTypesHandler = async (country) => {
    let error = null;

    const response = await getAllEmployeeLeaveTypes(country);
    let allLeavesTypes = null;
    if (response.ok) {

        allLeavesTypes = response.data;

    } else {
        error ="Error getAllEmployeeLeavesTypesHandler";
    }
    return {
        data: {
            error: error,
            allLeavesTypes: allLeavesTypes
        }
    };

}




export const deleteRequestHandler = async (feid) => {
    // const response = await FacilitiesEmployeesService.deleteEmployeeById(feid);
     const response = {ok:true};
    let error = null;
    if (response.ok) {

    } else {
        error ="Error deleteFacilityEmployeeHandler";
    }

    return {
        data: {
            error: error
        }
    };
}




