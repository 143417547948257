import { Route, Routes} from "react-router";
import React, {useState, useEffect} from "react";
import {HashRouter} from "react-router-dom";
import {IntlProvider} from "react-intl";
import localeData from "./i18n/data.json";
import * as StorageService from "./services/StorageServices.js";
import {LOCALSTORAGE_ITEMS} from "./util/Constants";
import {PATH, routes} from "./routes/routes";
import {AuthContext} from "./util/AuthContext";
import Aside from "./components/Aside";
import {LocaleContext} from "./util/LocaleContext";
import {handleAppVersionUpdate} from "./util/common";
import {PendingLeavesProvider} from "./util/PendingLeavesContext";
import PrivateComponent from "./components/PrivateComponent";




const App = () => {

    useEffect(() => {
        handleAppVersionUpdate();
    }, []);

    let language =
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage;

    let languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
    if (localeData[languageWithoutRegionCode] === undefined) {
        languageWithoutRegionCode = localeData.defaultLang;
    }

    const [state, setState] = useState({
        isLoggedIn:StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN) !== null,
        locale: languageWithoutRegionCode,
        messages: localeData[languageWithoutRegionCode],
        accesToken: null
    });


    const getRoutes = (routes) => {
        return (
                routes.map((prop, key) => {
                    const Component = prop.component;

                    if (prop.path === PATH.LOGIN || prop.path === PATH.RESET_PASSWORD || prop.path === PATH.NEW_PASSWORD) {
                        return (
                            <Route
                                key={key}
                                path={prop.path}
                                element={<Component />}
                            />
                        );
                    }

                    return (
                        <Route
                            key={key}
                            path={prop.path}
                            element={
                                <PrivateComponent>
                                    <Aside />
                                    <Component hasTabNavigation={true} />
                                </PrivateComponent>
                            }
                        />
                    );
                })
        );
    };

    const setIsLoggedIn = (isLoggedIn, callback) => {
        setState({
            ...state,
            isLoggedIn:isLoggedIn
        });
        callback();
    }


    const authContextValue = {
        isLoggedIn:state.isLoggedIn,
        setIsLoggedIn: setIsLoggedIn
    }

    const setLocale = (locale, callback) => {
        const localeFinal = (typeof localeData[locale] === "undefined") ? languageWithoutRegionCode : locale;
        if(localeFinal && state.locale !== localeFinal) {
            setState({
                ...state,
                locale: localeFinal,
                messages: localeData[localeFinal]
            });
        }
        callback();
    }


    const localeContextValue = {
        locale:state.locale,
        messages: state.messages,
        setLocale: setLocale
    }

    return (
        <PendingLeavesProvider>
        <LocaleContext.Provider value={localeContextValue}>
            <IntlProvider locale={state.locale} messages={state.messages}>
                <AuthContext.Provider value={authContextValue}>
                    <HashRouter>
                        <main className="wrapper">
                            <Routes>
                                {getRoutes(routes)}
                            </Routes>
                        </main>
                    </HashRouter>
                </AuthContext.Provider>
            </IntlProvider>
        </LocaleContext.Provider>
        </PendingLeavesProvider>
    )

}


export default App;
