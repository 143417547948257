import * as ClientConfig from "../config/ClientConfig";
import * as StorageService from "./StorageServices";
import {LOCALSTORAGE_ITEMS} from "../util/Constants";
import {handleAuthEmptyResponse, handleAuthResponse, handleResponse} from "../util/api/restClient";


const login = async (username, password) => {
    return handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/login"
        return fetch(finalURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "username": username,
                "password": password
            })
        });
    });
}

const getUserMe = () => {
    return handleAuthResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/me"
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN),
            }
        });
        return response;
    });
}

const requestVerificationCode = (phone) => {
    return handleAuthEmptyResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/reset-password-code"
        const response = fetch(finalURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN),
            },
            body: JSON.stringify({
                "phone": phone
            })
        });
        return response;
    });
}

const resetPassword = (phone, validCode, newPassword, newPasswordConfirmation) => {
    return handleAuthEmptyResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api/reset-password"
        const response = fetch(finalURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN),
            },
            body: JSON.stringify({
                "phone": phone,
                "validCode": validCode,
                "newPassword": newPassword,
                "newPasswordConfirmation": newPasswordConfirmation
            })
        });
        return response;
    });
}


const deleteAccount = () => {
    return handleAuthEmptyResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "/api"
        const response = fetch(finalURL, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+StorageService.getItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN),
            },
        });
        return response;
    });
}


export {
    login,
    getUserMe,
    requestVerificationCode,
    resetPassword,
    deleteAccount

}


